<template>
    <div id="app" :class="appClass">
        <under-construction v-if="showUnderConstruction"></under-construction>
        <error-box></error-box>
        <nav-bar></nav-bar>
        <div class="wrapper content-wrapper clearfix">
            <router-view></router-view>
        </div>

        <page-footer></page-footer>
        <scroll-top-button class="no-print"></scroll-top-button>
    </div>
</template>

<script>
import NavBar from './layout/NavBar.vue';
import PageFooter from './layout/Footer.vue';
import ErrorBox from './components/ErrorBox.vue';
import ScrollTopButton from './components/ScrollTopButton.vue';
import {
    PAGINATION_DEFAULTS,
    BASE_URL,
    ALTERNATE_LANGUAGES,
    IS_UNDER_CONSTRUCTION,
} from './config.js';
import UnderConstruction from './components/UnderConstruction.vue';
import { removeTrailingSlashFromUrl } from './lib/removeTrailingSlash';

export default {
    name: 'app',
    components: {
        NavBar,
        PageFooter,
        ErrorBox,
        ScrollTopButton,
        UnderConstruction,
    },
    computed: {
        appClass() {
            if (this.$route.name) {
                return this.$route.name.replace('.', '-');
            } else {
                return '';
            }
        },
    },
    data: function () {
        return {
            indexStatus: '',
            showUnderConstruction: false,
        };
    },
    methods: {
        noindexWhenNotDefaultPerPage() {
            /*
             *  sets robots indexing to true if there is no pagination or perPage is the same as PAGINATION_DEFAULTS.PER_PAGE
             *  otherwise we're on a non-default page size, set meta robots to noindex
             */
            let perPage = parseInt(this.$route.params.perPage);
            this.indexStatus =
                isNaN(perPage) || PAGINATION_DEFAULTS.PER_PAGE == perPage
                    ? 'index,follow'
                    : 'noindex,follow';
        },
    },
    mounted: function () {
        this.noindexWhenNotDefaultPerPage();

        if (
            IS_UNDER_CONSTRUCTION &&
            sessionStorage.getItem('uc_log_in') !== 'true'
        ) {
            this.showUnderConstruction = true;
        }
    },
    watch: {
        $route() {
            this.noindexWhenNotDefaultPerPage();
        },
    },
    metaInfo() {
        //Define what languages shall appear as Alt Links. /  href = language code in URL / hreflang = language code used as hreflang param in link tag
        var alternateLanguages = ALTERNATE_LANGUAGES;

        var links = [];

        //Generate link tag for each alternative Language
        alternateLanguages.forEach((element) => {
            //Replace language parameter from requests full path
            var requestPathwithoutLang = this.$route.fullPath.replace(
                /^(\/[a-z]{2})/g,
                '/' + element.href
            );

            //Concat new URL
            var url = BASE_URL + requestPathwithoutLang;
            links.push({
                rel: 'alternate',
                href: url,
                hreflang: element.hreflang,
            });
        });

        links.push({
            rel: 'canonical',
            href: removeTrailingSlashFromUrl(BASE_URL + this.$route.fullPath),
        });

        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Sixbid',
            // all titles will be injected into this template
            titleTemplate: this.$t('meta_title_template'),
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            meta: [
                { charset: 'utf-8' },
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1',
                },
                {
                    name: 'robots',
                    content: this.indexStatus,
                },
                {
                    'http-equiv': 'X-UA-Compatible',
                    content: 'IE=edge',
                },
                {},
            ],
            link: links,
        };
    },
};
</script>
<style src="./assets/scss/main.scss" lang="scss"></style>
