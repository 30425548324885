var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction-more"},[_c('h1',[_vm._v(_vm._s(_vm.auction.auctionName))]),_c('breadcrumb',[_c('router-link',{attrs:{"to":{
                name: 'page.auctionLotList',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_vm._v(_vm._s(_vm.auction.auctionName)+":\n            "+_vm._s(_vm._f("striphtml")(_vm._f("removeBR")(_vm.auction.auctionDescription))))]),_vm._v("\n        / "+_vm._s(_vm.$t('results'))+"\n    ")],1),_c('auction-tabs',{attrs:{"auctionId":_vm.$route.params.auctionId,"auction":_vm.auction}}),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-12"},[_c('h2',[_vm._v(_vm._s(_vm.$t('results')))]),_vm._l((_vm.results),function(result){return _c('div',{key:result.lotId,staticClass:"result"},[_c('router-link',{attrs:{"to":{
                            name: 'page.auctionLotDetail',
                            params: {
                                companySlug: result.companySlug,
                                auctionId: result.auctionId,
                                categorySlug: result.categorySlug,
                                lotId: result.lotId,
                                lotSlug: result.lotSlug,
                            },
                        }}},[_vm._v(_vm._s(result.lotNumber)+_vm._s(result.lotNumberAffix))]),_vm._v(":\n                    "),_c('price',{attrs:{"value":result.lotPriceRealised,"currency":result.auctionCurrency}})],1)})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }