import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import CompanyList from './pages/CompanyList';
import CompanyDetail from './pages/CompanyDetail';
import AuctionCalendar from './pages/AuctionCalendar';
import AuctionLotList from './pages/AuctionLotList';
import AuctionLotDetail from './pages/AuctionLotDetail';
import AuctionInformation from './pages/AuctionInformation';
import AuctionConditions from './pages/AuctionConditions';
import AuctionLivebidding from './pages/AuctionLivebidding';
import AuctionMore from './pages/AuctionMore';
import AuctionResults from './pages/AuctionResults';
import LotList from './pages/LotList';
import AuctionListPast from './pages/AuctionListPast';
import CollectionArea from './pages/CollectionArea';
import Help from './pages/Help';
import HowToBuy from './pages/HowToBuy';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import TermsAndConditions from './pages/TermsAndConditions';
import Contact from './pages/Contact';
import AccountProfile from './pages/AccountProfile';
import AccountBids from './pages/AccountBids';
import AccountWatchlist from './pages/AccountWatchlist';
import AccountBuyNow from './pages/AccountBuyNow';
import AccountNotifications from './pages/AccountNotifications';
import BidSheet from './pages/BidSheet';
import News from './pages/News';
import BestCoinsAll from './pages/BestCoinsAll.vue';
import HighlightAll from './pages/HighlightAll.vue';
import HighlightSpecialAll from './pages/HighlightSpecialAll.vue';
import NotFound from './pages/NotFound.vue';
import Redirecting from './pages/Redirecting.vue';
import ArchiveAuctionLotDetail from './pages/ArchiveAuctionLotDetail.vue';

export default {
    Home,
    AboutUs,
    Help,
    HowToBuy,
    Imprint,
    Privacy,
    TermsAndConditions,
    Contact,
    CompanyList,
    CompanyDetail,
    CollectionArea,
    AuctionCalendar,
    AuctionListPast,
    AuctionLotList,
    AuctionLotDetail,
    AuctionInformation,
    AuctionConditions,
    AuctionLivebidding,
    AuctionMore,
    AuctionResults,
    LotList,
    AccountProfile,
    AccountBids,
    AccountWatchlist,
    AccountBuyNow,
    AccountNotifications,
    BidSheet,
    News,
    BestCoinsAll,
    HighlightAll,
    HighlightSpecialAll,
    NotFound,
    Redirecting,
    ArchiveAuctionLotDetail
};
