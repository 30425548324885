/**
 *
 * @param {string} bucket_path
 * @param {string} size
 * @returns {string}
 */
export default function(bucket_path /* googleBucketImagePath */, size = 'M') {
    let cloudImageUrl = 'https://image-cdn.sixbid.com/v7/lots';

    let cloudImagePreset = 'lot_' + size.toLowerCase();

    return cloudImageUrl + '/' + bucket_path + '?p=' + cloudImagePreset;
}
