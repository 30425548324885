<template>
    <div class="auction-more">
        <auction-banner :companyId="auction.companyId"></auction-banner>
        <p class="style-like-h1">{{ auction.auctionName }}</p>
        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
                >{{ auction.auctionName }}:
                {{
                    auction.auctionDescription | removeBR | striphtml
                }}</router-link
            >
            / {{ $t('company_auctions_more') }}
        </breadcrumb>
        <auction-tabs
            :auctionId="$route.params.auctionId"
            :auction="auction"
        ></auction-tabs>
        <div class="grid">
            <div class="row">
                <div class="col col-lg-12">
                    <h1 class="style-like-h2">
                        {{ $t('company_auctions_more') }}
                    </h1>
                    <auction-list
                        :hideRightSide="true"
                        :hasTabs="true"
                        :companySlug="$route.params.companySlug"
                    ></auction-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs';
import AuctionBanner from '../components/AuctionBanner';
import AuctionList from '../components/AuctionList';
import Breadcrumb from '../components/Breadcrumb';
import { PAGINATION_DEFAULTS } from '../config.js';
import { getAuctionDetails } from '../lib/apis';

export default {
    name: 'Auction_Conditions_Page',
    components: {
        AuctionTabs,
        AuctionBanner,
        AuctionList,
        Breadcrumb,
    },
    data: function () {
        return {
            company: {},
            auction: {},
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    created: function () {
        getAuctionDetails(
            null,
            this.$route.params.companySlug,
            this.$route.params.auctionId,
            this.$route.params.lang
        )
            .then((response) => {
                this.auction = response.data;

                if (
                    this.$route.params.companySlug != this.auction.companySlug
                ) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
    },
};
</script>

<style scoped></style>
