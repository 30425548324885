import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        countriesWithPreferred: [],
        countries: [],
        topCountries: [],
        states: [],
    },
    getters: {
        getCountries(state) {
            return state.countries;
        },
        getTopCountries(state) {
            return state.topCountries;
        },
        getCountriesWithPreferred(state) {
            return state.countriesWithPreferred;
        },
        getStates(state) {
            return state.states;
        }
    },
    mutations: {
        SET_COUNTRIES_WITH_PREFERRED(state) {
            let sortedTopCountries = state.topCountries.sort((a, b) => {
                return a['name'].localeCompare(b['name']);
            });

            state.countriesWithPreferred = [].concat(
                sortedTopCountries,
                [{ code: '', name: '----------' }],
                _.differenceWith(state.countries, sortedTopCountries, _.isEqual)
            );
        },
        SET_COUNTRIES(state, payload) {
            const countries = Object.keys(payload).map(key => {
                return {
                    code: key,
                    name: payload[key]
                }
            })
            state.countries = [...countries];
        },
        SET_TOP_COUNTRIES(state, payload) {
            const topCountries = Object.keys(payload).map(key => {
                return {
                    code: key,
                    name: payload[key]
                }
            })
            state.topCountries = [...topCountries];
        },
        SET_STATES(state, payload) {
            const states = Object.keys(payload).map(key => {
                return {
                    code: key,
                    name: payload[key]
                }
            })
            state.states = [...states];
        }
    },
    actions: {
        setCountries({ commit }, payload) {
            commit('SET_COUNTRIES', payload);
        },
        setTopCountries({ commit }, payload) {
            commit('SET_TOP_COUNTRIES', payload);
        },
        setStates({ commit }, payload) {
            commit('SET_STATES', payload);
        },
        setCountriesWithPreferred({commit}) {
            commit('SET_COUNTRIES_WITH_PREFERRED');
        }
    },
};
