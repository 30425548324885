<template>
    <div class="auction-lot-list-page">
        <auction-banner
            :companyId="company.companyId"
            :auctionId="auction.auctionId"
        ></auction-banner>
        <breadcrumb>
            {{ auction.auctionName }}:
            <span>{{ auction.auctionDescription | removeBR | striphtml }}</span>
        </breadcrumb>
        <auction-tabs
            v-if="auction.auctionId"
            :auctionId="auction.auctionId"
            :auction="auction"
            :showSorting="$global.isMobile"
            @sorting="viewShown = !viewShown"
        ></auction-tabs>

        <div class="row">
            <div class="col col-md-4 col-lg-3 lot-list-sidebar-container">
                <div class="lot-list-sidebar">
                    <sidebar-box
                        class="sidebarsection sidebarsection-first auction-info"
                    >
                        <a
                            v-if="auction.auctionHasNGC"
                            class="ngc"
                            @click="overlayShown = true"
                            >{{ $t('grading_ngc') }}</a
                        >
                        <div
                            v-if="auction.auctionHasNGC"
                            class="clearfix ngcClearfix"
                        ></div>
                        <h3 class="sidebar-box-headline auction-headline">
                            {{ auction.auctionName }}
                        </h3>

                        <div
                            v-if="
                                auction.auctionLocationName ||
                                auction.auctionLocationStreet ||
                                auction.auctionLocationZip ||
                                auction.auctionLocationCity ||
                                auction.auctionLocationCountry
                            "
                        >
                            <p>
                                {{ auction.auctionLocationCity
                                }}<span
                                    v-if="
                                        auction.auctionLocationCity &&
                                        auction.auctionLocationCountry
                                    "
                                    >,</span
                                >
                                {{ auction.auctionLocationCountry }}
                            </p>
                            <p v-if="auction.auctionLocationAdditionalText">
                                {{ auction.auctionLocationAdditionalText }}
                            </p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="auctionDate">
                            {{ date }}
                        </div>
                        <div class="clearfix"></div>

                        <div class="auctionLivebiddingButtonWrapper">
                            <a
                                v-if="
                                    auction.auctionLiveBiddingLink &&
                                    !liveBiddingActive
                                "
                                class="button button-dark-grey"
                                :href="auction.auctionLiveBiddingLink"
                                target="_blank"
                            >
                                {{ $t('livebidding_register') }}
                            </a>
                            <router-link
                                class="button button-blue color-white"
                                :class="{
                                    active:
                                        $route.name ==
                                        'page.auctionLivebidding',
                                }"
                                v-if="liveBiddingActive"
                                :to="{
                                    name: 'page.auctionLivebidding',
                                    params: {
                                        companySlug: auction.companySlug,
                                        auctionId: auction.auctionId,
                                    },
                                    query: this.$route.query,
                                }"
                            >
                                <span>{{ $t('livebidding.sixbid') }}</span>
                            </router-link>
                        </div>

                        <div
                            v-if="auction.auctionLiveBiddingLink"
                            class="clearfix"
                        ></div>
                        <div class="auctionOnlineBiddingEndTitle">
                            {{ $t('bid_end') }}:
                        </div>
                        <div
                            v-for="session in sessions"
                            :key="session.sessionId"
                        >
                            <div class="auctionOnlineBiddingEnd">
                                <span class="auctionOnlineBiddingEndLabel"
                                    >{{ $t('part') }}
                                    {{ session.sessionLabel }}
                                    <span class="light"
                                        >({{ session.minLotId }} -
                                        {{ session.maxLotId }}):</span
                                    ></span
                                >
                                <span class="auctionOnlineBiddingEndDate">
                                    <date-timezone v-if="session.sessionEnds" :date="session.sessionEnds" />
                                </span>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                    </sidebar-box>

                    <sidebar-box
                        :headline="$t('auction_search')"
                        v-if="viewShown || !$global.isMobile"
                    >
                        <span
                            class="closeButton"
                            href="#"
                            v-if="$global.isMobile"
                            v-on:click.prevent="viewShown = false"
                            ><i class="fas fa-times"></i
                        ></span>
                        <form v-on:submit.prevent="submitSearch()" class="form">
                            <div class="group">
                                <input
                                    type="text"
                                    :placeholder="$t('search_term_lot')"
                                    name="term"
                                    id="term"
                                    v-model="lotSearchForm.term"
                                />
                            </div>
                            <div class="group price">
                                <div class="priceFromBox">
                                    <div>
                                        <input
                                            type="text"
                                            name="priceFrom"
                                            id="priceFrom"
                                            v-model.number="
                                                lotSearchForm.priceFrom
                                            "
                                            :placeholder="$t('lot_price_more')"
                                        />
                                    </div>
                                    <strong class="currency-addon">{{
                                        auction.auctionCurrency
                                    }}</strong>
                                </div>
                            </div>
                            <div class="form-submit">
                                <input
                                    type="submit"
                                    class="
                                        button button-blue
                                        search-form-submit
                                    "
                                    :value="$t('search_start')"
                                />
                            </div>
                        </form>
                    </sidebar-box>

                    <a
                        v-if="auction.auctionLiveBiddingLink"
                        class="button button-dark-grey hidden-above-md"
                        :href="auction.auctionLiveBiddingLink"
                        target="_blank"
                    >
                        {{ $t('livebidding_register')
                        }}<i class="fas fa-external-link-alt fa-sm"></i>
                    </a>

                    <a
                        class="viewOptionsToggle"
                        href="#"
                        v-if="$global.isMobile"
                        v-on:click.prevent="
                            showViewOptions = !showViewOptions;
                            showCategoryOptions = false;
                        "
                        >{{ $t('view_adjust') }}</a
                    >

                    <a
                        class="categoryOptionsToggle"
                        href="#"
                        v-if="$global.isMobile"
                        v-on:click.prevent="toggleCategories()"
                        >{{ $t('category_choose') }}</a
                    >

                    <div v-if="$global.isMobile" class="clearfix"></div>

                    <sidebar-box
                        class="viewOptionsBox"
                        :headline="$t('view_adjust')"
                        v-if="
                            !$global.isMobile ||
                            ($global.isMobile && showViewOptions)
                        "
                    >
                        <span
                            class="closeButton"
                            href="#"
                            v-if="$global.isMobile"
                            v-on:click.prevent="showViewOptions = false"
                            ><i class="fas fa-times"></i
                        ></span>
                        <div class="form">
                            <div class="group sort">
                                <span>
                                    <label for>{{ $t('sort_by') }}:</label>
                                </span>
                                <span>
                                    <select
                                        v-model="lotListSortOn"
                                        @change="changeSorting"
                                    >
                                        <option value="lot_number">
                                            {{ $t('lot_nr') }}
                                        </option>
                                        <option value="starting_price">
                                            {{ $t('lot_estimate') }}
                                        </option>
                                    </select>
                                    <a
                                        class="icon"
                                        :class="{
                                            active: lotListSortOrder === 'asc',
                                        }"
                                        v-on:click="
                                            lotListSortOrder = 'asc';
                                            handleChange(true);
                                            scrollToTop();
                                        "
                                    >
                                        <i class="fas fa-long-arrow-alt-up"></i>
                                    </a>
                                    <a
                                        class="icon"
                                        :class="{
                                            active: lotListSortOrder === 'desc',
                                        }"
                                        v-on:click="
                                            lotListSortOrder = 'desc';
                                            handleChange(true);
                                            scrollToTop();
                                        "
                                    >
                                        <i
                                            class="fas fa-long-arrow-alt-down"
                                        ></i>
                                    </a>
                                </span>
                            </div>
                            <div class="group results hidden-below-md">
                                <span>
                                    <label for
                                        >{{ $t('lots_per_page') }}:</label
                                    >
                                </span>
                                <span>
                                    <select
                                        v-model.number="lotListResultsPerPage"
                                        @change="
                                            handleChange(true);
                                            scrollToTop();
                                        "
                                    >
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </span>
                            </div>
                            <div class="group view hidden-below-md">
                                <span>
                                    <label>{{ $t('view') }}:</label>
                                </span>
                                <span>
                                    <a
                                        class="icon hidden-below-lg"
                                        :class="{
                                            active:
                                                lotListDisplayMode === 'small',
                                        }"
                                        v-on:click="
                                            lotListDisplayMode = 'small';
                                            handleChange();
                                            scrollToTop();
                                        "
                                    >
                                        <i class="fas fa-th"></i>
                                    </a>
                                    <a
                                        class="icon"
                                        :class="{
                                            active:
                                                lotListDisplayMode === 'large',
                                        }"
                                        v-on:click="
                                            lotListDisplayMode = 'large';
                                            handleChange();
                                            scrollToTop();
                                        "
                                    >
                                        <i class="fas fa-th-large"></i>
                                    </a>
                                    <a
                                        class="icon"
                                        :class="{
                                            active:
                                                lotListDisplayMode === 'detail',
                                        }"
                                        v-on:click="
                                            lotListDisplayMode = 'detail';
                                            handleChange();
                                            scrollToTop();
                                        "
                                    >
                                        <i class="fas fa-th-list"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </sidebar-box>

                    <div
                        class="sidebar-box-container categoryOptions"
                        :class="{
                            stick: sidebarIsSticky,
                            nonstick: !sidebarIsSticky,
                        }"
                    >
                        <sidebar-box
                            class="categoryOptionsBox"
                            ref="allSessions"
                            v-show="
                                !$global.isMobile ||
                                ($global.isMobile && showCategoryOptions)
                            "
                        >
                            <div class="stickyToggleContainer">
                                <a
                                    class="stickyToggle"
                                    href="#"
                                    v-if="!sidebarIsSticky"
                                    v-on:click.prevent="toggleStickySidebar()"
                                    >{{ $t('pin') }}</a
                                >
                                <a
                                    class="stickyToggle"
                                    href="#"
                                    v-else
                                    v-on:click.prevent="toggleStickySidebar()"
                                    >{{ $t('unpin') }}</a
                                >
                            </div>
                            <span
                                class="closeButton categoryCloseButton"
                                href="#"
                                v-if="$global.isMobile"
                                v-on:click.prevent="toggleCategories()"
                                ><i class="fas fa-times"></i
                            ></span>
                            <div class="sessionlist-scroll">
                                <h3
                                    class="
                                        sidebar-box-headline
                                        sidebar-box-headline--link
                                    "
                                    @click.prevent="
                                        deselectAll();
                                        handleChange(true);
                                        scrollToTop();
                                        showCategoryOptions = false;
                                    "
                                >
                                    {{ $t('lot_show_all') }} ({{ totalLots }})
                                </h3>
                                <div class="session-wrapper">
                                    <div
                                        class="sessions form"
                                        v-for="session in sessions"
                                        :key="session.sessionId"
                                    >
                                        <div
                                            class="session-part"
                                            ref="SessionPart"
                                        >
                                            <p
                                                v-if="Object.keys(sessions).length &gt; 1"
                                                class="session-part-headline"
                                                @click="
                                                    toggleVisibilty(
                                                        session.sessionId
                                                    )
                                                "
                                            >
                                                {{ $t('part') }}
                                                {{ session.sessionLabel }}
                                                <i
                                                    v-if="session.show"
                                                    class="fas fa-chevron-up"
                                                />
                                                <i
                                                    v-else
                                                    class="fas fa-chevron-down"
                                                />
                                            </p>
                                            <transition name="slide">
                                                <div
                                                    v-if="session.show"
                                                    class="session-part-scroll"
                                                >
                                                    <div
                                                        class="
                                                            session-part-filter
                                                        "
                                                        v-for="category in session.sessionCategories"
                                                        :key="
                                                            category.categoryId
                                                        "
                                                    >
                                                        <a
                                                            class="
                                                                session-filter
                                                            "
                                                            :class="{
                                                                'active session-filter--disabled':
                                                                    lotListQuery.auctionSessions.split(
                                                                        '|'
                                                                    )[1] ==
                                                                    category.categoryId,
                                                            }"
                                                            @click.prevent="
                                                                deselectAll();
                                                                changeSession(
                                                                    session.sessionId,
                                                                    category.categoryId
                                                                );
                                                                scrollToElement(
                                                                    '.auction-info'
                                                                );
                                                                showCategoryOptions = false;
                                                            "
                                                            >{{
                                                                category.categoryName
                                                            }}
                                                            ({{
                                                                category.lotCount
                                                            }})</a
                                                        >
                                                    </div>
                                                </div>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </sidebar-box>
                    </div>
                </div>
            </div>
            <div class="col col-md-8 col-lg-9">
                <div class="lot-list-modes hidden-below-md">
                    <span>
                        <label>{{ $t('view') }}:</label>
                    </span>
                    <span>
                        <a
                            class="icon hidden-below-lg"
                            :class="{ active: lotListDisplayMode === 'small' }"
                            v-on:click="
                                lotListDisplayMode = 'small';
                                handleChange();
                                scrollToTop();
                            "
                        >
                            <i class="fas fa-th"></i>
                        </a>
                        <a
                            class="icon"
                            :class="{ active: lotListDisplayMode === 'large' }"
                            v-on:click="
                                lotListDisplayMode = 'large';
                                handleChange();
                                scrollToTop();
                            "
                        >
                            <i class="fas fa-th-large"></i>
                        </a>
                        <a
                            class="icon"
                            :class="{ active: lotListDisplayMode === 'detail' }"
                            v-on:click="
                                lotListDisplayMode = 'detail';
                                handleChange();
                                scrollToTop();
                            "
                        >
                            <i class="fas fa-th-list"></i>
                        </a>
                    </span>
                </div>
                <lot-list-component
                    v-if="company.companySlug && auction.auctionId"
                    :company="company"
                    :auctionId="auction.auctionId"
                    :searchQuery="lotListQuery"
                    :sortOn="lotListSortOn"
                    :sortOrder="lotListSortOrder"
                    :resultsPerPage="lotListResultsPerPage"
                    :displayMode="lotListDisplayMode"
                    :showEstimatePrice="false"
                    :showResultPrice="
                        parseInt(auction.auctionPrAvailable, 10) == 1
                    "
                    :showBidding="parseInt(auction.auctionPrAvailable, 10) == 0"
                    @pagination-clicked="scrollToTop()"
                />
            </div>
        </div>
        <content-overlay :shown="overlayShown" @closing="overlayShown = false">
            <div v-html="ngcContent"></div>
        </content-overlay>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs.vue';
import SidebarBox from '../components/SidebarBox.vue';
import LotListComponent from '../components/LotListComponent.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import ContentOverlay from '../components/ContentOverlay.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import DateTimezone from '../components/DateTimezone.vue';

import { dateRange } from '@/filters/date';
import { getAuexAuctionLiveBiddingStatusCode } from '../lib/apis';

let Stickyfill = require('./../../node_modules/stickyfilljs/dist/stickyfill.js');

import {
    getCompanyDetails,
    getAuctionDetails,
    getSessions,
    getNgcContent,
} from '../lib/apis';
import { PAGINATION_DEFAULTS, SESSION_FILTER_OPENED_COUNT } from '../config.js';

export default {
    name: 'Auction_Lot_List_Page',
    metaInfo() {
        return {
            titleTemplate:
                this.company.companyName + ' | ' + this.auction.auctionId,
        };
    },
    components: {
        AuctionTabs,
        SidebarBox,
        LotListComponent,
        Breadcrumb,
        ContentOverlay,
        AuctionBanner,
        DateTimezone,
    },
    data: function () {
        return {
            company: {},
            auction: {},
            sessions: [],
            visibility: {},
            show: false,
            showViewOptions: false,
            showCategoryOptions: false,
            sidebarIsSticky: false,
            overlayShown: false,
            ngcContent: '',
            // Lot List Component Options
            lotListQuery: {
                auctionSessions: '',
            },
            lotListSortOn: 'lot_number',
            lotListSortOrder: 'asc',
            lotListResultsPerPage: PAGINATION_DEFAULTS.PER_PAGE,
            lotListDisplayMode: 'large',
            lotSearchForm: {
                priceFrom: null,
                priceTo: null,
                term: null,
            },
            viewShown: false,
            auctionStatusLocal: {
                found: false,
                status: 0,
            },
        };
    },
    mounted: function () {
        if (this.$route.query.term) {
            this.lotSearchForm.term = this.$route.query.term;
        }
        if (this.$route.query.priceFrom) {
            this.lotSearchForm.priceFrom = this.$route.query.priceFrom;
        }
        if (this.$route.query.orderCol) {
            this.lotListSortOn = this.$route.query.orderCol;
        }
        if (this.$route.query.orderDirection) {
            this.lotListSortOrder = this.$route.query.orderDirection;
        }
        if (this.$route.query.displayMode) {
            this.lotListDisplayMode = this.$route.query.displayMode;
        }
        if (this.$route.query.auctionSessions) {
            this.lotListQuery.auctionSessions =
                this.$route.query.auctionSessions;
        }
        if (this.$route.query.limit) {
            this.lotListResultsPerPage = Number(this.$route.query.limit);
        }
        if (
            this.$route.query.sidebarIsSticky == 'false' ||
            typeof this.$route.query.sidebarIsSticky === 'undefined'
        ) {
            this.sidebarIsSticky = false;
        } else {
            this.sidebarIsSticky = true;
        }
        this.searchFor();
        this.handleChange();
        var sidebar = document.querySelectorAll('.categoryOptions');
        Stickyfill.add(sidebar);
        this.$nextTick(() => {
            Stickyfill.refreshAll();
        });

        // setup periodic polling of livebidding status
        setInterval(
            function () {
                this.getLivebiddingStatus();
            }.bind(this),
            30000
        );
    },
    computed: {
        icsAPI() {
            return `${process.env.VUE_APP_GLOBAL_API_URL}`;
        },
        date() {
            return dateRange(
                this.auction.auctionStart,
                this.auction.auctionEnd
            );
        },
        totalLots() {
            let count = 0;
            for (let sessionKey in this.sessions) {
                count += this.sessions[sessionKey].sessionLotCount;
            }
            return count;
        },
        liveBiddingActive() {
            if (typeof this.auctionStatus == 'undefined') {
                return (
                    this.auctionStatusLocal.found &&
                    this.auctionStatusLocal.status > 0
                );
            } else {
                return this.auctionStatus.found;
            }
        },
    },
    created: function () {
        this.overlayShown =
            Object.keys(this.$route.query).indexOf('ngc') !== -1;

        Promise.all([
            getCompanyDetails(
                this.$route.params.companySlug,
                this.$route.params.lang
            ),
            getAuctionDetails(
                null,
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
            getSessions(
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
        ])
            .then(([company, auction, sessions]) => {
                this.company = company.data;
                this.auction = auction.data;
                this.sessions = sessions.data;

                if (this.company.companySlug != this.auction.companySlug) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }

                if (this.auction.auctionHasNGC) {
                    getNgcContent(this.$route.params.auctionId).then(
                        (response) => {
                            this.ngcContent = response.data;
                        }
                    );
                }

                Object.keys(this.sessions).forEach((key, index) => {
                    index < SESSION_FILTER_OPENED_COUNT
                        ? (this.sessions[key].show = true)
                        : (this.sessions[key].show = false);
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
        /* eslint-disable-next-line no-console */
        //console.log(this.$route.params.lang)
    },
    methods: {
        deselectAll() {
            const checkboxes =
                this.$refs.allSessions.$el.querySelectorAll(
                    '[type="checkbox"]'
                );
            Array.prototype.forEach.call(checkboxes, (checkbox) => {
                checkbox.checked = false;
            });
            this.resetSession();
            this.resetFilter();
        },
        handleChange(forceDefaults) {
            this.pushNewURL(
                {
                    displayMode: this.lotListDisplayMode,
                    term: this.lotSearchForm.term
                        ? this.lotSearchForm.term
                        : null,
                    priceFrom: this.lotSearchForm.priceFrom,
                    perPage: this.lotListResultsPerPage,
                    orderCol: this.lotListSortOn,
                    orderDirection: this.lotListSortOrder,
                    auctionSessions: this.lotListQuery.auctionSessions,
                    sidebarIsSticky: this.sidebarIsSticky
                        ? this.sidebarIsSticky
                        : false,
                },
                forceDefaults
            );
            /* eslint-disable-next-line no-console */
            //console.log("handleChange Route Query", this.$route.query);
        },
        toggleVisibilty(sessionId) {
            const sessionItem = { ...this.sessions[sessionId] };
            sessionItem.show = !sessionItem.show;
            this.$set(this.sessions, sessionId, sessionItem);
        },
        submitSearch() {
            this.setFilter();
            this.handleChange(true);
            this.scrollToTop();
        },
        searchFor() {
            this.lotListQuery = {
                term: this.lotSearchForm.term,
                priceFrom: this.lotSearchForm.priceFrom,
                auctionSessions: this.lotListQuery.auctionSessions,
            };
        },
        setFilter: function () {
            this.searchFor();
        },
        resetFilter: function () {
            /* eslint-disable-next-line no-console */
            //console.log("resetFilter");
            this.lotSearchForm = {
                priceFrom: null,
                priceTo: null,
            };
            this.lotListQuery = {
                auctionSessions: this.lotListQuery.auctionSessions,
            };
            /* eslint-disable-next-line no-console */
            //console.log("resetFilter Route Query", this.$route.query);
        },
        resetSession() {
            const query = { ...this.lotListQuery };
            query.auctionSessions = '';
            this.lotListQuery = query;
            /* eslint-disable-next-line no-console */
            //console.log("resetSession Route Query", this.$route.query);
        },
        changeSession: function (sessionId, categoryId) {
            const query = { ...this.lotListQuery };

            query.auctionSessions = sessionId + '|' + categoryId;

            this.lotListQuery = query;
            this.handleChange(true);
        },
        changeSorting() {
            if (this.lotListSortOn === 'starting_price') {
                this.lotListSortOrder = 'desc';
            }
            if (this.lotListSortOn === 'lot_number') {
                this.lotListSortOrder = 'asc';
            }

            this.handleChange(true);
            if (!this.$global.isMobile) {
                this.scrollToTop();
            }
        },
        scrollToElement(selector) {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = this.$el
                .querySelector(selector)
                .getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top - 100 - 35;

            this.scrollTo({ to: offset });
        },
        scrollToTop() {
            this.scrollToElement('.auction-tabs');
        },
        pushNewURL(
            {
                term,
                orderCol,
                orderDirection,
                priceFrom,
                displayMode,
                auctionSessions,
                page,
                perPage,
                sidebarIsSticky,
            },
            forceDefaults
        ) {
            let currentPage = PAGINATION_DEFAULTS.START_PAGE;
            if (!forceDefaults) {
                currentPage = page || this.$route.params.page;
            }
            let currentPerPage = PAGINATION_DEFAULTS.PER_PAGE;
            if (perPage) {
                currentPerPage = perPage || this.$route.params.perPage;
            }

            const historyObj = {
                path: '',
                params: {
                    page: currentPage,
                    perPage: currentPerPage,
                },
                query: {
                    ...this.$route.query,
                    term,
                    orderCol,
                    orderDirection,
                    priceFrom,
                    displayMode,
                    auctionSessions,
                    sidebarIsSticky,
                },
            };

            /* eslint-disable-next-line no-console */
            // console.log('pushNewURL', trigger, {term, orderCol, orderDirection, priceFrom, currency, displayMode});

            if (Object.keys(this.$route.query).length === 0) {
                this.$router.replace(historyObj);
            } else {
                this.$router.push(historyObj);
            }
        },
        waitForElement(selector) {
            return new Promise(function (resolve) {
                var element = document.querySelector(selector);

                if (element) {
                    resolve(element);
                    return;
                }

                var observer = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                        var nodes = Array.from(mutation.addedNodes);
                        for (var node of nodes) {
                            if (node.matches && node.matches(selector)) {
                                observer.disconnect();
                                resolve(node);
                                return;
                            }
                        }
                    });
                });

                observer.observe(document.documentElement, {
                    childList: true,
                    subtree: true,
                });
            });
        },
        toggleCategories() {
            this.showCategoryOptions = !this.showCategoryOptions;
            this.showViewOptions = false;

            this.waitForElement('.session-filter--disabled').then(function (
                element
            ) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            });
        },
        toggleStickySidebar() {
            this.sidebarIsSticky = !this.sidebarIsSticky;
            this.handleChange(true);
            this.$nextTick(() => {
                Stickyfill.refreshAll();
            });
        },
        getLivebiddingStatus: function () {
            if (
                this.auction.liveBiddingUrls &&
                this.auction.liveBiddingUrls.auex &&
                this.auction.auctionId
            ) {
                getAuexAuctionLiveBiddingStatusCode(
                    this.auction.liveBiddingUrls.auex,
                    this.auction.auctionId
                ).then((result) => {
                    this.auctionStatusLocal = result.data;
                });
            }
        },
    },
    watch: {
        '$global.isTablet'() {
            if (this.lotListDisplayMode === 'small') {
                this.lotListDisplayMode = 'medium';
            }
        },
        auction() {
            // request once up-front so we don't need to wait
            this.getLivebiddingStatus();
        },
        $route() {
            this.lotListResultsPerPage = Number(this.$route.params.perPage);
            this.lotListDisplayMode = this.$route.query.displayMode
                ? this.$route.query.displayMode
                : this.lotListDisplayMode;
            this.lotListSortOn = this.$route.query.orderCol
                ? this.$route.query.orderCol
                : this.lotListSortOn;
            this.lotListSortOrder = this.$route.query.orderDirection
                ? this.$route.query.orderDirection
                : this.lotListSortOrder;

            if (
                this.$route.query.sidebarIsSticky == 'false' ||
                typeof this.$route.query.sidebarIsSticky === 'undefined'
            ) {
                this.sidebarIsSticky = false;
            }
            if (this.$route.query.sidebarIsSticky == 'true') {
                this.sidebarIsSticky = true;
            }

            const lotSearchForm = { ...this.lotSearchForm };
            lotSearchForm.term = this.$route.query.term;
            lotSearchForm.priceFrom = this.$route.query.priceFrom;
            this.lotSearchForm = { ...lotSearchForm };

            const lotListQuery = { ...this.lotListQuery };
            lotListQuery.term = this.$route.query.term;
            lotListQuery.priceFrom = this.$route.query.priceFrom;
            lotListQuery.auctionSessions = this.$route.query.auctionSessions;
            this.lotListQuery = { ...lotListQuery };
        },
    },
};
</script>

<style scoped lang="scss">
.auctionLivebiddingButtonWrapper {
    margin-bottom: 10px;
}

a:not(.color-white) {
    text-decoration: underline;

    &:hover {
        color: black;
    }
}
a.active {
    font-weight: bold;
}
.sidebar-box {
    display: block;
}

.lot-list-sidebar {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    @media (min-width: $md-size) {
        &.nonstick {
            position: relative;
            top: auto;
        }
        &.stick {
            position: -webkit-sticky;
            position: sticky;
            top: 160px;
        }
    }
    /* max-height: calc(100vh - 154px);
    max-height: calc(100vh - 154px);
    overflow-y: scroll;*/
    @media (min-width: $md-size) {
        margin-top: 75px;
    }
    height: 100%;
}

.stickyToggle {
    display: none;
}

@media (min-width: $md-size) {
    .auction-headline {
        float: left;
        width: 100%;
    }

    .stickyToggle {
        margin-top: 5px;
        float: right;
        display: inline-block;
    }
}

.viewOptionsToggle,
.categoryOptionsToggle {
    display: inline-block;
    text-transform: uppercase;
    padding-top: 15px;
    text-decoration: none;
    line-height: 30px;
}
.viewOptionsToggle {
    float: left;
}
.categoryOptionsToggle {
    float: right;
}
.viewOptionsBox {
    // margin-top: 20px;
}
.categoryOptionsBox {
    height: 100%;

    @media (max-width: $md-size-max) {
        overflow-y: auto;
    }

    @media (max-width: $sm-size-max) {
        margin-top: 20px;
        max-height: 293px;
    }

    .sidebar-box-headline {
        width: calc(100% - 64px);
    }
}

.sidebar-box-container:not(.categoryOptions) {
    position: relative;
}

.categoryOptions {
    @media (min-width: $md-size) {
        &.nonstick {
            position: relative;
            top: auto;

            @media (min-width: $md-size) {
                .session-part-scroll {
                    max-height: 200px;
                }
            }
        }
        &.stick {
            top: 160px;
            position: -webkit-sticky;
            position: sticky;
            height: calc(100vh - 180px);

            .session-wrapper {
                height: calc(100vh - 260px);
                overflow-y: auto;
                margin-right: -15px;
                padding-right: 15px;
            }
        }
    }
}

.closeButton {
    float: right;
    cursor: pointer;
    padding: 10px;
    margin: -5px 20px;
}
.categoryCloseButton {
    position: absolute;
    right: $pad-lg;
}

a.button {
    width: 100%;
    text-decoration: none;
}

.auction-info {
    & > div:nth-child(2) {
        margin-top: $mar-md;
    }

    .auctionOnlineBiddingEndTitle {
        font-weight: bold;
        display: block;
    }

    & > div {
        margin-bottom: 15px;

        .auctionOnlineBiddingEndLabel {
            font-weight: bold;
            display: block;
            margin-bottom: $mar-sm;

            .light {
                font-weight: normal;
            }
        }

        & > p {
            margin-top: 5px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    @media (max-width: $sm-size-max) {
        padding-top: $pad-md;
        padding-bottom: $pad-md;

        & > div {
            width: auto;
            margin-bottom: 5px;
        }
        .auctionDate,
        .auctionICS,
        .auctionOnlineBiddingEndLabel,
        .auctionOnlineBiddingEndDate {
            display: inline-block;
        }
        .clearfix {
            margin-bottom: 0;
        }
        .auctionICS {
            margin-bottom: $mar-sm;
            max-width: 55%;
            margin-left: $mar-lg;
        }
        .auctionDate {
            max-width: 45%;
        }
        .auctionOnlineBiddingEnd {
            margin-bottom: 0;
        }
        .auctionOnlineBiddingEndLabel {
            max-width: 50%;
            float: left;
        }
        .auctionOnlineBiddingEndDate {
            max-width: 50%;
            margin-left: 10px;
        }
        .auctionOnlineBiddingEndTitle {
            width: 100%;
        }
    }
}

.sessions {
    padding: 0.5em 0;
    border-bottom: 1px solid $dark-grey-bg;

    label {
        line-height: 18px;
        margin: 2px 0;
        font-weight: normal;
    }
}

.session-part-headline {
    position: relative;
    font-weight: bold;
    cursor: pointer;

    i {
        position: absolute;
        right: 0;
    }
}
@media (min-width: $md-size) {
    .session-part-scroll {
        // max-height: 200px;
        overflow-y: auto;
    }
}

.sidebar-box-container.stick .session-part-scroll {
    max-height: initial;
}

.sidebar-box-container.stick .categoryOptionsBox {
    height: 100%;
    overflow-y: auto;
}

.session-filter {
    text-decoration: none;
    display: inline-block;
    padding: 2px 0;
    @media (max-width: $md-size-max) {
        padding: 8px 0;
        display: block;
    }
    color: black;
    font-size: 13px;

    &--disabled {
        /* active category */
        font-weight: bold;
    }
}

.group {
    margin-top: 10px;

    &:first-child {
        margin-top: 10px;
    }
}

label {
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    line-height: 30px;
}

select {
    margin-left: 5px;
    height: 30px;
    border-color: $grey-bg-light;
}

.price,
.emboss {
    .flex {
        input {
            width: 100%;
        }

        strong {
            margin: 5px 0 5px 10px;
        }

        .divider {
            margin: 5px 10px;
        }
    }
}

.price {
    @media (min-width: $md-size) {
        max-width: 290px;
    }
    .currency-addon,
    .placeholder-addon {
        position: absolute;
        top: 0.75em;
    }
    .currency-addon {
        right: 10px;
        font-size: 11px;
        color: #aaa;
    }
    .placeholder-addon {
        left: 6px;
        font-size: 11px;
        font-weight: normal;
        color: #999;
    }
    .priceFromBox {
        width: 100%;
        position: relative;
        input {
            text-align: left;
            padding-right: 37px;
        }
    }
}

.emboss {
    .flex {
        input {
            width: calc(100% - 75px);
        }

        select {
            width: 70px;
        }
    }
}

.view,
.sort,
.results {
    display: flex;

    & > span {
        width: 50%;
    }

    .icon {
        display: inline-block;
        color: $text-color;
        padding: 5px 2px;
        margin-left: 5px;

        i {
            margin-left: 0;
        }

        &.active {
            color: $main-color;
        }
    }
}

.sort {
    select {
        width: calc(100% - 40px);
    }
}

.results {
    select {
        width: 92%;
    }
}

.form-submit {
    margin-top: $mar-sm;

    a {
        margin: $mar-md $mar-md $mar-md 0;
        display: block;
    }

    & > * {
        margin-top: $mar-sm;
    }

    .search-form-submit {
        width: 100%;
    }
}
.lot-list-modes {
    text-align: right;
    .icon {
        display: inline-block;
        color: $text-color;
        padding: 5px 2px;
        margin-left: 5px;

        &.active {
            color: $main-color;
        }
    }
}

.ngc {
    display: inline-block;
    background: $ngc-color;
    padding: 3px 10px;
    color: white;
    cursor: pointer;
    text-decoration: none;
}
.ngc:hover {
    color: white;
}
.ngcClearfix {
    margin: 0;
}

.sidebar-box-headline {
    &--link {
        color: $main-color;
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
.content-wrapper {
    padding: 20px 15px 120px !important;
}

.auction-lot-list-page {
    .sidebar-box-headline {
        font-size: 14px;
    }
}
</style>
