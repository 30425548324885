<template>
    <div class="lot-search-component" :class="[size]" @click="openSearch()">
        <h2>{{ $t('lot_search') }}</h2>
        <p v-if="hasCompanyContext">
            {{ $t('search_lots_auctioneer') }}
            {{ companyName }}
        </p>
        <p v-else>
            {{ $t('search_lots') }}
        </p>
        <form
            v-on:submit.prevent="submitForm"
            class="form"
            @change="searchModified()"
        >
            <div class="searchterm">
                <input
                    type="text"
                    :placeholder="$t('search_terms')"
                    name="term"
                    id="term"
                    v-model="term"
                />
                <button
                    v-if="size !== 'small'"
                    type="submit"
                    class="button button-black"
                >
                    {{ $t('lot_search_start') }}
                </button>
            </div>

            <transition name="slide">
                <div v-if="isOpen">
                    <div v-if="!isReduced">
                        <div class="wrap">
                            <div class="prices">
                                <label for="priceFrom">{{
                                    $t('lot_price_more')
                                }}</label>
                                <input
                                    type="text"
                                    name="priceFrom"
                                    id="priceFrom"
                                    v-model="priceFrom"
                                    min="0"
                                    max="1000000"
                                    placeholder="1"
                                />
                                <select
                                    name="currency"
                                    id="currency"
                                    v-model="currency"
                                >
                                    <option value="EUR">EUR</option>
                                    <option value="GPB">GPB</option>
                                    <option value="CHF">CHF</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <button
                        v-if="size === 'small'"
                        type="submit"
                        class="button button-black button-for-small"
                    >
                        {{ $t('lot_search_start') }}
                    </button>
                    <button
                        v-if="!saved && showSaveButton && term"
                        type="submit"
                        class="button button-white button-for-small"
                        style="border: none"
                        @click="saveSearch(term)"
                    >
                        {{ $t('search_save') }}
                    </button>
                    <button
                        class="button button-for-small button-saved"
                        v-if="saved"
                        style="border: none"
                        disabled
                    >
                        <i class="fas fa-check"></i>
                        {{ $t('search_saved') }}
                    </button>
                    <div v-if="saveError" class="save-error">
                        {{ saveError }}
                    </div>
                </div>
            </transition>
        </form>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '../config';
import { postSearchToSearches } from '../lib/apis';

export default {
    name: 'LotSearchComponent',
    data: function () {
        return {
            term: '',
            currency: 'EUR',
            upcomingAuction: 0,
            pastAuction: 0,
            isOpen: false,
            saved: false,
            saveError: null,
            priceFrom: null,
        };
    },
    props: {
        companyName: {
            type: String,
            default: '',
        },
        showSaveButton: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '',
        },
        isFolded: {
            type: Boolean,
            default: false,
        },
        isReduced: {
            type: Boolean,
            default: false,
        },
        hasCompanyContext: {
            type: Boolean,
            default: false,
        },
    },
    mounted: function () {
        // trigger pending tasks
        let task = this.$store.getters['tasks/task'](this.$options.name); 
        if (
            typeof this.$route.query.runtask !== 'undefined' &&
            task.fn !== null &&
            typeof this[task.fn] === 'function'
        ) {
            this[task.fn].call(this, task.args, true);
            this.$route.query.term = task.args;
        }

        if (typeof this.$route.query.term != 'undefined') {
            this.term = this.$route.query.term;
        }
        if (typeof this.$route.query.priceFrom != 'undefined') {
            this.priceFrom = this.$route.query.priceFrom;
        }
        if (typeof this.$route.query.priceTo != 'undefined') {
            this.priceTo = this.$route.query.priceTo;
        }
        if (typeof this.$route.query.currency != 'undefined') {
            this.currency = this.$route.query.currency;
        }
        if (typeof this.$route.query.upcomingAuction != 'undefined') {
            this.upcomingAuction = this.$route.query.upcomingAuction;
        }
        if (typeof this.$route.query.pastAuction != 'undefined') {
            this.pastAuction = this.$route.query.pastAuction;
        }
        this.isOpen = !this.isFolded;
    },
    methods: {
        submitForm: function () {
            let newQuery = {};
            if (this.term) {
                newQuery.term = this.term;
            }
            if (this.priceFrom) {
                newQuery.priceFrom = this.priceFrom;
            }
            if (this.priceTo) {
                newQuery.priceTo = this.priceTo;
            }
            if (this.currency) {
                newQuery.currency = this.currency;
            }
            if (this.upcomingAuction) {
                newQuery.upcomingAuction = 1;
            }
            if (this.pastAuction) {
                newQuery.pastAuction = 1;
            }

            if (
                this.$route.name !== 'page.companyLots' &&
                this.$route.name !== 'page.lots'
            ) {
                if (
                    this.$route.params.companySlug &&
                    this.$props.hasCompanyContext
                ) {
                    this.$router.push({
                        name: 'page.companyLots',
                        params: {
                            companySlug: this.$route.params.companySlug,
                            perPage: PAGINATION_DEFAULTS.PER_PAGE,
                            page: PAGINATION_DEFAULTS.START_PAGE,
                        },
                        query: newQuery,
                    });
                } else {
                    this.$router.push({
                        name: 'page.lots',
                        params: {
                            perPage: PAGINATION_DEFAULTS.PER_PAGE,
                            page: PAGINATION_DEFAULTS.START_PAGE,
                        },
                        query: newQuery,
                    });
                }
            }
            this.$emit('query-change', newQuery);
        },
        openSearch() {
            this.isOpen = true;
        },

        saveSearch(searchQuery, preventStore) {
            this.saveError = null;
            const data = { searchQuery };

            // Store action to be executed after login
            if (typeof preventStore === 'undefined') {
                this.$store.commit('tasks/setTask', {
                    path: this.$route.path,
                    fn: 'saveSearch',
                    args: searchQuery,
                    component: this.$options.name,
                });
            }

            return postSearchToSearches(data)
                .then((response) => {
                    // remove action from store
                    this.$store.commit('tasks/unsetTask');
                    if (response.data.success) {
                        this.saved = true;
                        return response;
                    } else {
                        this.saveError = response.data.message;
                    }
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        searchModified() {
            this.saved = false;
        },
    },
    watch: {
        $route() {
            if (typeof this.$route.query.term != 'undefined') {
                this.term = this.$route.query.term;
            }
            if (typeof this.$route.query.priceFrom != 'undefined') {
                this.priceFrom = this.$route.query.priceFrom;
            }
            if (typeof this.$route.query.currency != 'undefined') {
                this.currency = this.$route.query.currency;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.lot-search-component {
    background-color: $main-color;
    color: white;
    padding: $pad-lg;
    position: relative;

    h2 {
        margin: 0;
    }
}

.wrap {
    margin-top: $mar-lg;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

#currency {
    margin-left: $mar-md;
}

.button-for-small {
    margin-top: 15px;
}

.searchterm {
    display: flex;

    .button {
        margin-left: 5%;
        max-width: 300px;
    }
}

.prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    label {
        min-width: 110px;
    }

    .to {
        margin: 0 $mar-sm;
        @media (min-width: $md-size) {
            margin: 0 $mar-lg;
        }
    }

    .years {
        min-width: 170px;
    }

    input {
        width: 80px;
        @media (min-width: $md-size) {
            width: 100px;
        }
    }
    select {
        width: 70px;
    }
}

.search-location {
    @media (min-width: $sm-size) {
        width: 425px;
    }

    & > span {
        width: 100%;

        @media (min-width: $sm-size) {
            width: auto;
        }
    }
}

.small {
    .prices {
        flex-basis: 100%;

        .to {
            margin: 0 $mar-sm;
        }

        input {
            width: 80px;
        }

        label {
            width: 100%;
            margin-bottom: $mar-sm;
        }

        & > * {
            margin-top: $mar-sm;
        }
    }

    .search-location {
        max-width: 100%;
        min-width: auto;

        & > span {
            width: 100%;
        }
    }
}

.medium {
    .prices,
    .issue {
        label {
            width: 100%;
            margin-bottom: $mar-md;
        }
    }
}

.save-search {
    color: white;

    i {
        margin-right: $mar-sm;
    }
}

.button-saved {
    color: #fff;
    background: transparent;
    cursor: default;
}

a.save-search {
    text-decoration: underline;
}

.sidebar .lot-search-component {
    margin-bottom: $mar-lg;
}

.save-error {
    color: red;
    padding: 3px;
    margin-top: 5px;
    text-align: center;
    background: rgba(255, 255, 255, 0.75);
}
</style>
