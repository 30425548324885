export const D = { day: 'numeric' };        // 1.
export const DD = { day: '2-digit' };       // 01.
export const Mon = { month: 'short' };      // Apr.
export const Month = { month: 'long' };    // April
export const M = { month: 'numeric' };      // 4.
export const MM = { month: '2-digit' };     // 04.
export const YYYY = { year: 'numeric' };    // 2021
export const HHMM = { hour: '2-digit', minute: '2-digit' };   // 12:01
export const MonD = { ...Mon, ...D };
export const YYYYMonthD = { ...YYYY, ...Month, ...D };
export const YYYYMMDD = { ...YYYY, ...MM, ...DD };
export const YYYYMon = { ...YYYY, ...Mon };
export const YYYYMonD = { ...YYYY, ...Mon, ...D };
export const YYYYMMDDHHMM = { ...YYYYMMDD, ...HHMM };
export const YYYYMonth = { ...YYYY, ...Month };
export const ISODATE = 'ISODATE'
export const CET = { timeZone: 'Europe/Berlin' };

function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

function getLastSunday(year, month) {
    let date = new Date(year, month, 0);
    date.setDate(date.getDate() - date.getDay());
    return date;
}

export const getDay = (lang, offset = 0, format = YYYYMMDD) => {
    const today = new Date();
    today.setDate(today.getDate() + offset);

    if (format === 'ISODATE') {
        return today.getUTCFullYear() +
            '-' + pad(today.getUTCMonth() + 1) +
            '-' + pad(today.getUTCDate());
    }

    return today.toLocaleDateString(lang, format);
}

export const getMonth = (lang, offset = 0, format = YYYYMon, day = 1, date = new Date()) => {
    const today = new Date(date);
    const month = new Date(today.getFullYear(), today.getMonth() + offset, day)

    return month.toLocaleDateString(lang, format);
}

export const compareDates = (date1, date2, type = 'exact') => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    switch (type) {
        case 'month':
            d1.setDate(1);
            d2.setDate(1);
            d1.setHours(0, 0, 0, 0);
            d2.setHours(0, 0, 0, 0);
            break;
        case 'day':
            d1.setHours(0, 0, 0, 0);
            d2.setHours(0, 0, 0, 0);
            break;
        default:
            break;
    }

    if (d1.getTime() > d2.getTime()) {
        return 1;
    }
    if (d1.getTime() == d2.getTime()) {
        return 0;
    }
    if (d1.getTime() < d2.getTime()) {
        return -1;
    }
}


export const formatDate = (lang, date, format, englishShortFormatException = false) => {
    const currentDate = new Date(date);

    const formattedDate = currentDate.toLocaleDateString(lang, format);

    if (englishShortFormatException) {
        const day = currentDate.toLocaleDateString(lang, { ...DD, timeZone: 'Europe/Berlin' });
        const month = currentDate.toLocaleDateString(lang, { ...Mon, timeZone: 'Europe/Berlin' });
        const year = currentDate.toLocaleDateString(lang, { ...YYYY, timeZone: 'Europe/Berlin' });

        return `${day} ${month} ${year}`;
    }
    return formattedDate;
}

export const isDST = (datestring) => {
    const date = new Date(datestring);
    const lastSundayInMarch = getLastSunday(date.getFullYear(), 3);
    const lastSundayInOctober = getLastSunday(date.getFullYear(), 10);

    return date < lastSundayInOctober && date > lastSundayInMarch;
}