
const CountryPlugin = {
    install(Vue, store) {
        Vue.filter('countryName', function(value) {
            let country = store.getters['countries/getCountries'].filter(item => item['code'] == value);
            if (country.length) {
                return country[0]['name'];
            } else {
                return value;
            }
        });
    },
};

export default CountryPlugin;
