import { DEFAULT_LANGUAGE } from "../config";

export default {
    namespaced: true,
    state: {
        lang: DEFAULT_LANGUAGE,
    },
    getters: {
        getLang(state) {
            return state.lang;
        },
    },
    mutations: {
        SET_LANG(state, lang) {
            state.lang = lang;
        },
    },
    actions: {
        setLang({ commit }, lang) {
            commit('SET_LANG', lang);
        },
    },
};
