<template>
    <div class="tabs-line tabs" :class="{ 'tabs-line-bottom': !isBottom }">
        <ul
            v-if="
                $route.name == 'page.auctionListPast' ||
                $route.name == 'page.auctionListPastCompany'
            "
            class="tab-button-list pull-left"
        >
            <li>
                <router-link
                    class="button button-grey"
                    :to="{ name: 'page.home' }"
                    >{{ $t('link_back_home') }}</router-link
                >
            </li>
        </ul>

        <ul v-else class="tab-button-list month-range-list pull-left">
            <li>
                <router-link
                    v-if="allTabLongName"
                    class="button button-grey"
                    :to="{ name: 'page.home' }"
                    >{{ $t('link_back_home') }}</router-link
                >
                <a
                    v-else
                    class="button"
                    v-bind:class="{
                        'button-grey': activeFilter !== 'all',
                        'button-blue': activeFilter === 'all',
                    }"
                    v-on:click="$emit('set-filter', 'all')"
                    >{{ $t('filter_all') }}</a
                >
            </li>

            <template v-if="!$global.isMobile">
                <li v-for="filter in filters" :key="filter.id">
                    <a
                        class="button"
                        v-bind:class="{
                            'button-grey': activeFilter !== filter.id,
                            'button-blue': activeFilter === filter.id,
                        }"
                        v-on:click="$emit('set-filter', filter.id)"
                        >{{ filter.label.split(' ')[0] }}&nbsp;<span
                            class="hidden-below-sm"
                            >{{ filter.label.split(' ')[1] }}</span
                        ></a
                    >
                </li>
            </template>
        </ul>

        <ul
            v-if="!hideRightSide"
            class="tab-button-list pull-right hidden-below-lg"
        >
            <li>
                <router-link
                    class="button button-grey"
                    :class="{
                        'button-blue':
                            $route.name == 'page.auctionListPast' ||
                            $route.name == 'page.auctionListPastCompany',
                    }"
                    :to="auctionListRoute"
                    >{{ $t('auctions_ended') }}</router-link
                >
            </li>
            <li>
                <router-link
                    class="button button-grey"
                    :class="{
                        'button-blue': $route.name == 'page.auctionCalendar',
                    }"
                    :to="{ name: 'page.auctionCalendar' }"
                    >{{ $t('auction_calendar') }}</router-link
                >
            </li>
        </ul>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '../config.js';

export default {
    props: {
        filters: {
            type: Array,
            required: true,
        },
        activeFilter: {
            required: false,
        },
        isBottom: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideRightSide: {
            type: Boolean,
            required: false,
            default: false,
        },
        allTabLongName: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            auctionListRoute: {
                name: 'page.auctionListPast',
                params: {
                    page: PAGINATION_DEFAULTS.START_PAGE,
                    perPage: PAGINATION_DEFAULTS.PER_PAGE,
                },
            },
        };
    },
    mounted() {
        if (this.$route.params.companySlug) {
            this.auctionListRoute.name = 'page.auctionListPastCompany';
            this.auctionListRoute.params.companySlug =
                this.$route.params.companySlug;
        }
    },
};
</script>

<style scoped lang="scss"></style>
