<template>
    <div class="news-list-component">
        <!-- News list -->
        <div class="news-list">
            <loader ref="loader"></loader>

            <div v-if="news.length > 1">
                <news-list-item
                    v-for="article in news"
                    :key="article.newsLink"
                    :newsHeadline="article.newsTitle"
                    :newsText="article.newsDescription"
                    :newsHref="article.newsLink"
                    :newsDate="article.newsDate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import NewsListItem from './NewsListItem';
import Loader from './Loader';
import { getNewsList } from '../lib/apis';

export default {
    components: {
        NewsListItem,
        Loader,
    },
    data: function () {
        return {
            news: [],
        };
    },
    mounted: function () {
        this.fetchNews();
    },
    methods: {
        fetchNews() {
            this.$refs.loader.show();
            getNewsList(this.$route.params.lang)
                .then((news) => {
                    this.news = news.data;
                    this.$refs.loader.hide();
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
};
</script>

<style scoped lang="scss">
.news-list-component {
    margin: $mar-xl 0;
}
.news-list {
    position: relative;
    min-height: 50px; /* for the loader */
    overflow: hidden;
}
</style>
