var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination",class:{ 'pagination-table-footer': _vm.tableFooter }},[(_vm.tableFooter)?[_c('div',{staticClass:"table-footer"},[_c('div',[_vm._t("default")],2),(_vm.hasPerPageSelect)?_c('ul',{staticClass:"entries-per-page"},[_c('li',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('show')))]),_vm._l((_vm.perPageAllowed),function(item){return _c('li',{key:item,class:{ active: _vm.perPage === item }},[_c('a',{on:{"click":function($event){_vm.perPage = item;
                            _vm.handleClick(true);}}},[_vm._v(_vm._s(item))])])}),_c('li',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('results_per_page')))])],2):_vm._e()]),_c('div',{staticClass:"pages"},[_c('ul',{staticClass:"pages-pagination"},[_c('li',{class:{ disabled: _vm.page === 1 }},[_c('a',{attrs:{"href":_vm.firstLink},on:{"click":function($event){$event.preventDefault();_vm.page = 1;
                            _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-step-forward fa-rotate-180"})])]),_c('li',{class:{ disabled: _vm.page === 1 }},[_c('a',{attrs:{"href":_vm.prevLink},on:{"click":function($event){$event.preventDefault();_vm.page--;
                            _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-play fa-rotate-180"})])]),(_vm.maxPages)?_c('li',[_vm._v("\n                    "+_vm._s(_vm.$t('pagination_x_of_y', { 0: _vm.page, 1: _vm.maxPages }))+"\n                ")]):_c('li',[_vm._v(_vm._s(_vm.$t('pagination_x', { 0: _vm.page })))]),_c('li',{class:{ disabled: _vm.page === _vm.maxPages }},[_c('a',{attrs:{"href":_vm.nextLink},on:{"click":function($event){$event.preventDefault();_vm.page++;
                            _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-play"})])]),_c('li',{class:{ disabled: _vm.page === _vm.maxPages }},[_c('a',{attrs:{"href":_vm.lastLink},on:{"click":function($event){$event.preventDefault();_vm.page = _vm.maxPages;
                            _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-step-forward"})])])])])]:[_c('div',[_vm._t("default")],2),_c('ul',{staticClass:"pages-pagination"},[_c('li',{class:{ disabled: _vm.page === 1 }},[_c('a',{attrs:{"href":_vm.firstLink},on:{"click":function($event){$event.preventDefault();_vm.page = 1;
                        _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-step-forward fa-rotate-180"})])]),_c('li',{class:{ disabled: _vm.page === 1 }},[_c('a',{attrs:{"href":_vm.prevLink},on:{"click":function($event){$event.preventDefault();_vm.page--;
                        _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-play fa-rotate-180"})])]),(_vm.maxPages)?_c('li',[_vm._v("\n                "+_vm._s(_vm.$t('pagination_x_of_y', { 0: _vm.page, 1: _vm.maxPages }))+"\n            ")]):_c('li',[_vm._v(_vm._s(_vm.$t('pagination_x', { 0: _vm.page })))]),_c('li',{class:{ disabled: _vm.page === _vm.maxPages }},[_c('a',{attrs:{"href":_vm.nextLink},on:{"click":function($event){$event.preventDefault();_vm.page++;
                        _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-play"})])]),_c('li',{class:{ disabled: _vm.page === _vm.maxPages }},[_c('a',{attrs:{"href":_vm.lastLink},on:{"click":function($event){$event.preventDefault();_vm.page = _vm.maxPages;
                        _vm.handleClick();}}},[_c('i',{staticClass:"fas fa-step-forward"})])])]),(_vm.hasPerPageSelect)?_c('ul',{staticClass:"entries-per-page"},[_c('li',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('show')))]),_vm._l((_vm.perPageAllowed),function(item){return _c('li',{key:item,class:{ active: _vm.perPage === item }},[_c('a',{on:{"click":function($event){_vm.perPage = item;
                        _vm.handleClick(true);}}},[_vm._v(_vm._s(item))])])}),_c('li',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('results_per_page')))])],2):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }