<template>
    <div class="auction-more">
        <h1>{{ auction.auctionName }}</h1>
        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
                >{{ auction.auctionName }}:
                {{
                    auction.auctionDescription | removeBR | striphtml
                }}</router-link
            >
            / {{ $t('results') }}
        </breadcrumb>
        <auction-tabs
            :auctionId="$route.params.auctionId"
            :auction="auction"
        ></auction-tabs>
        <div class="grid">
            <div class="row">
                <div class="col col-lg-12">
                    <h2>{{ $t('results') }}</h2>
                    <div
                        class="result"
                        v-for="result in results"
                        :key="result.lotId"
                    >
                        <router-link
                            :to="{
                                name: 'page.auctionLotDetail',
                                params: {
                                    companySlug: result.companySlug,
                                    auctionId: result.auctionId,
                                    categorySlug: result.categorySlug,
                                    lotId: result.lotId,
                                    lotSlug: result.lotSlug,
                                },
                            }"
                            >{{ result.lotNumber
                            }}{{ result.lotNumberAffix }}</router-link
                        >:
                        <price
                            :value="result.lotPriceRealised"
                            :currency="result.auctionCurrency"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs';
import Breadcrumb from '../components/Breadcrumb';
import { PAGINATION_DEFAULTS } from '../config.js';
import { getAuctionDetails, getAuctionResults } from '../lib/apis';
import Price from '../components/Price.vue';

export default {
    name: 'Auction_Results_Page',
    components: {
        AuctionTabs,
        Breadcrumb,
        Price,
    },
    data: function () {
        return {
            auction: {},
            results: [],
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    created: function () {
        getAuctionDetails(
            null,
            this.$route.params.companySlug,
            this.$route.params.auctionId,
            this.$route.params.lang
        )
            .then((response) => {
                this.auction = response.data;
            })
            .catch((e) => {
                this.genericApiErrorHandling(e);
            });
        getAuctionResults(this.$route.params.auctionId, this.$route.params.lang)
            .then((response) => {
                this.results = response.data;

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                this.genericApiErrorHandling(e);
            });
    },
};
</script>

<style scoped>
.result {
    background-color: #f0f0f0;
    padding: 3px;
    display: inline-block;
    margin: 3px;
    width: 180px;
}
span {
    float: right;
}
</style>
