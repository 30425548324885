<template>
    <div class="share-buttons">
        <a
            class="button button-grey"
            title="Mail"
            :href="mailtoLink"
            target="_blank"
            ><i class="fas fa-envelope"></i
        ></a>

        <a
            class="button button-grey"
            title="Twitter"
            :href="twitterLink"
            target="_blank"
            ><i class="fab fa-twitter"></i
        ></a>

        <a
            class="button button-grey"
            title="Facebook"
            :href="facebookLink"
            target="_blank"
            ><i class="fab fa-facebook"></i
        ></a>
    </div>
</template>

<script>
export default {
    name: 'SocialShare',
    props: {
        lot: {
            type: Object,
            required: true,
        },
    },
    computed: {
        fullURL() {
            return window.location.origin + this.$route.path;
        },

        fullURLEncoded() {
            return encodeURI(this.fullURL);
        },

        mailtoLink() {
            var subject = this.$t('social_share_mail_subject', [
                this.lot.auctionName,
                this.lot.lotNumber,
            ]);
            var body = this.$t('social_share_mail_body', [
                this.lot.companyName,
                this.$options.filters.removeBR(
                    this.$options.filters.striphtml(
                        this.lot.lotShortDescription
                    )
                ),
                this.fullURL,
            ]);

            var mailto_link =
                'mailto:?subject=' +
                subject.replace(/&/g, '%26') +
                '&body=' +
                body.replace(/&/g, '%26');

            return mailto_link;
        },

        facebookLink() {
            return (
                'https://www.facebook.com/sharer/sharer.php?u=' +
                this.fullURLEncoded
            );
        },

        twitterLink() {
            var text = encodeURIComponent(this.$t('social_share_twitter_text'));
            var hashtags = encodeURIComponent(
                ' #sixbid.com #numismatic #auction #collectible #coin #currency'
            );
            return (
                'https://twitter.com/intent/tweet?text=' +
                text +
                this.fullURLEncoded +
                hashtags
            );
        },
    },
    methods: {
        openPopup: function (event) {
            event.preventDefault();
            window.open(event.target.href, '', 'width=600,height=500');
        },
    },
};
</script>

<style scoped lang="scss">
.share-buttons {
    display: inline-block;
}

button,
a.button {
    margin-right: 10px;

    i {
        margin: 0 5px;
    }
}
</style>
