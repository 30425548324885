var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-print"},[_c('footer',{staticClass:"page-footer"},[_c('div',{staticClass:"wrapper clearfix"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-12 col-lg-3 hidden-below-lg",staticStyle:{"position":"relative"}},[_c('coin-collector')],1),_c('div',{staticClass:"col col-md-3"},[_c('h3',{staticClass:"page-footer-nav-headline",class:{ isOpen: _vm.auctionsShown },on:{"click":function($event){_vm.auctionsShown = !_vm.auctionsShown}}},[_vm._v("\n                            "+_vm._s(_vm.$t('auctions'))+"\n                        ")]),_c('transition',{attrs:{"name":"slide"}},[(!_vm.$global.isMobile || _vm.auctionsShown)?_c('ul',{staticClass:"page-footer-nav"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.home' }}},[_vm._v(_vm._s(_vm.$t('auctions_upcoming')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.auctionListPast',
                                            params: {
                                                page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                                perPage:
                                                    _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                            },
                                        }}},[_vm._v(_vm._s(_vm.$t('auctions_ended')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.auctionCalendar',
                                        }}},[_vm._v(_vm._s(_vm.$t('auction_calendar')))])],1)]):_vm._e()]),_c('h3',{staticClass:"page-footer-nav-headline",class:{ isOpen: _vm.serviceShown },on:{"click":function($event){_vm.serviceShown = !_vm.serviceShown}}},[_vm._v("\n                            "+_vm._s(_vm.$t('service'))+"\n                        ")]),_c('transition',{attrs:{"name":"slide"}},[(!_vm.$global.isMobile || _vm.serviceShown)?_c('ul',{staticClass:"page-footer-nav"},[_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.lots',
                                            params: {
                                                page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                                perPage:
                                                    _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                            },
                                        }}},[_vm._v(_vm._s(_vm.$t('lot_search')))])],1)]):_vm._e()])],1),_c('div',{staticClass:"col col-md-4 col-lg-3"},[_c('h3',{staticClass:"page-footer-nav-headline",class:{ isOpen: _vm.sixbidShown },on:{"click":function($event){_vm.sixbidShown = !_vm.sixbidShown}}},[_vm._v("\n                            "+_vm._s(_vm.$t('sixbid_url'))+"\n                        ")]),_c('transition',{attrs:{"name":"slide"}},[(!_vm.$global.isMobile || _vm.sixbidShown)?_c('ul',{staticClass:"page-footer-nav"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.about' }}},[_vm._v(_vm._s(_vm.$t('page_title_aboutus')))])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.help' }}},[_vm._v(_vm._s(_vm.$t('page_title_help')))])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.companyList' }}},[_vm._v(_vm._s(_vm.$t('companies')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.termsAndConditions',
                                        }}},[_vm._v(_vm._s(_vm.$t('page_title_conditions')))])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.privacy' }}},[_vm._v(_vm._s(_vm.$t('privacy')))])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.imprint' }}},[_vm._v(_vm._s(_vm.$t('page_title_imprint')))])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.contact' }}},[_vm._v(_vm._s(_vm.$t('page_title_contact')))])],1)]):_vm._e()])],1),(_vm.loggedIn)?_c('div',{staticClass:"col col-md-4 col-lg-3"},[_c('h3',{staticClass:"page-footer-nav-headline",class:{ isOpen: _vm.accountShown },on:{"click":function($event){_vm.accountShown = !_vm.accountShown}}},[_vm._v("\n                            "+_vm._s(_vm.$t('page_title_account'))+"\n                        ")]),_c('transition',{attrs:{"name":"slide"}},[(!_vm.$global.isMobile || _vm.accountShown)?_c('ul',{staticClass:"page-footer-nav"},[_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.accountBids',
                                            params: {
                                                page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                                perPage:
                                                    _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                            },
                                        }}},[_vm._v(_vm._s(_vm.$t('page_title_bids')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.accountWatchlist',
                                            params: {
                                                page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                                perPage:
                                                    _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                            },
                                        }}},[_vm._v(_vm._s(_vm.$t('watchlist')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                            name: 'page.accountBuyNow',
                                            params: {
                                                page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                                perPage:
                                                    _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                            },
                                        }}},[_vm._v(_vm._s(_vm.$t('buynow_items')))])],1),_c('li',[_c('a',{attrs:{"href":_vm.profileLink,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('page_title_profile.password')))])])])]):_vm._e()])],1):_vm._e()])]),_c('div',{staticClass:"sidebar"},[(_vm.getAds['Sidebar-Footer'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['Sidebar-Footer']}}):_vm._e()],1)])]),_c('footer',{staticClass:"page-footer-bar"},[_c('ul',{staticClass:"page-footer-bar-nav"},[_c('li',[_vm._v("© "+_vm._s(_vm.$t('sixbid_url')))]),_vm._v("\n            |\n            "),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.imprint' }}},[_vm._v(_vm._s(_vm.$t('page_title_imprint')))])],1),_vm._v("\n            |\n            "),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.termsAndConditions' }}},[_vm._v(_vm._s(_vm.$t('page_title_conditions')))])],1),_vm._v("\n            |\n            "),_c('li',[_c('router-link',{attrs:{"to":{ name: 'page.privacy' }}},[_vm._v(_vm._s(_vm.$t('privacy')))])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }