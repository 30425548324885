var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"page-best-coins-all"}},[_c('h1',[_vm._v(_vm._s(_vm.$t('top_coins_for'))+" "+_vm._s(_vm.lastMonth))]),_c('breadcrumb',[_vm._v(_vm._s(_vm.$t('top_coins_for'))+" "+_vm._s(_vm.lastMonth))]),_c('div',{staticClass:"lot-items"},_vm._l((_vm.lots),function(lot){return _c('div',{key:lot.lotId,staticClass:"lot-item"},[_c('span',{staticClass:"lot-item-company"},[_vm._v(_vm._s(lot.companyName))]),_c('span',{staticClass:"lot-item-auction"},[_vm._v(_vm._s(lot.auctionTitle))]),_c('router-link',{staticClass:"lot-item-image",attrs:{"to":{
                    name: 'page.auctionLotDetail',
                    params: {
                        companySlug: lot.companySlug,
                        auctionId: lot.auctionId,
                        categorySlug: lot.categoryId,
                        lotId: lot.lotId,
                        lotSlug: lot.lotSlug,
                    },
                }}},[_c('img',{attrs:{"alt":_vm.$t('lot'),"loading":"lazy","src":_vm.getImage(lot, 'M'),"width":"256","height":"128"}})]),(parseInt(lot.lotNumber, 10) > 0)?_c('span',{staticClass:"lotnumber"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('lot')))]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(lot.lotNumber))])]):_vm._e(),(parseInt(lot.lotStartingPrice, 10) > 0)?_c('span',{staticClass:"startingprice"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('lot_price_start'))+":")]),_c('price',{attrs:{"value":lot.lotStartingPrice,"currency":lot.auctionCurrency}})],1):_vm._e(),(parseInt(lot.lotPriceRealised, 10) > 0)?_c('span',{staticClass:"pricerealized"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('result'))+":")]),_c('price',{attrs:{"value":lot.lotPriceRealised,"currency":lot.auctionCurrency}})],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }