var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction-lot-detail"},[_c('auction-banner',{staticClass:"no-print",attrs:{"companyId":_vm.auction.companyId,"auctionId":_vm.auction.auctionId}}),_c('breadcrumb',[_c('router-link',{attrs:{"to":{
                name: 'page.auctionLotList',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_vm._v("\n            "+_vm._s(_vm.auction.auctionName)+":\n            "),_c('span',[_vm._v(_vm._s(_vm._f("striphtml")(_vm._f("removeBR")(_vm.auction.auctionDescription))))])]),_vm._v("\n        / "+_vm._s(_vm.$t('lot'))+": "+_vm._s(_vm.lot.lotNumber)+_vm._s(_vm.lot.lotNumberAffix)+"\n    ")],1),_c('auction-tabs',{staticClass:"no-print",attrs:{"companySlug":_vm.$route.params.companySlug,"auctionId":_vm.$route.params.auctionId,"auction":_vm.auction,"auctionStatus":_vm.auctionStatusLocal,"showSorting":_vm.$global.isMobile},on:{"sorting":function($event){_vm.viewShown = !_vm.viewShown}}}),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"row"},[(_vm.lot.lotId)?_c('aside',{staticClass:"lot-info col col-md-3"},[_c('sidebar-box',[_c('div',{staticClass:"datesbox"},[(_vm.auction.auctionHasNGC)?_c('a',{staticClass:"ngc",on:{"click":function($event){_vm.overlayShown = true}}},[_vm._v(_vm._s(_vm.$t('grading_ngc')))]):_vm._e(),_c('h2',{staticClass:"style-like-h3 auction"},[_vm._v("\n                            "+_vm._s(_vm.auction.auctionName)+"\n                        ")]),_c('h1',{staticClass:"style-like-h3 nr"},[_vm._v("\n                            "+_vm._s(_vm.$t('lot'))+" "+_vm._s(_vm.lot.lotNumber)+_vm._s(_vm.lot.lotNumberAffix)+"\n                        ")]),_c('div',{staticClass:"auctionDate"},[_vm._v("\n                            "+_vm._s(_vm.date)+"\n                        ")]),_c('div',{staticClass:"liveBiddingButtonWrapper"},[(
                                    _vm.auction.auctionLiveBiddingLink &&
                                    !_vm.liveBiddingActive
                                )?_c('a',{staticClass:"button button-dark-grey",attrs:{"href":_vm.auction.auctionLiveBiddingLink,"target":"_blank"}},[_vm._v("\n                                "+_vm._s(_vm.$t('livebidding_register'))+"\n                            ")]):_vm._e(),(_vm.liveBiddingActive)?_c('router-link',{staticClass:"button button-blue color-white",class:{
                                    active:
                                        _vm.$route.name ==
                                        'page.auctionLivebidding',
                                },attrs:{"to":{
                                    name: 'page.auctionLivebidding',
                                    params: {
                                        companySlug: _vm.auction.companySlug,
                                        auctionId: _vm.auction.auctionId,
                                    },
                                    query: this.$route.query,
                                }}},[_c('span',[_vm._v(_vm._s(_vm.$t('livebidding.sixbid')))])]):_vm._e()],1),(_vm.auction.auctionLiveBiddingLink)?_c('div',{staticClass:"clearfix"}):_vm._e(),(!_vm.hasEnded)?_c('div',{staticClass:"auctionICS no-print"},[_c('a',{attrs:{"href":(_vm.icsAPI + "/calendar-export/lot/" + (_vm.lot.lotId) + "?lang=" + (_vm.$route.params.lang))}},[_vm._v(_vm._s(_vm.$t('date_save')))])]):_vm._e(),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"auctionOnlineBiddingEnd"},[_c('span',{staticClass:"auctionOnlineBiddingEndLabel"},[_vm._v(_vm._s(_vm.$t('bid_end'))+":")]),_c('span',{staticClass:"auctionOnlineBiddingEndDate"},[_c('date-timezone',{attrs:{"date":_vm.lot.sessionEnds}})],1)]),_c('div',{staticClass:"clearfix"})]),(_vm.viewShown || !_vm.$global.isMobile)?_c('div',{staticClass:"searchform no-print"},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.searchLots.apply(null, arguments)}}},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lotSearchForm.term),expression:"lotSearchForm.term"}],attrs:{"type":"text","placeholder":_vm.$t('search_term_lot'),"name":"term","id":"term"},domProps:{"value":(_vm.lotSearchForm.term)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.lotSearchForm, "term", $event.target.value)}}})]),_c('div',{staticClass:"form-submit search-submit"},[_c('input',{staticClass:"button button-blue",attrs:{"type":"submit"},domProps:{"value":_vm.$t('auction_search_in')}})]),_c('div',{staticClass:"clearfix"})])]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"lot-content col col-md-9"},[_c('loader',{ref:"loader"}),(_vm.company.companyId)?_c('router-link',{staticClass:"company",attrs:{"tag":"a","to":{
                        name: 'page.companyDetail',
                        params: { companySlug: _vm.company.companySlug },
                    }}},[_c('company-image',{staticClass:"hidden-below-md",attrs:{"alt":_vm.company.companyLogoAlt,"title":_vm.company.companyLogoTitle,"companyId":_vm.company.companyId,"auctionId":_vm.auction.auctionId}})],1):_vm._e(),_c('div',{staticClass:"row no-print"},[_c('div',{staticClass:"col sm-col col-lg-4"},[(_vm.lot.prev && _vm.lot.prev.lotId)?_c('router-link',{attrs:{"to":{
                                name: 'page.auctionLotDetail',
                                params: {
                                    companySlug: _vm.company.companySlug,
                                    auctionId: _vm.auction.auctionId,
                                    categorySlug: _vm.lot.categorySlug,
                                    lotId: _vm.lot.prev.lotId,
                                    lotSlug: _vm.lot.prev.lotSlug,
                                },
                                query: this.$route.query,
                            }}},[_c('i',{staticClass:"fas fa-caret-left"}),_vm._v("\n                            "+_vm._s(_vm.$t('lot_previous'))+"\n                        ")]):_c('span',[_c('i',{staticClass:"fas fa-caret-left"}),_vm._v("\n                            "+_vm._s(_vm.$t('lot_previous'))+"\n                        ")])],1),_c('div',{staticClass:"col sm-col col-lg-4 centeralign"},[_c('router-link',{attrs:{"to":{
                                name: 'page.auctionLotList',
                                params: {
                                    page: _vm.currentPage,
                                    perPage: _vm.itemsPerPage,
                                },
                                query: this.$route.query,
                            }}},[_vm._v(_vm._s(_vm.$t('go_to_overview')))])],1),_c('div',{staticClass:"col sm-col col-lg-4 rightalign"},[(_vm.lot.next && _vm.lot.next.lotId)?_c('router-link',{attrs:{"to":{
                                name: 'page.auctionLotDetail',
                                params: {
                                    companySlug: _vm.company.companySlug,
                                    auctionId: _vm.auction.auctionId,
                                    categorySlug: _vm.lot.categorySlug,
                                    lotId: _vm.lot.next.lotId,
                                    lotSlug: _vm.lot.next.lotSlug,
                                },
                                query: this.$route.query,
                            }}},[_vm._v("\n                            "+_vm._s(_vm.$t('lot_next'))+"\n                            "),_c('i',{staticClass:"fas fa-caret-right"})]):_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t('lot_next'))+"\n                            "),_c('i',{staticClass:"fas fa-caret-right"})])],1)]),_c('div',{staticClass:"image"},[(_vm.lot.coinArchiveLink)?_c('coin-archive-link',{attrs:{"link":_vm.lot.coinArchiveLink,"has-animation":""}}):_vm._e(),_c('bookmark',{attrs:{"lotId":_vm.lot.lotId}}),(!!_vm.lot.hasImage)?_c('div',{staticClass:"zoom"},[(
                                _vm.lot.lotImageLargeUrl &&
                                _vm.lot.lotImageLargeUrlOriginal
                            )?_c('zoomer-container',{attrs:{"image":_vm.lot.lotImageLargeUrl,"zoom":_vm.lot.lotImageLargeUrlOriginal}}):_vm._e()],1):_c('div',{staticClass:"zoomspacer"},[_vm._v("No Image")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-4"},[(_vm.lot.lotStartingPrice)?[_c('strong',[_vm._v(_vm._s(_vm.$t('lot_price_start'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotStartingPrice,"currency":_vm.lot.auctionCurrency,"prAvailable":_vm.auction.auctionPrAvailable}})]:_vm._e()],2),_c('div',{staticClass:"col col-lg-4"},[(_vm.lot.lotEstimate)?[_c('strong',[_vm._v(_vm._s(_vm.$t('lot_estimate'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotEstimate,"currency":_vm.lot.auctionCurrency,"prAvailable":_vm.auction.auctionPrAvailable}})]:_vm._e()],2),(_vm.auction.auctionPrAvailable)?_c('div',{staticClass:"col col-lg-4"},[_c('strong',[_vm._v(_vm._s(_vm.$t('result'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotPriceRealised,"currency":_vm.lot.auctionCurrency,"showZeroAsUnsold":true}})],1):_c('div',{staticClass:"col col-lg-4 bid-box"},[(_vm.message.type != 'success')?_c('bid-lot-input',{staticClass:"bid-input no-print",attrs:{"lot":_vm.lot,"detailStyling":true},on:{"bid-placed":_vm.showMessage}}):_vm._e(),(_vm.liveBiddingActive)?_c('router-link',{staticClass:"button button-blue fullwidth margin-top",attrs:{"to":{
                                name: 'page.auctionLivebidding',
                                params: {
                                    companySlug: _vm.lot.companySlug,
                                    auctionId: _vm.lot.auctionId,
                                },
                                query: this.$route.query,
                            }},domProps:{"innerHTML":_vm._s(_vm.$t('livebidding.sixbid'))}}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-16 bid-lot-message-box"},[(_vm.hasMessage)?_c('bid-lot-message',{attrs:{"lot":_vm.lot,"message-type":_vm.message.type,"message-content":_vm.message.content}}):_vm._e()],1)]),_c('div',{staticClass:"desc"},[(_vm.lot.lotDescription)?_c('span',{domProps:{"innerHTML":_vm._s(
                            '<strong>' +
                            _vm.$t('lot') +
                            ' ' +
                            _vm.lot.lotNumber +
                            _vm.lot.lotNumberAffix +
                            '.</strong> ' +
                            _vm.lot.lotDescription
                        )}}):_vm._e()]),(_vm.lot.lotProvenance)?_c('div',{staticClass:"prov"},[_c('span',[_vm._v(_vm._s(_vm.$t('provenance'))+":")]),_vm._v("\n                    "+_vm._s(_vm.lot.lotProvenance)+"\n                ")]):_vm._e()],1)]),_c('div',{staticClass:"row no-print"},[_c('div',{staticClass:"toolbar col col-md-12"},[_c('social-share',{attrs:{"lot":_vm.lot}}),_c('button',{staticClass:"button button-grey",attrs:{"onclick":"window.print();"}},[_c('i',{staticClass:"fas fa-print"}),_vm._v(_vm._s(_vm.$t('print'))+"\n                ")])],1)]),(_vm.$global.isMobile && !_vm.showSimilarLots)?_c('a',{staticClass:"similarLotsToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showSimilarLots = !_vm.showSimilarLots}}},[_vm._v(_vm._s(_vm.$t('lot_similar_lots')))]):_vm._e(),(!(_vm.$global.isMobile && !_vm.showSimilarLots))?_c('div',{staticClass:"row no-print"},[_c('div',{staticClass:"col col-lg-12"},[_c('div',{staticClass:"similar-lots"},[_c('h2',[_vm._v(_vm._s(_vm.$t('lot_similar_lots')))]),_c('p',[_vm._v("\n                        "+_vm._s(_vm.$t('lot_similar_lots_text'))+"\n                    ")]),_c('similar-lot-list-component',{attrs:{"lotId":_vm.similarLotId}})],1)])]):_vm._e()]),_c('content-overlay',{attrs:{"shown":_vm.overlayShown},on:{"closing":function($event){_vm.overlayShown = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.ngcContent)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }