<template>
    <sidebar-box
        :headline="headline"
        theme="dark-grey"
        :route="{ name: 'page.bestCoinsAll' }"
    >
        <div class="lot-list-component">
            <div class="lot-list">
                <loader ref="loader"></loader>
                <carousel v-if="lots.length > 0" :autoPlayInterval="5000">
                    <div class="slides">
                        <slide v-for="lot in lots" :key="lot.lotId">
                            <router-link
                                tag="div"
                                :to="{
                                    name: 'page.auctionLotDetail',
                                    params: {
                                        lotId: lot.lotId,
                                        auctionId: lot.auctionId,
                                        companySlug: lot.companySlug,
                                        lotSlug: lot.lotSlug,
                                        categorySlug: lot.categorySlug || '-',
                                    },
                                }"
                                class="lot-list-item clearfix"
                            >
                                <img
                                    loading="lazy"
                                    :src="getImage(lot, 'S')"
                                    width="256"
                                    height="128"
                                    :alt="$t('lot')"
                                />
                                <p v-html="lot.companyName"></p>
                                <p v-html="lot.auctionTitle"></p>
                            </router-link>
                        </slide>
                    </div>
                </carousel>
            </div>
        </div>
    </sidebar-box>
</template>

<script>
import Carousel from './Carousel.vue';
import CloudImageGetter from '../lib/CloudImageGetter';
import Loader from './Loader.vue';
import SidebarBox from '../components/SidebarBox';
import Slide from './Slide.vue';
import { getBestCoinsBoxContent } from '../lib/apis';
import { formatDate, YYYYMon } from '../lib/date';
export default {
    name: 'SidebarBoxBestCoins',
    components: {
        Carousel,
        Loader,
        SidebarBox,
        Slide,
    },
    data: function () {
        return {
            lots: [],
            boxdate: '',
        };
    },
    computed: {
        headline() {
            if (this.boxdate.length > 1) {
                return `${this.$t('top_coins_for')} ${formatDate(
                    this.$route.params.lang,
                    this.boxdate,
                    YYYYMon
                )}`;
            } else {
                return '';
            }
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$refs.loader.show();
            getBestCoinsBoxContent(this.$route.params.lang)
                .then((response) => {
                    this.lots = response.data.lots;
                    this.boxdate = response.data.lots[0].bestcoinsDate;
                    this.$refs.loader.hide();
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        getImage: function (lot, size) {
            return CloudImageGetter(lot.googleBucketImagePath, size);
        },
    },
};
</script>

<style lang="scss" scoped>
.lot-list-component {
    margin: $mar-lg 0;
}
.lot-list {
    position: relative;
    min-height: 50px; /* for the loader */
    overflow: hidden;
    background: white;
}
.slides {
    padding: $pad-md 0;
}
.lot-list-item {
    cursor: pointer;
    text-align: center;
    color: $text-color;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $pad-md;

    img {
        width: auto;
        max-width: 100%;
        height: 160px;
        object-fit: contain;
    }

    p {
        margin: 5px 0;
    }
}
</style>
