<template>
    <div
        class="coinArchiveLink coinArchiveLink--top coinArchiveLink--icon"
        :class="hasAnimation ? 'coinArchiveLink--animation' : ''"
    >
        <a :href="link" target="_blank" :title="$t('coin_archive_link')">
            <i class="fas fa-bookmark fa-2x"></i>
            <i
                v-if="hasAnimation"
                class="animation-background fas fa-bookmark fa-2x"
            ></i>
            <svg
                viewBox="0 0 39 55"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="1.414"
            >
                <path
                    d="M7.74 40.863C6.488 34.147 2.846 31.985 0 31.188v22.196c3.756-1.594 8.992-5.236 7.74-12.521z"
                    fill="#fff"
                    fill-rule="nonzero"
                ></path>
                <path
                    d="M36.424 0h-7.399v5.35c-1.593-.569-3.187-.797-4.78-.797H14.57c-1.822 0-3.643.341-5.236 1.024V0H1.821c1.138 2.846 2.96 4.894 5.919 6.147A14.675 14.675 0 0 0 0 19.123v9.902c3.529.683 8.423 3.301 9.903 11.497 1.366 7.626-3.187 11.951-7.399 14.228h12.407C28.115 54.636 38.7 44.05 38.7 30.846V19.123c0-5.692-3.301-10.586-8.081-12.976C33.464 4.781 35.286 2.732 36.424 0zM9.22 24.814a6.243 6.243 0 0 1-6.261-6.261 6.243 6.243 0 0 1 6.261-6.26 6.242 6.242 0 0 1 6.26 6.26 6.242 6.242 0 0 1-6.26 6.261zm9.903 11.496l-3.871-7.626 3.871-2.618 3.87 2.618-3.87 7.626zm16.618-17.643a6.243 6.243 0 0 1-6.26 6.261 6.243 6.243 0 0 1-6.261-6.261 6.242 6.242 0 0 1 6.261-6.26 6.242 6.242 0 0 1 6.26 6.26z"
                    fill="#fff"
                    fill-rule="nonzero"
                ></path>
                <path
                    d="M9.22 15.822a2.818 2.818 0 0 0-2.846 2.845 2.818 2.818 0 0 0 2.846 2.846 2.818 2.818 0 0 0 2.845-2.846 2.818 2.818 0 0 0-2.845-2.845zM26.635 18.667a2.818 2.818 0 0 0 2.846 2.846 2.818 2.818 0 0 0 2.845-2.846 2.818 2.818 0 0 0-2.845-2.845 2.818 2.818 0 0 0-2.846 2.845z"
                    fill="#fff"
                    fill-rule="nonzero"
                ></path>
            </svg>
        </a>
    </div>
</template>

<script>
export default {
    name: 'CoinArchiveLink',
    props: {
        link: {
            type: String,
            required: true,
        },
        hasAnimation: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
};
</script>

<style lang="scss">
.lot-list-item .coinArchiveLink--top,
.lot-content .coinArchiveLink--top {
    position: absolute;
    right: 50px;
    top: -6px;
    z-index: 3;
}

.coinArchiveLink--icon {
    a {
        text-decoration: none;

        i {
            margin: 0;
        }

        svg {
            height: 14px;
            position: absolute;
            top: 4px;
            left: 6px;
        }
    }
}

.coinArchiveLink--animation {
    animation: beat 4s ease 0s infinite;
}
.animation-background {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    
    z-index: -2;
    animation: beat-background 4s ease-out 1s infinite;
}

@keyframes beat {
    0% {
        transform: scale(1) translateY(0);
    }
    50% {
        transform: scale(1.3) translateY(2px);
    }
}
@keyframes beat-background {
    0% {
        transform: scale(1) translateY(0);
    }
    50% {
        transform: scale(1.3) translateY(1px);
    }
}
</style>