<template>
    <div class="lot-list-item lot">
        <bookmark :lotId="lot.lotId" />
        <coin-archive-link
            v-if="lot.coinArchiveLink"
            :link="lot.coinArchiveLink"
        />
        <div class="lot-list-infos">
            <div v-if="showImage" class="lot-image">
                <router-link :to="detailRoute">
                    <img
                        v-if="useImageFallback"
                        loading="lazy"
                        :alt="$t('image')"
                        :src="
                            'https://lot-images.sixbid.com/placeholder/lot/' +
                            lot.companyId +
                            '/' +
                            lot.auctionId +
                            '/img.png'
                        "
                        width="256"
                        height="128"
                    />
                    <img
                        v-else
                        loading="lazy"
                        :src="getImage(lot, 'S')"
                        width="256"
                        height="128"
                        :alt="$t('lot')"
                    />
                </router-link>
            </div>
            <div class="lot-content">
                <div v-if="showDescription" class="description-wrapper">
                    <div class="description" v-line-clamp="5">
                        <strong v-if="showLotNr"
                            >{{ $t('lot') }} {{ lot.lotNumber
                            }}{{ lot.lotNumberAffix }}. </strong
                        >
                        <span
                            v-html="
                                $options.filters.searchHighlights(
                                    $options.filters.striphtml(
                                        lot.lotDescription
                                    )
                                )
                            "
                        ></span>
                    </div>
                </div>
                <div class="prices">
                    <ul>
                        <li v-if="showPrebidDate">
                            <div>{{ $t('bid_end') }}:</div>
                            <date-timezone :date="lot.sessionEnds" />
                        </li>
                        <li v-if="showCompany">
                            <div>{{ $t('company') }}:</div>
                            <router-link
                                :to="{
                                    name: 'page.companyDetail',
                                    params: { companySlug: lot.companySlug },
                                }"
                                >{{ lot.companyName }}</router-link
                            >
                        </li>
                        <li v-if="showAuction">
                            <div>{{ $t('auction') }}:</div>
                            {{ lot.auctionName }}
                        </li>
                        <li
                            v-if="
                                showStartingPrice &&
                                lot.lotStartingPrice &&
                                lot.auctionCurrency
                            "
                        >
                            <div>{{ $t('lot_price_start') }}:</div>
                            <price
                                :value="lot.lotStartingPrice"
                                :currency="lot.auctionCurrency"
                            ></price>
                        </li>
                        <li
                            v-if="
                                showEstimatePrice &&
                                lot.lotEstimate &&
                                lot.auctionCurrency &&
                                lot.lotEstimate > 0
                            "
                        >
                            <div>{{ $t('lot_estimate') }}</div>
                            <price
                                :value="lot.lotEstimate"
                                :currency="lot.auctionCurrency"
                            ></price>
                        </li>
                        <li
                            v-if="
                                showResultPrice &&
                                lot.lotPriceRealised &&
                                lot.auctionCurrency
                            "
                        >
                            <div>{{ $t('result') }}:</div>
                            <price
                                :value="lot.lotPriceRealised"
                                :currency="lot.auctionCurrency"
                                :showZeroAsUnsold="true"
                            ></price>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="lot-control-container">
            <div class="lot-controls">
                <div v-if="showDetailsButton" class="more-details">
                    <router-link class="button button-grey" :to="detailRoute">{{
                        $t('details_more')
                    }}</router-link>
                </div>
                <div v-if="showBidding" class="bid-lot">
                    <bid-lot-input
                        @bid-placed="showMessage"
                        :lot="lot"
                    ></bid-lot-input>
                </div>
            </div>
            <div class="clearfix"></div>
            <bid-lot-message
                v-if="hasMessage && showBidding"
                :lot="lot"
                :message-type="message.type"
                :message-content="message.content"
                :add-query="addQuery"
            ></bid-lot-message>
        </div>
    </div>
</template>

<script>
import CloudImageGetter from '../lib/CloudImageGetter';
import * as basicLightbox from 'basiclightbox';
import Bookmark from '../components/Bookmark.vue';
import BidLotInput from './BidLotInput.vue';
import BidLotMessage from './BidLotMessage.vue';
import Price from './Price.vue';
import CoinArchiveLink from './CoinArchiveLink.vue';
import DateTimezone from '../components/DateTimezone.vue';

export default {
    name: 'LotListItem',
    components: {
        BidLotInput,
        BidLotMessage,
        Price,
        Bookmark,
        CoinArchiveLink,
        DateTimezone
    },
    props: {
        lot: {
            type: Object,
            required: true,
        },

        isSimilar: {
            // controls appending get parameters
            type: Boolean,
            required: false,
            default: false,
        },

        showImage: {
            type: Boolean,
            required: true,
        },
        showLotNr: {
            type: Boolean,
            required: true,
        },
        showDescription: {
            type: Boolean,
            required: true,
        },
        showCompany: {
            type: Boolean,
            required: true,
        },
        showPrebidDate: {
            type: Boolean,
            required: false,
        },
        showAuction: {
            type: Boolean,
            required: false,
            default: false,
        },
        showStartingPrice: {
            type: Boolean,
            required: true,
        },
        showEstimatePrice: {
            // if true will only show if estimate exists
            type: Boolean,
            required: true,
        },
        showResultPrice: {
            // if true will always show, either with a price or with ---
            type: Boolean,
            required: true,
        },
        showDetailsButton: {
            type: Boolean,
            required: true,
        },
        showBidding: {
            type: Boolean,
            required: true,
        },

        useImageFallback: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            hasMessage: false,
            message: {
                type: '',
                content: '',
            },
        };
    },
    computed: {
        addQuery() {
            if (
                this.$route.name == 'page.companyLots' ||
                this.$route.name == 'page.lots'
            )
                return false;
            return (
                !this.isSimilar ||
                this.$route.params.auctionId == this.lot.auctionId
            );
        },
        detailRoute() {
            let obj = {
                name: 'page.auctionLotDetail',
                params: {
                    companySlug: this.lot.companySlug,
                    auctionId: this.lot.auctionId,
                    categorySlug: this.lot.categorySlug
                        ? this.lot.categorySlug
                        : 'category',
                    lotId: this.lot.lotId,
                    lotSlug: this.lot.lotSlug,
                },
            };
            if (this.addQuery) {
                obj.query = this.$route.query;
            }
            return obj;
        },
    },
    methods: {
        getImage: function (lot, size) {
            return CloudImageGetter(lot.googleBucketImagePath, size);
        },
        showLightbox: function (lot) {
            let lightbox = basicLightbox.create(`
                    <img loading="lazy" src="${this.getImage(lot, 'O')}" alt="lightbox" width="1024" height="512">
                `);

            lightbox.show();
        },
        showMessage(message) {
            this.hasMessage = true;
            this.message.type = message.type;
            this.message.content = message.content;
        },
    },
};
</script>

<style lang="scss" scoped>
.lot-list-item {
    border: 1px solid $grey-bg;
    padding: 15px;
    margin: 0 0 20px 0;
    display: inline-block;
    vertical-align: top;
    position: relative;
    background-color: white;

    @media (max-width: $sm-size-max) {
        width: 100%;
    }

    .description {
        word-break: normal !important;
    }
}

.small .lot-list-item,
.large .lot-list-item {
    display: flex;
    flex-direction: column;
}

.lot-list-infos {
    margin-bottom: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.detail .lot-list-infos {
    flex-direction: row;

    .lot-content {
        width: 50%;
    }
}

.lot-image {
    height: 200px;
    text-align: center;
    justify-content: flex-start;

    img {
        height: auto;
        width: auto;
        position: relative;
        max-height: 200px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.detail .lot-image {
    @media (min-width: $lg-size) {
        width: 50%;
    }
}

.small .lot-content,
.large .lot-content,
.detail .lot-content {
    margin-top: 20px;

    .prices {
        margin-top: 15px;

        ul,
        li {
            padding: 0;
            margin: 0;
            list-style: none inside;
        }
        li {
            margin: 0 0 12px;

            & > div {
                display: inline-block;
                width: 120px;
                font-weight: bold;
            }

            & > span {
                display: inline-block;
                width: calc(100% - 120px);
                white-space: nowrap;
            }
        }
    }

    .description-wrapper {
        @media (min-width: $lg-size) {
            height: 5.75em;
            overflow: visible;
        }
        .description {
            font-weight: 300;
            line-height: 18px;
        }
    }
}

.small .lot-content {
    .description {
        line-height: 18px;
        height: 90px;
    }
}
.lot-content {
    @media (max-width: $md-size-max) {
        .description {
            line-height: 18px;
            height: 90px;
        }
    }
}

.lot-control-container {
    flex-grow: 0;
}

.small .lot-controls,
.large .lot-controls,
.detail .lot-controls {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-content: space-between;
    margin: 0 #{-1 * $mar-sm};

    & > div {
        margin-top: $mar-lg;
        margin-left: $mar-sm;
        margin-right: $mar-sm;
        flex-grow: 1;
    }

    .more-details {
        min-width: 110px;

        .button {
            width: 100%;
        }
    }

    .bid-lot {
        min-width: 110px;
    }

    .resize-image {
        min-width: 120px;

        .button {
            width: 100%;
        }
    }
}

.detail .lot-controls {
    @media (min-width: $lg-size) {
        width: 50%;
        float: left;
    }
}

.message {
    margin-top: $mar-lg;
}
</style>

<style lang="scss">
.bid-lot {
    input[type='text'] {
        width: calc(100% - 34px);
    }
}
</style>

<style lang="scss">
.search-highlight {
    color: $main-color;
    font-weight: bold;
}
</style>
