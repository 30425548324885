<template>
    <sidebar-box :headline="$t('newsletter_title')" theme="grey">
        <div class="nl-content">
            <div id="successContainer" :class="successClass">
                {{ $t('newsletter_success') }}
            </div>

            <form v-on:submit.prevent="newsletterAPI">
                <div :class="successClass">
                    <div
                        class="newsletter-text"
                        v-html="$t('newsletter_text')"
                    ></div>
                    <input
                        type="email"
                        name="uemail"
                        :placeholder="$t('newsletter_email')"
                        required
                        v-model="newsletterEmail"
                    />
                    <input type="hidden" name="gid" :value="groupID" />
                </div>

                <button type="submit" :class="buttonClasses">
                    {{ $t('newsletter_subscribe') }}
                </button>
            </form>

            <div class="error" v-html="errorMessage"></div>
        </div>
    </sidebar-box>
</template>

<script>
import SidebarBox from '../components/SidebarBox';
import { registerNewsletter } from '../lib/apis.js';

export default {
    name: 'SidebarBoxNewsletter',
    components: {
        SidebarBox,
    },
    data: function () {
        return {
            loaderStatus: false,
            errorMessage: '',
            successClass: '',
            newsletterEmail: '',
        };
    },
    methods: {
        newsletterAPI() {
            this.loaderStatus = true;

            registerNewsletter(this.newsletterEmail, this.$route.params.lang)
                .then((response) => {
                    if (response.data.success == true) {
                        this.loaderStatus = false;
                        this.errorMessage = '';
                        this.successClass = 'success';
                    } else {
                        this.loaderStatus = false;
                        this.errorMessage = response.data.message;
                    }
                })
                .catch(() => {
                    (this.loaderStatus = false),
                        (this.errorMessage = this.$t('newsletter_error'));
                });
        },
    },
    computed: {
        groupID() {
            return this.$route.params.lang == 'de' ? 851057176 : 851057177;
        },
        loaderStatusClass() {
            return this.loaderStatus ? 'button-loading' : '';
        },
        buttonClasses() {
            return (
                'button button-blue ' +
                this.loaderStatusClass +
                ' ' +
                this.successClass
            );
        },
    },
};
</script>

<style lang="scss" scoped>
input {
    display: block;
    width: 100%;
    margin: 10px 0;
}
button {
    width: 100%;
}
.nl-content {
    position: relative;
}

.error {
    margin-top: 10px;
}

.success {
    display: none;
}

#successContainer {
    display: none;
}

#successContainer.success {
    display: block;
}
</style>
