<template>
    <!-- TODO: remove sorting HACK via flex order -->
    <sidebar-box
        v-bind:style="{ order: auction.companyId }"
        v-if="tickerItem.status && tickerItem.status > 0"
        headline="Sixbid Live Bidding"
        theme="black"
        :auex="true"
    >
        <div class="live-bidding-box">
            <div class="auex-live-bidding-img-wrapper">
                <img
                    :alt="$t('lot')"
                    loading="lazy"
                    width="270" height="148"
                    class="auex-live-bidding-img"
                    v-if="tickerItem.imgUrl"
                    :src="tickerItem.imgUrl.replace('-O.', '-XS.')"
                />
            </div>
            <p>
                <span class="auex-live-bidding-company">{{
                    auction.companyName
                }}</span
                ><br />
                <span class="auex-live-bidding-auction"
                    >{{ auction.auctionName }}, {{ $t('lot') }}
                    {{ tickerItem.losnummer }}</span
                ><br />
                <span class="auex-live-bidding-price"></span
                ><span v-if="tickerItem.status == 5">{{
                    $t('bid_hammer')
                }}</span
                ><span v-else-if="tickerItem.status == 7">{{
                    $t('bid_unsold')
                }}</span
                ><span v-else-if="tickerItem.status == 8">{{
                    $t('bid_startingprice')
                }}</span
                ><span v-else>{{ $t('bid_currentbid') }}</span
                ><span v-if="tickerItem.status != 7"
                    >:
                    {{
                        tickerItem.gebot ? tickerItem.gebot : tickerItem.ausruf
                    }}
                    {{ tickerItem.waehrung }}</span
                >
                &nbsp;<i
                    v-if="tickerItem.status == 5"
                    class="fa fa-gavel icon-hammer"
                ></i>
            </p>
            <router-link
                class="button button-blue live-bidding-cta-button"
                :to="{
                    name: 'page.auctionLivebidding',
                    params: {
                        companySlug: auction.companySlug,
                        auctionId: auctionId,
                    },
                }"
                >{{ $t('bid_livenow') }}</router-link
            >
        </div>
    </sidebar-box>
</template>

<script>
import SidebarBox from '../components/SidebarBox';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getAuctionDetails } from '../lib/apis';

export default {
    name: 'SidebarBoxAuexTicker',
    components: {
        SidebarBox,
    },
    data: function () {
        return {
            tickerItem: {},
            auction: {},
            connection: null,
        };
    },
    props: {
        baseUrl: {
            type: String,
            required: true,
        },
        auctionId: {
            type: Number,
            required: true,
        },
    },
    mounted: function () {
        // attach to auex websocket
        this.connection = new HubConnectionBuilder()
            .withUrl(this.baseUrl + '/v2/ticker')
            .build();
        this.connection.on('TickerSixbidUpdated', (o) => {
            this.tickerItem = o;
        });
        this.connection.start().then(() => {
            this.connection.invoke(
                'RegisterTickerSixbid',
                this.$route.params.lang
            );
        });

        getAuctionDetails(null, '_', this.auctionId, this.$route.params.lang)
            .then((response) => {
                this.auction = response.data;

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );
                });
            })
            .catch((e) => {
                this.genericApiErrorHandling(e);
            });
    },
    beforeDestroy: function () {
        // detach from auex websocket
        this.connection.stop();
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-hammer:before {
    font-size: 0.8;
}
.live-bidding-box {
    background-color: white;
    margin: 15px 0 15px 0;
    padding: 15px;
    color: black;
    text-align: center;
}
.auex-live-bidding-img-wrapper {
    width: 100%;
    height: 170px;
}
.auex-live-bidding-img {
    max-height: 170px;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.auex-live-bidding-company {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 0.5em;
    display: inline-block;
}
.auex-live-bidding-auction {
    margin-bottom: 0.5em;
    display: inline-block;
}
.live-bidding-cta-button {
    width: 100%;
}
</style>
