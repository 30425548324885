<template>
    <div class="auction-livebidding">
        <auction-banner
            :companyId="auction.companyId"
            :auctionId="auction.auctionId"
        ></auction-banner>
        <h1>{{ auction.auctionName }}</h1>
        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
                >{{ auction.auctionName }}:
                {{
                    auction.auctionDescription | removeBR | striphtml
                }}</router-link
            >
            / {{ $t('auction_conditions') }}
        </breadcrumb>
        <auction-tabs
            :auctionId="$route.params.auctionId"
            :auction="auction"
            :auctionStatus="auctionStatus"
        ></auction-tabs>
        <confirm-overlay
            :shown="showConsent"
            :acceptText="$t('livebidding_acceptsheet_accept')"
            :declineText="$t('livebidding_acceptsheet_decline')"
            @accept="userAccept"
            @decline="userDecline"
            @closing="userDecline"
        >
            <h2>{{ $t('livebidding_acceptsheet_headline') }}</h2>
            <p v-html="$t('livebidding_acceptsheet_body')"></p>
        </confirm-overlay>
        <div class="grid">
            <div class="row">
                <aside class="lot-info col col-md-3">
                    <sidebar-box
                        class="sidebarsection sidebarsection-first auction-info"
                    >
                        <a
                            v-if="auction.auctionHasNGC"
                            class="ngc"
                            @click="overlayShown = true"
                            >{{ $t('grading_ngc') }}</a
                        >
                        <div
                            v-if="auction.auctionHasNGC"
                            class="clearfix ngcClearfix"
                        ></div>
                        <h3 class="sidebar-box-headline auction-headline">
                            {{ auction.auctionName }}
                        </h3>

                        <div
                            v-if="
                                auction.auctionLocationName ||
                                auction.auctionLocationStreet ||
                                auction.auctionLocationZip ||
                                auction.auctionLocationCity ||
                                auction.auctionLocationCountry
                            "
                        >
                            <p>
                                {{ auction.auctionLocationCity }}
                                <span
                                    v-if="
                                        auction.auctionLocationCity &&
                                        auction.auctionLocationCountry
                                    "
                                    >,</span
                                >
                                {{ auction.auctionLocationCountry }}
                            </p>
                            <p v-if="auction.auctionLocationAdditionalText">
                                {{ auction.auctionLocationAdditionalText }}
                            </p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="auctionDate">{{ date }}</div>
                        <div class="clearfix"></div>
                        <a
                            v-if="!loggedIn"
                            @click="doLogin()"
                            class="button button-dark-grey hidden-below-md"
                            >{{ $t('livebidding_register') }}</a
                        >
                        <div
                            v-if="auction.auctionLiveBiddingLink"
                            class="clearfix"
                        ></div>
                        <div class="auctionOnlineBiddingEndTitle">
                            {{ $t('bid_end') }}:
                        </div>
                        <div
                            v-for="session in sessions"
                            :key="session.sessionId"
                        >
                            <div class="auctionOnlineBiddingEnd">
                                <span class="auctionOnlineBiddingEndLabel">
                                    {{ $t('part') }} {{ session.sessionLabel }}
                                    <span class="light"
                                        >({{ session.minLotId }} -
                                        {{ session.maxLotId }}):</span
                                    >
                                </span>
                                <span class="auctionOnlineBiddingEndDate">
                                    <date-timezone
                                        :date="session.sessionEnds"
                                    />
                                </span>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <div class="auex-box">
                            <span class="auex-poweredby">{{
                                $t('powered_by')
                            }}</span>
                            <img
                                width="66"
                                height="28"
                                class="auex-logo"
                                alt="auex"
                                src="/assets/svg/auex-logo.svg"
                            />
                        </div>
                    </sidebar-box>
                </aside>
                <!--
        liveBidding:<br />
        {{liveBidding.currentSource}}
        <span v-if="liveBidding.currentSource">GOT CURRENT SOURCE</span>
        -->
                <div class="lot-content col col-md-9">
                    <iframe
                        ref="liveBiddingFull"
                        id="liveBiddingFrameFull"
                        v-iframe-resize="{ log: false, checkOrigin: false }"
                        v-if="liveBidding.currentSource"
                        :src="liveBidding.currentSource"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs';
import AuctionBanner from '../components/AuctionBanner.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import ConfirmOverlay from '../components/ConfirmOverlay.vue';
import SidebarBox from '../components/SidebarBox.vue';
import DateTimezone from '../components/DateTimezone.vue';
import { dateRange } from '@/filters/date';
import { PAGINATION_DEFAULTS } from '../config.js';
import {
    getCompanyDetails,
    getAuctionDetails,
    getSessions,
    getDatashares,
    postDatashare,
    getLiveBiddingToken,
    getAuexAuctionLiveBiddingStatusCode,
} from '../lib/apis';

// eslint-disable-next-line
import iframeResize from '@/lib/v-iframe-resize.js';

export default {
    directives: {
        'iframe-resize': {
            bind(el, binding) {
                // eslint-disable-next-line
                iFrameResize(binding.value, el);
            },
        },
    },
    name: 'Auction_Livebidding_Page',
    components: {
        AuctionTabs,
        SidebarBox,
        Breadcrumb,
        AuctionBanner,
        ConfirmOverlay,
        DateTimezone,
    },
    data: function () {
        return {
            company: {},
            liveBidding: {
                base: false,
                url: {
                    path: '/bieten/sixbid',
                    complete: false,
                },
                GTCUrl: {
                    path: '/iframes/pager/sixbid',
                    complete: false,
                },
                currentSource: false,
            },
            auction: {},
            auctionStatus: {
                found: false,
                status: 0,
            },
            sessions: [],
            datashares: {},
            showConsent: false,
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    methods: {
        doLogin() {
            if (typeof preventStore === 'undefined') {
                this.$store.commit('tasks/setTask', {
                    path: this.$route.path,
                    fn: null,
                    args: null,
                    component: this.$options.name,
                });
            }

            this.$router.push({
                name: 'redirect.login',
                query: { redirect: window.location.href },
            });
        },
        receiveMessage(event) {
            switch (event.data) {
                case 'login':
                    this.$router.push({
                        name: 'redirect.login',
                        query: { redirect: window.location.href },
                    });
                    break;
                case 'reinit':
                    // reload iFrame
                    this.loadLivebiddingIframe(true); // required for re-auth with new token
                    break;
                case 'triggeraccept':
                    this.checkIframeStatus();
            }
        },
        buildIframeURL: function (scenario, token, loggedout, error) {
            var params = '';
            var url = '';

            // we only allow de and en here
            const auexLanguage = this.$route.params.lang === 'de' ? 'de' : 'en';

            // settings based on iframe scenario (livebidding or GTC (deutsch: AGB))
            switch (scenario) {
                case 'gtc':
                    params = {
                        lang: auexLanguage,
                        sixbidAuctionId: this.$route.params.auctionId,
                    };

                    url =
                        this.liveBidding.base +
                        this.liveBidding.GTCUrl.path +
                        (this.liveBidding.base.includes('?') ? '&' : '?');

                    if (token) params.sixbidtoken = token;
                    if (loggedout) params.loggedout = '';
                    if (!this.hasDatashare) params.rejected = '';

                    break;

                default:
                    params = {
                        lang: auexLanguage,
                        callbackUrl: encodeURIComponent(this.$route.fullPath),
                        sixbidAuctionId: this.$route.params.auctionId,
                    };

                    url =
                        this.liveBidding.base +
                        this.liveBidding.url.path +
                        (this.liveBidding.base.includes('?') ? '&' : '?');

                    if (token) params.sixbidtoken = token;
                    if (error) params.error = '';
                    if (loggedout) params.loggedout = '';
                    if (!this.hasDatashare) params.rejected = '';

                    break;
            }

            // build query string
            var queryString = '';

            // console.log("building Query String");

            Object.entries(params).forEach((entry) => {
                const [key, value] = entry;
                if (queryString != '') queryString += '&';
                queryString += key + (value ? '=' + value : '');
            });

            // console.log("url is " + url);
            // console.log("queryString is " + queryString);

            // return final URL
            return url + queryString;
        },
        loadLivebiddingIframe: function (withToken) {
            if (this.liveBidding.base) {
                // console.log("this.showLivebiddingIframe", this.showLivebiddingIframe);

                let iframeScenario = this.showLivebiddingIframe
                    ? 'livebidding'
                    : 'gtc'; // set iframe scenario

                if (withToken == true) {
                    // we only allow de and en here
                    const auexLanguage =
                        this.$route.params.lang === 'de' ? 'de' : 'en';
                    // Retrieve iFrame Token. We hardcode 'auex' because it will be the only platform for now
                    getLiveBiddingToken(
                        this.auction.companyId,
                        'auex',
                        auexLanguage
                    )
                        .then((result) => {
                            this.liveBidding.currentSource =
                                this.buildIframeURL(
                                    iframeScenario,
                                    result.data.challenge,
                                    false,
                                    false
                                );
                        })
                        .catch(() => {
                            // If backend request fails we load the iframe without token
                            this.liveBidding.currentSource =
                                this.buildIframeURL(
                                    iframeScenario,
                                    false,
                                    false,
                                    true
                                );
                        });
                } else {
                    //Load iFrame without Token
                    this.liveBidding.currentSource = this.buildIframeURL(
                        iframeScenario,
                        false,
                        !this.$store.getters['account/loggedIn'],
                        false
                    );
                }
            }
        },
        userAccept: function () {
            // If user accepts we send a create Datashare request to the backend, hide the consent popup and load the iframe
            this.createDatashare().then(() => {
                this.showConsent = false;
                this.loadLivebiddingIframe(true);
            });
        },
        userDecline: function () {
            // If user declines we hide the consent popup and load the iframe
            this.showConsent = false;
            this.loadLivebiddingIframe(false);
        },
        createDatashare: function () {
            return postDatashare({}, this.$route.params.lang).then(() => {
                return true;
            });
        },
        checkIframeStatus: function () {
            if (this.loggedIn) {
                getDatashares({}, this.$route.params.lang).then((result) => {
                    this.datashares = result.data;
                    if (this.hasDatashare) {
                        this.loadLivebiddingIframe(true);
                    } else {
                        this.showConsent = true;
                    }
                });
            } else {
                this.loadLivebiddingIframe(false);
            }
        },
        getLivebiddingStatus: function () {
            getAuexAuctionLiveBiddingStatusCode(
                this.auction.liveBiddingUrls.auex,
                this.auction.auctionId
            ).then((result) => {
                // only load Iframe if status changed to surpress unecessary iframe reloading
                if (this.auctionStatus.status !== result.data.status) {
                    this.auctionStatus = result.data;
                    this.checkIframeStatus();
                }
            });
        },
    },
    computed: {
        showLivebiddingIframe() {
            return this.auctionStatus.status >= 2;
        },
        showGTCIframe() {
            return this.auctionStatus.status < 2;
        },
        loggedIn() {
            return this.$store.getters['account/loggedIn'];
        },
        hasDatashare() {
            // Check if platform has active datashare
            // As long as "auex" is the only platform this check is hardcoded. Later we can make it dynamic
            return 'auex' in this.datashares;
        },
        date() {
            return dateRange(
                this.auction.auctionStart,
                this.auction.auctionEnd
            );
        },
    },
    mounted() {
        window.addEventListener('message', this.receiveMessage);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.receiveMessage);
    },
    created: function () {
        Promise.all([
            getCompanyDetails(
                this.$route.params.companySlug,
                this.$route.params.lang
            ),
            getAuctionDetails(
                null,
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
            getSessions(
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
        ])
            .then(([company, auction, sessions]) => {
                this.company = company.data;
                this.auction = auction.data;
                this.sessions = sessions.data;
                this.liveBidding.base =
                    this.auction.liveBiddingUrls.auex || this.liveBidding.base;

                if (
                    this.$route.params.companySlug != this.auction.companySlug
                ) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }

                this.getLivebiddingStatus(); // inital poll of livebidding status

                // setup periodic polling of livebidding status
                setInterval(
                    function () {
                        this.getLivebiddingStatus();
                    }.bind(this),
                    30000
                );

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
    },
};
</script>

<style scoped lang="scss">
#liveBiddingFrameFull {
    width: 1px;
    min-width: 100%;
    border: 0px;
}
.sidebar-box {
    display: block;
}

.auex-box {
    margin-top: 20px;
}
.auex-poweredby {
    font-size: 0.8em;
}
.auex-logo {
    width: 66px;
    height: 28px;
}
.auction-info {
    & > div:nth-child(2) {
        margin-top: $mar-md;
    }

    .auctionOnlineBiddingEndTitle {
        font-weight: bold;
        display: block;
    }

    & > div {
        margin-bottom: 15px;

        .auctionOnlineBiddingEndLabel {
            font-weight: bold;
            display: block;
            margin-bottom: $mar-sm;

            .light {
                font-weight: normal;
            }
        }

        & > p {
            margin-top: 5px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    @media (max-width: $sm-size-max) {
        padding-top: $pad-md;
        padding-bottom: $pad-md;

        & > div {
            width: auto;
            margin-bottom: 5px;
        }
        .auctionDate,
        .auctionICS,
        .auctionOnlineBiddingEndLabel,
        .auctionOnlineBiddingEndDate {
            display: inline-block;
        }
        .clearfix {
            margin-bottom: 0;
        }
        .auctionICS {
            margin-bottom: $mar-sm;
            max-width: 55%;
            margin-left: $mar-lg;
        }
        .auctionDate {
            max-width: 45%;
        }
        .auctionOnlineBiddingEnd {
            margin-bottom: 0;
        }
        .auctionOnlineBiddingEndLabel {
            max-width: 50%;
            float: left;
        }
        .auctionOnlineBiddingEndDate {
            max-width: 50%;
            margin-left: 10px;
        }
        .auctionOnlineBiddingEndTitle {
            width: 100%;
        }
    }
}
</style>
