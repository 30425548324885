<template>
    <div class="company-list-page">
        <div v-if="getAds['companies-top-banner-wide']">
            <ad-banner
                v-if="getAds['companies-top-banner-wide']"
                :ad="getAds['companies-top-banner-wide']"
                :visible="['desktop']"
            />

            <ad-banner
                v-if="getAds['companies-top-banner-medium']"
                :ad="getAds['companies-top-banner-medium']"
                :visible="['tablet']"
            />

            <ad-banner
                v-if="getAds['companies-top-banner-narrow']"
                :ad="getAds['companies-top-banner-narrow']"
                :visible="['mobile']"
            />
        </div>
        <auction-banner v-else />
        <div class="content">
            <h1>{{ $t('companies_sixbid') }}</h1>
            <breadcrumb>{{ $t('companies') }}</breadcrumb>

            <div class="clearfix">
                <div class="searchbox">
                    <input
                        type="text"
                        :placeholder="$t('search_term')"
                        ref="search"
                        v-model="filter"
                    />
                    <button class="button button-blue" @click="search">
                        {{ $t('search') }}
                    </button>
                    <p v-if="filter !== ''">
                        <a @click.prevent="reset">{{ $t('reset') }}</a>
                    </p>
                </div>
                <div class="anchors hidden-below-md">
                    <strong>{{ $t('go_to') }}</strong>
                    <div class="letters">
                        <span
                            v-for="letter in displayedLetters"
                            :key="letter"
                            v-on:click="jumpToLetter(letter)"
                            :class="{
                                'is-disabled':
                                    displayedLetters.indexOf(letter) === -1,
                            }"
                            >{{ letter }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="company-list" ref="list">
                <div
                    class="company-list-section"
                    v-for="groupName in displayedLetters"
                    :key="groupName"
                    :data-letter="groupName"
                >
                    <h2>&mdash; {{ groupName }} &mdash;</h2>
                    <div
                        class="company-list-item"
                        v-for="company in displayedCompanies[groupName]"
                        :key="company.companyId"
                    >
                        <div>
                            <company-image
                                v-if="company.companyId"
                                :alt="company.companyLogoAlt"
                                :title="company.companyLogoTitle"
                                :companyId="company.companyId"
                                isFrontPage="true"
                            ></company-image>
                        </div>
                        <div>
                            <h2>{{ company.companyName }}</h2>
                            <div class="address">
                                {{ company.companyStreet }} -
                                {{ company.companyZip }}
                                {{ company.companyCity }} -
                                {{ company.companyCountry }}
                            </div>
                            <div class="contact">
                                <div>
                                    <span>{{ $t('phone') }}</span>
                                    <a
                                        class="tel-link"
                                        :href="
                                            'tel:' +
                                            company.companyContactPhoneNumber
                                        "
                                        ><span>{{
                                            company.companyContactPhoneNumber
                                        }}</span
                                        ><span>&#xf095;</span></a
                                    >
                                </div>
                                <div>
                                    <span>{{ $t('fax') }}</span>
                                    <span>{{
                                        company.companyContactFaxNumber
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <router-link
                                :to="{
                                    name: 'page.companyDetail',
                                    params: {
                                        companySlug: company.companySlug,
                                    },
                                }"
                                >{{ $t('details_show') }}</router-link
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="
                        visibleCompanies.length >=
                        $global.showCounts.currentCalendarItems
                    "
                    @click="
                        $global.showCounts.currentCalendarItems =
                            $global.showCounts.currentCalendarItems +
                            itemsVisibleDefault
                    "
                    class="hidden-above-lg show-more"
                >
                    <span>
                        {{ $t('auctions_more') }}
                        <i class="fas fa-caret-down"></i>
                    </span>
                </div>
            </div>
        </div>
        <home-sidebar></home-sidebar>
    </div>
</template>

<script>
import HomeSidebar from '../layout/HomeSidebar.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import CompanyImage from '../components/CompanyImage.vue';
import AdBanner from '../components/AdBanner';
import { getCompanies } from '../lib/apis';
import { COMPANIES_VISIBLE } from '../config';
import { mapGetters } from 'vuex';

export default {
    name: 'Company_List_Page',
    components: {
        HomeSidebar,
        Breadcrumb,
        AuctionBanner,
        CompanyImage,
        AdBanner,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_companies'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_companies'),
                },
            ],
        };
    },
    data: function () {
        return {
            companies: [],
            filter: '',
            itemsVisibleDefault: COMPANIES_VISIBLE,
        };
    },
    computed: {
        ...mapGetters({ getAds: 'ads/getAds' }),
        displayedCompanies() {
            return this.groupedVisibleCompanies;
        },
        displayedLetters() {
            let filteredLetters = [];
            let filteredNumbers = [];

            for (let key in this.displayedCompanies) {
                if (this.displayedCompanies[key].length !== 0) {
                    if (key == '0-9') {
                        filteredNumbers.push(key);
                    } else {
                        filteredLetters.push(key);
                    }
                }
            }
            filteredLetters = filteredLetters.sort();
            return filteredLetters.concat(filteredNumbers);
        },
        filteredCompanies() {
            // Filter by USER INPUT
            return this.companies.filter((company) => {
                return (
                    company.companyName
                        .toLowerCase()
                        .indexOf(this.filter.toLowerCase()) !== -1
                );
            });
        },
        visibleCompanies() {
            //
            return this.filteredCompanies.filter((company, index) => {
                if (this.$global.isMobile) {
                    return index < this.$global.showCounts.currentCalendarItems;
                }
                return true;
            });
        },
        groupedVisibleCompanies() {
            // group into OBJECT
            return this.visibleCompanies.reduce(this.reducer, {});
        },
    },
    methods: {
        setVisibleCompanies() {
            const visibleCompanies = this.filteredCompanies.filter(
                (company, index) => {
                    if (this.$global.isMobile) {
                        return (
                            index < this.$global.showCounts.currentCalendarItems
                        );
                    }
                    return true;
                }
            );

            this.visibleCompanies = visibleCompanies;
        },
        reducer(result, company) {
            var key = company.companyName[0].toUpperCase();
            if (isNaN(key) === false) {
                key = '0-9';
            }
            result[key] = result[key] || [];
            result[key].push(company);
            return result;
        },
        search() {
            this.filter = this.$refs.search.value;
        },
        reset() {
            this.filter = '';
            this.$refs.search.value = '';
        },
        jumpToLetter(letter) {
            let el = this.$refs.list.querySelector(`[data-letter="${letter}"]`);

            this.scrollTo({ to: el.offsetTop, offset: 120 });
        },
        sortByCompanyName(a, b) {
            if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
                return 1;
            }
            if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
                return -1;
            }
            return 0;
        },
    },
    mounted() {
        getCompanies(this.$route.params.lang, 250)
            .then((response) => {
                this.companies = response.data.items.sort(
                    this.sortByCompanyName
                );

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
    },
    watch: {
        '$global.isMobile'() {
            this.$global.showCounts.currentCalendarItems =
                this.itemsVisibleDefault;
        },
        filter() {
            this.$global.showCounts.currentCalendarItems =
                this.itemsVisibleDefault;
        },
    },
};
</script>

<style lang="scss" scoped>
.company-list-section > h2 {
    text-align: center;
}
.company-list-item {
    background-color: $grey-bg-lightest;
    padding: $pad-lg;
    margin: $mar-lg 0;
    display: flex;
    position: relative;

    @media (max-width: $sm-size-max) {
        flex-flow: column;
    }

    & > div {
        img {
            @media (max-width: $sm-size-max) {
                display: inline-block;
                margin-right: $mar-xl;
                float: left;
            }
        }
        h2 {
            margin-top: 0;
        }
        &:nth-child(1) {
            @media (min-width: $md-size) {
                margin-right: $mar-xl;
                width: 100px;
            }
            @media (min-width: $lg-size) {
                width: 140px;
            }
        }
        &:nth-child(2) {
            margin-top: $mar-md;

            @media (min-width: $md-size) {
                margin-top: 0;
                max-width: calc(100% - 235px);
                width: 100%;
            }
            @media (min-width: $lg-size) {
                max-width: calc(100% - 290px);
            }
        }
        &:nth-child(3) {
            @media (max-width: $sm-size-max) {
                position: absolute;
                right: $pad-lg;
                top: $pad-lg;
            }

            @media (min-width: $md-size) {
                margin-top: 0;
                margin-left: auto;
                align-self: flex-end;
            }

            a {
                display: inline-block;
                text-align: right;
            }
        }
    }
}

.searchbox {
    margin-top: 20px;
    width: 50%;
    float: left;

    input {
        width: 60%;
    }
}

.anchors {
    float: left;
    margin-top: 15px;
    width: 50%;

    .letters {
        display: flex;

        span {
            cursor: pointer;
            display: inline-block;
            padding: 2px 1px;

            &.is-disabled {
                color: $grey-bg;
            }
        }
    }
}

.address {
    color: $grey-bg-darker;
}

.contact {
    margin-top: $mar-md;
    & > div {
        display: flex;

        & > span:first-child {
            display: inline-block;
            width: 75px;
        }

        .tel-link {
            width: calc(100% - 75px);

            span:first-child {
                max-width: calc(100% - 35px);
            }
        }
    }
}
</style>
