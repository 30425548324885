<template>
    <div class="collection-areas-component">
        <!-- collection area tabs -->
        <ul class="tab-button-list collection-filter hidden-below-lg">
            <li>
                <button
                    v-bind:class="{
                        'button button-grey': activeTab !== null,
                        'button button-blue': activeTab === null,
                    }"
                    @click="fetchCollectionAreaLabels()"
                >
                    {{ $t('collection_all') }}
                </button>
            </li>
            <li v-for="tab in tabs" :key="tab.collectionAreaTypeId">
                <button
                    v-bind:class="{
                        'button button-grey':
                            activeTab !== tab.collectionAreaTypeId,
                        'button button-blue':
                            activeTab === tab.collectionAreaTypeId,
                    }"
                    @click="fetchCollectionAreaLabels(tab.collectionAreaTypeId)"
                >
                    {{ tab.collectionAreaTypeLabel }}
                </button>
            </li>
        </ul>
        <select
            @change="selectChanged()"
            ref="select"
            class="hidden-above-lg collection-select"
        >
            <option value>{{ $t('collection_all') }}</option>
            <option
                v-for="tab in tabs"
                :key="tab.collectionAreaTypeId"
                :value="tab.collectionAreaTypeId"
            >
                {{ tab.collectionAreaTypeLabel }}
            </option>
        </select>
        <ul class="collection-list">
            <li v-for="area in areas" :key="area.collectionAreaId">
                <router-link
                    class="button button-white"
                    :to="{
                        name: 'page.collectionArea',
                        params: { collectionAreaSlug: area.collectionAreaSlug },
                    }"
                    >{{ area.collectionAreaTitle }}</router-link
                >
            </li>
        </ul>
    </div>
</template>

<script>
import Loader from './Loader';
import { getCollectionAreaTabs, getCollectionAreaLabels } from '../lib/apis';

export default {
    components: {
        Loader,
    },
    props: {},
    data: function () {
        return {
            tabs: [],
            areas: [],
            activeTab: null,
        };
    },
    mounted: function () {
        this.fetchCollectionAreaTabs();
    },
    methods: {
        fetchCollectionAreaTabs() {
            getCollectionAreaTabs(this.$route.params.lang)
                .then((tabs) => {
                    this.tabs = tabs.data;
                    this.fetchCollectionAreaLabels();
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        fetchCollectionAreaLabels(collectionAreaType = null) {
            this.activeTab = collectionAreaType;
            const data = {
                collectionAreaTypeId: collectionAreaType,
            };

            getCollectionAreaLabels(data, this.$route.params.lang)
                .then((areas) => {
                    this.areas = areas.data;
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        selectChanged() {
            if (this.$refs.select.value !== '') {
                this.fetchCollectionAreaLabels(this.$refs.select.value);
            } else {
                this.fetchCollectionAreaLabels();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.collection-areas-component {
    text-align: center;
}

.collection-filter {
    li {
        button {
            margin: 0 1px;
            padding: 0.5em 1.5em;
        }
    }
}

.collection-list {
    margin: 0;
    padding: 0;

    margin-top: $mar-lg;

    @media (min-width: $sm-size) {
        display: flex;
        flex-flow: row wrap;
    }

    li {
        display: flex;

        @media (min-width: $sm-size) {
            width: calc(25% - #{3 * $mar-2xl / 4});
            margin-right: $mar-2xl;
        }

        &:nth-child(4n) {
            margin-right: 0;
        }

        .button {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            margin-top: $mar-md;

            line-height: 1.2em;
            padding: 1em 1em;
        }
    }
}
.collection-select {
    width: 100%;

    @media (min-width: $sm-size) {
        width: auto;
    }
}
</style>
