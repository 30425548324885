var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-list-page"},[(_vm.getAds['companies-top-banner-wide'])?_c('div',[(_vm.getAds['companies-top-banner-wide'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['companies-top-banner-wide'],"visible":['desktop']}}):_vm._e(),(_vm.getAds['companies-top-banner-medium'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['companies-top-banner-medium'],"visible":['tablet']}}):_vm._e(),(_vm.getAds['companies-top-banner-narrow'])?_c('ad-banner',{attrs:{"ad":_vm.getAds['companies-top-banner-narrow'],"visible":['mobile']}}):_vm._e()],1):_c('auction-banner'),_c('div',{staticClass:"content"},[_c('h1',[_vm._v(_vm._s(_vm.$t('companies_sixbid')))]),_c('breadcrumb',[_vm._v(_vm._s(_vm.$t('companies')))]),_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"searchbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],ref:"search",attrs:{"type":"text","placeholder":_vm.$t('search_term')},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}),_c('button',{staticClass:"button button-blue",on:{"click":_vm.search}},[_vm._v("\n                    "+_vm._s(_vm.$t('search'))+"\n                ")]),(_vm.filter !== '')?_c('p',[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('reset')))])]):_vm._e()]),_c('div',{staticClass:"anchors hidden-below-md"},[_c('strong',[_vm._v(_vm._s(_vm.$t('go_to')))]),_c('div',{staticClass:"letters"},_vm._l((_vm.displayedLetters),function(letter){return _c('span',{key:letter,class:{
                            'is-disabled':
                                _vm.displayedLetters.indexOf(letter) === -1,
                        },on:{"click":function($event){return _vm.jumpToLetter(letter)}}},[_vm._v(_vm._s(letter))])}),0)])]),_c('div',{ref:"list",staticClass:"company-list"},[_vm._l((_vm.displayedLetters),function(groupName){return _c('div',{key:groupName,staticClass:"company-list-section",attrs:{"data-letter":groupName}},[_c('h2',[_vm._v("— "+_vm._s(groupName)+" —")]),_vm._l((_vm.displayedCompanies[groupName]),function(company){return _c('div',{key:company.companyId,staticClass:"company-list-item"},[_c('div',[(company.companyId)?_c('company-image',{attrs:{"alt":company.companyLogoAlt,"title":company.companyLogoTitle,"companyId":company.companyId,"isFrontPage":"true"}}):_vm._e()],1),_c('div',[_c('h2',[_vm._v(_vm._s(company.companyName))]),_c('div',{staticClass:"address"},[_vm._v("\n                            "+_vm._s(company.companyStreet)+" -\n                            "+_vm._s(company.companyZip)+"\n                            "+_vm._s(company.companyCity)+" -\n                            "+_vm._s(company.companyCountry)+"\n                        ")]),_c('div',{staticClass:"contact"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('phone')))]),_c('a',{staticClass:"tel-link",attrs:{"href":'tel:' +
                                        company.companyContactPhoneNumber}},[_c('span',[_vm._v(_vm._s(company.companyContactPhoneNumber))]),_c('span',[_vm._v("")])])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fax')))]),_c('span',[_vm._v(_vm._s(company.companyContactFaxNumber))])])])]),_c('div',[_c('router-link',{attrs:{"to":{
                                name: 'page.companyDetail',
                                params: {
                                    companySlug: company.companySlug,
                                },
                            }}},[_vm._v(_vm._s(_vm.$t('details_show')))])],1)])})],2)}),(
                    _vm.visibleCompanies.length >=
                    _vm.$global.showCounts.currentCalendarItems
                )?_c('div',{staticClass:"hidden-above-lg show-more",on:{"click":function($event){_vm.$global.showCounts.currentCalendarItems =
                        _vm.$global.showCounts.currentCalendarItems +
                        _vm.itemsVisibleDefault}}},[_c('span',[_vm._v("\n                    "+_vm._s(_vm.$t('auctions_more'))+"\n                    "),_c('i',{staticClass:"fas fa-caret-down"})])]):_vm._e()],2)],1),_c('home-sidebar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }