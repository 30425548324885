<template>
    <div
        class="carousel"
        @mouseenter="pauseRotation"
        @mouseleave="unpauseRotation"
    >
        <transition name="transition">
            <slot></slot>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: 0,
            currentInterval: 0,
        };
    },
    props: {
        autoPlayInterval: {
            type: Number,
            required: true,
        },
    },
    computed: {
        slides() {
            return this.$children;
        },
        slidesCount() {
            return this.slides.length;
        },
        nextIndex() {
            const nextIndex = this.activeIndex + 1;

            return nextIndex <= this.slidesCount - 1 ? nextIndex : 0;
        },
        prevIndex() {
            const prevIndex = this.activeIndex - 1;

            return prevIndex >= 0 ? prevIndex : this.slidesCount - 1;
        },
    },
    mounted() {
        this.goToIndex(this.activeIndex);
        this.runAutoplay();
    },
    methods: {
        goToIndex(index) {
            const direction = index > this.activeIndex ? 'up' : 'down';
            this.slides[this.activeIndex].hide(direction);
            this.slides[index].show(direction);
            this.activeIndex = index;
        },
        next() {
            this.goToIndex(this.nextIndex);
        },
        pauseRotation() {
            clearInterval(this.currentInterval);
        },
        unpauseRotation() {
            this.runAutoplay();
        },
        runAutoplay() {
            this.currentInterval = setInterval(() => {
                this.next();
            }, this.autoPlayInterval);
        },
    },
};
</script>

<style>
.carousel {
    position: relative;
    height: 100%;
}
.slides {
    position: relative;
    height: 100%;
}
.slide {
    height: 100%;
}
</style>
