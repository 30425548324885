<template>
    <div v-if="noteText || noteEditMode">
        <div v-if="!noteEditMode">
            <span class="notelabel" v-if="$global.isMobile"
                >{{ $t('note') }}:
            </span>
            <span class="notetext">{{ noteText }}</span>
            <a
                href="#"
                class="actionlink"
                v-on:click.prevent="noteEditMode = true"
                >{{ $t('edit') }}</a
            >
        </div>
        <div v-else>
            <textarea
                v-model="noteText"
                :placeholder="$t('note_enter')"
            ></textarea>
            <a
                href="#"
                class="actionlink"
                v-on:click.prevent="updateNote(lotId, noteText)"
                >{{ $t('save') }}</a
            >
        </div>
    </div>
    <div v-else class="icons">
        <i
            class="far fa-edit fa-lg"
            :title="$t('note_enter')"
            v-on:click="noteEditMode = true"
        ></i>
    </div>
</template>

<script>
import Auth from '../lib/Authorization';

export default {
    name: 'WatchListNote',
    props: {
        watchId: {
            required: true,
        },
        lotId: {
            required: true,
        },
        note: {
            type: String,
            required: false,
            default: '',
        },
    },
    data: function () {
        return {
            noteEditMode: false,
            noteText: this.note,
        };
    },
    methods: {
        updateNote: function (lotId, note) {
            const data = {
                lotId: lotId,
                note: note,
            };
            Auth.createAxiosClient()
                .then((axios) => {
                    return axios.put(
                        process.env.VUE_APP_USER_PRIVATE_API_URL +
                            '/my-watchlist',
                        { lotId: data.lotId, note: data.note }
                    );
                })
                .then(() => {
                    this.noteEditMode = false;
                })
                .catch(() => {
                    /* eslint-disable-next-line no-console */
                    //console.log("error");
                });
        },
    },
};
</script>

<style scoped lang="scss">
.notelabel {
    font-weight: bold;
}
.notetext {
    display: inline-block;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 100%;
    @media (min-width: $md-size) {
        max-width: 260px;
    }
}
a.actionlink {
    margin-top: $mar-sm;
    display: block;
    @media (max-width: $xs-size-max) {
        text-align: right;
    }
}
.icons {
    @media (max-width: $xs-size-max) {
        margin-bottom: $mar-lg;
    }
}
.far {
    cursor: pointer;
    @media (max-width: $xs-size-max) {
        float: right;
    }
}

textarea {
    height: 9em;
    font-size: 14px;
    min-width: 260px;
    width: 100%;
}
</style>
