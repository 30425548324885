<template>
    <div :class="{ 'section-header': true, 'section-header-spacer': spacer }">
        <h1
            v-if="size == 'h1'"
            :class="'style-like-' + headlinestyle + ' section-header-title'"
        >
            {{ title }}
        </h1>
        <h2
            v-if="size == 'h2'"
            :class="'style-like-' + headlinestyle + ' section-header-title'"
        >
            {{ title }}
        </h2>
        <h3
            v-if="size == 'h3'"
            :class="'style-like-' + headlinestyle + ' section-header-title'"
        >
            {{ title }}
        </h3>
        <p v-if="description">{{ description }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        spacer: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: 'h2',
        },
        headlinestyle: {
            type: String,
            default: 'h2',
        },
    },
};
</script>

<style lang="scss">
.section-header {
    margin-top: $mar-2xl;

    &:first-child {
        margin-top: 0;
    }

    &-title {
        margin-top: 0;
    }

    &-spacer {
        text-align: center;

        &:before {
            content: '';
            display: inline-block;
            height: 2px;
            width: 280px;
            background: #000;
            margin-bottom: $mar-xl;
        }
    }
}
</style>
