<template>
    <div class="news-page">
        <h1>{{ $t('page_title_news') }}</h1>
        <breadcrumb>
            {{ $t('page_title_news') }}
        </breadcrumb>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';

export default {
    name: 'News',
    components: {
        Breadcrumb,
    },
};
</script>

<style scoped></style>
