<template>
    <div id="page-highlight-special-all">
        <h1>{{ boxTitleDisplay }}</h1>
        <breadcrumb>{{ boxTitleDisplay }}</breadcrumb>
        <div class="highlight-list">
            <div class="lot-list large">
                <loader ref="loader"></loader>
                <lot-list-item
                    v-for="lot in lots"
                    :key="lot.lotId"
                    :lot="lot"
                    :showImage="true"
                    :showLotNr="true"
                    :showDescription="true"
                    :showCompany="false"
                    :showAuction="false"
                    :showStartingPrice="true"
                    :showEstimatePrice="true"
                    :showResultPrice="false"
                    :showDetailsButton="true"
                    :showBidding="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import LotListItem from '../components/LotListItem.vue';
import Loader from '../components/Loader';

import { getHighlightSpecialBoxContent, getWatchListIds } from '../lib/apis';

export default {
    name: 'pageHighlightSpecialAll',
    components: {
        Breadcrumb,
        LotListItem,
        Loader,
    },
    data: function () {
        return {
            lots: [],
            lotListDisplayMode: 'large',
            boxTitle: '',
        };
    },
    mounted: function () {
        this.fetchData();
    },
    computed: {
        boxTitleDisplay: function () {
            return this.boxTitle + ' Highlights';
        },
    },
    methods: {
        fetchData() {
            this.$refs.loader.show();

            // Fetch lots.
            getHighlightSpecialBoxContent(this.$route.params.lang)
                .then((response) => {
                    this.lots = response.data.lots;
                    this.boxTitle = response.data.boxTitle;
                    this.$refs.loader.hide();

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );

                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    this.$refs.loader.hide();

                    this.genericApiErrorHandling(e);
                });

            // Fetch data for watched icon.
            getWatchListIds(this.$route.params.lang).then((response) => {
                this.$store.commit('lotWatchlist/setWatches', response.data);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.highlight-list {
    margin-top: $mar-xl;
}

.group.view {
    text-align: right;
    margin-bottom: $mar-xl;
    .icon {
        display: inline-block;
        color: $text-color;
        padding: 5px 2px;
        margin-left: 5px;

        &.active {
            color: $main-color;
        }
    }
}
.lot-list {
    margin-top: $mar-xl;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &.large {
        .lot-list-item {
            @media (min-width: $md-size) {
                width: calc(50% - #{ceil($mar-xl / 2) + 1});

                &:nth-child(2n) {
                    margin-right: $mar-xl;
                }
            }
        }
    }

    &.small {
        .lot-list-item {
            @media (min-width: $md-size) {
                width: calc(33% - #{ceil($mar-lg / 2)});
                margin-right: $mar-lg;

                &:nth-child(3n + 1) {
                    margin-right: 0;
                }
            }
        }
    }

    &.detail {
        .lot-list-item {
            @media (min-width: $md-size) {
                width: 100%;
            }
        }
    }
}
</style>
