<template>
    <div class="account-page" id="account-watchlist-page">
        <div class="row">
            <account-navigation />
            <div class="col col-md-12 col-lg-12">
                <account-header />
                <h1>{{ $t('watchlist') }}</h1>
                <pagination
                    ref="PaginationTop"
                    @pagination-changed="getWatches()"
                    :hasPerPageSelect="true"
                />
                <!--<table width="100%" v-if="watches.length > 0">-->
                <table width="100%">
                    <thead class="hidden-below-sm">
                        <tr>
                            <th>&nbsp;</th>
                            <th>{{ $t('image') }}</th>
                            <th>{{ $t('auction_lot_nr') }}</th>
                            <th>{{ $t('prices') }}</th>
                            <th>{{ $t('note') }}</th>
                            <th>{{ $t('options') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(watch, index) in watches"
                            :key="watch.watchId"
                            :class="index % 2 === 1 ? 'odd' : 'even'"
                        >
                            <td class="check">
                                <label class="container">
                                    <input
                                        type="checkbox"
                                        v-model="checkedWatches"
                                        :value="watch.lotId"
                                    />
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td class="image">
                                <router-link
                                    :to="{
                                        name: 'page.auctionLotDetail',
                                        params: {
                                            page: PAGINATION_DEFAULTS.START_PAGE,
                                            perPage:
                                                PAGINATION_DEFAULTS.PER_PAGE,
                                            auctionId: watch.auctionId,
                                            companySlug: watch.companySlug,
                                            lotId: watch.lotId,
                                            categorySlug:
                                                watch.categorySlug === ''
                                                    ? '-'
                                                    : watch.categorySlug,
                                            lotSlug: watch.lotSlug,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    <img
                                        :alt="$t('lot')"
                                        loading="lazy"
                                        :src="getWatchImage(watch)"
                                        width="256"
                                        height="128"
                                    />
                                </router-link>
                            </td>
                            <td class="desc">
                                <div>
                                    <router-link
                                        :to="{
                                            name: 'page.auctionLotDetail',
                                            params: {
                                                page: PAGINATION_DEFAULTS.START_PAGE,
                                                perPage:
                                                    PAGINATION_DEFAULTS.PER_PAGE,
                                                auctionId: watch.auctionId,
                                                companySlug: watch.companySlug,
                                                lotId: watch.lotId,
                                                categorySlug:
                                                    watch.categorySlug === ''
                                                        ? '-'
                                                        : watch.categorySlug,
                                                lotSlug: watch.lotSlug,
                                            },
                                        }"
                                        target="_blank"
                                        ><span v-if="watch.companyNameShort">{{
                                            watch.companyNameShort
                                        }}</span>
                                        <span v-else>{{
                                            watch.companyName
                                        }}</span>
                                        {{ watch.auctionTitle }},
                                        {{ $t('lot') }} {{ watch.lotNumber
                                        }}{{
                                            watch.lotNumberAffix
                                        }}</router-link
                                    >
                                    <span>{{
                                        watch.auctionStart | dateLongFormater
                                    }}</span>
                                    <span
                                        v-if="
                                            dateTimeInFuture(watch.sessionEnds)
                                        "
                                        >{{ $t('bid_end') }}:
                                        <date-timezone
                                            :date="watch.sessionEnds"
                                        />
                                    </span>
                                    <span v-else>{{ $t('bid_ended') }}</span>
                                </div>
                                <div class="icons hidden-above-sm">
                                    <i
                                        class="far fa-trash-alt fa-lg"
                                        @click="deleteWatch(watch.lotId)"
                                    ></i>
                                </div>
                            </td>
                            <td class="prices">
                                <div>
                                    <span
                                        >{{ $t('lot_price_start') }}&nbsp;</span
                                    >
                                    <price
                                        :value="Number(watch.lotStartingPrice)"
                                        :currency="watch.auctionCurrency"
                                    ></price>
                                </div>

                                <div v-if="watch.lotEstimate > 0">
                                    <span>{{ $t('lot_estimate') }}&nbsp;</span>
                                    <price
                                        :value="Number(watch.lotEstimate)"
                                        :currency="watch.auctionCurrency"
                                    ></price>
                                </div>

                                <div v-if="watch.lotPriceRealised > 0">
                                    <strong>
                                        <span
                                            >{{ $t('bid_hammer') }}&nbsp;</span
                                        >
                                    </strong>
                                    <strong>
                                        <price
                                            :value="
                                                Number(watch.lotPriceRealised)
                                            "
                                            :currency="watch.auctionCurrency"
                                        ></price>
                                    </strong>
                                </div>
                            </td>

                            <td class="note">
                                <watch-list-note
                                    :key="watch.watchId"
                                    :watchId="watch.watchId"
                                    :lotId="watch.lotId"
                                    :note="watch.note"
                                ></watch-list-note>
                            </td>
                            <td class="actions">
                                <bid-lot-input
                                    class="bid-lot-input-container"
                                    :lot="watch"
                                    :showBiddingCompletedNotice="false"
                                    @bid-placed="
                                        addMessageForWatchItem(
                                            watch.watchId,
                                            $event
                                        )
                                    "
                                ></bid-lot-input>

                                <bid-lot-message
                                    v-if="getMessageForWatchItem(watch.watchId)"
                                    :lot="watch"
                                    :message-type="
                                        getMessageForWatchItem(watch.watchId)
                                            .type
                                    "
                                    :message-content="
                                        getMessageForWatchItem(watch.watchId)
                                            .content
                                    "
                                ></bid-lot-message>

                                <div class="icons trash-icon hidden-below-sm">
                                    <i
                                        class="far fa-trash-alt fa-lg"
                                        @click="deleteWatch(watch.lotId)"
                                    ></i>
                                </div>
                            </td>
                        </tr>
                        <tr
                            v-if="!watches.length || watches.length <= 0"
                            style="white-space: nowrap"
                        >
                            <td colspan="5">
                                {{ $t('watchlist_empty') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination
                    ref="PaginationBottom"
                    @pagination-changed="getWatches()"
                    :hasPerPageSelect="!$global.isMobile"
                >
                    <!--
                    :tableFooter=" !$global.isMobile"
                    v-show="watches.length > 0"
                    -->
                    <select
                        @change="triggerAction"
                        v-model="action"
                        :disabled="checkedWatches.length &lt; 1"
                        v-if="
                            !$global.isMobile &&
                            !$global.isTablet &&
                            watches.length
                        "
                    >
                        <option value>{{ $t('action_choose') }}</option>
                        <option value="delete">{{ $t('delete') }}</option>
                    </select>
                </pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Auth from '../lib/Authorization';
import CloudImageGetter from '../lib/CloudImageGetter';
import AccountHeader from '../components/AccountHeader.vue';
import AccountNavigation from '../components/AccountNavigation.vue';
import Pagination from '../components/Pagination.vue';
import BidLotInput from '../components/BidLotInput.vue';
import BidLotMessage from '../components/BidLotMessage.vue';
import WatchListNote from '../components/WatchListNote.vue';
import DateTimezone from '../components/DateTimezone.vue';
import Price from '../components/Price.vue';
import _ from 'lodash';
import { PAGINATION_DEFAULTS } from '../config.js';
import { compareDates } from '../lib/date';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default {
    name: 'Account_Watchlist',
    components: {
        AccountHeader,
        AccountNavigation,
        Pagination,
        BidLotInput,
        BidLotMessage,
        WatchListNote,
        Price,
        DateTimezone
    },
    data: function () {
        return {
            watches: [],
            checkedWatches: [],
            watchesInEditMode: {},
            action: '',
            messages: {},
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    methods: {
        getWatches: function () {
            const data = {
                limit: this.$route.params.perPage,
                page: this.$route.params.page,
                lang: this.$route.params.lang,
            };
            Auth.createAxiosClient()
                .then((axios) => {
                    return axios.get(
                        process.env.VUE_APP_USER_PRIVATE_API_URL +
                            '/my-watchlist',
                        { params: data }
                    );
                })
                .then((response) => {
                    this.watches = response.data.items;
                    this.$refs.PaginationTop.responseHelper(response);
                    this.$refs.PaginationBottom.responseHelper(response);
                })
                .catch(() => {
                    /* eslint-disable-next-line no-console */
                    //console.log("error");
                });
        },
        deleteWatch: function (lotId) {
            Auth.createAxiosClient()
                .then((axios) => {
                    return axios.delete(
                        process.env.VUE_APP_USER_PRIVATE_API_URL +
                            '/my-watchlist/' +
                            lotId
                    );
                })
                .then(() => {
                    this.getWatches();
                })
                .catch(() => {
                    /* eslint-disable-next-line no-console */
                    //console.log("error");
                });
        },
        getWatchImage: function (watch) {
            return CloudImageGetter(watch.googleBucketImagePath, 'S');
        },
        addMessageForWatchItem(id, message) {
            Vue.delete(this.messages, id, message);
            Vue.set(this.messages, id, message);
        },
        getMessageForWatchItem(id) {
            return this.messages[id] || false;
        },
        triggerAction: function () {
            let checkedWatches = this.checkedWatches;
            if (this.action == 'delete') {
                Auth.createAxiosClient()
                    .then((axios) => {
                        let promises = _.map(checkedWatches, (lotId) => {
                            return axios.delete(
                                process.env.VUE_APP_USER_PRIVATE_API_URL +
                                    '/my-watchlist/' +
                                    lotId
                            );
                        });
                        return Promise.all(promises);
                    })
                    .then(() => {
                        this.checkedWatches = [];
                        this.action = '';
                        this.getWatches();
                    })
                    .catch(() => {
                        /* eslint-disable-next-line no-console */
                        //console.log("error");
                    });
            }
        },
        dateTimeInFuture(dt) {
            dayjs.extend(utc);
            dayjs.extend(timezone);

            let now = new Date();
            let cet = dayjs.tz(dt, 'Europe/Berlin').local();

            return compareDates(now, cet) === -1;
        },
    },
    created: function () {
        this.getWatches();
    },
};
</script>

<style scoped lang="scss">
/* reset table display */

table {
    border-spacing: 0;
}

thead {
    background: $grey-bg;
}

tbody {
    tr {
        padding: $pad-md 0;
    }
}

tr {
    & > :nth-child(1) {
        width: 35px;
    }
    & > :nth-child(2) {
        width: 220px;
    }
    & > :nth-child(3) {
    }
    & > :nth-child(4) {
        width: 190px;
    }
    & > :nth-child(5) {
    }
    & > :nth-child(6) {
        width: 250px;
    }

    @media (max-width: $md-size-max) {
        & > :nth-child(1) {
            display: none;
        }
        & > :nth-child(2) {
            width: 140px;
        }
        & > :nth-child(3) {
            min-width: 145px;
        }
        & > :nth-child(4) {
        }
        & > :nth-child(5) {
        }
        & > :nth-child(6) {
            width: 250px;
        }
    }
}

td,
th {
    padding: $pad-sm $pad-sm;

    vertical-align: top;

    @media (min-width: $lg-size) {
        padding: $pad-md $pad-lg;
    }

    position: relative;
}

td {
    @media (min-width: $md-size) {
        border-bottom: 1px solid $grey-bg;
    }
    @media (min-width: $lg-size) {
        &.check {
            border-left: 1px solid $grey-bg;
        }
        &.actions {
            border-right: 1px solid $grey-bg;
        }
    }
}

.even {
    background: white;
}

.odd {
    background: $grey-bg-lightest;
}

@media (max-width: $sm-size-max) {
    table,
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        display: block;
    }
}

.check {
    .container {
        padding-left: 12px;
        transform: translateY(-12px);
    }
}

.image {
    /*display: flex;
    justify-content: center;*/

    img {
        max-height: 100px;
    }
}

.desc {
    & > div:first-child {
        & > :first-child {
            margin: 0;
        }
        a,
        span {
            display: block;

            margin-top: $mar-sm;
        }
    }
}

.prices {
    div {
        & > span {
            display: block;
        }

        & + div {
            margin-top: $mar-md;
        }

        @media (min-width: $lg-size) {
            display: flex;
            justify-content: space-between;

            &:nth-child(3) {
                margin-top: $mar-md;
            }
        }
    }
}

.message {
    width: 100%;
    margin-top: $mar-xl;
}

.fa-trash-alt {
    color: $error-color;
}

.actions {
    text-align: right;
    padding-bottom: $pad-md + 40px;
    .bid-lot-input-container {
        text-align: left;
    }
}

.icons {
    padding-bottom: $pad-md;
    i {
        cursor: pointer;
    }
}

.trash-icon {
    position: absolute;
    bottom: $pad-md;
    left: $pad-sm;
    @media (min-width: $md-size) {
        left: auto;
        right: $pad-lg;
    }
}

@media (max-width: $xs-size-max) {
    tr {
        display: block;
        border: 1px solid $grey-bg;
        padding: $pad-md !important;

        & > td:nth-child(n) {
            padding: $pad-sm 0;
            width: 100%;
        }

        & + tr {
            margin-top: $mar-md;
        }

        &.odd {
            background: white;
        }

        & > td:nth-child(1) {
            text-align: center;
        }

        .prices div {
            strong:first-child,
            span:first-child {
                display: inline-block;
                width: 150px;
            }

            strong,
            span {
                display: inline-block;
            }
        }

        .desc {
            display: flex;
            justify-content: space-between;

            .icons {
                margin-left: $mar-lg;

                i + i {
                    margin-left: $mar-lg;
                }
            }
        }

        .bid-lot-input-container {
            width: 100%;
        }
    }
}
</style>
