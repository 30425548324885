import { render, staticRenderFns } from "./TwihList.vue?vue&type=template&id=366681a5&scoped=true&"
import script from "./TwihList.vue?vue&type=script&lang=js&"
export * from "./TwihList.vue?vue&type=script&lang=js&"
import style0 from "./TwihList.vue?vue&type=style&index=0&id=366681a5&scoped=true&lang=scss&"
import style1 from "./TwihList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "366681a5",
  null
  
)

export default component.exports