<template>
    <div id="page-best-coins-all">
        <h1>{{ $t('top_coins_for') }} {{ lastMonth }}</h1>
        <breadcrumb>{{ $t('top_coins_for') }} {{ lastMonth }}</breadcrumb>
        <div class="lot-items">
            <div v-for="lot in lots" :key="lot.lotId" class="lot-item">
                <span class="lot-item-company">{{ lot.companyName }}</span>
                <span class="lot-item-auction">{{ lot.auctionTitle }}</span>

                <router-link
                    class="lot-item-image"
                    :to="{
                        name: 'page.auctionLotDetail',
                        params: {
                            companySlug: lot.companySlug,
                            auctionId: lot.auctionId,
                            categorySlug: lot.categoryId,
                            lotId: lot.lotId,
                            lotSlug: lot.lotSlug,
                        },
                    }"
                >
                    <img :alt="$t('lot')" loading="lazy" :src="getImage(lot, 'M')" width="256" height="128" />
                </router-link>
                <span class="lotnumber" v-if="parseInt(lot.lotNumber, 10) > 0">
                    <span class="label">{{ $t('lot') }}</span>
                    <span class="value">{{ lot.lotNumber }}</span>
                </span>
                <span
                    class="startingprice"
                    v-if="parseInt(lot.lotStartingPrice, 10) > 0"
                >
                    <span class="label">{{ $t('lot_price_start') }}:</span>
                    <price
                        :value="lot.lotStartingPrice"
                        :currency="lot.auctionCurrency"
                    ></price>
                </span>
                <span
                    class="pricerealized"
                    v-if="parseInt(lot.lotPriceRealised, 10) > 0"
                >
                    <span class="label">{{ $t('result') }}:</span>
                    <price
                        :value="lot.lotPriceRealised"
                        :currency="lot.auctionCurrency"
                    ></price>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import CloudImageGetter from '../lib/CloudImageGetter';
import Price from '../components/Price.vue';
import { getBestCoinsBoxContent } from '../lib/apis';
import { formatDate, Month } from '../lib/date';

export default {
    name: 'pageBestCoinsAll',
    components: {
        Breadcrumb,
        Price,
    },
    data: function () {
        return {
            lots: [],
            boxdate: '',
        };
    },
    computed: {
        lastMonth() {
            // @Holger
            if (this.boxdate.length > 1) {
                return formatDate(this.$route.params.lang, this.boxdate, Month);
            } else {
                return '';
            }
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            //this.$refs.loader.show();
            getBestCoinsBoxContent(this.$route.params.lang)
                .then((response) => {
                    this.lots = response.data.lots;
                    // @Holger
                    this.boxdate = response.data.lots[0].bestcoinsDate;
                    // EOF Holger
                    //this.$refs.loader.hide();
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        getImage: function (lot, size) {
            return CloudImageGetter(lot.googleBucketImagePath, size);
        },
    },
};
</script>

<style lang="scss" scoped>
.lot-items {
    margin-top: $mar-lg;
    display: flex;
    flex-wrap: wrap;
}
.lot-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 $mar-lg 0;
    position: relative;

    padding: $pad-lg $pad-lg $pad-lg $pad-lg;
    border: 1px solid $grey-bg;

    .pricerealized {
        display: block;
        margin-top: $pad-lg;
        .label {
            font-weight: bold;
        }
    }

    .startingprice {
        display: block;
        margin-top: $pad-lg;
        .label {
            font-weight: bold;
        }
    }

    .lotnumber {
        display: block;
        bottom: $pad-lg * 5;
        margin-top: $pad-lg * 2;
        .label {
            font-weight: bold;
        }
        .value {
            padding-left: $pad-sm;
        }
    }

    @media (min-width: $sm-size) {
        width: calc(50% - #{ceil(1 * $mar-lg / 2)});
        margin: 0 $mar-lg $mar-lg 0;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    &-company {
        display: block;
        font-weight: bold;
    }

    &-auction {
        display: block;
        margin-top: $mar-md;
    }

    &-image {
        display: block;
        margin-top: $mar-lg;
        text-align: center;
        flex-grow: 1;
    }
}
</style>
