<template>
    <div class="error-box global-error-box">
        <transition name="show-error">
            <div v-if="errors.length > 0" class="alert alert--error">
                <i class="fas fa-exclamation-circle"></i>
                {{ errors }}
                <div class="close" @click="remove">
                    <i class="fas fa-times fa-lg"></i>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ErrorBox',
    data() {
        return {
            errors: [],
        };
    },
    methods: {
        remove() {
            this.errors = [];
        },
    },
    mounted() {
        this.$global.$on('global-error', errors => {
            this.errors = errors;
        });
    },
    watch: {
        $route() {
            this.errors = [];
        },
    },
};
</script>

<style scoped lang="scss">
.error-box {
    position: fixed;
    left: 0;
    right: 0;
    top: 5px;
    margin: 0 auto;
    min-width: 300px;
    max-width: 600px;
    z-index: 999999;
}

.alert {
    position: relative;
    top: 0;
    padding-right: 30px;
    background-color: white;
    border: 1px solid #c00;
}

.close {
    position: absolute;
    right: 30px;
    top: 2px;
    cursor: pointer;
}

.show-error-enter-active,
.show-error-leave-active {
    transition: top 0.5s;
}
.show-error-enter,
.show-error-leave-to {
    top: -50px;
}
</style>
