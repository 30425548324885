<template>
    <div>
        <auction-banner :companyId="baseData.companyId"></auction-banner>
        <h1>{{ baseData.auctionName }}</h1>
        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
            >
                {{ baseData.auctionName }}:
                <span>{{
                    baseData.auctionDescription | removeBR | striphtml
                }}</span>
            </router-link>
            / {{ $t('bid_sheet') }}
        </breadcrumb>

        <auction-tabs :auctionId="auctionId" :auction="baseData"></auction-tabs>
        <div class="bid-sheet-wrapper">
            <div class="company" v-if="Object.keys(baseData).length > 0">
                <p v-if="!$global.isMobile" class="company-image-wrapper">
                    <company-image
                        v-if="baseData.companyId"
                        :alt="baseData.companyLogoAlt"
                        :title="baseData.companyLogoTitle"
                        :companyId="baseData.companyId"
                    ></company-image>
                </p>
                <div>
                    <span class="sep">{{ baseData.companyName }}</span>
                    <span class="sep">{{ baseData.auctionName }}</span>
                    <span>{{ baseData.auctionEnd }}</span>
                </div>
                <div>
                    <span>{{ $t('bid_end') }}:&nbsp;</span>
                    <span
                        ><date-timezone :date="baseData.auctionOnlineEnd"
                    /></span>
                </div>
            </div>

            <h2>{{ $t('bid_sheet') }}</h2>
            <table class="bid-sheet" v-if="lots.length > 0">
                <thead class="hidden-below-md">
                    <tr class="bid-sheet-header">
                        <th>{{ $t('image') }}</th>
                        <th>{{ $t('lot_nr') }}</th>
                        <th>{{ $t('lot_price_start') }}</th>
                        <th>{{ $t('bid_your') }}</th>
                    </tr>
                </thead>

                <tbody class="bid-sheet-lots" v-if="lots">
                    <template v-for="(lot, index) in sortedLots">
                        <tr
                            class="bid-sheet-lot"
                            ref="lot"
                            :data-id="lot.lotId"
                            :key="'sheet_' + lot.lotId"
                            :class="index % 2 === 1 ? 'odd' : 'even'"
                        >
                            <td>
                                <img
                                    v-if="!lot.hasImage"
                                    width="256"
                                    height="128"
                                    :alt="$t('lot')"
                                    loading="lazy"
                                    :src="
                                        'https://lot-images.sixbid.com/placeholder/lot/' +
                                        lot.companyId +
                                        '/' +
                                        lot.auctionId +
                                        '/img.png'
                                    "
                                />
                                <img
                                    v-else
                                    width="256"
                                    height="128"
                                    :alt="$t('lot')"
                                    loading="lazy"
                                    :src="getImage(lot, 'XS')"
                                />
                            </td>
                            <td>
                                <router-link
                                    :to="{
                                        name: 'page.auctionLotDetail',
                                        params: {
                                            companySlug: lot.companySlug,
                                            auctionId: lot.auctionId,
                                            categorySlug: lot.categorySlug,
                                            lotId: lot.lotId,
                                            lotSlug: lot.lotSlug,
                                        },
                                        query: $route.query,
                                    }"
                                    >{{ $t('lot') }} {{ lot.lotNumber
                                    }}{{ lot.lotNumberAffix }}</router-link
                                >

                                <a
                                    @click="removeLotFromBidSheet(lot.lotId)"
                                    class="hidden-above-md"
                                >
                                    <i class="far fa-trash-alt fa-lg"></i>
                                </a>
                            </td>
                            <td class="prices">
                                <div>
                                    <price
                                        :value="lot.lotStartingPrice"
                                        :currency="lot.auctionCurrency"
                                    ></price>
                                </div>
                            </td>
                            <td>
                                <span class="hidden-above-md">{{
                                    $t('bid_your')
                                }}</span>
                                <input
                                    type="text"
                                    class="bid"
                                    :class="{ error: lot.bidError }"
                                    :value="formattedBid(lot.lotId)"
                                    :disabled="readyForSend"
                                    @input="updateBid($event, lot)"
                                />
                                {{ lot.auctionCurrency }}
                            </td>
                            <td
                                class="actions hidden-below-md"
                                v-if="!readyForSend"
                            >
                                <a @click="removeLotFromBidSheet(lot.lotId)">
                                    <i class="far fa-trash-alt fa-lg"></i>
                                </a>
                            </td>
                        </tr>
                        <tr
                            class="lot-error"
                            :class="index % 2 === 1 ? 'odd' : 'even'"
                            :key="'error_' + lot.lotId"
                            v-if="lot.increaseError || lot.bidError || true"
                        >
                            <td>
                                <p
                                    class="alert alert--error"
                                    v-if="lot.bidError"
                                >
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ $t(lot.bidError) }}
                                </p>
                                <p
                                    class="alert alert--error"
                                    v-if="lot.increaseError"
                                >
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ $t(lot.increaseError) }}
                                </p>
                                <p
                                    class="alert alert--error"
                                    v-if="lotErrors[lot.lotId]"
                                >
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ lotErrors[lot.lotId].message }}
                                </p>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div v-else>
                {{ $t('bid_sheet_empty') }}
            </div>

            <p class="alert alert--error" v-if="sheetError">
                <i class="fas fa-exclamation-circle"></i>
                {{ $t('bid_sheetError') }}
            </p>
            <p class="alert alert--success" v-if="hasUpdated && !sheetError">
                <i class="fas fa-exclamation-circle"></i>
                {{ $t('bid_sheetSuccess') }}
            </p>

            <div class="bid-sheet-total" v-if="lots.length > 0">
                <div v-if="auctionCurrency">
                    <strong>{{ $t('bid_sheet_total') }}:</strong>
                    <price
                        :value="totalBidSum"
                        :currency="auctionCurrency"
                    ></price>
                </div>
                <div v-if="!readyForSend">
                    <button
                        class="button button-blue"
                        @click="updateBidSheet()"
                    >
                        {{ $t('bid_update') }}
                    </button>
                </div>
            </div>

            <p v-if="lots.length > 0 && !readyForSend" class="done">
                <span>{{ $t('bid_done') }}</span>
                <button class="button button-blue" @click="checkBidSheet()">
                    {{ $t('bid_continue') }}
                </button>
            </p>
        </div>
        <bid-sheet-user-info
            v-if="lots.length > 0 && readyForSend"
            @go-back="goBack()"
            @send-sheet="sendBidSheet"
            :userErrors="userErrors"
            :buttonLoading="buttonLoading"
        ></bid-sheet-user-info>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs.vue';
import Price from '../components/Price.vue';
import BidSheetUserInfo from '../components/BidSheetUserInfo.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import CompanyImage from '../components/CompanyImage.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import DateTimezone from '../components/DateTimezone.vue';
import CloudImageGetter from '../lib/CloudImageGetter';

import { getLotDetails, postBidSheet, getMyAddresses } from '../lib/apis';
import { validateBid, validateIncrease } from '../lib/Validation.js';
import { PAGINATION_DEFAULTS } from '../config.js';

import Vue from 'vue';

export default {
    name: 'BidSheet',
    data() {
        return {
            auctionId: this.$route.params.auctionId,
            companySlug: this.$route.params.companySlug,
            lots: [],
            baseData: {},
            lotErrors: {},
            userErrors: {},
            sheetError: false,
            hasUpdated: false,
            readyForSend: false,
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            buttonLoading: false,
        };
    },
    components: {
        AuctionTabs,
        AuctionBanner,
        Price,
        BidSheetUserInfo,
        Breadcrumb,
        CompanyImage,
        DateTimezone,
    },
    computed: {
        totalBidSum() {
            return this.bidSheets[this.auctionId].reduce(
                (total, lot) => (total += lot.bid),
                0
            );
        },
        auctionCurrency() {
            if (this.lots.length > 0) {
                return this.lots[0].auctionCurrency;
            }
            return '';
        },
        sortedLots() {
            const sorted = [...this.lots].sort(function (a, b) {
                if (a.lotNumber < b.lotNumber) return -1;
                if (b.lotNumber < a.lotNumber) return 1;
                return 0;
            });
            return sorted;
        },
    },
    methods: {
        fetchLots() {
            const lots = this.bidSheets[this.auctionId];
            if (!lots) {
                return;
            }
            const lotIds = lots.map((lot) => lot.lotId);
            const { companySlug, auctionId } = this;

            getLotDetails(
                { lotIds, companySlug, auctionId },
                this.$route.params.lang
            )
                .then((results) => {
                    Vue.set(
                        this,
                        'lots',
                        results.map((result) => result.data)
                    );
                    this.baseData = this.lots[0];

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );
                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        getCurrentLotFromStorage(lotId) {
            const currentLot = this.bidSheets[this.auctionId].filter(
                (lot) => lot.lotId == lotId
            );
            return currentLot[0];
        },
        formattedBid(lotId) {
            return this.getCurrentLotFromStorage(lotId)
                .bid.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        },
        updateBid(event, lot) {
            if (event && event.target && event.target.value) {
                let value = event.target.value;

                // Remove non-digit characters from input
                let number = value.replace(/[^0-9]/g, '');

                if (isNaN(parseInt(number, 10))) {
                    number = 0;
                }

                // Update the current lot's bid value
                this.$set(
                    this.getCurrentLotFromStorage(lot.lotId),
                    'bid',
                    parseInt(number, 10)
                );
            }
        },
        goBack() {
            this.readyForSend = false;
        },
        removeLotFromBidSheet(lotId) {
            this.lots = this.lots.filter((lot) => lotId !== lot.lotId);
            this.removeBid(lotId, this.auctionId);
        },
        sendBidSheet(userData) {
            const data = {
                auctionId: this.auctionId,
                lots: this.bidSheets[this.auctionId],
                ...userData,
            };

            this.userErrors = {};
            this.buttonLoading = true;

            postBidSheet(data, this.$route.params.lang)
                .then((response) => {
                    if (response.data.success) {
                        this.buttonLoading = false;
                        this.removeBidSheetFromStorage(this.auctionId);
                        this.$router.push({
                            name: 'page.accountBids',
                            params: {
                                page: PAGINATION_DEFAULTS.START_PAGE,
                                perPage: PAGINATION_DEFAULTS.PER_PAGE,
                            },
                        });
                    } else if (response.data.errors) {
                        this.buttonLoading = false;
                        const userErrors = { ...response.data.errors };
                        delete userErrors.lots;
                        const lotErrors = { ...response.data.errors.lots };
                        this.backendValidation(userErrors, lotErrors);
                    } else {
                        this.$global.globalError(this.$t('error_generic'));
                    }
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        backendValidation(userErrors, lotErrors) {
            if (Object.keys(lotErrors).length > 0) {
                this.lotErrors = lotErrors;
                this.sheetError = true;
                this.goBack();
                this.scrollTo({ to: this.$el.querySelector('h2').offsetTop });
            }
            if (Object.keys(userErrors).length > 0) {
                this.userErrors = userErrors;
            }
        },
        updateBidSheet() {
            this.$refs.lot.forEach((lotRef) => {
                // collect data
                const lotId = Number(lotRef.dataset.id);
                const bid = Number(
                    lotRef
                        .querySelector('input.bid')
                        .value.replace(/[^0-9]/g, '')
                );
                let increase = 0;
                if (lotRef.querySelector('input.increase')) {
                    increase = Number(
                        lotRef.querySelector('input.increase').value
                    );
                }
                const auctionId = this.auctionId;
                const lots = [...this.lots];

                // needed to ensure reactivity
                let currentLot;
                let currentIndex;
                lots.forEach((lot, index) => {
                    if (lot.lotId === lotId) {
                        currentLot = { ...lot };
                        currentIndex = index;
                    }
                });

                // validate bid
                const { type: bidType, content: bidContent } = validateBid(
                    bid,
                    currentLot.lotStartingPrice
                );
                if (bidType === 'error') {
                    currentLot.bidError = bidContent;
                } else {
                    currentLot.bidError = '';
                }

                // validate increase
                const { type: increaseType, content: increaseContent } =
                    validateIncrease(increase);
                if (increaseType === 'error') {
                    currentLot.increaseError = increaseContent;
                } else {
                    currentLot.increaseError = '';
                }

                // needed to ensure reactivity
                this.lots = lots.map((lot, index) => {
                    if (index === currentIndex) {
                        return currentLot;
                    }
                    return lot;
                });

                this.hasUpdated = false;

                if (bidType === 'success' && increaseType === 'success') {
                    delete this.lotErrors[lotId];
                    this.updateBid({ lotId, bid, increase, auctionId });
                    this.hasUpdated = true;
                }
            });
        },
        checkBidSheet() {
            /* eslint-disable-next-line no-console */
            //console.log("checkBidSheet");

            if (typeof preventStore === 'undefined') {
                this.$store.commit('tasks/setTask', {
                    path: this.$route.path,
                    fn: 'checkBidSheet',
                    args: null,
                    component: this.$options.name,
                });
            }

            return getMyAddresses(this.$route.params.lang)
                .then((response) => {
                    /* eslint-disable-next-line no-console */
                    //console.log("response", response);
                    this.$store.commit('tasks/unsetTask');
                    this.readyForSend = true;
                    return response;
                })
                .catch((e) => {
                    /* eslint-disable-next-line no-console */
                    // send to login in error case that is not 401 - 401 is handled by AuthorizationInterceptor
                    if (e.response.status !== 401) {
                        this.$router.push({
                            name: 'redirect.login',
                            params: { referer: this.$route.fullPath },
                        });
                    }
                });
        },
        getImage: function (lot, size) {
            return CloudImageGetter(lot.googleBucketImagePath, size);
        },
    },
    mounted() {
        // trigger pending tasks
        let task = this.$store.getters['tasks/task'](this.$options.name);
        if (
            typeof this.$route.query.runtask !== 'undefined' &&
            task.fn !== null &&
            typeof this[task.fn] === 'function'
        ) {
            /* eslint-disable-next-line no-console */
            //console.log("run task fn");
            this[task.fn].call(this, task.args, true).then((/* response */) => {
                /* eslint-disable-next-line no-console */
                //console.log("done task", response);
            });
        }

        // this will use an empty bid sheet on page reload (see main.js)
        this.fetchLots();
    },
    watch: {
        bidSheets() {
            // watch bid sheets, because they are loaded asyncronously from storage after language is loaded (main.js)
            this.fetchLots();
        },
        lots() {
            let hasError = false;
            this.lots.forEach((lot) => {
                if (lot.bidError || lot.increaseError) {
                    hasError = true;
                }
            });

            this.sheetError = hasError;
        },
    },
};
</script>

<style scoped lang="scss">
/* reset table layout */
table,
tr,
td,
th,
thead,
tbody,
tfoot {
    display: block;
}

.odd {
    @media (min-width: $md-size) {
        background: $grey-bg-lightest;
    }
}

.bid-sheet-wrapper {
    max-width: 1020px;
}

.company {
    p {
        text-align: center;
    }

    .company-image-wrapper {
        text-align: left;
        border-top: 1px solid $grey-bg-light;
        border-bottom: 1px solid $grey-bg-light;
    }

    .sep {
        &::after {
            display: inline-block;
            padding: 0 10px;
            content: '|';
        }
    }
}

.bid-sheet-lots {
    position: relative;
}

.bid-sheet-header,
.bid-sheet-lot,
.bid-sheet-total {
    display: flex;
    align-items: top;

    & > td,
    & > tr,
    & > th {
        padding: $pad-md $pad-lg;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            width: 20%;
        }
        &:nth-child(4) {
            width: 30%;
        }
        &:nth-child(5) {
            width: 10%;
        }
        // &:nth-child(4),
        // &:nth-child(5) {
        //     width: 15%;
        // }
        // &:nth-child(6) {
        //     width: 10%;
        // }

        strong {
            display: inline-block;
            width: 100px;
        }
    }
}

.bid-sheet-header {
    font-weight: bold;
    background: $grey-bg-light;
}

.bid-sheet-total {
    justify-content: space-between;
    margin-top: $mar-lg;

    & > :first-child {
        margin: 0 $mar-sm;

        @media (min-width: $md-size) {
            margin-left: calc(40% + #{$pad-lg});
        }
    }
}

.bid-sheet-lot-wrapper {
    border-top: 1px dashed $grey-bg-light;
    &:nth-child(2n - 1) {
        background-color: $grey-bg-lightest;
    }
    &:hover {
        background-color: $grey-bg-lighter;
    }
}

.bid-sheet-lot {
    .prices {
        div + div {
            margin-top: 10px;
        }
    }

    input {
        width: 30%;
        margin-right: $mar-sm;

        &:disabled {
            background-color: #fafafa;
            border: none;
            font-size: 14px;
            padding: 0;
            height: auto;
        }
    }

    a {
        text-decoration: underline;
    }
}

.lot-error {
    padding: 10px 20px;
    td {
        max-width: 850px;
    }
}

.done {
    display: flex;
    align-items: center;
    float: right;

    span {
        margin-right: $mar-lg;
    }
}

@media (max-width: $sm-size-max) {
    .bid-sheet-lot {
        display: block;
        padding: $pad-sm;
        border: 1px solid $grey-bg;

        & > td {
            &:nth-child(n) {
                width: 100%;
            }

            &:nth-child(1) {
                text-align: center;
            }

            &:nth-child(2) {
                display: flex;
                justify-content: space-between;
            }

            span,
            strong {
                display: inline-block;
                width: 130px;
            }

            input {
                max-width: 100px;
            }
        }
    }
}
</style>
