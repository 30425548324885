var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lot-list-item lot"},[_c('bookmark',{attrs:{"lotId":_vm.lot.lotId}}),(_vm.lot.coinArchiveLink)?_c('coin-archive-link',{attrs:{"link":_vm.lot.coinArchiveLink}}):_vm._e(),_c('div',{staticClass:"lot-list-infos"},[(_vm.showImage)?_c('div',{staticClass:"lot-image"},[_c('router-link',{attrs:{"to":_vm.detailRoute}},[(_vm.useImageFallback)?_c('img',{attrs:{"loading":"lazy","alt":_vm.$t('image'),"src":'https://lot-images.sixbid.com/placeholder/lot/' +
                        _vm.lot.companyId +
                        '/' +
                        _vm.lot.auctionId +
                        '/img.png',"width":"256","height":"128"}}):_c('img',{attrs:{"loading":"lazy","src":_vm.getImage(_vm.lot, 'S'),"width":"256","height":"128","alt":_vm.$t('lot')}})])],1):_vm._e(),_c('div',{staticClass:"lot-content"},[(_vm.showDescription)?_c('div',{staticClass:"description-wrapper"},[_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(5),expression:"5"}],staticClass:"description"},[(_vm.showLotNr)?_c('strong',[_vm._v(_vm._s(_vm.$t('lot'))+" "+_vm._s(_vm.lot.lotNumber)+_vm._s(_vm.lot.lotNumberAffix)+". ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.$options.filters.searchHighlights(
                                _vm.$options.filters.striphtml(
                                    _vm.lot.lotDescription
                                )
                            )
                        )}})])]):_vm._e(),_c('div',{staticClass:"prices"},[_c('ul',[(_vm.showPrebidDate)?_c('li',[_c('div',[_vm._v(_vm._s(_vm.$t('bid_end'))+":")]),_c('date-timezone',{attrs:{"date":_vm.lot.sessionEnds}})],1):_vm._e(),(_vm.showCompany)?_c('li',[_c('div',[_vm._v(_vm._s(_vm.$t('company'))+":")]),_c('router-link',{attrs:{"to":{
                                name: 'page.companyDetail',
                                params: { companySlug: _vm.lot.companySlug },
                            }}},[_vm._v(_vm._s(_vm.lot.companyName))])],1):_vm._e(),(_vm.showAuction)?_c('li',[_c('div',[_vm._v(_vm._s(_vm.$t('auction'))+":")]),_vm._v("\n                        "+_vm._s(_vm.lot.auctionName)+"\n                    ")]):_vm._e(),(
                            _vm.showStartingPrice &&
                            _vm.lot.lotStartingPrice &&
                            _vm.lot.auctionCurrency
                        )?_c('li',[_c('div',[_vm._v(_vm._s(_vm.$t('lot_price_start'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotStartingPrice,"currency":_vm.lot.auctionCurrency}})],1):_vm._e(),(
                            _vm.showEstimatePrice &&
                            _vm.lot.lotEstimate &&
                            _vm.lot.auctionCurrency &&
                            _vm.lot.lotEstimate > 0
                        )?_c('li',[_c('div',[_vm._v(_vm._s(_vm.$t('lot_estimate')))]),_c('price',{attrs:{"value":_vm.lot.lotEstimate,"currency":_vm.lot.auctionCurrency}})],1):_vm._e(),(
                            _vm.showResultPrice &&
                            _vm.lot.lotPriceRealised &&
                            _vm.lot.auctionCurrency
                        )?_c('li',[_c('div',[_vm._v(_vm._s(_vm.$t('result'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotPriceRealised,"currency":_vm.lot.auctionCurrency,"showZeroAsUnsold":true}})],1):_vm._e()])])])]),_c('div',{staticClass:"lot-control-container"},[_c('div',{staticClass:"lot-controls"},[(_vm.showDetailsButton)?_c('div',{staticClass:"more-details"},[_c('router-link',{staticClass:"button button-grey",attrs:{"to":_vm.detailRoute}},[_vm._v(_vm._s(_vm.$t('details_more')))])],1):_vm._e(),(_vm.showBidding)?_c('div',{staticClass:"bid-lot"},[_c('bid-lot-input',{attrs:{"lot":_vm.lot},on:{"bid-placed":_vm.showMessage}})],1):_vm._e()]),_c('div',{staticClass:"clearfix"}),(_vm.hasMessage && _vm.showBidding)?_c('bid-lot-message',{attrs:{"lot":_vm.lot,"message-type":_vm.message.type,"message-content":_vm.message.content,"add-query":_vm.addQuery}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }