import { render, staticRenderFns } from "./Redirecting.vue?vue&type=template&id=eb1f80ce&scoped=true&"
import script from "./Redirecting.vue?vue&type=script&lang=js&"
export * from "./Redirecting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb1f80ce",
  null
  
)

export default component.exports