<template>
    <div id="nav-wrapper">
        <div id="nav-bar">
            <div class="wrapper">
                <router-link :to="{ name: 'page.home' }" class="logo" tag="a">
                    <span class="logo-main">{{ $t('sixbid_url') }}</span>
                    <span class="logo-slogan" v-if="slogan">{{
                        $t('auctions_numismatic')
                    }}</span>
                </router-link>

                <div class="small-menu no-print">
                    <div class="coin">
                        <a :href="coinCollectorAction" target="_blank">
                            <!-- OWL ICON -->
                            <svg
                                viewBox="0 0 39 55"
                                xmlns="http://www.w3.org/2000/svg"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                stroke-linejoin="round"
                                stroke-miterlimit="1.414"
                            >
                                <path
                                    d="M7.74 40.863C6.488 34.147 2.846 31.985 0 31.188v22.196c3.756-1.594 8.992-5.236 7.74-12.521z"
                                    fill="#fff"
                                    fill-rule="nonzero"
                                ></path>
                                <path
                                    d="M36.424 0h-7.399v5.35c-1.593-.569-3.187-.797-4.78-.797H14.57c-1.822 0-3.643.341-5.236 1.024V0H1.821c1.138 2.846 2.96 4.894 5.919 6.147A14.675 14.675 0 0 0 0 19.123v9.902c3.529.683 8.423 3.301 9.903 11.497 1.366 7.626-3.187 11.951-7.399 14.228h12.407C28.115 54.636 38.7 44.05 38.7 30.846V19.123c0-5.692-3.301-10.586-8.081-12.976C33.464 4.781 35.286 2.732 36.424 0zM9.22 24.814a6.243 6.243 0 0 1-6.261-6.261 6.243 6.243 0 0 1 6.261-6.26 6.242 6.242 0 0 1 6.26 6.26 6.242 6.242 0 0 1-6.26 6.261zm9.903 11.496l-3.871-7.626 3.871-2.618 3.87 2.618-3.87 7.626zm16.618-17.643a6.243 6.243 0 0 1-6.26 6.261 6.243 6.243 0 0 1-6.261-6.261 6.242 6.242 0 0 1 6.261-6.26 6.242 6.242 0 0 1 6.26 6.26z"
                                    fill="#fff"
                                    fill-rule="nonzero"
                                ></path>
                                <path
                                    d="M9.22 15.822a2.818 2.818 0 0 0-2.846 2.845 2.818 2.818 0 0 0 2.846 2.846 2.818 2.818 0 0 0 2.845-2.846 2.818 2.818 0 0 0-2.845-2.845zM26.635 18.667a2.818 2.818 0 0 0 2.846 2.846 2.818 2.818 0 0 0 2.845-2.846 2.818 2.818 0 0 0-2.845-2.845 2.818 2.818 0 0 0-2.846 2.845z"
                                    fill="#fff"
                                    fill-rule="nonzero"
                                ></path>
                            </svg>
                        </a>
                    </div>
                    <div class="search" @click="search()">
                        <i class="fas fa-search"></i>
                    </div>
                    <div class="burger" @click="toggleNav()">
                        <i class="fas fa-bars fa-lg" v-if="!navOpen"></i>
                        <i class="fa fa-times fa-lg" v-else></i>
                    </div>
                </div>

                <ul
                    class="navigation clearfix no-print"
                    :class="{ 'navigation--open': navOpen }"
                >
                    <router-link
                        v-for="(item, i) in nav"
                        tag="li"
                        :to="item.url"
                        v-bind:key="item.name"
                        v-on:mouseover.native="submenuActive = i"
                        v-on:mouseleave.native="submenuActive = null"
                    >
                        <a>{{ $t(item.name) }}</a>

                        <ul
                            v-if="item.submenu"
                            v-bind:class="{
                                submenu: true,
                                active: submenuActive === i,
                            }"
                        >
                            <router-link
                                v-for="subItem in item.submenu"
                                v-bind:key="subItem.name"
                                tag="li"
                                :to="subItem.url"
                            >
                                <a>{{ $t(subItem.name) }}</a>
                            </router-link>
                        </ul>
                    </router-link>

                    <!-- Login/Account Links -->
                    <li v-if="!loggedIn">
                        <a :href="`/${$root.$i18n.locale}/login`">{{ $t('page_title_login') }}</a>
                    </li>
                    <li v-if="!loggedIn">
                        <a :href="`/${$root.$i18n.locale}/register`">{{
                            $t('page_title_register')
                        }}</a>
                    </li>
                    <li v-if="loggedIn">
                        <router-link
                            :to="{
                                name: 'page.accountBids',
                                params: {
                                    page: PAGINATION_DEFAULTS.START_PAGE,
                                    perPage: PAGINATION_DEFAULTS.PER_PAGE,
                                },
                            }"
                            >{{ $t('account') }}</router-link
                        >
                    </li>
                    <li v-if="loggedIn">
                        <a :href="`/${$root.$i18n.locale}/logout`">{{ $t('logout') }}</a>
                    </li>

                    <!-- Language -->
                    <li
                        v-on:mouseover="submenuActive = 'language'"
                        v-on:mouseleave="submenuActive = null"
                    >
                        <span class="link-like"
                            >{{ lang }} <i class="fas fa-globe-americas"></i
                        ></span>
                        <ul
                            :class="{
                                submenu: true,
                                active: submenuActive === 'language',
                                'submenu-language': true,
                            }"
                        >
                            <li
                                v-for="language in availableLanguages"
                                :key="language.code"
                                :class="{ 'is-active': lang === language.code }"
                            >
                                <a @click="changeLanguage(language.code)">
                                    <span>{{ language.originalName }}</span>
                                    <span>{{ language.code }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div id="nav-spacer" class="no-print"></div>
        <div v-if="$global.isMobile && searchOpen" class="searchContainer">
            <span href="#" class="searchCloseButton" @click="search()"
                ><i class="fas fa-times"></i
            ></span>
            <lot-search size="small" :isReduced="true"></lot-search>
        </div>
    </div>
</template>

<script>
import Auth from './../lib/Authorization';
import LotSearch from '../components/LotSearch.vue';
import {
    PAGINATION_DEFAULTS,
    COIN_COLLECTOR,
    AVAILABLE_LANGUAGES,
} from '../config.js';
import { mapGetters } from 'vuex';

export default {
    name: 'Navigation',
    components: {
        LotSearch,
    },
    methods: {
        changeLanguage(lang) {
            this.$root.$i18n.locale = lang;

            let params = this.$route.params;
            let query = this.$route.query;
            params.lang = lang;

            this.nav.forEach((item) => {
                if (
                    typeof item.url === 'object' &&
                    typeof item.url.params !== 'undefined'
                ) {
                    item.url.params.lang = lang;
                }
            });

            this.$root.$emit('update:language', lang);

            this.$router.push({
                params: params,
                query: query,
            });

            window.location.href = this.$route.fullPath;
        },
        logout() {
            Auth.logout().then(() => {
                this.removeAllBidSheetsFromStorage();
                this.$router.push({ name: 'page.home' });
                window.location.reload();
            });
        },
        toggleNav() {
            this.navOpen = !this.navOpen;
        },
        search() {
            this.searchOpen = !this.searchOpen;
        },
    },
    data() {
        return {
            submenuActive: null,
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            slogan: true,
            navOpen: false,
            searchOpen: false,
            coinCollectorAction: COIN_COLLECTOR.ACTION,
            availableLanguages: AVAILABLE_LANGUAGES,
        };
    },
    computed: {
        ...mapGetters({ loggedIn: 'account/loggedIn', lang: 'lang/getLang' }),
        nav() {
            return [
                {
                    name: 'auctions',
                    url: {
                        name: 'page.home',
                    },
                    submenu: [
                        {
                            name: 'link_back_home',
                            url: {
                                name: 'page.home',
                            },
                        },
                        {
                            name: 'auctions_ended',
                            url: {
                                name: 'page.auctionListPast',
                                params: {
                                    page: this.PAGINATION_DEFAULTS.START_PAGE,
                                    perPage: this.PAGINATION_DEFAULTS.PER_PAGE,
                                },
                            },
                        },
                        {
                            name: 'auction_calendar',
                            url: {
                                name: 'page.auctionCalendar',
                            },
                        },
                        {
                            name: 'companies',
                            url: {
                                name: 'page.companyList',
                            },
                        },
                    ],
                },
                {
                    name: 'page_title_aboutus',
                    url: {
                        name: 'page.about',
                    },
                    submenu: [
                        {
                            name: 'page_title_help',
                            url: {
                                name: 'page.help',
                            },
                        },
                        {
                            name: 'page_title_howtobuy',
                            url: {
                                name: 'page.howToBuy',
                            },
                        },
                    ],
                },
            ];
        },
    },
    watch: {
        $route() {
            this.navOpen = false;
            this.searchOpen = false;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#nav-bar {
    width: 100%;
    position: fixed;
    z-index: 9999;
    background-color: white;
    height: $nav-height;

    @media (min-width: $md-size) {
        border-bottom: 1px solid #1660aa;
    }

    .wrapper {
        @media (max-width: $sm-size-max) {
            padding: 0;
        }
    }
}

#nav-spacer {
    height: $nav-height-small;
    background-color: transparent;
    width: 100%;
    @media (min-width: $sm-size) {
        height: $nav-height;
        background-color: #1660aa;
    }
}

.searchContainer {
    position: fixed;
    width: 100%;
    z-index: 1111;

    .lot-search-component {
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    }

    .searchCloseButton {
        color: white;
        position: absolute;
        top: 0;
        right: 0;
        padding: $pad-xl $pad-lg;
        z-index: 2222;
        cursor: pointer;
    }
}

.logo {
    display: inline-block;
    line-height: $nav-height-small;

    @media (min-width: $sm-size) {
        line-height: $nav-height;
    }

    @media (max-width: $sm-size-max) {
        padding: 0 $pad-md;
        background: $main-color;
        color: white;
        width: 100%;
    }
}

.logo-main {
    font-family: Times New Roman, serif;
    font-size: 22px;
    color: white;
    cursor: pointer;

    text-indent: -9999px;
    display: inline-block;
    width: 135px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 135px 35px;
    background-image: url('/assets/svg/sixbid-logo-white.svg');

    @media (min-width: $md-size) {
        background-image: url('/assets/svg/sixbid-logo.svg');
    }
}

.logo-slogan {
    display: none;

    @media (min-width: $sm-size) {
        display: inline-block;
        font-size: 14px;
        text-transform: uppercase;
        color: white;
        vertical-align: -3px;
        margin-bottom: -3px;
    }

    @media (min-width: $md-size) {
        color: #1660aa;
    }

    .logo-main + &:before {
        @media (min-width: $sm-size) {
            content: '';
            display: inline-block;
            margin: 0 17px;
            width: 2px;
            height: 9px;
            background: #e6e6e6;
        }
    }
}

.small-menu {
    padding: 0 $pad-sm;
    height: 70px;
    display: flex;
    align-items: center;
    width: 100px;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    z-index: 9999;

    @media (min-width: $sm-size) {
        height: $nav-height;
    }

    @media (min-width: $md-size) {
        color: $text-color;
        width: 50px;

        .search {
            display: none;
        }
    }

    @media (min-width: $desktop-nav) {
        display: none;
    }

    .coin {
        position: absolute;
        right: 110px;
        width: 25px;
    }

    .search {
        position: relative;
        width: 60%;
        cursor: pointer;

        input {
            width: 100%;
            padding-right: $pad-xl;
        }

        i {
            position: absolute;
            right: 0;
            padding: $pad-md;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: $sm-size-max) {
                font-size: 30px;
            }
        }
    }
    .burger {
        cursor: pointer;
        width: 50px;

        i {
            padding: $pad-md;

            @media (max-width: $sm-size-max) {
                font-size: 30px;
            }
        }
    }
}

.navigation {
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: $md-size) {
        position: absolute;
        right: $mar-md;
    }

    @media (max-width: $burger-nav) {
        max-height: calc(100vh - #{2 * $nav-height + 10});
        max-width: 90%;
        overflow: auto;
        display: none;
        box-shadow: 0px 4px 4px 2px $grey_bg_light;
        background: #fff;
    }

    @media (min-width: $desktop-nav) {
        position: relative;
        right: 0;
        line-height: $nav-height;
        font-size: $nav-font-size;
        text-align: right;
        float: right;
    }

    &--open {
        display: block;
        //position: absolute;
    }

    li {
        @media (max-width: $burger-nav) {
            background: $grey_bg;
        }
        @media (min-width: $desktop-nav) {
            display: inline-block;
            margin-left: 30px;
            text-transform: uppercase;
            vertical-align: -3px;
        }
    }

    & > li + li {
        margin-top: 3px;
    }

    a,
    .link-like {
        color: #333;
        text-decoration: none;

        @media (max-width: $burger-nav) {
            text-transform: uppercase;
            padding: $pad-sm $pad-lg;
            display: block;
        }
        @media (min-width: $desktop-nav) {
            font-family: $headlines-font;

            &:hover {
                color: lighten(#333, 40%);
            }
        }
    }
}

.submenu {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: -3px;

    @media (min-width: $desktop-nav) {
        background: #fff;
        position: absolute;
        text-align: left;
        padding: 5px 0;
        box-shadow: $submenu-box-shadow;
        font-size: $submenu-font-size;
        display: none;
        margin: 0;
        min-width: 210px;
        z-index: 1;
    }

    &.active {
        @media (min-width: $desktop-nav) {
            display: block;
        }
    }

    &::before {
        @media (min-width: $desktop-nav) {
            display: block;
            content: '▲';
            position: absolute;
            color: white;
            text-shadow: $triangle-text-shadow;
            transform: scalex(3) scaleY(2);
            top: -55px;
            left: 20px;
            z-index: -1;
            height: 0;
            width: 0;
        }
    }

    li {
        @media (max-width: $burger-nav) {
            background: white;
        }
        display: block;
        margin: 0;
        line-height: $submenu-item-height;

        &:hover {
            @media (min-width: $md-size) {
                background-color: $grey-bg-lightest;
            }
            @media (min-width: $desktop-nav) {
                background-color: white;
            }
        }
    }

    a {
        @media (max-width: $burger-nav) {
            padding: $pad-md $pad-lg;
        }
        text-transform: none;
        padding: 0 $pad-lg;
        display: block;
        font-family: $body-font;
    }

    &.submenu-language {
        right: 0;
        min-width: 120px;

        li.is-active a {
            background-color: $main-color-lightest-quarter;
            font-weight: bold;
        }

        &::before {
            @media (min-width: $desktop-nav) {
                left: unset;
                right: 50px;
            }
        }

        span:first-child {
            width: 80px;
            display: inline-block;
        }
        span:last-child {
            text-transform: uppercase;
            display: inline-block;
            text-align: right;
            color: $grey-bg-darker;
        }
    }
}
</style>
