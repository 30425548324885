<template>
    <div class="auction-conditions">
        <auction-banner
            :companyId="auction.companyId"
            :auctionId="auction.auctionId"
        ></auction-banner>
        <h1>{{ auction.auctionName }}</h1>
        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
                >{{ auction.auctionName }}:
                {{
                    auction.auctionDescription | removeBR | striphtml
                }}</router-link
            >
            / {{ $t('auction_conditions') }}
        </breadcrumb>
        <auction-tabs
            :auctionId="$route.params.auctionId"
            :auction="auction"
        ></auction-tabs>
        <div class="reading" v-html="terms.auctionTerms"></div>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs';
import AuctionBanner from '../components/AuctionBanner.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import { PAGINATION_DEFAULTS } from '../config.js';
import { getAuctionTerms, getAuctionDetails } from '../lib/apis';

export default {
    name: 'Auction_Conditions_Page',
    components: {
        AuctionTabs,
        Breadcrumb,
        AuctionBanner,
    },
    data: function () {
        return {
            auction: {},
            terms: {},
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    created: function () {
        Promise.all([
            getAuctionDetails(
                null,
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
            getAuctionTerms(
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
        ])
            .then(([auction, terms]) => {
                this.auction = auction.data;
                this.terms = terms.data;

                if (
                    this.$route.params.companySlug != this.auction.companySlug
                ) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
    },
};
</script>

<style scoped></style>
