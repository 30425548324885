export const removeTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};

export const removeTrailingSlashFromUrl = (url) => {
    const urlWithParams = url.split('?');
    const urlWithoutParams = removeTrailingSlash(urlWithParams[0]);
    return urlWithParams.length > 1 ?
        `${urlWithoutParams}?${urlWithParams[1]}` :
        urlWithoutParams;
}