<script src="../../node_modules/choices.js/public/choices.min.js"></script>

<template>
    <div class="auction-list-past-page">
        <ad-banner
            v-if="getAds['ended-auctions-top-banner-wide']"
            :ad="getAds['ended-auctions-top-banner-wide']"
            :visible="['desktop']"
        />

        <ad-banner
            v-if="getAds['ended-auctions-top-banner-medium']"
            :ad="getAds['ended-auctions-top-banner-medium']"
            :visible="['tablet']"
        />

        <ad-banner
            v-if="getAds['ended-auctions-top-banner-narrow']"
            :ad="getAds['ended-auctions-top-banner-narrow']"
            :visible="['mobile']"
        />

        <div class="content">
            <h1>{{ $t('auctions_ended') }}</h1>
            <breadcrumb>
                {{ $t('auctions_ended') }}
            </breadcrumb>
            <p v-html="$t('auction_ended_archive_hint')" />
            <div class="auctioneer-select">
                <select
                    :class="selectIEClassString"
                    v-model="companySlug"
                    @change="handleChange"
                >
                    <option value="" selected disabled hidden>
                        {{ $t('company_select') }}
                    </option>
                    <option
                        v-for="company in companies"
                        v-if="isIE"
                        :key="company.companyId"
                        :value="company.companySlug"
                    >
                        {{ company.companyName }}
                    </option>
                </select>

                <a v-on:click="resetSelect">{{ $t('reset') }}</a>
            </div>
            <auction-list
                :usePastFilter="true"
                :usePagination="true"
                :companySlug="currentCompanySlug"
                @pagination-clicked="scrollToTop()"
            ></auction-list>
        </div>
        <home-sidebar></home-sidebar>
    </div>
</template>

<script>
import HomeSidebar from '../layout/HomeSidebar';
import AuctionList from '../components/AuctionList.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import AdBanner from '../components/AdBanner';
import { getCompanies } from '../lib/apis';
import { PAGINATION_DEFAULTS } from '../config.js';
import { mapGetters } from 'vuex';
import Choices from 'choices.js';

export default {
    name: 'AuctionListPast',
    components: {
        AuctionList,
        Breadcrumb,
        AuctionBanner,
        HomeSidebar,
        AdBanner,
        Choices,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_auctionspast'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_auctionspast'),
                },
            ],
        };
    },
    data() {
        return {
            companies: [],
            companySlug: '',
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            select: '',
        };
    },
    computed: {
        currentCompanySlug() {
            return this.companySlug;
        },
        currentPage() {
            if (this.$route.query.page) {
                return this.$route.query.page;
            }
            return PAGINATION_DEFAULTS.START_PAGE;
        },
        isIE() {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf('MSIE ');

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                // If Internet Explorer, return version number
                return true;
            } // If another browser, return 0
            else {
                return false;
            }
        },
        selectIEClassString() {
            return this.isIE ? 'select-choices isIE' : 'select-choices';
        },
        ...mapGetters({ getAds: 'ads/getAds' }),
    },
    methods: {
        pushNewURL({ auctioneer }, forceDefaults) {
            // if $router is not yet available return early
            if (!this.$router) return;

            const historyObj = {
                path: '',
                params: {
                    page: this.currentPage,
                    perPage: PAGINATION_DEFAULTS.PER_PAGE,
                },
                query: {
                    ...this.$route.query,
                    auctioneer,
                },
            };

            /* eslint-disable-next-line no-console */
            // console.log('pushNewURL', {term, orderCol, orderDirection, priceFrom, currency, displayMode});

            if (Object.keys(this.$route.query).length === 0) {
                this.$router.replace(historyObj);
            } else {
                this.$router.push(historyObj).catch((err) => {});
            }
        },
        handleChange(forceDefaults) {
            this.pushNewURL(
                {
                    auctioneer: this.companySlug,
                },
                forceDefaults
            );
        },
        scrollToElement(selector) {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = this.$el
                .querySelector(selector)
                .getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top - 100 - 35;

            this.scrollTo({ to: offset });
        },
        resetSelect() {
            this.companySlug = '';
            this.select.setChoiceByValue(this.companySlug);
            this.handleChange();
        },
        scrollToTop() {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = this.$el
                .querySelector('.content')
                .getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top - 100 - 35;

            this.scrollTo({ to: offset });
        },
        fetchCompanies() {
            getCompanies(this.$route.params.lang, 250)
                .then((response) => {
                    this.companies = response.data.items.sort((a, b) => {
                        if (
                            a.companyName.toLowerCase() >
                            b.companyName.toLowerCase()
                        )
                            return 1;
                        if (
                            a.companyName.toLowerCase() <
                            b.companyName.toLowerCase()
                        )
                            return -1;
                        if (
                            a.companyName.toLowerCase() ===
                            b.companyName.toLowerCase()
                        )
                            return 0;
                    });

                    if (!this.isIE) {
                        const element =
                            document.querySelector('.select-choices');

                        // eslint-disable-next-line
                        this.select = new Choices(element, {
                            itemSelectText: '',
                        });

                        //Fill Select with data
                        this.companies.forEach((company) =>
                            this.select.setValue([
                                {
                                    value: company.companySlug,
                                    label: company.companyName,
                                },
                            ])
                        );

                        this.select.setChoiceByValue(this.companySlug);
                    }
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
    mounted() {
        this.fetchCompanies();
    },
    updated() {
        if (this.$route.query.auctioneer) {
            this.companySlug = this.$route.query.auctioneer;
            if (this.select) this.select.setChoiceByValue(this.companySlug);
        }

        if (
            this.$route.query.auctioneer == '' ||
            !this.$route.query.auctioneer
        ) {
            this.pushNewURL(
                {
                    auctioneer: '',
                },
                false
            );
            if (this.select) this.select.setChoiceByValue('');
        }
    },
    created() {
        if (this.$route.query.auctioneer) {
            this.companySlug = this.$route.query.auctioneer;
            if (this.select) this.select.setChoiceByValue(this.companySlug);
        }

        if (
            this.$route.query.auctioneer == '' ||
            !this.$route.query.auctioneer
        ) {
            this.pushNewURL(
                {
                    auctioneer: '',
                },
                false
            );
            if (this.select) this.select.setChoiceByValue('');
        }
    },
    watch: {
        $route() {
            this.companySlug = this.$route.query.auctioneer
                ? this.$route.query.auctioneer
                : '';

            if (this.select) this.select.setChoiceByValue(this.companySlug);
        },
    },
};
</script>

<style scoped lang="scss">
.choices {
    flex-grow: 1;
    margin-bottom: 0;
}

.choices__inner {
    border-radius: 0;
    background: #fff;
}

.auctioneer-select {
    margin-top: 20px;
    display: flex;
    align-items: center;
    max-width: 440px;

    select {
        margin-right: 10px;
    }

    select:not(.isIE) {
        display: none;
    }

    a {
        display: inline-block;
    }
}
</style>

<style lang="scss">
.auctioneer-select {
    .choices {
        flex-grow: 1;
        margin-bottom: 0;
    }

    .choices__inner {
        border-radius: 0;
        background: #fff;
    }

    .choices.is-open {
        .choices__inner {
            border-radius: 0;
        }
    }

    .choices__item--disabled {
        display: none;
    }

    a {
        margin-left: 20px;
    }

    select.choices__input {
        display: none !important;
    }
}
</style>
