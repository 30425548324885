<template>
    <picture
        class="company-image"
        :class="{ 'is-front-page': isFrontPage }"
        :title="title"
    >
        <source media="(min-width: 1024px)" :srcset="largeSrc" />
        <source media="(min-width: 768px)" :srcset="mediumSrc" />
        <source media="(min-width: 160px)" :srcset="mobileSrc" />
        <img loading="lazy" :src="mediumSrc" :alt="alt" width="140" height="48" />
        <!-- fallback -->
    </picture>
</template>

<script>
import { COMPANY_IMAGES } from '../config.js';

export default {
    name: 'CompanyImage',
    data() {
        return {};
    },
    props: {
        alt: {
            required: false,
            default: 'logo',
        },
        title: {
            required: false,
            default: 'logo',
        },
        isFrontPage: {
            required: false,
            default: false,
        },
        companyId: {
            required: true,
        },
        auctionId: {
            required: false,
            default: false,
        },
    },
    computed: {
        auctionPathPart() {
            return this.auctionId ? `/${this.auctionId}` : '';
        },
        mobileSrc() {
            if (this.isFrontPage) {
                return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.FRONTPAGE_LOGO_FILE}`;
            }
            if (this.auctionId) {
                return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.LOT_HEADER_MOBILE_FILE}`;
            }
            return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.LOT_HEADER_MOBILE_FILE}`;
        },
        mediumSrc() {
            if (this.isFrontPage) {
                return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.FRONTPAGE_LOGO_FILE}`;
            }
            if (this.auctionId) {
                return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.LOT_HEADER_FILE}`;
            }
            return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.LOT_HEADER_FILE}`;
        },
        largeSrc() {
            if (this.isFrontPage) {
                return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.FRONTPAGE_LOGO_2X_FILE}`;
            }
            if (this.auctionId) {
                return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.LOT_HEADER_2X_FILE}`;
            }
            return `${COMPANY_IMAGES.URL}${this.companyId}${this.auctionPathPart}/${COMPANY_IMAGES.LOT_HEADER_2X_FILE}`;
        },
    },
};
</script>

<style scoped lang="scss">
.company-image {
    height: 45px;
    display: block;
    overflow: hidden;

    &.is-front-page {
        height: 60px;

        img {
            height: auto;
            max-width: 100%;
        }
    }

    img {
        height: 100%;
        max-width: initial;
    }
}
</style>
