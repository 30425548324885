export const validateBid = (value, lotStartingPrice) => {
    let type = 'error';
    let content = '';

    if (
        typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value &&
        value > 0
    ) {
        if (value === 0 || value === '') {
            content = 'bid_errors_empty';
        } else {
            if (value < lotStartingPrice) {
                content = 'bid_errors_tooLow';
            } else {
                type = 'success';
                content = 'bid_success';
            }
        }
    } else {
        content = 'bid_errors_invalid';
    }

    return {
        type,
        content,
    };
};

export const validateIncrease = value => {
    let type = 'error';
    let content = '';

    if (
        typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value &&
        value >= 0 &&
        value <= 100
    ) {
        if (value === '') {
            content = 'bid_increase_errors_empty';
        } else {
            type = 'success';
            content = 'bid_increase_success';
        }
    } else {
        content = 'bid_increase_errors_invalid';
    }

    return {
        type,
        content,
    };
};
