<template>
    <aside class="sidebar">
        <lot-search
            size="small"
            :isReduced="true"
            class="hidden-below-md"
        ></lot-search>

        <div id="auex-live-ticker-wrapper">
            <sidebar-box-auex-ticker
                v-for="liveBiddingTicker in auexLiveTickerAuctions"
                :key="liveBiddingTicker.auctionId"
                :baseUrl="'https://' + liveBiddingTicker.hostname"
                :auctionId="liveBiddingTicker.auctionId"
            />
        </div>

        <sidebar-box
            v-if="
                flexibleTeaserBoxes['1-flexible-box'] &&
                flexibleTeaserBoxes['1-flexible-box'].boxVisible == 1
            "
            :headline="flexibleTeaserBoxes['1-flexible-box'].headline"
        >
            <div v-html="flexibleTeaserBoxes['1-flexible-box'].content"></div>
        </sidebar-box>
        <sidebar-box
            v-if="
                flexibleTeaserBoxes['2-flexible-box'] &&
                flexibleTeaserBoxes['2-flexible-box'].boxVisible == 1
            "
            :headline="flexibleTeaserBoxes['2-flexible-box'].headline"
        >
            <div v-html="flexibleTeaserBoxes['2-flexible-box'].content"></div>
        </sidebar-box>

        <!-- currently not in use
        <sidebar-box class="livebidding-box" :headline="$t('Sincona Live-Ticker')">
            <iframe class="livebidding-iframe" src="https://auexliveticker.inova.de/SINCONA.aspx" />
        </sidebar-box>
        -->

        <sidebar-box-highlight-special />
        <sidebar-box-highlight />

        <!-- currently not in use
        <sidebar-box-newsletter />
        --> 
        <sidebar-box
            v-if="
                flexibleTeaserBoxes['3-flexible-box'] &&
                flexibleTeaserBoxes['3-flexible-box'].boxVisible == 1
            "
            :headline="flexibleTeaserBoxes['3-flexible-box'].headline"
        >
            <div v-html="flexibleTeaserBoxes['3-flexible-box'].content"></div>
        </sidebar-box>
        <sidebar-box
            v-if="
                flexibleTeaserBoxes['4-flexible-box'] &&
                flexibleTeaserBoxes['4-flexible-box'].boxVisible == 1
            "
            :headline="flexibleTeaserBoxes['4-flexible-box'].headline"
        >
            <div v-html="flexibleTeaserBoxes['4-flexible-box'].content"></div>
        </sidebar-box>

        <coin-collector></coin-collector>

        <sidebar-box-best-coins />

        <div
            v-if="getAds['Sidebar-3'] || getAds['Sidebar-4']"
            class="sidebar-box-group"
        >
            <div class="sidebar-box-slots">
                <ad-banner
                    v-if="getAds['Sidebar-3']"
                    :ad="getAds['Sidebar-3']"
                />
            </div>
            <div class="sidebar-box-slots">
                <ad-banner
                    v-if="getAds['Sidebar-4']"
                    :ad="getAds['Sidebar-4']"
                />
            </div>
        </div>

        <div
            v-if="getAds['Sidebar-1'] || getAds['Sidebar-2']"
            class="sidebar-box-group"
        >
            <div class="sidebar-box-slots">
                <ad-banner
                    v-if="getAds['Sidebar-1']"
                    :ad="getAds['Sidebar-1']"
                />
            </div>
            <div class="sidebar-box-slots">
                <ad-banner
                    v-if="getAds['Sidebar-2']"
                    :ad="getAds['Sidebar-2']"
                />
            </div>
        </div>

        <sidebar-box :headline="$t('news_numismatic')">
            <p>
                {{ $t('news_numismatic_world') }}
            </p>
            <p
                class="powered-by-coinsweekly"
                v-html="$t('powered_by_coinsweekly')"
            ></p>
            <news-list></news-list>
        </sidebar-box>

        <sidebar-box :headline="$t('history_week')">
            <twih-list></twih-list>
        </sidebar-box>
    </aside>
</template>

<script>
import SidebarBox from '../components/SidebarBox';
import NewsList from '../components/NewsList';
import TwihList from '../components/TwihList';
import LotSearch from '../components/LotSearch.vue';
import CoinCollector from '../components/CoinCollector.vue';
import SidebarBoxAuexTicker from '../components/SidebarBoxAuexTicker.vue';
import SidebarBoxBestCoins from '../components/SidebarBoxBestCoins.vue';
import SidebarBoxHighlight from '../components/SidebarBoxHighlight.vue';
import SidebarBoxHighlightSpecial from '../components/SidebarBoxHighlightSpecial.vue';
import SidebarBoxNewsletter from '../components/SidebarBoxNewsletter.vue';
import AdBanner from '../components/AdBanner';

import { mapGetters } from 'vuex';
import { getFlexibleTeaserBoxes, getAuexRunningAuctions } from '../lib/apis';

export default {
    name: 'HomeSidebar',
    components: {
        SidebarBox,
        NewsList,
        TwihList,
        LotSearch,
        CoinCollector,
        SidebarBoxAuexTicker,
        SidebarBoxBestCoins,
        SidebarBoxHighlight,
        SidebarBoxHighlightSpecial,
        SidebarBoxNewsletter,
        AdBanner,
    },
    data: function () {
        return {
            buyNowItems: [],
            flexibleTeaserBoxes: [],
            auexLiveTickerAuctions: [],
        };
    },
    computed: {
        ...mapGetters({ getAds: 'ads/getAds' }),
    },
    mounted: function () {
        this.fetchFlexibleTeaserBoxes();
        this.fetchAuexRunningAuctions();

        /*
         *
         * Code snippet by Wolfgang from https://git.brainson.de/marm/19_0634_sixbid_com_wartung_kunde/issues/140#note_40956
         * edited by PM
         *
         */

        /*
        let d = new Date();
        let hideBox = true;
        let auctionParams = {'month': 9, 'startDay' : 21, 'endDay' : 24};
        let box = document.querySelector('.livebidding-box');
        let showBoxSlots = {};
        // UTC is currently 2 hours behind, so `'startHour' : 11` is actually 13:00 German time
        showBoxSlots[21] = {'startHour' : 11, 'startMin' : 30, 'endHour' : 16, 'endMin' : 30};
        showBoxSlots[22] = {'startHour' : 6, 'startMin' : 30, 'endHour' : 17, 'endMin' : 30};
        showBoxSlots[23] = {'startHour' : 6, 'startMin' : 30, 'endHour' : 18, 'endMin' : 30};
        showBoxSlots[24] = {'startHour' : 6, 'startMin' : 30, 'endHour' : 11, 'endMin' : 30};
    
        // Hours Format is UTC
        if (d.getUTCMonth() === auctionParams.month && d.getUTCDate() >= auctionParams.startDay && d.getUTCDate() <= auctionParams.endDay)
        {
            let timeSlot = showBoxSlots[d.getDate()];
            let startHour = timeSlot.startHour;
            let startMin = timeSlot.startMin;
            let endHour = timeSlot.endHour;
            let endMin = timeSlot.endMin;
    
            if ( d.getUTCHours() === startHour && d.getUTCMinutes() >= startMin || d.getUTCHours() > startHour && d.getUTCHours() <= endHour)
            {
                hideBox = d.getUTCHours() === endHour && d.getUTCMinutes() > endMin ? true : false;
            }
        }
    
        if ( hideBox ) box.style.display = 'none';
        
        */
    },
    methods: {
        fetchFlexibleTeaserBoxes() {
            getFlexibleTeaserBoxes(this.$route.params.lang)
                .then((response) => {
                    this.flexibleTeaserBoxes = response.data;
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        fetchAuexRunningAuctions() {
            getAuexRunningAuctions()
                .then((response) => {
                    this.auexLiveTickerAuctions = response.data;
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.livebidding-iframe {
    width: 100%;
    height: 250px;
    border: none;
    margin-top: 15px;
}
</style>

<style lang="scss" scoped>
#auex-live-ticker-wrapper {
    display: flex;
    flex-direction: column;
}
.powered-by-coinsweekly {
    margin-top: 0;
}

.sidebar-box-group {
    display: flex;
    justify-content: space-between;
}

.sidebar-box-slots {
    background: $grey-bg-light;
    padding: $pad-md;
    margin-bottom: $mar-lg;
    line-height: 0;
    width: calc(50% - #{$pad-md});

    &:first-child {
        margin-right: $pad-md;
    }
    &:last-child {
        margin-left: $pad-md;
    }
}
</style>
