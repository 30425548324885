<template>
    <div class="imprint-page">
        <h1>{{ $t('page_title_aboutus') }}</h1>
        <breadcrumb>{{ $t('page_title_imprint') }}</breadcrumb>

        <about-us-tabs></about-us-tabs>

        <div class="reading">
            <h2>{{ $t('page_title_imprint') }}</h2>
            <div class="text" v-html="$t('page_content_imprint')"></div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import AboutUsTabs from '../components/AboutUsTabs.vue';

export default {
    name: 'imprint',
    components: {
        AboutUsTabs,
        Breadcrumb,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_imprint'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_imprint'),
                },
            ],
        };
    },
};
</script>

<style scoped></style>
