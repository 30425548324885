<template>
    <div>
        <div class="shipping form">
            <h3>{{ $t('address_shipping') }}</h3>
            <div class="grid">
                <div class="row">
                    <div class="col col-lg-6">
                        <select ref="shipping">
                            <option
                                v-for="address in addresses"
                                :key="address.address_id"
                                :value="address.address_id"
                            >
                                <template v-if="address.user_company"
                                    >{{ address.user_company }},</template
                                >
                                {{ address.user_title }}
                                {{ address.user_first_name }}
                                {{ address.user_last_name }},
                                {{ address.user_address }},
                                {{ address.user_city }}, {{ address.user_zip }},
                                {{ address.user_country | countryName }}
                            </option>
                        </select>
                        <p>{{ $t('address_change') }}</p>
                        <a @click="showNewShipping = true">{{
                            $t('address_shipping_add')
                        }}</a>
                        <a
                            class="close"
                            @click="closeNewAddress()"
                            v-if="showNewShipping"
                        >
                            <i class="fas fa-times fa-2x"></i>
                        </a>
                    </div>
                </div>
                <shipping-address-add
                    v-if="showNewShipping"
                    @address-added="addedNewShippingAddress()"
                ></shipping-address-add>
            </div>
        </div>
        <div class="comment form">
            <div class="row">
                <div class="col col-lg-6">
                    <h3>{{ $t('comments') }}</h3>
                    <textarea v-model="form.comment"></textarea>
                </div>
            </div>
        </div>
        <div class="conditions form">
            <div class="row">
                <div class="col col-lg-6">
                    <h3>{{ $t('auction_conditions') }}</h3>
                    <label
                        class="container"
                        :class="{
                            hasInput: form.conditions,
                            hasError:
                                errors.conditions || userErrors.conditions,
                        }"
                    >
                        <input
                            type="checkbox"
                            required
                            v-model="form.conditions"
                            value="1"
                            @change="validateFieldEmpty('conditions')"
                        />
                        {{ $t('conditions_accept') }}
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div
                    class="col col-lg-4"
                    v-if="errors.conditions || userErrors.conditions"
                >
                    <span class="alert alert--error">
                        <i class="fas fa-exclamation-circle"></i>
                        <template v-if="errors.conditions">{{
                            $t(errors.conditions)
                        }}</template>
                        <template v-if="userErrors.conditions">{{
                            $t(userErrors.conditions)
                        }}</template>
                    </span>
                </div>
            </div>
        </div>

        <div class="eco form">
            <div class="row">
                <div class="col col-lg-4">
                    <h3>{{ $t('eco_save') }}</h3>
                    <label class="container">
                        <input type="checkbox" v-model="form.eco" value="1" />
                        {{ $t('catalogues_decline') }}
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="ecosticker col col-lg-2">
                    <img
                        width="60"
                        height="60"
                        alt="eco"
                        loading="lazy"
                        src="/assets/img/eco-logo-aussicht.gif"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col col-lg-9">
                <p>
                    <strong>{{ $t('bid_disclaimer1') }}</strong>
                    {{ $t('bid_disclaimer2') }}
                </p>
            </div>
        </div>

        <div class="buttons row">
            <div class="col push-lg-6 col-lg-1">
                <a class="back" @click="goBack()">{{ $t('back') }}</a>
            </div>
            <div class="col col-lg-2">
                <button-loader
                    class="full"
                    :clickHandler="sendSheet"
                    :loading="buttonLoading"
                    >{{ $t('bid_send') }}</button-loader
                >
            </div>
        </div>
    </div>
</template>

<script>
import ShippingAddressAdd from './ShippingAddressAdd.vue';
import { getMyAddresses } from '../lib/apis';
import ButtonLoader from '../components/ButtonLoader.vue';

import Vue from 'vue';

export default {
    name: 'BidSheetUserInfo',
    components: {
        ShippingAddressAdd,
        ButtonLoader,
    },
    props: {
        userErrors: {
            type: Object,
            required: true,
        },
        buttonLoading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            addresses: [],
            errors: {},
            form: {
                eco: '',
                conditions: '',
                comment: '',
            },
            showNewShipping: false,
        };
    },
    methods: {
        addedNewShippingAddress() {
            this.showNewShipping = false;
            this.updateAddresses();
        },
        goBack() {
            this.$emit('go-back');
        },
        validateFieldEmpty(fieldName) {
            if (this.form[fieldName] === '' || !this.form[fieldName]) {
                Vue.set(this.errors, fieldName, `form.errors.${fieldName}`);
            } else {
                Vue.delete(this.errors, fieldName);
            }
        },
        closeNewAddress() {
            this.showNewShipping = false;
        },
        sendSheet() {
            this.validateFieldEmpty('conditions');

            const data = {
                shippingAddressId: this.$refs.shipping.value,
                eco: this.form.eco ? 1 : 0,
                conditions: this.form.conditions ? 1 : 0,
                comment: this.form.comment,
            };

            if (Object.keys(this.errors).length === 0) {
                this.$emit('send-sheet', data);
            }
        },
        updateAddresses() {
            getMyAddresses(this.$route.params.lang)
                .then((response) => {
                    Vue.set(this, 'addresses', response.data);
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
    mounted() {
        this.updateAddresses();
    },
};
</script>

<style scoped lang="scss">
.close {
    float: right;
}
.back {
    margin-right: 20px;
    vertical-align: -0.75em;
}
.full {
    width: 100%;
}
.eco {
    color: #080;
}
.buttons {
    margin-top: 3em;
}
</style>
