import Vue from '../../node_modules/vue/dist/vue.js';
import Vuex from 'vuex';
import account from './account';
import lotWatchlist from './lotWatchlist';
import tasks from './tasks';
import ads from './ads';
import lang from './lang';
import countries from './countries';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        account: account,
        lotWatchlist: lotWatchlist,
        tasks: tasks,
        ads: ads,
        lang: lang,
        countries: countries,
    },
});

export default store;
