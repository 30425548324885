<template>
    <div class="auction-list-item clearfix" v-on:click="itemClicked">
        <company-image
            class="auction-list-item-img"
            :isFrontPage="true"
            v-if="companyId"
            :alt="companyLogoAlt"
            :title="companyLogoTitle"
            :companyId="companyId"
            :auctionId="auctionId"
        ></company-image>

        <div class="auction-list-item-info">
            <a
                v-if="auctionLink"
                :href="auctionLink"
                ref="externalLink"
                target="_blank"
                @click.prevent="overlay = true"
                class="nostyle"
            >
                <h3 class="auction-list-item-title">
                    {{ auctionName }}
                    <i class="fas fa-external-link-alt"></i>
                </h3>
            </a>
            <leaving-overlay
                v-if="auctionLink"
                :url="auctionLink"
                :shown="overlay"
                type="external"
                @closing="overlay = false"
            ></leaving-overlay>
            <router-link
                v-else
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        companySlug: companySlug,
                        auctionId: auctionId,
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
                event
                @click.native.prevent
                class="nostyle"
            >
                <h3 class="auction-list-item-title">{{ auctionName }}</h3>
            </router-link>
            <div class="auction-list-item-company">{{ companyName }}</div>

            <p
                :inner-html.prop="auctionDescription | renderBR"
                v-if="auctionDescription"
                class="auction-list-item-description"
            ></p>
            <leaving-overlay
                v-if="
                    auctionLiveBiddingLink &&
                    $route.name !== 'page.auctionListPast' &&
                    $route.name !== 'page.auctionListPastCompany'
                "
                :url="auctionLiveBiddingLink"
                :shown="overlay"
                @closing="overlay = false"
            ></leaving-overlay>
        </div>

        <div class="auction-list-item-date">
            <div>{{ date }}</div>
            <div
                class="auction-list-item-results"
                v-if="auctionPrLink || auctionPrAvailable"
            >
                <a
                    :href="auctionPrLink"
                    v-if="auctionPrLink != '' && auctionPrLink != null"
                    target="_blank"
                    >{{ $t('results') }}</a
                >
                <router-link
                    v-if="auctionPrAvailable == 1"
                    :to="{
                        name: 'page.auctionResults',
                        params: {
                            companySlug: companySlug,
                            auctionId: auctionId,
                            page: PAGINATION_DEFAULTS.START_PAGE,
                            perPage: PAGINATION_DEFAULTS.PER_PAGE,
                        },
                    }"
                    >{{ $t('results') }}</router-link
                >
            </div>
            <div
                class="auction-list-item-livebidding"
                v-if="auctionLiveBiddingLink && !auctionEnded"
            >
                <router-link
                    v-if="auctionLiveBiddingLink && liveBiddingEnabled == 1"
                    :class="liveBiddingEnabled == 1 ? 'blue' : ''"
                    :to="{
                        name: 'page.auctionLivebidding',
                        params: {
                            companySlug: companySlug,
                            auctionId: auctionId,
                            page: PAGINATION_DEFAULTS.START_PAGE,
                            perPage: PAGINATION_DEFAULTS.PER_PAGE,
                        },
                        query: this.$route.query,
                    }"
                    >{{ $t('livebidding') }}</router-link
                >
                <span
                    v-if="auctionLiveBiddingLink && liveBiddingEnabled == 0"
                    class=""
                    >{{ $t('livebidding') }}</span
                >
            </div>
            <div v-if="auctionisNew" class="auction-list-item-new">
                {{ $t('new') }}
            </div>
        </div>
    </div>
</template>

<script>
import { dateRange } from '@/filters/date';
import { PAGINATION_DEFAULTS } from '../config';
import LeavingOverlay from './LeavingOverlay.vue';
import CompanyImage from './CompanyImage.vue';
import { compareDates } from '../lib/date';

export default {
    name: 'AuctionListItem',
    components: {
        LeavingOverlay,
        CompanyImage,
    },
    props: {
        auctionId: {
            type: Number,
            required: true,
        },
        auctionName: {
            type: String,
            required: true,
        },
        companyId: {
            type: Number,
            required: true,
        },
        companyName: {
            type: String,
            required: true,
        },
        companySlug: {
            type: String,
            required: true,
        },
        auctionDescription: {
            type: String,
        },
        companyLogoAlt: {
            type: String,
            default: '',
        },
        companyLogoTitle: {
            type: String,
            default: '',
        },
        auctionStart: {
            type: String,
            required: true,
        },
        auctionEnd: {
            type: String,
            required: false,
        },
        auctionAltBidLink: {
            type: Number,
            required: false,
        },
        auctionLiveBiddingLink: {
            type: String,
            required: false,
        },
        auctionLink: {
            type: String,
            required: false,
        },
        auctionisNew: {
            type: Number,
            required: false,
            default: 0,
        },
        auctionHasNGC: {
            type: Boolean,
            required: false,
            default: false,
        },
        auctionPrAvailable: {
            type: Number,
            required: false,
            default: 0,
        },
        auctionPrLink: {
            type: String,
            required: false,
            default: '',
        },
        liveBiddingEnabled: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            overlay: false,
        };
    },
    computed: {
        date() {
            return dateRange(this.auctionStart, this.auctionEnd);
        },
        auctionEnded() {
            return compareDates(this.auctionEnd, new Date(), 'day') === -1;
        },
    },
    methods: {
        itemClicked: function (event) {
            if (
                event.target.tagName == 'A' ||
                event.target.classList.contains('auction-list-item-ngc')
            ) {
                return false;
            }
            if (this.auctionLink) {
                this.$refs.externalLink.click();
            } else {
                this.$router.push({
                    name: 'page.auctionLotList',
                    params: {
                        companySlug: this.companySlug,
                        auctionId: this.auctionId,
                        page: this.PAGINATION_DEFAULTS.START_PAGE,
                        perPage: this.PAGINATION_DEFAULTS.PER_PAGE,
                    },
                });
            }
        },
    },
    mounted() {
        this.$root.$on('update:language', () => {
            this.$forceUpdate();
        });
    },
};
</script>
<style lang="scss" scoped>
$image_size: 150px;
$date_max: 140px; // 30.September 2020
$new_max: 45px; // new
$results_max: 40px; //Ergebnisse

.nostyle {
    text-decoration: none;
}

.auction-list-item {
    background: $grey-bg-lighter;
    margin-bottom: $mar-lg;
    padding: $pad-lg;
    position: relative;
    cursor: pointer;
    min-height: 77px + 30px;

    &-img {
        margin-right: $mar-md;
        margin-bottom: $mar-md;
        display: block;
        width: $image_size;

        @media (min-width: $lg-size) {
            float: left;
            margin-right: $mar-xl;
            margin-bottom: 0;
        }
    }

    &-info {
        max-width: calc(100% - #{$results_max});
        @media (min-width: $lg-size) {
            float: left;
            width: calc(100% - #{$image_size + $mar-xl + $date_max + $new_max});
        }
    }

    &-title {
        word-break: break-word;
        margin: 0;
        font-size: 18px;
        color: $text_color;
        text-decoration: none;

        i {
            color: $main-color;
            font-size: 0.8em;
        }

        a:visited & {
            color: #c00;
        }
    }

    &-company {
        font-size: 13px;
        color: #999;
    }

    &-description {
        margin: 2px 0;

        margin-right: #{-1 * $results_max};
    }

    &-date {
        position: absolute;
        /*background: $dark-grey-bg;*/
        font-size: 13px;
        color: $text-color;
        padding: 3px 10px;

        right: 0;
        top: $pad-lg;
        max-width: 40%;

        text-align: right;

        @media (min-width: $sm-size) {
            max-width: none;
        }

        a {
            text-decoration: none;
        }
    }

    &-ngc {
        width: 95px;
        position: absolute;
        right: 0;
        top: 0;
        background: $ngc-color;
        font-size: 13px;
        color: white;
        padding: 3px 10px;
        transform: translateY(150%);
    }

    &-new {
        background: $red-highlight;
        font-size: 13px;
        color: white;
        padding: 3px 10px;
        margin-right: -10px;
        margin-top: 10px;
        display: inline-block;

        .auction-list-item-ngc + & {
            transform: translateY(300%);
        }

        // @media (min-width: $lg-size) {
        //     position: absolute;
        //     top: 0;
        //     right: auto;
        //     left: 0;
        //     transform: translateY(0) translateX(-100%);
        //     margin-right: 0;
        //     margin-top: 0;

        //     .auction-list-item-ngc + & {
        //         transform: translateY(0) translateX(-100%);
        //     }
        // }
    }

    &-results {
        font-size: 13px;
        color: white;
        margin-top: 10px;
        margin-right: -10px;
        position: relative;
        text-align: right;

        a {
            color: white;
            background: $dark-grey-bg;
            padding: 3px 10px;
        }

        .auction-list-item-ngc + & {
            @media (min-width: $lg-size) {
                top: 0;
            }
        }
    }

    &-livebidding {
        font-size: 13px;
        color: white;
        margin-top: 10px;
        margin-right: -10px;

        a {
            color: white;
            background: $main-color;
            padding: 3px 10px;
        }

        span {
            background: $dark-grey-bg;
            padding: 3px 10px;
        }
    }
}
</style>
