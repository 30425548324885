<template>
    <div class="account-page" id="account-notifications-page">
        <div class="row">
            <account-navigation />
            <div class="col col-md-12 col-lg-12">
                <account-header />
                <h1>{{ $t('searches_saved') }}</h1>
                <pagination
                    ref="PaginationTop"
                    @pagination-changed="getSearches()"
                    :hasPerPageSelect="true"
                    v-if="!$global.isMobile"
                />
                <table width="100%">
                    <thead>
                        <tr>
                            <th colspan="2">{{ $t('search_term') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(search, index) in searches"
                            :key="search.searchId"
                            :class="index % 2 === 1 ? 'odd' : 'even'"
                        >
                            <td>{{ search.searchQuery }}</td>
                            <td class="links">
                                <router-link
                                    class="btn"
                                    :to="{
                                        name: 'page.lots',
                                        query: { term: search.searchQuery },
                                        params: {
                                            page: PAGINATION_DEFAULTS.START_PAGE,
                                            perPage:
                                                PAGINATION_DEFAULTS.PER_PAGE,
                                        },
                                    }"
                                    >{{ $t('search_now') }}</router-link
                                >
                                -
                                <a
                                    class="ico"
                                    @click="delteSearch(search.searchId)"
                                    >{{ $t('delete') }}</a
                                >
                                <div v-if="getError(search.searchId)" class="error-message">
                                    {{ getError(search.searchId) }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!searches.length">
                            <td colspan="2">
                                {{ $t('searches_empty') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination
                    ref="PaginationBottom"
                    @pagination-changed="getSearches()"
                    :hasPerPageSelect="!$global.isMobile"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '../lib/Authorization';
import AccountHeader from '../components/AccountHeader';
import AccountNavigation from '../components/AccountNavigation';
import Pagination from '../components/Pagination';
import { PAGINATION_DEFAULTS } from '../config.js';

import { deleteSearchFromSearches } from '../lib/apis';

export default {
    name: 'Account_Notifications',
    components: {
        AccountHeader,
        AccountNavigation,
        Pagination,
    },
    data: function () {
        return {
            searches: [],
            errors: {},
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    methods: {
        getError: function (searchId) {
            return this.errors[searchId];
        },
        getSearches: function () {
            const data = {
                limit: this.$route.params.perPage,
                page: this.$route.params.page,
                lang: this.$route.params.lang,
            };
            Auth.createAxiosClient()
                .then((axios) => {
                    return axios.get(
                        process.env.VUE_APP_USER_PRIVATE_API_URL +
                            '/my-searches',
                        { params: data }
                    );
                })
                .then((response) => {
                    this.searches = response.data.items;
                    this.$refs.PaginationTop.responseHelper(response);
                    this.$refs.PaginationBottom.responseHelper(response);
                });
        },
        delteSearch(searchId) {
            this.errors = {};
            return deleteSearchFromSearches(searchId)
                .then((response) => {
                    if (response.data.success) {
                        this.searches = this.searches.filter(
                            (search) => search.searchId !== searchId
                        );
                    } else {
                        this.errors[searchId] = response.data.message;
                    }
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
    created: function () {
        this.getSearches();
    },
};
</script>

<style scoped lang="scss">
/* reset table display */
table,
thead,
tbody,
tfoot,
tr,
td,
th {
    display: block;
}

thead {
    background: $grey-bg;
}

tr {
    display: flex;
    justify-content: space-between;
}

tbody tr {
    padding: $pad-md 0;
}

td,
th {
    padding: $pad-sm $pad-lg;
}

.even {
    background: white;
}

.odd {
    background: $grey-bg-lightest;
}

.links {
    min-width: 180px;
}

.error-message {
    color: red;
    margin-top: 3px;
    font-size: 12px;
}
</style>
