<template>
    <div class="similar-lot-list">
        <loader ref="loader"></loader>
        <div class="similar-lot-list-wrapper small">
            <lot-list-item
                :lot="lot"
                v-for="lot in lots"
                :key="lot.lotId"
                :is-similar="true"
                :showImage="showImage"
                :showLotNr="showLotNr"
                :showDescription="showDescription"
                :showCompany="showCompany"
                :showStartingPrice="showStartingPrice"
                :showEstimatePrice="showEstimatePrice"
                :showResultPrice="showResultPrice"
                :showDetailsButton="showDetailsButton"
                :showBidding="showBidding"
                :useImageFallback="!lot.hasImage"
            ></lot-list-item>
        </div>
    </div>
</template>

<script>
import CloudImageGetter from '../lib/CloudImageGetter';
import Loader from './Loader';
import * as basicLightBox from 'basiclightbox';
import LotListItem from './LotListItem.vue';
import { getSimilarLots } from '../lib/apis';

export default {
    props: {
        lotId: {
            type: Number,
            required: true,
        },

        showImage: {
            type: Boolean,
            required: false,
            default: true,
        },
        showLotNr: {
            type: Boolean,
            required: false,
            default: true,
        },
        showDescription: {
            type: Boolean,
            required: false,
            default: true,
        },
        showCompany: {
            type: Boolean,
            required: false,
            default: false,
        },
        showStartingPrice: {
            type: Boolean,
            required: false,
            default: true,
        },
        showEstimatePrice: {
            // if true will only show if estimate exists
            type: Boolean,
            required: false,
            default: true,
        },
        showResultPrice: {
            // if true will always show, either with a price or with ---
            type: Boolean,
            required: false,
            default: false,
        },
        showDetailsButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        showBidding: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        Loader,
        LotListItem,
    },
    data: function () {
        return {
            lots: [],
        };
    },
    mounted: function () {
        this.fetchLots();
    },
    methods: {
        showLightbox(lot) {
            let lightbox = basicLightBox.create(`
                <img loading="lazy" src="${this.getImage(
                    lot,
                    'L'
                )}" alt="lightbox" width="1024" height="512">
            `);

            lightbox.show();
        },
        getImage(lot, size) {
            return CloudImageGetter(lot.googleBucketImagePath, size);
        },
        fetchLots() {
            this.$refs.loader.show();

            getSimilarLots(this.lotId, this.$route.params.lang)
                .then((lots) => {
                    this.lots = lots.data.splice(0, 3);

                    this.$refs.loader.hide();
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
    watch: {
        lotId() {
            this.fetchLots();
        },
    },
};
</script>

<style scoped lang="scss">
.similar-lot-list {
    position: relative;
    min-height: 50px;
}

.similar-lot-list-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.lot-list-item {
    border: 1px solid lightgray;
    padding: $pad-lg;

    width: 100%;

    @media (min-width: $sm-size) {
        width: calc(50% - #{$mar-lg});
        margin-right: 20px;

        &:nth-child(2) {
            margin-right: 0;
        }

        &:nth-child(3) {
            display: none;
        }
    }

    @media (min-width: $lg-size) {
        width: calc(33% - #{$mar-lg});
        margin-right: 20px;

        &:nth-child(2n) {
            margin-right: 20px;
        }

        &:nth-child(3n) {
            display: flex;
            margin-right: 0;
        }
    }
}
</style>
