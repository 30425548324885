var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-page",attrs:{"id":"account-watchlist-page"}},[_c('div',{staticClass:"row"},[_c('account-navigation'),_c('div',{staticClass:"col col-md-12 col-lg-12"},[_c('account-header'),_c('h1',[_vm._v(_vm._s(_vm.$t('watchlist')))]),_c('pagination',{ref:"PaginationTop",attrs:{"hasPerPageSelect":true},on:{"pagination-changed":function($event){return _vm.getWatches()}}}),_c('table',{attrs:{"width":"100%"}},[_c('thead',{staticClass:"hidden-below-sm"},[_c('tr',[_c('th',[_vm._v(" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('image')))]),_c('th',[_vm._v(_vm._s(_vm.$t('auction_lot_nr')))]),_c('th',[_vm._v(_vm._s(_vm.$t('prices')))]),_c('th',[_vm._v(_vm._s(_vm.$t('note')))]),_c('th',[_vm._v(_vm._s(_vm.$t('options')))])])]),_c('tbody',[_vm._l((_vm.watches),function(watch,index){return _c('tr',{key:watch.watchId,class:index % 2 === 1 ? 'odd' : 'even'},[_c('td',{staticClass:"check"},[_c('label',{staticClass:"container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedWatches),expression:"checkedWatches"}],attrs:{"type":"checkbox"},domProps:{"value":watch.lotId,"checked":Array.isArray(_vm.checkedWatches)?_vm._i(_vm.checkedWatches,watch.lotId)>-1:(_vm.checkedWatches)},on:{"change":function($event){var $$a=_vm.checkedWatches,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=watch.lotId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedWatches=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedWatches=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedWatches=$$c}}}}),_c('span',{staticClass:"checkmark"})])]),_c('td',{staticClass:"image"},[_c('router-link',{attrs:{"to":{
                                    name: 'page.auctionLotDetail',
                                    params: {
                                        page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                        perPage:
                                            _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                        auctionId: watch.auctionId,
                                        companySlug: watch.companySlug,
                                        lotId: watch.lotId,
                                        categorySlug:
                                            watch.categorySlug === ''
                                                ? '-'
                                                : watch.categorySlug,
                                        lotSlug: watch.lotSlug,
                                    },
                                },"target":"_blank"}},[_c('img',{attrs:{"alt":_vm.$t('lot'),"loading":"lazy","src":_vm.getWatchImage(watch),"width":"256","height":"128"}})])],1),_c('td',{staticClass:"desc"},[_c('div',[_c('router-link',{attrs:{"to":{
                                        name: 'page.auctionLotDetail',
                                        params: {
                                            page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                            perPage:
                                                _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                            auctionId: watch.auctionId,
                                            companySlug: watch.companySlug,
                                            lotId: watch.lotId,
                                            categorySlug:
                                                watch.categorySlug === ''
                                                    ? '-'
                                                    : watch.categorySlug,
                                            lotSlug: watch.lotSlug,
                                        },
                                    },"target":"_blank"}},[(watch.companyNameShort)?_c('span',[_vm._v(_vm._s(watch.companyNameShort))]):_c('span',[_vm._v(_vm._s(watch.companyName))]),_vm._v("\n                                    "+_vm._s(watch.auctionTitle)+",\n                                    "+_vm._s(_vm.$t('lot'))+" "+_vm._s(watch.lotNumber)+_vm._s(watch.lotNumberAffix))]),_c('span',[_vm._v(_vm._s(_vm._f("dateLongFormater")(watch.auctionStart)))]),(
                                        _vm.dateTimeInFuture(watch.sessionEnds)
                                    )?_c('span',[_vm._v(_vm._s(_vm.$t('bid_end'))+":\n                                    "),_c('date-timezone',{attrs:{"date":watch.sessionEnds}})],1):_c('span',[_vm._v(_vm._s(_vm.$t('bid_ended')))])],1),_c('div',{staticClass:"icons hidden-above-sm"},[_c('i',{staticClass:"far fa-trash-alt fa-lg",on:{"click":function($event){return _vm.deleteWatch(watch.lotId)}}})])]),_c('td',{staticClass:"prices"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('lot_price_start'))+" ")]),_c('price',{attrs:{"value":Number(watch.lotStartingPrice),"currency":watch.auctionCurrency}})],1),(watch.lotEstimate > 0)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('lot_estimate'))+" ")]),_c('price',{attrs:{"value":Number(watch.lotEstimate),"currency":watch.auctionCurrency}})],1):_vm._e(),(watch.lotPriceRealised > 0)?_c('div',[_c('strong',[_c('span',[_vm._v(_vm._s(_vm.$t('bid_hammer'))+" ")])]),_c('strong',[_c('price',{attrs:{"value":Number(watch.lotPriceRealised),"currency":watch.auctionCurrency}})],1)]):_vm._e()]),_c('td',{staticClass:"note"},[_c('watch-list-note',{key:watch.watchId,attrs:{"watchId":watch.watchId,"lotId":watch.lotId,"note":watch.note}})],1),_c('td',{staticClass:"actions"},[_c('bid-lot-input',{staticClass:"bid-lot-input-container",attrs:{"lot":watch,"showBiddingCompletedNotice":false},on:{"bid-placed":function($event){return _vm.addMessageForWatchItem(
                                        watch.watchId,
                                        $event
                                    )}}}),(_vm.getMessageForWatchItem(watch.watchId))?_c('bid-lot-message',{attrs:{"lot":watch,"message-type":_vm.getMessageForWatchItem(watch.watchId)
                                        .type,"message-content":_vm.getMessageForWatchItem(watch.watchId)
                                        .content}}):_vm._e(),_c('div',{staticClass:"icons trash-icon hidden-below-sm"},[_c('i',{staticClass:"far fa-trash-alt fa-lg",on:{"click":function($event){return _vm.deleteWatch(watch.lotId)}}})])],1)])}),(!_vm.watches.length || _vm.watches.length <= 0)?_c('tr',{staticStyle:{"white-space":"nowrap"}},[_c('td',{attrs:{"colspan":"5"}},[_vm._v("\n                            "+_vm._s(_vm.$t('watchlist_empty'))+"\n                        ")])]):_vm._e()],2)]),_c('pagination',{ref:"PaginationBottom",attrs:{"hasPerPageSelect":!_vm.$global.isMobile},on:{"pagination-changed":function($event){return _vm.getWatches()}}},[(
                        !_vm.$global.isMobile &&
                        !_vm.$global.isTablet &&
                        _vm.watches.length
                    )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.action),expression:"action"}],attrs:{"disabled":_vm.checkedWatches.length < 1},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.action=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.triggerAction]}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('action_choose')))]),_c('option',{attrs:{"value":"delete"}},[_vm._v(_vm._s(_vm.$t('delete')))])]):_vm._e()])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }