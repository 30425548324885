<template>
    <div class="account-page" id="account-bids-page">
        <div class="row">
            <account-navigation />
            <div class="col col-md-12 col-lg-12">
                <account-header />
                <h1>{{ $t('page_title_bids') }}</h1>
                <bid-list></bid-list>
            </div>
        </div>
    </div>
</template>

<script>
import AccountHeader from '../components/AccountHeader';
import AccountNavigation from '../components/AccountNavigation';
import BidList from '../components/BidList';

export default {
    name: 'Account_Bids',
    components: {
        AccountHeader,
        AccountNavigation,
        BidList,
    },
};
</script>

<style scoped lang="scss">
/* reset table display */
table,
thead,
tbody,
tfoot,
tr,
td,
th {
    display: block;
}
tr {
    display: flex;
}
td,
th {
    padding: 5px;
}
.table-col-full {
    width: 100% !important;
    padding: 0;
}
.even {
    background: white;
}

.odd {
    background: $grey-bg-lightest;

    & + .open {
        background: $grey-bg-lightest;

        .bids > thead {
            background: white;
        }
    }
}

.open {
    margin: 0;
    background: white;
    border-bottom: 1px solid $grey-bg;
}

.sheets {
    & > thead {
        background: $grey-bg;
    }
    & > tbody {
        & > tr {
            padding: $pad-md 0;
        }
    }
    tr {
        & > :nth-child(1),
        & > :nth-child(2),
        & > :nth-child(3),
        & > :nth-child(4) {
            width: 25%;
        }
    }
}

.bids {
    & > thead {
        background: $grey-bg-lighter;
    }
    & > tbody {
        & > tr {
            padding: $pad-md 0;
        }
    }
    tr {
        border-top: 1px solid $grey-bg-lighter;

        & > :nth-child(1),
        & > :nth-child(2),
        & > :nth-child(3),
        & > :nth-child(4),
        & > :nth-child(5) {
            width: 20%;
        }
    }

    div + div {
        margin-top: $mar-md;
    }

    strong {
        display: inline-block;
        width: 100px;
    }
}

.loadercontainer {
    position: relative;
    min-height: 60px; /* for the loader */
    overflow: hidden;
    margin-bottom: -60px;
}

@media (max-width: $xs-size-max) {
    .total {
        display: inline-block;
        margin-right: $mar-md;
    }
    .sheets {
        & > tbody {
            & > tr {
                display: block;

                & > td:nth-child(n) {
                    padding: $pad-sm 0;
                    width: 100%;
                }
            }
        }
    }

    .bids {
        padding: $pad-md;
        & > tbody {
            & > tr {
                display: block;
                border: 1px solid $grey-bg;
                background: white;
                padding: $pad-md;

                & > td:nth-child(n) {
                    padding: $pad-sm 0;
                    width: 100%;
                }

                & > td:nth-child(1) {
                    text-align: center;
                }

                td {
                    strong:first-child,
                    span:first-child {
                        display: inline-block;
                        width: 150px;
                    }
                }

                & + tr {
                    margin-top: $mar-md;
                }
            }
        }
    }
}
</style>
