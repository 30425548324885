<template>
    <form
        class="collector form"
        :method="method"
        :action="action"
        :target="target"
    >
        <div class="collector-flag">{{ $t('new') }}</div>
        <h4>{{ $t('sixbid_archive') }}</h4>
        <p>{{ $t('collectors_archive_text') }}</p>
        <button class="button button-black">
            {{ $t('sixbid_archive.visit') }}
        </button>
    </form>
</template>

<script>
import { COIN_COLLECTOR } from '../config';

export default {
    name: 'CoinCollector',
    data() {
        return {
            param: COIN_COLLECTOR.PARAM,
            method: COIN_COLLECTOR.METHOD,
            action: COIN_COLLECTOR.ACTION,
            target: COIN_COLLECTOR.TARGET,
        };
    },
    methods: {
        send() {},
    },
};
</script>

<style lang="scss">
.collector {
    position: relative;
    background: $main-color;
    color: white;
    padding: $pad-lg;

    footer & {
        @media (min-width: $lg-size) {
            position: absolute;
            top: -50px;
            width: calc(100% - #{2 * $pad-lg});
            padding: $pad-md;
        }
    }

    .sidebar & {
        margin-bottom: $mar-lg;
    }

    &-flag {
        position: relative;
        top: -4px;
        margin-right: 10px;
        padding: $pad-sm;
        float: left;

        background: $red-highlight;
        text-transform: uppercase;

        @media (min-width: $md-size) {
            position: absolute;
            left: 0;
            top: #{$pad-lg - $pad-sm};
            transform: translateX(-50%);
        }
    }

    h4 {
        margin-top: 0;
        margin-left: $mar-lg;
        text-transform: uppercase;
    }

    button {
        margin-top: $mar-md;
    }
}
</style>
