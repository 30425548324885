<template>
    <div @click="overlayShown = true" class="zoomer-container" ref="wrapper">
        <img loading="lazy" :src="image" alt="zoom-image" ref="image" width="1024" height="512" />
        <span
            v-show="!$global.isMobile"
            class="container-overlay-icon"
        >
            <div class="container-overlay-icon__icon">
                <i class="fas fa-search"></i>
            </div>
        </span>
        <content-overlay
            :shown="overlayShown"
            :supersize="true"
            @closing="overlayShown = false"
        >
            <zoomer :image="zoom" :zoom="zoom"></zoomer>
        </content-overlay>
    </div>
</template>

<script>
import ContentOverlay from './ContentOverlay.vue';
import Zoomer from './Zoomer.vue';

export default {
    name: 'ZoomerContainer',
    components: {
        ContentOverlay,
        Zoomer,
    },
    data: function() {
        return {
            overlayShown: false,
        };
    },
    props: {
        image: {
            type: String,
            required: true,
        },
        zoom: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.zoomer-container {
    cursor: pointer;
    height: auto;
    padding: $pad-xl;

    img {
        height: auto;
    }

    &.active {
        &:hover,
        &:active {
            cursor: crosshair;

            .overlay {
                opacity: 1;
            }
        }
    }
    .container-overlay-icon {
        position: absolute;
        pointer-events: none;

        height: 100%;
        width: 100%;

        background-color: transparent;

        top: 0;
        left: 0;

        /* opacity: 0; */

        transition: background-color 0.3s, opacity 0.3s;

        &__icon {
            background: $main-color;
            height: 40px;
            width: 40px;
            position: absolute;

            opacity: 0;
            transition: opacity 0.3s;

            display: flex;
            justify-content: center;
            align-items: center;

            top: calc(50% - 20px);
            left: calc(50% - 20px);
            border-radius: 20px;

            i {
                color: white;
                font-size: 20px;
                margin-left: 0;
            }
        }
    }
    &:hover .container-overlay-icon {
        background-color: rgba(255, 255, 255, 0.4);
        opacity: 1;
    }
    &:hover .container-overlay-icon__icon {
        opacity: 1;
    }
}
</style>
