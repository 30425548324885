
import Vue from './../node_modules/vue/dist/vue.js';
import VueI18n from 'vue-i18n';
import axios from 'axios'
import { getTranslation } from './lib/apis';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from './config.js';

Vue.use(VueI18n);

const browserLanguage = navigator.language || navigator.userLanguage;

export const getLanguageFromAvailableLanguages = (lang) => {
    const baseLang = lang || browserLanguage;
    
    const detectedLanguage = AVAILABLE_LANGUAGES.filter(language => baseLang.indexOf(language.code) !== -1)[0];

    return (detectedLanguage && detectedLanguage.code) || DEFAULT_LANGUAGE;
}

const i18n = new VueI18n({
    locale: getLanguageFromAvailableLanguages(browserLanguage),
    fallbackLocale: getLanguageFromAvailableLanguages(browserLanguage),
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    window.cmp_setlang = lang;
    return lang;
}

export async function loadLanguageAsync(lang) {
    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    const result = await getTranslation(lang);
    const messages = result.data.data;
    i18n.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
}


export default i18n;
