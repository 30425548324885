var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lots)?_c('sidebar-box',{directives:[{name:"show",rawName:"v-show",value:(_vm.lots.length > 0),expression:"lots.length > 0"}],attrs:{"headline":_vm.boxTitleDisplay,"theme":"dark-grey","route":{ name: 'page.highlightSpecialAll' }}},[_c('div',{staticClass:"lot-list-component"},[_c('div',{staticClass:"lot-list"},[_c('loader',{ref:"loader"}),(_vm.lots.length > 0)?_c('carousel',{attrs:{"autoPlayInterval":5000}},[_c('div',{staticClass:"slides"},_vm._l((_vm.lots),function(lot){return _c('slide',{key:lot.lotId},[_c('router-link',{staticClass:"lot-list-item clearfix",attrs:{"tag":"div","to":{
                                name: 'page.auctionLotDetail',
                                params: {
                                    lotId: lot.lotId,
                                    auctionId: lot.auctionId,
                                    companySlug: lot.companySlug,
                                    lotSlug: lot.lotSlug,
                                    categorySlug: lot.categorySlug || '-',
                                },
                            }}},[_c('img',{attrs:{"loading":"lazy","src":_vm.getImage(lot, 'S'),"width":"256","height":"128","alt":_vm.$t('lot')}}),_c('p',{domProps:{"innerHTML":_vm._s(lot.companyName)}}),_c('p',{domProps:{"innerHTML":_vm._s(lot.auctionTitle)}})])],1)}),1)]):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }