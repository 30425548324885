export const PAGINATION_DEFAULTS = {
    START_PAGE: 1,
    PER_PAGE: 100,
    PER_PAGE_ALLOWED: [50, 100],
    MAX: 250,
};

export const BASE_URL = 'https://www.sixbid.com';

export const AUTH_URL = process.env.VUE_APP_AUTH_API_URL;
export const AUTH_CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID;
export const AUTH_CLIENT_SECRET = process.env.VUE_APP_AUTH_CLIENT_SECRET;

export const COIN_COLLECTOR = {
    ACTION: 'https://www.sixbid-coin-archive.com/',
    METHOD: 'GET',
    PARAM: '',
    TARGET: '_blank',
    TABURL: 'https://www.sixbid-coin-archive.com/',
};

export const AUCTION_BANNER = {
    URL: 'https://images.sixbid.com/company/',
    BG_FILE: 'banner.jpg',
    BG_2X_FILE: 'banner@2.jpg',
    LOGO_FILE: 'logo.png',
    LOGO_2X_FILE: 'logo@2.png',
};

export const COMPANY_IMAGES = {
    URL: 'https://images.sixbid.com/company/',
    FRONTPAGE_LOGO_FILE: 'frontpage-logo.jpg',
    FRONTPAGE_LOGO_2X_FILE: 'frontpage-logo@2.jpg',
    LOT_HEADER_FILE: 'lot-header.jpg',
    LOT_HEADER_2X_FILE: 'lot-header@2.jpg',
    LOT_HEADER_MOBILE_FILE: 'lot-header-mobile@2.jpg',
};

export const SESSION_FILTER_OPENED_COUNT = 99; // high number: all are shown

export const DEBUG = process.env.VUE_APP_DEBUG; // not used

export const MOBILE_WIDTH_MAX = 768;
export const TABLET_WIDTH_MAX = 1024;

export const AUCTION_ITEMS_VISIBLE = 20;
export const CALENDAR_ITEMS_VISIBLE = 10;
export const COMPANIES_VISIBLE = 10;

export const LOADER_TIMEOUT = 250;

/* eslint-disable */

export const PHONE_REGEX = /^([+(])?\d*\)*[0-9 \-\/]*\d$/i;

// RFC 5322 Regex
export const MAIL_REGEX = /([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])/i;

/* eslint-enable */

// i18n configurations
export const DEFAULT_LANGUAGE = 'en';

export const AVAILABLE_LANGUAGES = [
    {
        code: 'de',
        locale: 'de-DE',
        name: 'german',
        originalName: 'Deutsch',
    },
    {
        code: 'en',
        locale: 'en-GB',
        name: 'english',
        originalName: 'English',
    },
    {
        code: 'zh',
        locale: 'zh-CN',
        name: 'chinese',
        originalName: '中文',
    },
    {
        code: 'es',
        locale: 'es-ES',
        name: 'spanish',
        originalName: 'Español',
    },
    {
        code: 'fr',
        locale: 'fr-FR',
        name: 'french',
        originalName: 'Français',
    },
    {
        code: 'it',
        locale: 'it-IT',
        name: 'italian',
        originalName: 'Italiano',
    },
    {
        code: 'ja',
        locale: 'ja-JP',
        name: 'japanese',
        originalName: '日本語',
    },
    {
        code: 'ru',
        locale: 'ru-RU',
        name: 'russian',
        originalName: 'Русский',
    },
]

export const ALTERNATE_LANGUAGES = [
    {
        href: 'de',
        hreflang: 'de-DE',
    },
    {
        href: 'de',
        hreflang: 'de-AT',
    },
    {
        href: 'de',
        hreflang: 'de-CH',
    },
    {
        href: 'en',
        hreflang: 'en-GB',
    },
    {
        href: 'en',
        hreflang: 'en-US',
    },
    {
        href: 'ja',
        hreflang: 'ja-JP',
    },
    {
        href: 'it',
        hreflang: 'it-IT',
    },
    {
        href: 'es',
        hreflang: 'es-ES',
    },
    {
        href: 'zh',
        hreflang: 'zh-CN',
    },
    {
        href: 'ru',
        hreflang: 'ru-RU',
    },
    {
        href: 'fr',
        hreflang: 'fr-FR',
    },
]

// configure UNDER_CONSTRUCTION mode
export const IS_UNDER_CONSTRUCTION = false;
export const IS_UNDER_CONSTRUCTION_UNTIL = "12:00";
export const IS_UNDER_CONSTRUCTION_TOKEN = 'sixbid_testing';