<template>
    <div class="redirecting-page">Redirecting</div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import { getCompanyObject } from '../lib/apis';
import { PAGINATION_DEFAULTS } from '../config';

export default {
    name: 'Redirecting',
    components: {
        Breadcrumb,
    },
    mounted() {
        const params = this.$route.query;
        const browserLanguage = navigator.language || navigator.userLanguage;

        if (params.lot) {
            getCompanyObject({ lotId: params.lot }).then((response) => {
                if (response.data) {
                    this.$router.push({
                        name: 'page.auctionLotDetail',
                        params: {
                            lang:
                                browserLanguage.indexOf('de') !== -1
                                    ? 'de'
                                    : 'en',
                            companySlug: response.data.companySlug,
                            auctionId: params.auction,
                            categorySlug: 'category',
                            lotId: params.lot,
                            lotSlug: 'lot',
                        },
                    });
                } else {
                    this.$global.globalError(this.$t('error_generic'));
                }
            }).catch(e => {
                this.genericApiErrorHandling(e);
            });
        } else if (params.auction) {
            getCompanyObject({ auctionId: params.auction }).then((response) => {
                if (response.data) {
                    this.$router.push({
                        name: 'page.auctionLotList',
                        params: {
                            lang:
                                browserLanguage.indexOf('de') !== -1
                                    ? 'de'
                                    : 'en',
                            companySlug: response.data.companySlug,
                            auctionId: params.auction,
                            page: PAGINATION_DEFAULTS.START_PAGE,
                            perPage: PAGINATION_DEFAULTS.PER_PAGE,
                        },
                    });
                } else {
                    this.$global.globalError(this.$t('error_generic'));
                }
            }).catch(e => {
                this.genericApiErrorHandling(e);
            });
        } else {
            this.$global.globalError(this.$t('error_generic'));
        }
    },
};
</script>

<style scoped></style>
