import { render, staticRenderFns } from "./LotListComponent.vue?vue&type=template&id=c63bc5a4&scoped=true&"
import script from "./LotListComponent.vue?vue&type=script&lang=js&"
export * from "./LotListComponent.vue?vue&type=script&lang=js&"
import style0 from "./LotListComponent.vue?vue&type=style&index=0&id=c63bc5a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c63bc5a4",
  null
  
)

export default component.exports