var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"archive-auction-lot-detail"},[_c('auction-banner',{staticClass:"no-print",attrs:{"companyId":_vm.auction.companyId,"auctionId":_vm.auction.auctionId}}),_c('div',{staticClass:"grid"},[_c('loader',{ref:"loader"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.lotShown === true),expression:"lotShown === true"}],staticClass:"row"},[(_vm.lot.lotId)?_c('aside',{staticClass:"lot-info col col-md-3"},[_c('sidebar-box',[_c('div',{staticClass:"datesbox"},[(_vm.auction.auctionHasNGC)?_c('a',{staticClass:"ngc",on:{"click":function($event){_vm.overlayShown = true}}},[_vm._v(_vm._s(_vm.$t('grading_ngc')))]):_vm._e(),_c('h2',{staticClass:"style-like-h3 auction"},[_vm._v("\n                            "+_vm._s(_vm.auction.auctionName)+"\n                        ")]),_c('h1',{staticClass:"style-like-h3 nr"},[_vm._v("\n                            "+_vm._s(_vm.$t('lot'))+" "+_vm._s(_vm.lot.lotNumber)+_vm._s(_vm.lot.lotNumberAffix)+"\n                        ")]),_c('div',{staticClass:"auctionDate"},[_vm._v("\n                            "+_vm._s(_vm.date)+"\n                        ")]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"auctionOnlineBiddingEnd"},[_c('span',{staticClass:"auctionOnlineBiddingEndLabel"},[_vm._v(_vm._s(_vm.$t('bid_end'))+":")]),_c('span',{staticClass:"auctionOnlineBiddingEndDate"},[_c('date-timezone',{attrs:{"date":_vm.lot.sessionEnds}})],1)]),_c('div',{staticClass:"clearfix"})])])],1):_vm._e(),_c('div',{staticClass:"lot-content col col-md-9"},[(_vm.company.companyId)?_c('router-link',{staticClass:"company",attrs:{"tag":"a","to":{
                        name: 'page.companyDetail',
                        params: { companySlug: _vm.company.companySlug },
                    }}},[_c('company-image',{staticClass:"hidden-below-md",attrs:{"alt":_vm.company.companyLogoAlt,"title":_vm.company.companyLogoTitle,"companyId":_vm.company.companyId,"auctionId":_vm.auction.auctionId}})],1):_vm._e(),_c('div',{staticClass:"image"},[(!!_vm.lot.hasImage)?_c('div',{staticClass:"zoom"},[(
                                _vm.lot.lotImageLargeUrl &&
                                _vm.lot.lotImageLargeUrlOriginal
                            )?_c('zoomer-container',{attrs:{"image":_vm.lot.lotImageLargeUrl,"zoom":_vm.lot.lotImageLargeUrlOriginal}}):_vm._e()],1):_c('div',{staticClass:"zoomspacer"},[_vm._v("No Image")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-lg-4"},[(_vm.lot.lotStartingPrice)?[_c('strong',[_vm._v(_vm._s(_vm.$t('lot_price_start'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotStartingPrice,"currency":_vm.lot.auctionCurrency,"prAvailable":_vm.auction.auctionPrAvailable}})]:_vm._e()],2),_c('div',{staticClass:"col col-lg-4"},[(_vm.lot.lotEstimate)?[_c('strong',[_vm._v(_vm._s(_vm.$t('lot_estimate'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotEstimate,"currency":_vm.lot.auctionCurrency,"prAvailable":_vm.auction.auctionPrAvailable}})]:_vm._e()],2),(_vm.auction.auctionPrAvailable)?_c('div',{staticClass:"col col-lg-4"},[_c('strong',[_vm._v(_vm._s(_vm.$t('result'))+":")]),_c('price',{attrs:{"value":_vm.lot.lotPriceRealised,"currency":_vm.lot.auctionCurrency,"showZeroAsUnsold":true}})],1):_vm._e()]),_c('div',{staticClass:"desc"},[(_vm.lot.lotDescription)?_c('span',{domProps:{"innerHTML":_vm._s(
                            '<strong>' +
                            _vm.$t('lot') +
                            ' ' +
                            _vm.lot.lotNumber +
                            _vm.lot.lotNumberAffix +
                            '.</strong> ' +
                            _vm.lot.lotDescription
                        )}}):_vm._e()]),(_vm.lot.lotProvenance)?_c('div',{staticClass:"prov"},[_c('span',[_vm._v(_vm._s(_vm.$t('provenance'))+":")]),_vm._v("\n                    "+_vm._s(_vm.lot.lotProvenance)+"\n                ")]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.lotShown === false),expression:"lotShown === false"}],staticClass:"row"},[_c('div',{staticClass:"col col-md-12"},[_c('h2',[_vm._v(_vm._s(_vm.$t('lot_archive_headline')))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('lot_archive_text', [_vm.coinArchiveLink]))}})])])],1),_c('content-overlay',{attrs:{"shown":_vm.overlayShown},on:{"closing":function($event){_vm.overlayShown = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.ngcContent)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }