<template>
    <transition name="fade">
        <div class="overlay" v-if="shown" @click.stop>
            <div class="overlay-content">
                <div class="close" @click="$emit('closing')">
                    <i class="fa fa-times" />
                </div>
                <div class="info">
                    <strong>{{ $t('overlay_hint_important') }}</strong>
                    <template v-if="type === 'live-bidding'">{{
                        $t('overlay_hint_liveBidding')
                    }}</template>
                    <template v-else-if="type === 'external-bidding'">{{
                        $t('overlay_hint_externalBidding')
                    }}</template>
                    <template v-else>{{
                        $t('overlay_hint_external')
                    }}</template>
                </div>
                <div class="links">
                    <div @click="$emit('closing')">
                        <span v-if="type === 'live-bidding'">{{
                            $t('overlay_back_liveBidding')
                        }}</span>
                        <span v-else-if="type === 'external-bidding'">{{
                            $t('overlay_back_externalBidding')
                        }}</span>
                        <span v-else>{{ $t('overlay_back_external') }}</span>
                    </div>
                    <div>
                        <a
                            v-if="type === 'live-bidding'"
                            :href="url"
                            target="_blank"
                            @click="$emit('closing')"
                            >{{ $t('overlay_continue_liveBidding') }}</a
                        >
                        <a
                            v-else-if="type === 'external-bidding'"
                            :href="url"
                            target="_blank"
                            @click="$emit('closing')"
                            >{{ $t('overlay_continue_externalBidding') }}</a
                        >
                        <a
                            v-else
                            :href="url"
                            target="_blank"
                            @click="$emit('closing')"
                            >{{ $t('overlay_continue_external') }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LeavingOverlay',
    props: {
        shown: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'live-bidding',
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    cursor: default;
}

.overlay-content {
    text-align: center;
    background: white;
    min-width: 300px;
    max-width: 800px;
    padding: $pad-xl;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media (min-width: $sm-size) {
        padding: $pad-2xl;
    }
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: $pad-sm;
    cursor: pointer;
}

.links {
    margin-top: $mar-lg;

    div {
        margin-top: $mar-lg;
    }

    a,
    span {
        text-decoration: underline;
        cursor: pointer;
        color: $main-color;
    }
}
</style>
