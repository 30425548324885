<template>
    <div class="auction-calendar-item">
        <div class="info">
            <span
                >{{ auction.calendarStart | dateFormater }} -
                {{ auction.calendarEnd | dateFormater }}</span
            >
            <span
                ><a :href="auction.companyUrl" target="_blank">{{
                    auction.companyName
                }}</a></span
            >
            <span v-html="auction.calendarTitle"></span>
        </div>
    </div>
</template>

<script>
import LeavingOverlay from '../components/LeavingOverlay.vue';

export default {
    name: 'AuctionCalendarItem',
    props: {
        auction: {
            type: Object,
            required: true,
        },
    },
    components: {
        LeavingOverlay,
    },
    data() {
        return {
            overlay: false,
        };
    },
};
</script>

<style scoped lang="scss">
.auction-calendar-item {
    background-color: $grey-bg-light;
    margin-bottom: $mar-lg;
    display: flex;
    justify-content: space-between;

    & > .info {
        padding: $pad-md;
        width: 50%;

        @media (min-width: $sm-size) {
            width: 65%;
        }

        @media (min-width: $md-size) {
            padding: 0;
            width: 100%;
            display: flex;

            span {
                padding: $pad-md;

                &:nth-child(1) {
                    width: 25%;
                }
                &:nth-child(2) {
                    width: 37.5%;
                }
                &:nth-child(3) {
                    width: 37.5%;
                }
            }
        }
    }
}

span {
    padding-bottom: $pad-sm;
    display: block;
}
</style>
