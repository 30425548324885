import Vue from '../../node_modules/vue/dist/vue.js';
import { D, formatDate, MonD, YYYYMMDD, YYYYMMDDHHMM, YYYYMonD, YYYYMonthD } from '../lib/date.js';
import store from '../store/store';

function dateFormatHelper(
    dateString,
    dateFormat = YYYYMMDD,
    useEnglishException = false) {
    const lang = store.getters['lang/getLang'];
    
    return formatDate(lang, dateString, dateFormat, useEnglishException && lang === 'en');
}


export const dateRange = (start, end) => {
    let date = '';
    if (start === end) {
        // day.month.year (same day)
        date = dateShortFormater(start, false);
    } else {
        // any range
        date = `${dateShortFormater(start, false)} - ${dateShortFormater(end, false)}`;
    }

    return date;
};

Vue.filter('dateFormater', function (value) {
    return dateFormatHelper(value, {...YYYYMMDD, timeZone: 'Europe/Berlin'});
});

Vue.filter('dateTimeFormater', function (value) {
    return dateFormatHelper(value, YYYYMMDDHHMM);
});

Vue.filter('dateLongFormater', function (value) {
    return dateFormatHelper(value, {...YYYYMonthD, timeZone: 'Europe/Berlin'});
});

const dateShortFormater = (value) => {
    return dateFormatHelper(value, {...YYYYMonD, timeZone: 'Europe/Berlin'}, true);
};

Vue.filter('dateShortFormater', function (value) {
    dateShortFormater(value);
});

const dateShortFormaterDay = (value) => {
    return dateFormatHelper(value, D);
};

Vue.filter('dateShortFormaterDay', function (value) {
    dateShortFormaterDay(value);
});

const dateShortFormaterMonth = (value) => {
    return dateFormatHelper(value, MonD);
};

Vue.filter('dateShortFormaterMonth', function (value) {
    dateShortFormaterMonth(value);
});