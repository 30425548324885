<template>
    <div class="auction-list-component">
        <!-- pagination -->
        <pagination
            v-if="usePagination"
            ref="PaginationTop"
            v-on:pagination-changed="changeLimit()"
            :hasPerPageSelect="false"
        ></pagination>

        <!-- Tabs above auction list -->
        <auction-list-tabs
            v-if="hasTabs && !$global.isMobile"
            :filters="filters"
            :activeFilter="activeFilter"
            :hideRightSide="hideRightSide"
            @set-filter="setFilter($event)"
        ></auction-list-tabs>
        <!-- v-on shorthand @ -->
        <!-- Auction list -->
        <div class="auction-list">
            <loader ref="loader"></loader>
            <div v-if="activeFilter === 'past' && getFilteredAuctionsByMonth(activeFilter).length === 0">
                <p class="text-center">
                    {{ $t('auctions_ended_no_past') }}
                </p>
            </div>
            <auction-list-item
                v-else
                v-for="auction in getFilteredAuctionsByMonth(activeFilter)"
                :key="auction.auctionId"
                :auctionId="auction.auctionId"
                :companyName="auction.companyName"
                :companyId="auction.companyId"
                :companySlug="auction.companySlug"
                :companyLogoAlt="auction.companyLogoAlt"
                :companyLogoTitle="auction.companyLogoTitle"
                :auctionName="auction.auctionName"
                :auctionStart="auction.auctionStart"
                :auctionEnd="auction.auctionEnd"
                :auctionDescription="auction.auctionDescription"
                :auctionAltBidLink="auction.auctionAltBidLink"
                :auctionLink="auction.auctionLink"
                :auctionisNew="auction.companyMarkNewAuctions && auction.isNew"
                :auctionHasNGC="auction.auctionHasNGC"
                :auctionLiveBiddingLink="auction.auctionLiveBiddingLink"
                :auctionPrAvailable="auction.auctionPrAvailable"
                :auctionPrLink="auction.auctionPrLink"
                :liveBiddingEnabled="auction.liveBiddingEnabled"
            />
            
        </div>
        <div
            v-if="
                getFilteredAuctionsByMonth(activeFilter).length >=
                $global.showCounts.currentAuctionItems
            "
            @click="
                $global.showCounts.currentAuctionItems =
                    $global.showCounts.currentAuctionItems + itemsVisibleDefault
            "
            class="hidden-above-lg show-more"
        >
            <span>
                {{ $t('auctions_more') }}
                <i class="fas fa-caret-down"></i>
            </span>
        </div>

        <!-- Tabs below auction list -->
        <auction-list-tabs
            class="hidden-below-lg"
            v-if="hasTabs && !$global.isMobile"
            :filters="filters"
            :activeFilter="activeFilter"
            :isBottom="true"
            :hideRightSide="hideRightSide"
            @set-filter="setFilter($event)"
        ></auction-list-tabs>

        <!-- pagination -->
        <pagination
            v-if="usePagination"
            ref="PaginationBottom"
            v-on:pagination-changed="changeLimit()"
            :hasPerPageSelect="false"
            @pagination-clicked="$emit('pagination-clicked')"
        ></pagination>
    </div>
</template>

<script>
import AuctionListItem from './AuctionListItem.vue';
import AuctionListTabs from './AuctionListTabs.vue';
import Pagination from './Pagination.vue';
import Loader from './Loader';
import { getAuctions } from '../lib/apis';
import { getDay, getMonth, ISODATE } from '../lib/date';
import { PAGINATION_DEFAULTS, AUCTION_ITEMS_VISIBLE } from '../config';

export default {
    components: {
        AuctionListItem,
        AuctionListTabs,
        Loader,
        Pagination,
    },
    props: {
        companySlug: {
            type: String,
            required: false,
        },
        hasTabs: {
            type: Boolean,
            required: false,
            default: true,
        },
        usePastFilter: {
            type: Boolean,
            required: false,
            default: false,
        },
        usePagination: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideRightSide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            auctions: [],
            auctionsCurrentMonth: [],
            auctionsNextMonth: [],
            auctionsAfterNextMonth: [],
            filters: [],
            activeFilter: 'all',
            apiParams: {},
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            itemsVisibleDefault: AUCTION_ITEMS_VISIBLE,
            lang: this.$route.params.lang,
        };
    },
    mounted: function () {
        if (this.usePastFilter) {
            this.setPastAuctions();
        } else {
            this.setCurrentAuctions();
        }
    },
    methods: {
        changeLimit() {
            if (this.usePagination) {
                this.apiParams = {
                    ...this.apiParams,
                    page: this.$refs.PaginationTop.page,
                };
            }
            this.fetchAuctions(this.apiParams);
        },
        setPastAuctions() {
            this.apiParams = {
                ...this.apiParams,
                limit: this.$refs.PaginationTop.perPage,
                perPage: this.$refs.PaginationTop.perPage,
                page: this.$refs.PaginationTop.page,
                end_to: getDay(this.lang, -1, ISODATE),
                orderCol: 'start',
                orderDirection: 'DESC',
            };

            this.activeFilter = 'past';
            this.fetchAuctions(this.apiParams);
        },
        setCurrentAuctions() {
            this.apiParams = {
                ...this.apiParams,
                end_from: getDay(this.lang, 0, ISODATE),
                orderDirection: 'ASC',
                limit: 250,
            };

            this.fetchAuctions(this.apiParams);
        },
        setFilter(filterId) {
            let filters = [];

            if (this.getFilteredAuctionsByMonth(0).length > 0) {
                filters.push({
                    id: 0,
                    label: getMonth(this.lang, 0),
                });
            }
            if (this.getFilteredAuctionsByMonth(1).length > 0) {
                filters.push({
                    id: 1,
                    label: getMonth(this.lang, 1),
                });
            }
            if (this.getFilteredAuctionsByMonth(2).length > 0) {
                filters.push({
                    id: 2,
                    label: getMonth(this.lang, 2),
                });
            }

            if (
                typeof filterId !== 'undefined' &&
                this.activeFilter !== 'past'
            ) {
                this.activeFilter = filterId;
            }

            if (this.$global.showCounts.currentAuctionItems) {
                this.itemsVisibleCurrent =
                    this.$global.showCounts.currentAuctionItems;
            } else {
                this.itemsVisibleCurrent = this.itemsVisibleDefault;
            }
            this.filters = filters;
        },
        fetchAuctions(data) {
            const dataMerged = {
                limit: this.PAGINATION_DEFAULTS.MAX,
                perPage: this.PAGINATION_DEFAULTS.MAX,
                page: this.PAGINATION_DEFAULTS.START_PAGE,
                ...data,
            };

            if (this.$refs.loader) {
                this.$refs.loader.show();
            }

            getAuctions(dataMerged, this.companySlug, this.$route.params.lang)
                .then((response) => {
                    this.auctions = response.data.items;

                    this.setFilter('all');

                    if (this.usePagination) {
                        this.$refs.PaginationTop.responseHelper(response);
                        this.$refs.PaginationBottom.responseHelper(response);
                    }

                    if (this.$refs.loader) {
                        this.$refs.loader.hide();
                    }

                    this.$nextTick(() => {
                        //prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );
                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
        getFilteredAuctionsByMonth(monthOffset) {
            switch (monthOffset) {
                case 'all': {
                    const activeAuctions = this.auctions;

                    if (this.$global.isMobile /*&& false*/) {
                        return activeAuctions.filter((auction, index) => {
                            return (
                                index <
                                this.$global.showCounts.currentAuctionItems
                            );
                        });
                    }

                    return this.auctions;
                }
                case 0:
                case 1:
                case 2: {
                    return this.auctions.filter((auction) => {
                        const auctionStartDate = new Date(
                            Date.parse(auction.auctionStart)
                        );
                        const auctionEndDate = new Date(
                            Date.parse(auction.auctionEnd)
                        );
                        const currentDate = new Date();
                        const auctionStartMonth = auctionStartDate.getMonth();
                        const auctionEndMonth = auctionEndDate.getMonth();
                        const selectedMonth =
                            currentDate.getMonth() + monthOffset < 12
                                ? currentDate.getMonth() + monthOffset
                                : currentDate.getMonth() + monthOffset - 12;

                        return (
                            auctionStartMonth === selectedMonth ||
                            auctionEndMonth === selectedMonth
                        );
                    });
                }
                default: {
                    return this.auctions.filter(() => {
                        return true;
                    });
                }
            }
        },
    },
    watch: {
        companySlug() {
            if (this.usePastFilter) {
                this.setPastAuctions();
            } else {
                this.setCurrentAuctions();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.auction-list {
    margin-top: 15px;
    position: relative;
    min-height: 50px; /* for the loader */
}
</style>
