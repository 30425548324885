import axios from 'axios';
import store from '../store/store';
import router from '../router';
import { AUTH_CLIENT_ID, AUTH_CLIENT_SECRET, AUTH_URL } from '../config';

const Authorization = {
    name: 'Authorization',
    currentPromise: null,

    /**
     * save token data into localStorage
     * @param {object} { access_token, expires_in, refresh_token, token_type, client_id, scope }
     * @returns tokenData enhanced with expires_at
     */
    saveTokenData({ access_token, expires_in, refresh_token, token_type, client_id, scope }) {
        const tokenData = {
            expires_in: Number(expires_in) * 1000, //ms
            token_type,
            expires_at: Number(expires_in) * 1000 + new Date().getTime(),  // ms
            access_token,
            refresh_token,
            client_id,
            scope
        }

        // set token data into localStorage
        localStorage.setItem('tokenData', JSON.stringify(tokenData));
        store.dispatch('account/login');

        return tokenData;
    },

    /**
     * get stored token data from localStorage
     * @returns object with token data
     */
    getTokenData() {
        try {
            const tokenDataString = localStorage.getItem('tokenData');
            const tokenData = JSON.parse(tokenDataString);

            return tokenData;
        } catch (e) {
            throw new Error('Could not parse token data');
        }
    },

    /**
     * refresh token via refresh token
     * @returns {Promise}
     */
    refreshAuthorization() {
        let tokenData = this.getTokenData();

        return axios
            .post(AUTH_URL + '/api/v1/oauth/token', {
                grant_type: 'refresh_token',
                refresh_token: tokenData.refresh_token,
                client_id: AUTH_CLIENT_ID,
            })
            .then(this.saveTokenData)
            .catch((error) => {
                /* eslint-disable-next-line no-console */
                console.log('error', error);

                this.logout();
            });
    },

    /**
     * remove token data and clear 
     * @returns {Promise} empty promise with no token data
     */
    removeTokenData() {
        localStorage.removeItem('tokenData');
        store.dispatch('account/logout');
        store.commit('lotWatchlist/setWatches', []);

        return new Promise(function (resolve) {
            resolve({
                data: null,
            });
        });

    },

    /**
     * creates the axios client with the authorization header
     * modifies the response as well as the request
     * @returns {Promise}
     */

    createAxiosClient() {
        return this.authorization()
            .then(function (response) {
                let axiosInstance = axios.create({
                    headers: {
                        Authorization:
                            response.data.token_type +
                            ' ' +
                            response.data.access_token,
                    },
                });

                axiosInstance.interceptors.request.use(function (config) {
                    // Add cliend_id and client_secret to all requests
                    config.params = {
                        ...config.params,
                        client_id: AUTH_CLIENT_ID,
                        client_secret: AUTH_CLIENT_SECRET,
                    }
                    return config;
                });

                axiosInstance = Authorization.addResponseInterceptor(axiosInstance);

                return axiosInstance;
            })
            // if authorization fails, return an axios instance without the authorization header and request interceptors
            .catch(function () {
                let axiosInstance = axios.create();

                axiosInstance = Authorization.addResponseInterceptor(axiosInstance);

                return axiosInstance;
            });
    },

    /**
     * add response interceptor to handle specific errors
     * @param {*} axiosInstance 
     * @returns 
     */

    addResponseInterceptor(axiosInstance) {
        axiosInstance.interceptors.response.use(undefined, error => {
            // handle 401 errors
            if (error.response && error.response.status === 401) {
                switch (true) {
                    case error.response.config.url.indexOf('my-watchlist-ids') !== -1:
                        // intentional: do nothing
                        break;
                    case /\/lots.*?\.sixbid\.com/.test(error.response.config.url):
                    case /\/auctions.*?\.sixbid\.com/.test(error.response.config.url):
                    case error.response.config.url.indexOf('/my-addresses') !== -1:
                    case error.response.config.url.indexOf('/my-searches') !== -1:
                    case error.response.config.url.indexOf('/my-watchlist') !== -1: {
                        // get the latest stored task
                        const storedTask = store.getters['tasks/task']();

                        // redirect to task handling
                        Authorization.handleTask({
                            task: storedTask,
                            redirect: window.location.href,
                        });
                        break;
                    }
                    default: {
                        // redirect to login
                        Authorization.login();
                        break;
                    }
                }
            }
            // handle 403 errors
            if (error.response && error.response.status === 403) {
                switch (true) {
                    case /\/lots.*?\.sixbid\.com/.test(error.response.config.url):
                    case /\/auctions.*?\.sixbid\.com/.test(error.response.config.url):
                        if (!router.currentRoute.params.companySlug || !router.currentRoute.params.auctionId || !router.currentRoute.params.categorySlug || !router.currentRoute.params.lotId || !router.currentRoute.params.lotSlug) {
                            // if any of the params is missing, do nothing // intentional
                            return;
                        }
                        // redirect to archive lot detail on 403
                        router.push({ name: 'page.archiveAuctionLotDetail', params: { ...router.currentRoute.params } }).catch((err) => {
                            // supress duplication error, throw any other
                            if (
                                err.name !== 'NavigationDuplicated' &&
                                !err.message.includes('Avoided redundant navigation to current location')
                            ) {
                                throw err;
                            }
                        });
                        break;
                    default: {
                        // redirect to home
                        router.push({ name: 'page.home' }).catch((err) => {
                            // supress duplication error, throw any other
                            if (
                                err.name !== 'NavigationDuplicated' &&
                                !err.message.includes('Avoided redundant navigation to current location')
                            ) {
                                throw err;
                            }
                        });
                        break;
                    }
                }
            }
            // throw an error to move to the catch block on axios creation
            throw error;
        });
        return axiosInstance
    },

    authorization() {
        if (this.currentPromise === null || this.currentPromise === undefined) {
            this.currentPromise = this.handleTokenData();
        }
        return this.currentPromise;
    },

    handleTokenData() {
        let tokenData = this.getTokenData();

        if (tokenData) {
            if (tokenData.expires_at <= new Date().getTime()) {
                // token is expired, request a new token
                if (tokenData.refresh_token) {
                    return this.refreshAuthorization();
                } else {
                    // clear token data
                    return this.removeTokenData();
                }
            } else {
                // token is still valid and usable
                return new Promise(function (resolve) {
                    resolve({
                        data: tokenData,
                    });
                });
            }
        } else {
            // no token data found
            return this.removeTokenData();
        }
    },

    /**
    * checks if user is logged in
    * @returns {boolean} true if user is logged in
    */
    isLoggedIn() {
        const tokenData = this.getTokenData();
        // check if token data is not expired
        const isStillValid = new Date(tokenData && tokenData.expires_at) > new Date();

        return tokenData && isStillValid;
    },

    /**
     * logout user
     * handling is done in the router
     */
    logout() {
        // logout redirect
        router.push({ name: 'redirect.logout' })
    },

    /** 
     * login user 
     * handling is done in the router
     */
    login() {
        // login redirect
        router.push({ name: 'redirect.login' })
    },

    /**
     * redirect to task handling
     * handling is done in the router
     * @param {object} { task, redirect }
     */
    handleTask({ task, redirect }) {
        // task redirect
        router.push({
            name: 'redirect.task', query: { ...task, redirect }
        }).catch((err) => {
            // supress duplication error, throw any other
            if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
            ) {
                throw err;
            }
        });
    }
}

export default Authorization;