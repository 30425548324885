<template>
    <div class="archive-auction-lot-detail">
        <auction-banner
            class="no-print"
            :companyId="auction.companyId"
            :auctionId="auction.auctionId"
        ></auction-banner>

        <div class="grid">
            <loader ref="loader"></loader>
            <div class="row" v-show="lotShown === true">
                <aside class="lot-info col col-md-3" v-if="lot.lotId">
                    <sidebar-box>
                        <div class="datesbox">
                            <a
                                v-if="auction.auctionHasNGC"
                                class="ngc"
                                @click="overlayShown = true"
                                >{{ $t('grading_ngc') }}</a
                            >

                            <h2 class="style-like-h3 auction">
                                {{ auction.auctionName }}
                            </h2>
                            <h1 class="style-like-h3 nr">
                                {{ $t('lot') }} {{ lot.lotNumber
                                }}{{ lot.lotNumberAffix }}
                            </h1>
                            <div class="auctionDate">
                                {{ date }}
                            </div>
                            <div class="clearfix"></div>
                            <div class="auctionOnlineBiddingEnd">
                                <span class="auctionOnlineBiddingEndLabel"
                                    >{{ $t('bid_end') }}:</span
                                >
                                <span class="auctionOnlineBiddingEndDate">
                                    <date-timezone :date="lot.sessionEnds" />
                                </span>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </sidebar-box>
                </aside>
                <div class="lot-content col col-md-9">
                    <router-link
                        tag="a"
                        class="company"
                        v-if="company.companyId"
                        :to="{
                            name: 'page.companyDetail',
                            params: { companySlug: company.companySlug },
                        }"
                    >
                        <company-image
                            class="hidden-below-md"
                            :alt="company.companyLogoAlt"
                            :title="company.companyLogoTitle"
                            :companyId="company.companyId"
                            :auctionId="auction.auctionId"
                        ></company-image>
                    </router-link>
                    <div class="image">
                        <div v-if="!!lot.hasImage" class="zoom">
                            <zoomer-container
                                v-if="
                                    lot.lotImageLargeUrl &&
                                    lot.lotImageLargeUrlOriginal
                                "
                                :image="lot.lotImageLargeUrl"
                                :zoom="lot.lotImageLargeUrlOriginal"
                            ></zoomer-container>
                        </div>
                        <div v-else class="zoomspacer">No Image</div>
                    </div>
                    <div class="row">
                        <div class="col col-lg-4">
                            <template v-if="lot.lotStartingPrice">
                                <strong>{{ $t('lot_price_start') }}:</strong>
                                <price
                                    :value="lot.lotStartingPrice"
                                    :currency="lot.auctionCurrency"
                                    :prAvailable="auction.auctionPrAvailable"
                                ></price>
                            </template>
                        </div>
                        <div class="col col-lg-4">
                            <template v-if="lot.lotEstimate">
                                <strong>{{ $t('lot_estimate') }}:</strong>
                                <price
                                    :value="lot.lotEstimate"
                                    :currency="lot.auctionCurrency"
                                    :prAvailable="auction.auctionPrAvailable"
                                ></price>
                            </template>
                        </div>
                        <div
                            class="col col-lg-4"
                            v-if="auction.auctionPrAvailable"
                        >
                            <strong>{{ $t('result') }}:</strong>
                            <price
                                :value="lot.lotPriceRealised"
                                :currency="lot.auctionCurrency"
                                :showZeroAsUnsold="true"
                            ></price>
                        </div>
                    </div>

                    <div class="desc">
                        <span
                            v-html="
                                '<strong>' +
                                $t('lot') +
                                ' ' +
                                lot.lotNumber +
                                lot.lotNumberAffix +
                                '.</strong> ' +
                                lot.lotDescription
                            "
                            v-if="lot.lotDescription"
                        ></span>
                    </div>
                    <div class="prov" v-if="lot.lotProvenance">
                        <span>{{ $t('provenance') }}:</span>
                        {{ lot.lotProvenance }}
                    </div>
                </div>
            </div>
            <div class="row" v-show="lotShown === false">
                <div class="col col-md-12">
                    <h2>{{ $t('lot_archive_headline') }}</h2>
                    <p v-html="$t('lot_archive_text', [coinArchiveLink])"></p>
                </div>
            </div>
        </div>
        <content-overlay :shown="overlayShown" @closing="overlayShown = false">
            <div v-html="ngcContent"></div>
        </content-overlay>
    </div>
</template>

<script>
import {
    getAuctionDetails,
    getCompanyDetails,
    getLotDetails,
    getNgcContent,
} from '../lib/apis';
import CloudImageGetter from '../lib/CloudImageGetter';
import AuctionBanner from '../components/AuctionBanner.vue';
import Loader from '../components/Loader.vue';
import ZoomerContainer from '../components/ZoomerContainer.vue';
import SidebarBox from '../components/SidebarBox.vue';
import ContentOverlay from '../components/ContentOverlay.vue';
import DateTimezone from '../components/DateTimezone.vue';
import CompanyImage from '../components/CompanyImage.vue';
import Price from '../components/Price.vue';

import { dateRange } from '../filters/date';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default {
    name: 'ArchiveAuctionLotDetail',
    components: {
        AuctionBanner,
        Loader,
        ZoomerContainer,
        SidebarBox,
        ContentOverlay,
        DateTimezone,
        CompanyImage,
        Price,
    },
    data() {
        return {
            company: {},
            auction: {},
            lot: {},
            viewShown: false,
            lotShown: null,
            coinArchiveLink: 'https://www.sixbid-coin-archive.com/',
            overlayShown: false,
            ngcContent: '',
        };
    },
    methods: {
        fetchData() {
            this.$refs.loader.show();
            Promise.all([
                getCompanyDetails(
                    this.$route.params.companySlug,
                    this.$route.params.lang
                ),
                getAuctionDetails(
                    null,
                    this.$route.params.companySlug,
                    this.$route.params.auctionId,
                    this.$route.params.lang,
                    true
                ),
                getLotDetails(
                    {
                        lotIds: [this.$route.params.lotId],
                        companySlug: this.$route.params.companySlug,
                        auctionId: this.$route.params.auctionId,
                    },
                    this.$route.params.lang,
                    true
                ),
            ])
                .then(([company, auction, lots]) => {
                    this.company = company.data;
                    this.auction = auction.data;
                    this.lot = lots[0].data;

                    this.lotShown = true;
                    this.$refs.loader.hide();

                    if (this.company.companySlug != this.auction.companySlug) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }

                    if (this.auction.auctionHasNGC) {
                        getNgcContent(this.$route.params.auctionId).then(
                            (response) => {
                                //console.log("NGC", response);
                                this.ngcContent = response.data;
                            }
                        );
                    }

                    this.lot.lotImageLargeUrl = CloudImageGetter(
                        this.lot.googleBucketImagePath,
                        'XL'
                    );

                    this.lot.lotImageLargeUrlOriginal = CloudImageGetter(
                        this.lot.googleBucketImagePath,
                        'O'
                    );
                })
                .catch((e) => {
                    if (
                        e.message.indexOf(403) !== -1 &&
                        e.response.data.code === 403
                    ) {
                        this.coinArchiveLink = e.response.data.name;

                        this.lotShown = false;
                        this.$refs.loader.hide();
                    } else {
                        this.$refs.loader.hide();
                        this.$router.push({ name: '404' });
                    }
                });
        },
    },
    computed: {
        date() {
            return dateRange(
                this.auction.auctionStart,
                this.auction.auctionEnd
            );
        },
        hasEnded() {
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const auctionEnd = dayjs
                .tz(this.auction.auctionOnlineEnd, 'Europe/Berlin')
                .local();
            const currentTime = new Date();

            return currentTime > auctionEnd;
        },
        icsAPI() {
            return `${process.env.VUE_APP_GLOBAL_API_URL}`;
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>

<style scoped lang="scss">
.lot-info {
    @media (max-width: $sm-size-max) {
        .sidebar-box {
            padding: 0;
        }
    }
    .auctionOnlineBiddingEndLabel {
        font-weight: bold;
        display: block;
        margin-bottom: $mar-sm;
    }

    .auctionDate,
    .auctionICS {
        margin-bottom: $mar-lg;
    }

    @media (max-width: $sm-size-max) {
        .datesbox {
            padding: 5px 15px;
            margin-bottom: 0;
            margin-top: 0;
            background: $grey-bg-light;
        }

        .auction,
        .nr {
            font-size: 14px;
            font-family: $body-font;
        }

        .auctionDate,
        .auctionICS,
        .auctionOnlineBiddingEndLabel,
        .auctionOnlineBiddingEndDate {
            display: inline-block;
        }
        .clearfix {
            margin-bottom: 0;
        }
        .auctionDate,
        .auctionICS {
            margin-bottom: $mar-sm;
        }
        .auctionDate {
            max-width: 45%;
        }
        .auctionICS {
            max-width: 55%;
            margin-left: $mar-lg;
        }
        .auctionOnlineBiddingEnd {
            margin-bottom: 0;
        }
        .auctionOnlineBiddingEndLabel {
            max-width: 45%;
            float: left;
        }
        .auctionOnlineBiddingEndDate {
            max-width: 55%;
            margin-left: $mar-lg;
        }
    }
}

.lot-content {
    position: relative;
    @media (min-width: $md-size) {
        background-color: $grey-bg-lighter;
    }
    padding-bottom: 2em;
    @media (min-width: $md-size) {
        width: calc(75% - 30px);
    }
    & > div + div {
        margin-top: $mar-lg;
    }
    .company picture {
        margin-top: $mar-lg;
    }
}

.company {
    cursor: pointer;
    border-top: 1px solid $grey-bg-light;
    border-bottom: 1px solid $grey-bg-light;
}

@media (max-width: $md-size-max) {
    .sm-col {
        width: 33%;
        .fas {
            display: none;
        }
    }
    .lot-content {
        margin-top: 0;
    }
    .lot-info {
        margin-top: 0;
    }
    .bid-box {
        margin-top: 1em;
    }
    .bid-input {
        float: none;
    }
}

.zoom {
    position: relative;
    border: 1px solid $grey-bg-light;
    text-align: center;
    padding: $pad-xl;

    & + div {
        position: relative;
        padding: 3px $pad-lg;
        margin: 0 auto;
        text-align: center;
        background: $grey-bg-light;

        @media (min-width: $lg-size) {
            min-width: 400px;
            width: 40%;

            &::after,
            &::before {
                top: 0;
                position: absolute;
                content: '';
                border-top: 23px solid $grey-bg-light;
                width: 0;
            }

            &::before {
                border-left: 23px solid transparent;
                left: -23px;
            }

            &::after {
                border-right: 23px solid transparent;
                right: -23px;
            }
        }
    }
}

.zoomspacer {
    padding: $pad-xl;
    background: white;
    text-align: center;
    color: $grey-bg-dark;
}

.image {
    position: relative;
    margin-top: $mar-lg;

    .zoom {
        background-color: white;
    }

    &-mobile {
        display: block;

        @media (min-width: $md-size) {
            display: none;
        }
    }
}

.ngc {
    display: inline-block;
    background: $ngc-color;
    padding: 3px 10px;
    color: white;
    cursor: pointer;
}

.fullwidth {
    width: 100%;
}

.margin-top {
    display: inline-block;
    margin-top: 10px;
}

// Print Styles
@media print {
    .row:not(.no-print) {
        display: block;
    }

    .col {
        width: 100%;
        max-width: 800px;
        padding: 5px 0;
        display: block;
        vertical-align: top;
    }

    * {
        margin: 0 !important;
    }
}
</style>