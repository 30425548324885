import Routes from './Routes';
import Vue from './../node_modules/vue/dist/vue.js';
import Pages from './Pages';
import main from './main';

import Meta from 'vue-meta';
import VueRouter from 'vue-router';
import i18n from './i18n';
import Authorization from './lib/Authorization';

import {
    AUCTION_ITEMS_VISIBLE,
    CALENDAR_ITEMS_VISIBLE,
    COMPANIES_VISIBLE,
    IS_UNDER_CONSTRUCTION,
} from './config';

import isEqual from 'lodash.isequal';
import store from './store/store';

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/browse.html',
            component: Pages.Redirecting,
        },
        {
            // Redirect user to supported lang version.
            path: '/',
            redirect: `/${i18n.fallbackLocale}`,
        },
        {
            path: '/:lang',
            component: {
                template: '<router-view></router-view>',
            },
            children: Routes,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // if anchor -> scroll to hash selector
            return { selector: to.hash };
        } else if (
            from &&
            to.name === from.name &&
            isEqual(to.params, from.params)
        ) {
            // no position change on same route
            return;
        } else if (
            savedPosition &&
            (savedPosition.x !== 0 || savedPosition.y !== 0)
        ) {
            // if savedPosition aka browser back
            return new Promise(resolve => {
                main.$root.$once('triggerScroll', () => {
                    resolve(savedPosition);
                });
            });
        } else {
            if (to.name == 'page.home') {
                main.$global.showCounts.currentAuctionItems = AUCTION_ITEMS_VISIBLE;
            } else if (to.name == 'page.auctionCalendar') {
                main.$global.showCounts.currentCalendarItems = CALENDAR_ITEMS_VISIBLE;
            } else if (to.name == 'page.companyList') {
                main.$global.showCounts.currentCompanies = COMPANIES_VISIBLE;
            }
            return { x: 0, y: 0 };
        }
    },
});

/**
 * router guard before each route
 * handles authorization
 * handles a buffered task
 * handles under construction
 */

router.beforeEach((to, from, next) => {
    Authorization.currentPromise = null;

    // AUTHORIZATION
    // get oauth data from url
    const { access_token, expires_in, refresh_token, token_type, client_id, scope, ...authQueryRest } = to.query;

    // if all oauth data is present
    if (!!access_token && !!expires_in && !!refresh_token) {
        Authorization.saveTokenData({ access_token, expires_in, refresh_token, token_type, client_id, scope });
        // remove the oauth data from the url, keep the rest
        next({
            ...to,
            query: { ...authQueryRest },
        });
    }

    // BUFFERED TASK
    // get task data from url
    const { task_args, task_component, task_fn, task_path, ...taskQueryRest } = to.query;

    // if all task data is present
    if (task_args && task_component && task_fn && task_path) {
        // commit the task to the store
        store.commit('tasks/setTask', { args: task_args, component: task_component, fn: task_fn, path: window.decodeURIComponent(task_path) });
        // remove the task data from the url, keep the rest
        next({
            ...to,
            query: { ...taskQueryRest, runtask: null },
        });
    }

    // UNDER CONSTRUCTION
    // if under construction
    if (IS_UNDER_CONSTRUCTION && sessionStorage.getItem('uc_log_in') !== 'true') {
        next(false);
    } else {
        
        // DEFAULT
        next();
    }
});

export default router;
