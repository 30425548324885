<template>
    <div class="collection-area-page">
        <h1>{{ $t('page_title_collection.areas') }}</h1>
        <breadcrumb>
            {{ $t('page_title_collection.areas') }}
        </breadcrumb>
        <div class="reading mt-2xl">
            <h2>{{ content.collectionAreaTitle }}</h2>
            <div v-html="content.collectionAreaContent"></div>
        </div>
    </div>
</template>

<script>
import { getCollectionAreaContent } from '../lib/apis';
import Breadcrumb from '../components/Breadcrumb.vue';

export default {
    name: 'Collection',
    components: {
        Breadcrumb,
    },
    data: function () {
        return {
            content: [],
        };
    },
    mounted: function () {
        this.fetchCollectionAreaContent();
    },
    methods: {
        fetchCollectionAreaContent() {
            getCollectionAreaContent(
                this.$route.params.collectionAreaSlug,
                this.$route.params.lang
            )
                .then((content) => {
                    this.content = content.data;

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );

                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
};
</script>

<style scoped></style>
