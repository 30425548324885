<template>
    <div class="help-page">
        <h1>{{ $t('page_title_aboutus') }}</h1>
        <breadcrumb>
            <router-link :to="{ name: 'page.about' }">{{
                $t('page_title_aboutus')
            }}</router-link>
            / {{ $t('page_title_help') }}
        </breadcrumb>

        <about-us-tabs></about-us-tabs>

        <div class="reading">
            <h2>{{ $t('page_title_help') }}</h2>

            <div v-html="$t('page_content_howtouse')"></div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import AboutUsTabs from '../components/AboutUsTabs.vue';

export default {
    name: 'help',
    components: {
        AboutUsTabs,
        Breadcrumb,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_help'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_help'),
                },
            ],
        };
    },
};
</script>

<style scoped></style>
