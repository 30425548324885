var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction-information"},[_c('auction-banner',{attrs:{"companyId":_vm.company.companyId,"auctionId":_vm.auction.auctionId}}),_c('p',{staticClass:"style-like-h1"},[_vm._v(_vm._s(_vm.auction.auctionName))]),_c('breadcrumb',[_c('router-link',{attrs:{"to":{
                name: 'page.auctionLotList',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_vm._v(_vm._s(_vm.auction.auctionName)+":\n            "+_vm._s(_vm._f("removeBR")(_vm.auction.auctionDescription)))]),_vm._v("\n        / "+_vm._s(_vm.$t('auction_information'))+"\n    ")],1),_c('auction-tabs',{attrs:{"auctionId":_vm.$route.params.auctionId,"auction":_vm.auction}}),_c('h1',{staticClass:"style-like-h2"},[_vm._v(_vm._s(_vm.auction.auctionName))]),_c('h3',{domProps:{"innerHTML":_vm._f("renderBR")(_vm.auction.auctionDescription)}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-5 col-lg-4"},[_c('div',{staticClass:"sidebarsection auction-info"},[_c('h4',[_vm._v(_vm._s(_vm.$t('auction')))]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('date')))]),_vm._v("\n                    "+_vm._s(_vm.date)+"\n                ")]),(!_vm.hasEnded)?_c('div',[_c('span'),_c('a',{attrs:{"href":(_vm.icsAPI + "/calendar-export/auction/" + (_vm.auction.auctionId))}},[_vm._v(_vm._s(_vm.$t('date_save')))])]):_vm._e(),(_vm.auction.auctionOnlineEnd)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('bid_end'))+":")]),_c('date-timezone',{attrs:{"date":_vm.auction.auctionOnlineEnd}})],1):_vm._e(),(
                        _vm.auction.auctionLocationName ||
                        _vm.auction.auctionLocationStreet ||
                        _vm.auction.auctionLocationZip ||
                        _vm.auction.auctionLocationCity ||
                        _vm.auction.auctionLocationCountry
                    )?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('location')))]),_c('p',[_vm._v("\n                        "+_vm._s(_vm.auction.auctionLocationName)+"\n                        "),(_vm.auction.auctionLocationName)?_c('br'):_vm._e(),_vm._v("\n                        "+_vm._s(_vm.auction.auctionLocationStreet)+"\n                        "),(_vm.auction.auctionLocationStreet)?_c('br'):_vm._e(),_vm._v("\n                        "+_vm._s(_vm.auction.auctionLocationZip)+"\n                        "+_vm._s(_vm.auction.auctionLocationCity)+"\n                        "),(
                                _vm.auction.auctionLocationZip ||
                                _vm.auction.auctionLocationCity
                            )?_c('br'):_vm._e(),_vm._v("\n                        "+_vm._s(_vm.auction.auctionLocationCountry)+"\n                    ")]),(_vm.auction.auctionLocationAdditionalText)?_c('p',[_vm._v("\n                        "+_vm._s(_vm.auction.auctionLocationAdditionalText)+"\n                    ")]):_vm._e()]):_vm._e(),(_vm.auction.auctionPhone)?_c('div',[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('auction_phone')))])]),_c('strong',[_vm._v(_vm._s(_vm.auction.auctionPhone))])]):_vm._e(),(_vm.auction.auctionFax)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('auction_fax')))]),_vm._v("\n                    "+_vm._s(_vm.auction.auctionFax)+"\n                ")]):_vm._e(),_c('div',[_c('span'),_c('a',{attrs:{"href":_vm.auctionVcfLink}},[_vm._v(_vm._s(_vm.$t('contact_save')))])])]),_c('div',{staticClass:"sidebarsection"},[_c('h4',[_vm._v(_vm._s(_vm.$t('company')))]),_c('p',[_vm._v(_vm._s(_vm.auction.companyName))]),_c('p',[_c('router-link',{attrs:{"to":{ name: 'page.auctionMore' }}},[_c('a',[_vm._v(_vm._s(_vm.$t('company_auctions_more')))])])],1)]),_c('div',{staticClass:"sidebarsection"},[_c('h4',[_vm._v("\n                    "+_vm._s(_vm.$t('auction_viewing'))+" "+_vm._s(_vm.auction.auctionName)+"\n                ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.auction.auctionDescription2)}})])]),_c('div',{staticClass:"col col-md-7 col-lg-8"},[(_vm.company.auctionGoogleMaps)?_c('iframe',{staticClass:"googlemap cmplazyload",staticStyle:{"border":"0"},attrs:{"src":"","title":"googlemaps","allowfullscreen":"","data-cmp-preview":"1","data-cmp-vendor":"s1104","data-cmp-src":_vm.company.auctionGoogleMaps}}):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.auction.auctionDescription1)}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }