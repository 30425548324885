<template>
    <div class="under-construction">
        <div class="under-construction__content">
            <div class="logo">
                <span class="logo-main">{{ $t('sixbid_url') }}</span>
                <span class="logo-slogan">{{ $t('auctions_numismatic') }}</span>
            </div>
            <div class="under-construction__item">
                <p>
                    Wir führen gerade Wartungsarbeiten durch und sind bis
                    {{ until }} Uhr leider nicht erreichbar. Anschließend sind
                    wir wie gewohnt wieder für Sie da.
                </p>
                <p>Ihr Sixbid Team</p>
            </div>
            <div class="under-construction__item">
                <p>
                    We are currently performing maintenance work and are
                    unfortunately not available until {{ until }}. Afterwards we
                    will be there for you again as usual.
                </p>
                <p>Your Sixbid Team</p>
            </div>

            <div
                class="under-construction__form"
                :class="{ 'has-error': error }"
            >
                <h3>Kein Login möglich / No login possible </h3>
                <label>
                    <input type="password" v-model="token" />
                </label>
                <button @click="requestAccess">&gt;</button>
            </div>
        </div>
    </div>
</template>
<script>
import {
    IS_UNDER_CONSTRUCTION_TOKEN,
    IS_UNDER_CONSTRUCTION_UNTIL,
} from '../config';
export default {
    name: 'UnderConstruction',
    data() {
        return {
            token: '',
            error: false,
        };
    },
    computed: {
        until() {
            return IS_UNDER_CONSTRUCTION_UNTIL;
        },
    },
    methods: {
        requestAccess() {
            if (
                this.token === IS_UNDER_CONSTRUCTION_TOKEN
            ) {
                sessionStorage.setItem('uc_log_in', 'true');
                this.$router.go();
            } else {
                this.error = true;
            }
        },
    },
};
</script>

<style lang="scss">
.under-construction {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 1);

    z-index: 99999;

    &__content {
        width: 100%;
        max-width: 600px;
        max-height: 100%;
        padding: 40px 20px;
        overflow: auto;
    }

    &__item {
        padding: 30px 0;
    }

    &__item + &__item {
        border-top: 1px solid #cdcdcd;
    }

    &__form {
        margin-top: 50px;
        padding: 10px;
        background: #f6f6f6;

        &.has-error {
            background: #fff6f6;
            color: red;
        }

        span {
            width: 100px;
            display: inline-block;
        }

        input {
            width: 200px;
        }

        label {
            display: block;
            margin-bottom: 10px;
        }

        button {
            padding: 5px 10px;
            background: $main-color;
            color: white;
            border: none;
        }
    }
}
</style>
