<template>
    <div class="message alert" :class="alertClass">
        <i class="fas fa-exclamation-circle"></i>
        <i18n
            :path="messageContent"
            tag="span"
            v-if="messageType === 'success'"
        >
            <router-link :to="bidSheetRoute">{{ $t('bid_sheet') }}</router-link>
        </i18n>
        <span v-else>{{ $t(messageContent) }}</span>
    </div>
</template>

<script>
export default {
    name: 'BidLotMessage',
    props: {
        lot: {
            type: Object,
            required: true,
        },
        messageType: {
            type: String,
            required: true,
        },
        messageContent: {
            type: String,
            required: true,
        },
        addQuery: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        alertClass() {
            const className = `alert--${this.messageType}`;
            return {
                [className]: this.messageType !== '',
            };
        },
        bidSheetRoute() {
            let obj = {
                name: 'page.bidSheet',
                params: {
                    companySlug: this.lot.companySlug,
                    auctionId: this.lot.auctionId,
                },
            };
            if (this.addQuery) {
                obj.query = this.$route.query;
            }
            return obj;
        },
    },
};
</script>

<style scoped></style>
