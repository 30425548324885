var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lot-list-page"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"row"},[_c('aside',{class:_vm.getClassStringSidebar},[_c('sidebar-box',{staticClass:"sidebar-search",attrs:{"theme":"blue"}},[_c('lot-search',{class:{
                            'lot-search-mobile': _vm.searchSize === 'small',
                        },attrs:{"isReduced":true,"showSaveButton":true,"companyName":_vm.company.companyName,"size":"small"},on:{"query-change":_vm.updateQuery}})],1),(_vm.$global.isMobile)?_c('div',{staticClass:"viewOptionsToggles"},[_c('a',{staticClass:"viewOptionsToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showViewOptions = !_vm.showViewOptions;
                            _vm.showCategoryOptions = false;}}},[_vm._v(_vm._s(_vm.$t('view_adjust')))]),_c('a',{staticClass:"categoryOptionsToggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleCategories()}}},[_vm._v(_vm._s(_vm.$t('companies')))])]):_vm._e(),(
                        !_vm.$global.isMobile ||
                        (_vm.$global.isMobile && _vm.showViewOptions)
                    )?_c('sidebar-box',{staticClass:"view-settings",attrs:{"headline":_vm.$t('view_adjust')}},[(_vm.$global.isMobile)?_c('span',{staticClass:"closeButton",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showViewOptions = false}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('view')))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.order),expression:"order"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.order=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"standard"}},[_vm._v("\n                                "+_vm._s(_vm.$t('default'))+"\n                            ")]),_c('option',{attrs:{"value":"price-asc"}},[_vm._v("\n                                "+_vm._s(_vm.$t('lot_price'))+"\n                                "+_vm._s(_vm.$t('aufsteigend'))+"\n                            ")]),_c('option',{attrs:{"value":"price-desc"}},[_vm._v("\n                                "+_vm._s(_vm.$t('lot_price'))+"\n                                "+_vm._s(_vm.$t('price_descending'))+"\n                            ")])])])]):_vm._e(),(
                        _vm.companyList !== false &&
                        (!_vm.$global.isMobile ||
                            (_vm.$global.isMobile && _vm.showCategoryOptions))
                    )?_c('sidebar-box',[(_vm.$global.isMobile)?_c('span',{staticClass:"closeButton",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showCategoryOptions = false}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('div',{staticClass:"company-list"},[_c('ul',[_c('li',{staticClass:"showAll",on:{"click":function($event){return _vm.filterCompany(undefined)}}},[_vm._v("\n                                "+_vm._s(_vm.$t('lot_show_all'))+" ("+_vm._s(_vm.totalCount)+")\n                            ")]),_vm._l((_vm.companyList),function(company){return _c('li',{key:company.companySlug},[(
                                        company.companySlug !== _vm.companySlug
                                    )?_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.filterCompany(company.companySlug)}}},[(company.companyNameShort)?[_vm._v(_vm._s(company.companyNameShort)+" ("+_vm._s(company.cnt)+")")]:[_vm._v(_vm._s(company.companyName)+" ("+_vm._s(company.cnt)+")")]],2):_vm._e(),(
                                        company.companySlug == _vm.companySlug
                                    )?_c('span',[(company.companyNameShort)?_c('b',[_vm._v(_vm._s(company.companyNameShort)+" ("+_vm._s(company.cnt)+")")]):_c('b',[_vm._v(_vm._s(company.companyName)+" ("+_vm._s(company.cnt)+")")])]):_vm._e()])})],2)])]):_vm._e()],1),_c('div',{staticClass:"lot-content col col-md-9"},[_c('div',{staticClass:"group view"},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.$t('view'))+":")])]),_c('span',[_c('a',{staticClass:"icon",class:{
                                active: _vm.lotListDisplayMode === 'small',
                            },on:{"click":function($event){_vm.lotListDisplayMode = 'small';
                                _vm.handleChange();}}},[_c('i',{staticClass:"fas fa-th"})]),_c('a',{staticClass:"icon",class:{
                                active: _vm.lotListDisplayMode === 'large',
                            },on:{"click":function($event){_vm.lotListDisplayMode = 'large';
                                _vm.handleChange();}}},[_c('i',{staticClass:"fas fa-th-large"})]),_c('a',{staticClass:"icon",class:{
                                active: _vm.lotListDisplayMode === 'detail',
                            },on:{"click":function($event){_vm.lotListDisplayMode = 'detail';
                                _vm.handleChange();}}},[_c('i',{staticClass:"fas fa-th-list"})])])]),_c('div',{staticClass:"results"},[(_vm.searching)?_c('lot-list-component',{attrs:{"company":_vm.company,"auctionId":_vm.auctionId,"searchQuery":_vm.lotListQuery,"sortOn":_vm.lotListSortOn,"sortOrder":_vm.lotListSortOrder,"resultsPerPage":_vm.lotListResultsPerPage,"displayMode":_vm.lotListDisplayMode,"showCompany":true,"resultGrouping":_vm.resultGrouping},on:{"pagination-clicked":function($event){return _vm.scrollToTop()}}}):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }