<template>
    <span :class="isSameTime(date) ? 'convertable-time' : ''">
        <!-- safari date fix -->
        {{ date.replace(' ', 'T') | dateTimeFormater }}
        {{ getDst(date) }}
        <span class="time-conversion">
            {{ localTime(date) | dateTimeFormater }}
            {{ getShortTimeZone() }}
        </span>
    </span>
</template>

<script>
import { isDST } from '../lib/date';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default {
    name: 'DateTimezone',
    props: {
        date: String,
    },
    methods: {
        isSameTime(dt) {
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const cet = dayjs.tz(dt, 'Europe/Berlin');
            const converted = dayjs(cet).tz(dayjs.tz.guess());
            
            return cet.utcOffset() != converted.utcOffset();
        },
        localTime(dt) {
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const cet = dayjs.tz(dt, 'Europe/Berlin');
            const converted = dayjs(cet).tz(dayjs.tz.guess());

            return converted.valueOf();
        },
        getDst(dt) {
            return isDST(dt.replace(' ', 'T')) ? 'CEST' : 'CET';
        },
        getShortTimeZone() {
            dayjs.extend(utc);
            dayjs.extend(timezone);

            const timeZ = dayjs.tz.guess();
            const shortTimezone = new Intl.DateTimeFormat(
                this.$route.params.lang,
                {
                    timeZone: timeZ,
                    timeZoneName: 'short',
                }
            )
                .format(Date.now())
                .split(' ')[1];

            return shortTimezone;
        },
    },
};
</script>

<style lang="scss">
.convertable-time {
    border-bottom: 1px dashed $main-color;
    position: relative;
    cursor: pointer;
}

.time-conversion {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 0 4px 0 $grey-bg-dark;
    padding: 5px 10px;
    bottom: 1.3em;
    left: 0;
    text-align: right;
    z-index: 5;
    white-space: nowrap;

    span {
        font-size: 0.8em;
        display: block;
    }
}

.convertable-time:hover {
    color: $main-color;
}

.convertable-time:hover .time-conversion {
    display: inline-block;
    color: $text-color;
}
</style>