<template>
    <div>
        <h1>{{ $t('page_title_aboutus') }}</h1>
        <breadcrumb>{{ $t('page_title_aboutus') }}</breadcrumb>

        <about-us-tabs></about-us-tabs>
        <div class="reading">
            <div v-html="$t('page_content_aboutus')"></div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import AboutUsTabs from '../components/AboutUsTabs.vue';

export default {
    name: 'help',
    components: {
        AboutUsTabs,
        Breadcrumb,
    },
};
</script>
