<template>
    <div v-if="false" class="breadcrumb">
        <router-link :to="{ name: 'page.home' }">{{
            $t('page_title_home')
        }}</router-link>
        / <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'breadcrumb',
};
</script>

<style scoped lang="scss">
.breadcrumb {
    display: none;

    @media (min-width: $md-size) {
        display: block;
    }
}
</style>
