<template>
    <div class="not-found-page">
        <h1>{{ $t('404_headline') }}</h1>

        <p>{{ $t('404_body') }}</p>

        <p>
            <router-link :to="{ name: 'page.home' }" class="logo" tag="a">{{
                $t('404_homelink')
            }}</router-link>
        </p>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';

export default {
    name: 'NotFound',
    components: {
        Breadcrumb,
    },
};
</script>

<style scoped></style>
