<template>
    <div class="twih-list-component">
        <!-- This Week In History list -->
        <div class="twih-list">
            <loader ref="loader"></loader>

            <div v-if="twih.length > 0">
                <twih-list-item
                    v-for="twihItem in twih"
                    :key="twihItem.link"
                    :twihHeadline="twihItem.title"
                    :twihHref="twihItem.link"
                    :twihImage="twihItem.image"
                    :imageHeight="twihItem.imageHeight"
                    :imageWidth="twihItem.imageWidth"
                />
            </div>
        </div>
        <div class="byline">
            {{ $t('powered_by') }}
            <a href="https://vcoins.com" target="_blank">VCoins</a>
        </div>
    </div>
</template>

<script>
import TwihListItem from './TwihListItem';
import Loader from './Loader';
import { getThisWeekInHistory } from '../lib/apis';

export default {
    components: {
        TwihListItem,
        Loader,
    },
    data: function () {
        return {
            twih: {},
        };
    },
    mounted: function () {
        this.fetchTwih();
    },
    methods: {
        fetchTwih() {
            this.$refs.loader.show();
            getThisWeekInHistory(this.$route.params.lang)
                .then((twih) => {
                    this.twih = Object.values(twih.data);
                    this.$refs.loader.hide();
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
};
</script>

<style scoped lang="scss">
.twih-list-component {
    margin: $mar-xl 0 0 0;
}
.twih-list {
    position: relative;
    min-height: 50px; /* for the loader */
}
.byline {
    margin: $mar-xl 0 0 0;
}
</style>
<style lang="scss">
.twih-list-component {
    .slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
