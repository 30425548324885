<template>
    <div class="bidlist-auction">
        <div class="details">
            <div class="details-prebidding">
                <div class="title">{{ $t('bid_end') }}</div>
                <div class="date">
                    <date-timezone :date="auction.auctionTime" />
                </div>
            </div>
            <div>
                <company-image
                    class="company-image"
                    :isFrontPage="true"
                    :companyId="auction.companyId"
                    :auctionId="auction.auctionId"
                ></company-image>
            </div>
        </div>

        <h3>{{ auction.companyName }} - {{ auction.auctionTitle }}</h3>

        <bid-list-item-sheet
            :sheet="livebidding"
            v-for="livebidding in livebiddings"
            :key="livebidding.id"
        ></bid-list-item-sheet>

        <bid-list-item-sheet
            :sheet="sheet"
            v-for="sheet in sheets"
            :key="sheet.id"
        ></bid-list-item-sheet>
    </div>
</template>

<script>
import BidListItemSheet from '../components/BidListItemSheet';
import CompanyImage from '../components/CompanyImage';
import Price from '../components/Price';
import DateTimezone from '../components/DateTimezone';

export default {
    name: 'BidListItem',
    components: { BidListItemSheet, CompanyImage, Price, DateTimezone },
    props: {
        auction: {
            required: true,
        },
    },
    data() {
        return {
            sheets: [],
            livebiddings: [],
        };
    },
    mounted: function () {
        this.groupSheets();
    },
    methods: {
        groupSheets: function () {
            if (this.auction.sheets) {
                this.auction.sheets.forEach((element) => {
                    element.isExpanded = false;
                    if (element.isBidsheet == '0') {
                        this.livebiddings.push(element);
                    } else {
                        this.sheets.push(element);
                    }
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.bidlist-auction {
    background: white;
    box-shadow: 0 0 4px 0 $grey-bg-dark;
    padding: 20px 15px 0;

    margin-bottom: 20px;

    h3 {
        margin-top: 0;
        font-size: 20px;
    }
}

.details {
    float: right;
    display: flex;

    margin-left: 25px;

    .company-image {
        width: 150px;
    }

    .details-prebidding {
        text-align: right;

        margin-right: 25px;

        .title {
            font-size: 14px;
            font-weight: bold;
        }

        .date {
            font-size: 16px;
        }
    }
}
</style>
