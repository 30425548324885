<template>
    <div class="contact-page">
        <h1>{{ $t('page_title_aboutus') }}</h1>
        <breadcrumb>
            <router-link :to="{ name: 'page.about' }">{{
                $t('page_title_aboutus')
            }}</router-link>
            / {{ $t('page_title_contact') }}
        </breadcrumb>

        <about-us-tabs></about-us-tabs>

        <h2>{{ $t('page_title_contact') }}</h2>
        <div class="question">
            <p @click="q1shown = !q1shown">
                <a>{{ $t('contact_1') }}</a>
            </p>
            <transition name="slide">
                <p v-if="q1shown">
                    {{ $t('contact_1_1') }}
                    <router-link :to="{ name: 'page.companyList' }">{{
                        $t('contact_company')
                    }}</router-link>
                    {{ $t('contact_1_2') }}
                </p>
            </transition>
        </div>
        <div class="question" v-if="!sendSuccess">
            <p @click="q2shown = !q2shown">
                <a>{{ $t('contact_2') }}</a>
            </p>
            <transition name="slide">
                <form v-if="q2shown" class="form" novalidate>
                    <span>{{ $t('contact_2_1') }}</span>
                    <div class="grid">
                        <div class="row">
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="text"
                                    v-model="form.user_company"
                                    :placeholder="$t('form_user_company')"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-md-4 col-lg-3">
                                <select
                                    v-model="form.user_title"
                                    :class="{
                                        hasInput: form.user_title,
                                        hasError: errors.user_title,
                                    }"
                                >
                                    <option value disabled>
                                        {{ $t('form_user_title') }}
                                    </option>
                                    <option value="">---</option>
                                    <option value="dr">
                                        {{ $t('form_user_dr') }}
                                    </option>
                                    <option value="prof">
                                        {{ $t('form_user_prof') }}
                                    </option>
                                    <option value="profDr">
                                        {{ $t('form_user_profDr') }}
                                    </option>
                                </select>
                            </div>

                            <div
                                class="col push-md-4 col-md-4 push-lg-3"
                                v-if="errors.user_title"
                            >
                                <span class="alert alert--error">
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ $t(this.errors.user_title) }}
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="text"
                                    :placeholder="$t('form_user_first_name')"
                                    v-model="form.user_first_name"
                                    :class="{
                                        hasInput: form.user_first_name,
                                        hasError: errors.user_first_name,
                                    }"
                                    required
                                    @blur="
                                        validateFieldEmpty('user_first_name')
                                    "
                                />
                            </div>
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="text"
                                    :placeholder="$t('form_user_last_name')"
                                    v-model="form.user_last_name"
                                    :class="{
                                        hasInput: form.user_last_name,
                                        hasError: errors.user_last_name,
                                    }"
                                    required
                                    @blur="validateFieldEmpty('user_last_name')"
                                />
                            </div>
                            <div
                                class="col col-md-4"
                                v-if="
                                    errors.user_first_name ||
                                    errors.user_last_name
                                "
                            >
                                <span class="alert alert--error">
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ $t(this.errors.user_first_name)
                                    }}<span
                                        v-if="
                                            this.errors.user_first_name &&
                                            this.errors.user_last_name
                                        "
                                        >,
                                    </span>
                                    {{ $t(this.errors.user_last_name) }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="tel"
                                    :placeholder="$t('form_user_phone')"
                                    v-model="form.user_phone"
                                    :class="{
                                        hasInput: validPhone,
                                        hasError: errors.user_phone,
                                    }"
                                    required
                                    @blur="validateFieldPhone()"
                                />
                            </div>
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="email"
                                    :placeholder="$t('form_user_mail')"
                                    v-model="form.user_mail"
                                    :class="{
                                        hasInput: validMail,
                                        hasError: errors.user_mail,
                                    }"
                                    required
                                    @blur="validateFieldMail()"
                                />
                            </div>
                            <div
                                class="col col-md-4"
                                v-if="errors.user_phone || errors.user_mail"
                            >
                                <span class="alert alert--error">
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ $t(this.errors.user_phone)
                                    }}<span
                                        v-if="
                                            this.errors.user_phone &&
                                            this.errors.user_mail
                                        "
                                        >,
                                    </span>
                                    {{ $t(this.errors.user_mail) }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-md-8 col-lg-6">
                                <textarea
                                    id="message"
                                    :placeholder="$t('form_user_message')"
                                    v-model="form.user_message"
                                    :class="{
                                        hasInput: form.user_message,
                                        hasError: errors.user_message,
                                    }"
                                    required
                                    @blur="validateMessageLength()"
                                ></textarea>
                            </div>
                            <div
                                class="col col-md-4"
                                v-if="errors.user_message"
                            >
                                <span class="alert alert--error">
                                    <i class="fas fa-exclamation-circle"></i>
                                    {{ $t(this.errors.user_message) }}
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="
                                    col
                                    push-md-4
                                    col-md-4
                                    push-lg-3
                                    col-lg-3
                                "
                            >
                                <button
                                    class="button button-blue"
                                    @click.prevent="sendForm()"
                                >
                                    {{ $t('form_send_button') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </transition>
        </div>
        <div v-if="sendSuccess">{{ $t('form_send_success') }}</div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import AboutUsTabs from '../components/AboutUsTabs.vue';
import Vue from 'vue';
import { PHONE_REGEX, MAIL_REGEX } from '../config';
import { postContact } from '../lib/apis';

export default {
    name: 'contact',
    components: {
        Breadcrumb,
        AboutUsTabs,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_contact'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_contact'),
                },
            ],
        };
    },
    data() {
        return {
            q1shown: false,
            q2shown: false,
            sendSuccess: false,
            sendError: false,
            form: {
                user_company: '',
                user_title: '',
                user_first_name: '',
                user_last_name: '',
                user_phone: '',
                user_mail: '',
                user_message: '',
            },
            errors: {},
        };
    },
    computed: {
        validMail() {
            const re = MAIL_REGEX;

            return (
                re.test(String(this.form.user_mail).toLowerCase()) &&
                this.form.user_mail !== ''
            );
        },
        validPhone() {
            const re = PHONE_REGEX;

            return (
                re.test(String(this.form.user_phone).toLowerCase()) &&
                this.form.user_phone !== ''
            );
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
    methods: {
        scrollToForm() {
            if (this.hasErrors) {
                const bodyRect = document.body.getBoundingClientRect();
                const elemRect = this.$el
                    .querySelector('form')
                    .getBoundingClientRect();
                const offset = elemRect.top - bodyRect.top;
                this.scrollTo({ to: offset });
            }
        },
        validateTitle() {
            if (this.form['user_title'] === null) {
                Vue.set(this.errors, 'user_title', 'form.errors.user_title');
            } else {
                Vue.delete(this.errors, 'user_title');
            }
        },
        validateFieldEmpty(fieldName) {
            if (this.form[fieldName] === '') {
                Vue.set(this.errors, fieldName, `form.errors.${fieldName}`);
            } else {
                Vue.delete(this.errors, fieldName);
            }
        },
        validateMessageLength() {
            if (this.form.user_message.length < 50) {
                Vue.set(
                    this.errors,
                    'user_message',
                    'form.errors.user_message_short'
                );
            } else {
                Vue.delete(this.errors, 'user_message');
            }
        },
        validateFieldPhone() {
            if (!this.validPhone) {
                Vue.set(this.errors, 'user_phone', 'form.errors.user_phone');
            } else {
                Vue.delete(this.errors, 'user_phone');
            }
        },
        validateFieldMail() {
            if (!this.validMail) {
                Vue.set(this.errors, 'user_mail', 'form.errors.user_mail');
            } else {
                Vue.delete(this.errors, 'user_mail');
            }
        },
        validateForm() {
            this.validateTitle('user_title');
            this.validateFieldEmpty('user_first_name');
            this.validateFieldEmpty('user_last_name');
            this.validateMessageLength();
            this.validateFieldMail();
            this.validateFieldPhone();
            this.scrollToForm();
        },
        sendForm() {
            this.validateForm();

            if (Object.keys(this.errors).length === 0) {
                const data = { ...this.form };

                postContact(data, this.$route.params.lang)
                    .then((response) => {
                        if (response.data.success) {
                            this.sendSuccess = true;
                        } else {
                            this.errors = response.data.error;
                        }
                    })
                    .catch((e) => {
                        this.genericApiErrorHandling(e);
                    });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.question {
    & > :first-child {
        display: list-item;
        list-style: disc inside;
    }

    & > :nth-child(2) {
        padding-left: 20px;
        padding-bottom: 20px;
    }
}

.form {
    margin-top: $mar-lg;
}
</style>
