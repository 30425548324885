import { render, staticRenderFns } from "./BidListItem.vue?vue&type=template&id=36ac38cc&scoped=true&"
import script from "./BidListItem.vue?vue&type=script&lang=js&"
export * from "./BidListItem.vue?vue&type=script&lang=js&"
import style0 from "./BidListItem.vue?vue&type=style&index=0&id=36ac38cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ac38cc",
  null
  
)

export default component.exports