var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-page",attrs:{"id":"account-notifications-page"}},[_c('div',{staticClass:"row"},[_c('account-navigation'),_c('div',{staticClass:"col col-md-12 col-lg-12"},[_c('account-header'),_c('h1',[_vm._v(_vm._s(_vm.$t('searches_saved')))]),(!_vm.$global.isMobile)?_c('pagination',{ref:"PaginationTop",attrs:{"hasPerPageSelect":true},on:{"pagination-changed":function($event){return _vm.getSearches()}}}):_vm._e(),_c('table',{attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.$t('search_term')))])])]),_c('tbody',[_vm._l((_vm.searches),function(search,index){return _c('tr',{key:search.searchId,class:index % 2 === 1 ? 'odd' : 'even'},[_c('td',[_vm._v(_vm._s(search.searchQuery))]),_c('td',{staticClass:"links"},[_c('router-link',{staticClass:"btn",attrs:{"to":{
                                    name: 'page.lots',
                                    query: { term: search.searchQuery },
                                    params: {
                                        page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                                        perPage:
                                            _vm.PAGINATION_DEFAULTS.PER_PAGE,
                                    },
                                }}},[_vm._v(_vm._s(_vm.$t('search_now')))]),_vm._v("\n                            -\n                            "),_c('a',{staticClass:"ico",on:{"click":function($event){return _vm.delteSearch(search.searchId)}}},[_vm._v(_vm._s(_vm.$t('delete')))]),(_vm.getError(search.searchId))?_c('div',{staticClass:"error-message"},[_vm._v("\n                                "+_vm._s(_vm.getError(search.searchId))+"\n                            ")]):_vm._e()],1)])}),(!_vm.searches.length)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("\n                            "+_vm._s(_vm.$t('searches_empty'))+"\n                        ")])]):_vm._e()],2)]),_c('pagination',{ref:"PaginationBottom",attrs:{"hasPerPageSelect":!_vm.$global.isMobile},on:{"pagination-changed":function($event){return _vm.getSearches()}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }