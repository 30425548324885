<template>
    <div class="lot-list-page">
        <div class="grid">
            <div class="row">
                <aside :class="getClassStringSidebar">
                    <sidebar-box theme="blue" class="sidebar-search">
                        <lot-search
                            :isReduced="true"
                            :showSaveButton="true"
                            :companyName="company.companyName"
                            @query-change="updateQuery"
                            size="small"
                            :class="{
                                'lot-search-mobile': searchSize === 'small',
                            }"
                        />
                    </sidebar-box>

                    <div class="viewOptionsToggles" v-if="$global.isMobile">
                        <a
                            class="viewOptionsToggle"
                            href="#"
                            v-on:click.prevent="
                                showViewOptions = !showViewOptions;
                                showCategoryOptions = false;
                            "
                            >{{ $t('view_adjust') }}</a
                        >

                        <a
                            class="categoryOptionsToggle"
                            href="#"
                            v-on:click.prevent="toggleCategories()"
                            >{{ $t('companies') }}</a
                        >
                    </div>

                    <sidebar-box
                        :headline="$t('view_adjust')"
                        class="view-settings"
                        v-if="
                            !$global.isMobile ||
                            ($global.isMobile && showViewOptions)
                        "
                    >
                        <span
                            class="closeButton"
                            href="#"
                            v-if="$global.isMobile"
                            v-on:click.prevent="showViewOptions = false"
                            ><i class="fas fa-times"></i
                        ></span>
                        <div>
                            <label>{{ $t('view') }}</label>
                            <select v-model="order">
                                <option value="standard">
                                    {{ $t('default') }}
                                </option>
                                <option value="price-asc">
                                    {{ $t('lot_price') }}
                                    {{ $t('aufsteigend') }}
                                </option>
                                <option value="price-desc">
                                    {{ $t('lot_price') }}
                                    {{ $t('price_descending') }}
                                </option>
                            </select>
                        </div>
                    </sidebar-box>

                    <sidebar-box
                        v-if="
                            companyList !== false &&
                            (!$global.isMobile ||
                                ($global.isMobile && showCategoryOptions))
                        "
                    >
                        <span
                            class="closeButton"
                            href="#"
                            v-if="$global.isMobile"
                            v-on:click.prevent="showCategoryOptions = false"
                            ><i class="fas fa-times"></i
                        ></span>
                        <div class="company-list">
                            <ul>
                                <li
                                    class="showAll"
                                    @click="filterCompany(undefined)"
                                >
                                    {{ $t('lot_show_all') }} ({{ totalCount }})
                                </li>
                                <li
                                    v-for="company in companyList"
                                    :key="company.companySlug"
                                >
                                    <span
                                        @click.prevent="
                                            filterCompany(company.companySlug)
                                        "
                                        v-if="
                                            company.companySlug !== companySlug
                                        "
                                        ><template
                                            v-if="company.companyNameShort"
                                            >{{ company.companyNameShort }} ({{
                                                company.cnt
                                            }})</template
                                        ><template v-else
                                            >{{ company.companyName }} ({{
                                                company.cnt
                                            }})</template
                                        ></span
                                    >
                                    <span
                                        v-if="
                                            company.companySlug == companySlug
                                        "
                                        ><b v-if="company.companyNameShort"
                                            >{{ company.companyNameShort }} ({{
                                                company.cnt
                                            }})</b
                                        ><b v-else
                                            >{{ company.companyName }} ({{
                                                company.cnt
                                            }})</b
                                        ></span
                                    >
                                </li>
                            </ul>
                        </div>
                    </sidebar-box>
                </aside>
                <div class="lot-content col col-md-9">
                    <div class="group view">
                        <span>
                            <label>{{ $t('view') }}:</label>
                        </span>
                        <span>
                            <a
                                class="icon"
                                :class="{
                                    active: lotListDisplayMode === 'small',
                                }"
                                v-on:click="
                                    lotListDisplayMode = 'small';
                                    handleChange();
                                "
                            >
                                <i class="fas fa-th"></i>
                            </a>
                            <a
                                class="icon"
                                :class="{
                                    active: lotListDisplayMode === 'large',
                                }"
                                v-on:click="
                                    lotListDisplayMode = 'large';
                                    handleChange();
                                "
                            >
                                <i class="fas fa-th-large"></i>
                            </a>
                            <a
                                class="icon"
                                :class="{
                                    active: lotListDisplayMode === 'detail',
                                }"
                                v-on:click="
                                    lotListDisplayMode = 'detail';
                                    handleChange();
                                "
                            >
                                <i class="fas fa-th-list"></i>
                            </a>
                        </span>
                    </div>
                    <div class="results">
                        <lot-list-component
                            v-if="searching"
                            :company="company"
                            :auctionId="auctionId"
                            :searchQuery="lotListQuery"
                            :sortOn="lotListSortOn"
                            :sortOrder="lotListSortOrder"
                            :resultsPerPage="lotListResultsPerPage"
                            :displayMode="lotListDisplayMode"
                            :showCompany="true"
                            :resultGrouping="resultGrouping"
                            @pagination-clicked="scrollToTop()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCompanyDetails, getLotsCompanyList } from '../lib/apis';
import LotSearch from '../components/LotSearch';
import LotListComponent from '../components/LotListComponent';
import { PAGINATION_DEFAULTS } from '../config';
import SidebarBox from '../components/SidebarBox.vue';

export default {
    components: {
        LotSearch,
        LotListComponent,
        SidebarBox,
    },
    data: function () {
        return {
            // Lot List Component Options
            lotListQuery: {
                priceFrom: 0,
                priceTo: 100000,
                currency: 'EUR',
                groupByAuction: '1',
            },
            lotListSortOrder: 'asc',
            lotListResultsPerPage: PAGINATION_DEFAULTS.PER_PAGE,
            lotListDisplayMode: 'large',
            company: {},
            companySlug: undefined,
            auctionId: undefined,
            searching: false,
            companyList: false,
            order: 'standard',
            showViewOptions: false,
            showCategoryOptions: false,
        };
    },
    computed: {
        totalCount() {
            let count = 0;

            if (this.companyList) {
                this.companyList.forEach((company) => {
                    count += company.cnt;
                });
            }

            return count;
        },
        searchSize() {
            return this.$global.isMobile ? 'small' : '';
        },
        resultGrouping() {
            return this.lotListQuery.groupByAuction == '1';
        },
        lotListSortOn() {
            return this.lotListQuery.groupByAuction == '1'
                ? 'auction'
                : 'price';
        },
        getClassStringSidebar() {
            if (this.companySlug === undefined) {
                return 'lot-info col col-md-3 lot-list-sidebar-container';
            } else {
                return 'lot-info col col-md-3 lot-list-sidebar-container filtered';
            }
        },
    },
    methods: {
        toggleCategories() {
            this.showCategoryOptions = !this.showCategoryOptions;
            this.showViewOptions = false;

            this.waitForElement('.session-filter--disabled').then(function (
                element
            ) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            });
        },
        getCompanyDetails() {
            /* eslint-disable-next-line no-console */
            if (typeof this.$route.params.companySlug !== 'undefined') {
                this.companySlug = this.$route.params.companySlug;

                getCompanyDetails(this.companySlug, this.$route.params.lang)
                    .then((response) => {
                        this.company = response.data;
                        this.searching = true;
                    })
                    .catch((e) => {
                        this.genericApiErrorHandling(e);
                    });
            } else {
                this.searching = true;
                this.company = {};
                this.companySlug = undefined;
            }
        },
        getCompanyList() {
            getLotsCompanyList(
                this.$route.params.lang,
                this.$route.query.term
            ).then((response) => {
                this.companyList = response.data;
            });
        },
        filterCompany(slug) {
            this.companySlug = slug;
            this.handleChange();
        },
        scrollToTop() {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = this.$el
                .querySelector('.results')
                .getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top;

            this.scrollTo({ to: offset });
        },
        handleChange(forceDefaults) {
            this.pushNewURL(
                {
                    displayMode: this.lotListDisplayMode,
                    term: this.lotListQuery.term,
                    priceFrom: this.lotListQuery.priceFrom,
                    currency: this.lotListQuery.currency,
                    groupByAuction: this.lotListQuery.groupByAuction,
                    companySlug: this.companySlug,
                },
                forceDefaults
            );
        },
        updateQuery(newQuery) {
            this.lotListQuery = { ...this.lotListQuery, ...newQuery };
            this.handleChange(true);
        },
        pushNewURL(
            {
                term,
                orderCol,
                orderDirection,
                priceFrom,
                currency,
                displayMode,
                page,
                perPage,
                groupByAuction,
                companySlug,
            },
            forceDefaults
        ) {
            let currentPage = PAGINATION_DEFAULTS.START_PAGE;
            if (!forceDefaults) {
                currentPage = page || this.$route.params.page;
            }
            let currentPerPage = PAGINATION_DEFAULTS.PER_PAGE;
            if (perPage) {
                currentPerPage = perPage || this.$route.params.perPage;
            }

            let name =
                companySlug === undefined ? 'page.lots' : 'page.companyLots';

            const historyObj = {
                path: '',
                params: {
                    page: currentPage,
                    perPage: currentPerPage,
                    companySlug: companySlug,
                },
                query: {
                    ...this.$route.query,
                    term,
                    orderCol,
                    orderDirection,
                    priceFrom,
                    displayMode,
                    currency,
                    groupByAuction,
                },
                name: name,
            };

            /* eslint-disable-next-line no-console */
            // console.log('pushNewURL', {term, orderCol, orderDirection, priceFrom, currency, displayMode});

            if (Object.keys(this.$route.query).length === 0) {
                this.$router.replace(historyObj);
            } else {
                this.$router.push(historyObj);
            }
        },
    },
    mounted: function () {
        this.$root.$on('update:language', function () {
            if (
                this.$route.name == 'page.lots' ||
                this.$route.name == 'page.companyLots'
            ) {
                this.$router.push({
                    name: 'page.lots',
                });
            }
        });

        this.getCompanyDetails();
        this.getCompanyList();

        this.lotListQuery = this.$route.query;
        this.lotListQuery.groupByAuction =
            this.$route.query.groupByAuction !== undefined
                ? this.$route.query.groupByAuction
                : '1';

        this.order =
            this.lotListQuery.groupByAuction == '0' ? 'price-asc' : 'standard';
    },
    watch: {
        '$route.query.term': function () {
            this.filterCompany(undefined);
        },
        $route() {
            this.lotListDisplayMode = this.$route.query.displayMode;

            const lotListQuery = { ...this.lotListQuery };
            lotListQuery.term = this.$route.query.term;
            lotListQuery.priceFrom = this.$route.query.priceFrom;
            lotListQuery.currency = this.$route.query.currency;
            lotListQuery.groupByAuction =
                this.$route.query.groupByAuction !== undefined
                    ? this.$route.query.groupByAuction
                    : '1';
            this.lotListQuery = { ...lotListQuery };
            this.getCompanyDetails();
            this.getCompanyList();
        },
        order() {
            if (this.order == 'standard') {
                this.lotListQuery.groupByAuction = '1';
                this.lotListSortOrder = 'asc';
            }

            if (this.order == 'price-asc') {
                this.lotListQuery.groupByAuction = '0';
                this.lotListSortOrder = 'asc';
            }

            if (this.order == 'price-desc') {
                this.lotListQuery.groupByAuction = '0';
                this.lotListSortOrder = 'desc';
            }

            this.handleChange();
        },
    },
};
</script>

<style lang="scss">
.sidebar-box {
    position: relative;
}

.lot-list-item {
    padding: $pad-md $pad-lg;
    margin: $pad-lg 0;
}
.group.view {
    text-align: right;
    margin-top: $mar-lg;
    .icon {
        display: inline-block;
        color: $text-color;
        padding: 5px 2px;
        margin-left: 5px;

        &.active {
            color: $main-color;
        }
    }
}

.lot-search-mobile {
    margin: #{-1 * $mar-xl} #{-1 * $mar-sm} 0;
}

.view-settings {
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 0;

        @media (max-width: $md-size-max) {
            flex-direction: column;
            align-items: flex-start;
        }

        label,
        select {
            @media (min-width: $lg-size) {
                flex-basis: 50%;
            }
        }

        label {
            font-weight: bold;
            flex-shrink: 0;
        }

        select {
            width: 100%;
        }
    }
}

.lot-info.lot-list-sidebar-container {
    @media (min-width: $md-size) {
        margin-top: 122px;

        &.filtered {
            margin-top: 102px;
        }
    }
}

.sidebar-box.sidebar-search {
    padding: 0;
}

.company-list {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            cursor: pointer;
            width: 100%;
            font-size: 13px;
            padding: 2px 0;

            &.showAll {
                color: $main-color;
                text-transform: uppercase;
                text-decoration: underline;
                font-size: 14px;
                margin: 5px 0 13px;
            }
        }
    }
}

.closeButton {
    position: absolute;
    cursor: pointer;
    padding: 10px;
    top: 0;
    right: 0;
}

.viewOptionsToggles {
    display: flex;
    flex-direction: row;

    .viewOptionsToggle,
    .categoryOptionsToggle {
        flex-grow: 1;
        text-decoration: none;
        text-transform: uppercase;
        padding: 15px 0;

        &:hover {
            color: #000;
        }

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}
</style>
