<template>
    <div v-bind:class="'sidebar-box sidebar-box-' + theme">
        <h3 v-if="headline" class="sidebar-box-headline" v-html="headline"></h3>
        <div v-if="auex" class="auex-box">
            <span class="auex-poweredby">{{ $t('powered_by') }}</span>
            <img
                width="50" height="25"
                loading="lazy"
                class="auex-logo"
                alt="auex"
                src="/assets/svg/auex-logo-mono-white.svg"
            />
        </div>
        <a v-if="href" v-bind:href="href" class="sidebar-box-show-all">{{
            $t('show.all')
        }}</a>
        <router-link v-if="route" class="sidebar-box-show-all" :to="route">{{
            $t('show.all')
        }}</router-link>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        headline: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        route: {
            type: Object,
            default: null,
        },
        theme: {
            type: String,
            default: 'grey',
            validator: function (val) {
                return (
                    [
                        'grey',
                        'dark-grey',
                        'blue',
                        'light-grey',
                        'black',
                    ].indexOf(val) !== -1
                );
            },
        },
        auex: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.sidebar-box {
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
        flex-grow: 1;
        width: 100%;
    }

    &-headline {
        margin: $mar-sm 0;
        display: inline-block;
        text-transform: uppercase;
    }

    &-show-all {
        margin: $mar-sm 0;
        color: currentColor;

        @media (min-width: 767px) and (max-width: 1150px) {
            order: 3;
            margin-left: auto;
            margin-right: auto;
        }
    }

    input[type='text'] {
        width: 100%;
    }

    &-blue {
        background: $main-color;
        color: #fff;
    }

    &-dark-grey {
        background: #b4b0b0;
        color: #fff;
    }

    &-light-grey {
        background: $grey-bg-lighter;
    }

    &-grey {
        background: $grey-bg-light;
    }

    &-black {
        background: #444242;
        color: #fff;
    }
    .auex-box {
        display: inline-block;
        width: auto;
        text-align: right;
    }
    .auex-poweredby {
        font-size: 0.6em;
        opacity: 0.5;
        display: inline-block;
        margin-right: 0.75em;
        vertical-align: 0.3em;
    }
    .auex-logo {
        width: 50px;
        height: 25px;
    }
}
</style>
