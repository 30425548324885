<template>
    <div class="twih-list-item clearfix">
        <h4 v-html="twihHeadline"></h4>
        <a :href="twihHref" target="_blank"
            ><img :width="imageWidth" :height="imageHeight" alt="twih" loading="lazy" :src="'https://twih.sixbid.com/images/' + twihImage"
        /></a>
        <a :href="twihHref" target="_blank" class="button button-blue">{{
            $t('read_story')
        }}</a>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {
    name: 'TwihListItem',
    data: function() {
        return {
            languageKey: '',
        };
    },
    props: {
        twihHeadline: {
            type: String,
            required: true,
        },
        twihHref: {
            type: String,
            required: true,
        },
        twihImage: {
            type: String,
            required: true,
        },
        imageHeight: {
            type: Number,
            required: true,
        },
        imageWidth: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.$root.$on('update:language', lang => {
            this.languageKey = lang;
            this.$forceUpdate();
        });
    },
};
</script>
<style lang="scss">
.twih-list-item {
    width: 100%;
    h4 {
        margin: 0 0 1em 0;
    }
    img {
        display: block;
        margin-bottom: 1em;
    }

    &:nth-child(2) {
        margin-top: 40px;
    }
}
</style>
