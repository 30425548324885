export default {
    namespaced: true,
    state: {
        ads: {},
    },
    getters: {
        getAds(state) {
            return state.ads;
        },
    },
    mutations: {
        SET_ADS(state, payload) {
            const ads = {};
            payload.forEach(ad => {
                ads[ad.banner_place] = ad;
            });
            state.ads = { ...ads };
        },
    },
    actions: {
        setAds({ commit }, payload) {
            commit('SET_ADS', payload);
        },
    },
};
