<template>
    <transition name="fade">
        <div class="overlay" v-if="shown" @click.stop>
            <div class="overlay-content">
                <div class="close" @click="$emit('closing')">
                    <i class="fa fa-times" />
                </div>
                <div class="info">
                    <slot></slot>
                </div>
                <div class="links">
                    <button
                        class="button button-grey button-decline"
                        @click="$emit('decline')"
                    >
                        {{ declineText }}
                    </button>
                    <button class="button button-blue" @click="$emit('accept')">
                        {{ acceptText }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ConfirmOverlay',
    props: {
        shown: {
            type: Boolean,
            default: false,
            required: true,
        },
        acceptText: {
            type: String,
        },
        declineText: {
            type: String,
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    cursor: default;
}

.overlay-content {
    background: white;
    min-width: 300px;
    max-width: 800px;
    padding: $pad-md $pad-xl;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media (min-width: $sm-size) {
        padding: $pad-md $pad-2xl;
    }
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: $pad-sm;
    cursor: pointer;
}

.links {
    text-align: right;
    margin-top: $mar-lg;

    div {
        margin-top: $mar-lg;
    }

    a,
    span {
        text-decoration: underline;
        cursor: pointer;
        color: $main-color;
    }

    .button-decline {
        margin-right: $mar-lg;
    }
}
</style>
