var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"account-tabs tabs"},[_c('li',[_c('router-link',{staticClass:"icon-bids button button-grey",class:{ active: _vm.$route.name == 'page.accountBids' },attrs:{"to":{
                name: 'page.accountBids',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('page_title_bids')))])])],1),_c('li',[_c('router-link',{staticClass:"icon-watchlist button button-grey",class:{ active: _vm.$route.name == 'page.accountWatchlist' },attrs:{"to":{
                name: 'page.accountWatchlist',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('watchlist')))])])],1),_c('li',[_c('router-link',{staticClass:"icon-buyNow button button-grey",class:{ active: _vm.$route.name == 'page.accountBuyNow' },attrs:{"to":{
                name: 'page.accountBuyNow',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('buynow_items')))])])],1),_c('li',[_c('router-link',{staticClass:"icon-notifications button button-grey",class:{ active: _vm.$route.name == 'page.accountNotifications' },attrs:{"to":{
                name: 'page.accountNotifications',
                params: {
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            }}},[_c('span',[_vm._v(_vm._s(_vm.$t('searches_saved')))])])],1),_c('li',{staticClass:"external"},[_c('a',{attrs:{"href":_vm.profileLink,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('page_title_profile.password')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }