<template>
    <div class="banner-container">
        <div class="banner">
            <img loading="lazy" width="1600" height="100" alt="banner-background" class="banner-bg" :src="bgSrc" :srcset="bgSrcSet" />
            <div class="wrapper banner-content" v-if="companyId">
                <img
                    loading="lazy"
                    class="banner-logo"
                    width="300"
                    height="70"
                    :src="getImageUrls().logo"
                    :srcset="logoSrcSet"
                    alt="logo"
                />
            </div>
        </div>
        <div id="banner-spacer"></div>
    </div>
</template>

<script>
import { AUCTION_BANNER } from '../config.js';

export default {
    name: 'AuctionBanner',
    props: {
        companyId: {
            required: false,
        },
        auctionId: {
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        auctionPathPart() {
            return this.auctionId ? `/${this.auctionId}` : '';
        },
        bgSrc() {
            if (this.companyId) {
                return this.getImageUrls().bg;
            }
            return '/assets/img/banner.jpg';
        },
        logoSrcSet() {
            if (this.companyId) {
                return `${this.getImageUrls().logo} 1x, ${
                    this.getImageUrls().logo2x
                } 2x`;
            }
        },
        bgSrcSet() {
            if (this.companyId) {
                return `${this.getImageUrls().bg} 1x, ${
                    this.getImageUrls().bg2x
                } 2x`;
            }
            return `/assets/img/banner.jpg 1x, /assets/img/banner@2.jpg 2x`;
        },
    },
    methods: {
        getImageUrls() {
            return {
                bg: `${AUCTION_BANNER.URL}${this.companyId}${this.auctionPathPart}/${AUCTION_BANNER.BG_FILE}`,
                bg2x: `${AUCTION_BANNER.URL}${this.companyId}${this.auctionPathPart}/${AUCTION_BANNER.BG_2X_FILE}`,
                logo: `${AUCTION_BANNER.URL}${this.companyId}${this.auctionPathPart}/${AUCTION_BANNER.LOGO_FILE}`,
                logo2x: `${AUCTION_BANNER.URL}${this.companyId}${this.auctionPathPart}/${AUCTION_BANNER.LOGO_2X_FILE}`,
            };
        },
    },
};
</script>

<style scoped lang="scss">
#banner-spacer {
    height: 100px;
    background-color: transparent;
    width: 100%;
    @media (max-width: $md-size) {
        height: 80px;
    }
    @media (max-width: $sm-size) {
        height: 12vw;
    }
}
.banner {
    margin-top: #{-1 * $pad-xl};
    /*@media (min-width: $md-size) {
        margin-top: #{-1 * $mar-xl};
    }*/

    @media (max-width: 645px) {
        height: 16vw;
    }

    overflow: hidden;

    width: 100vw;
    position: fixed;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 100px;
    z-index: 999;

    border-bottom: 1px solid #1660aa;

    &-logo {
        width: auto;
        height: auto;
        @media (max-width: 645px) {
            height: 11vw;
        }
    }

    &-content {
        padding-top: $pad-lg;
        position: relative;
        z-index: 1;

        @media (max-width: 645px) {
            padding-top: 2.5vw;
        }
    }

    img.banner-bg {
        height: 100%;
        width: 100%;
        position: absolute;
    }
}
</style>
