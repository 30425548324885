<template>
    <div
        class="bid-lot-input"
        :class="{ hasText: showBidText && !$global.isTablet }"
        v-if="
            typeof lot.frontendBiddingActive !== 'undefined' &&
            lot.lotPriceRealised !== -1
        "
    >
        <template v-if="canBid">
            <input
                v-if="lot.auctionAltBidLink == 0 || lot.auctionAltBidLink == 2"
                type="text"
                :placeholder="$t('bid_box')"
                v-model="formattedAmount"
                @keydown.enter="bidLot()"
                @input="formatInput"
            />
            <button
                v-if="lot.auctionAltBidLink == 0 || lot.auctionAltBidLink == 2"
                class="button button-for-input"
                :class="{ btnsmall: !showBidText || $global.isTablet }"
                @click="bidLot()"
            >
                <i class="fas fa-gavel"></i>
                <span v-if="showBidText && !$global.isTablet">{{
                    $t('bid_now')
                }}</span>
            </button>
            <a
                v-if="lot.auctionAltBidLink == 1"
                class="button fullwidth"
                :class="{
                    'button-grey button-blue-text': detailStyling == false,
                    'button-blue': detailStyling == true,
                }"
                :href="lot.lotAltBidLink"
                target="_blank"
                >{{ $t('bid_at_company') }}</a
            >
            <a
                v-if="detailStyling && lot.auctionAltBidLink == 2"
                class="button button-grey fullwidth margin-top"
                :class="{
                    'button-grey button-blue-text': detailStyling == false,
                    'button-blue': detailStyling == true,
                }"
                :href="lot.lotAltBidLink"
                ref="externalLink"
                target="_blank"
                @click.prevent="overlay = true"
                >{{ $t('company_bid_there')
                }}<i class="fas fa-external-link-alt fa-sm"></i
            ></a>
            <leaving-overlay
                :url="lot.lotAltBidLink"
                :shown="overlay"
                type="external-bidding"
                @closing="overlay = false"
            ></leaving-overlay>
            <!-- Link NUR auf Detailseite -->
        </template>
        <template v-else>
            <div
                class="alert alert--success"
                v-if="
                    (!lot.lotPriceRealised || lot.lotPriceRealised == 0) &&
                    showBiddingCompletedNotice
                "
            >
                <i class="fas fa-check-circle"></i>
                {{ $t('auction_ended') }}
            </div>
        </template>
    </div>
</template>

<script>
import { validateBid } from '../lib/Validation';
import LeavingOverlay from './LeavingOverlay.vue';

export default {
    name: 'BidLot',
    components: {
        LeavingOverlay,
    },
    data() {
        return {
            type: '',
            content: '',
            overlay: false,
            amount: null,
            formattedAmount: null,
        };
    },
    props: {
        lot: {
            type: Object,
            required: true,
        },
        showBidText: {
            type: Boolean,
            default: false,
        },
        detailStyling: {
            type: Boolean,
            default: false,
        },
        showBiddingCompletedNotice: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        canBid: function () {
            let frontendBidding = parseInt(this.lot.frontendBiddingActive, 10);
            return frontendBidding;
        },
    },
    methods: {
        formatInput() {
            let input = this.formattedAmount.replace(/[^0-9]/g, '');
            this.formattedAmount = input.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
            this.amount = parseInt(input, 10);
        },
        validateBid,
        bidLot() {
            
            const { type, content } = this.validateBid(
                this.amount,
                this.lot.lotStartingPrice
            );

            this.type = type;
            this.content = content;

            if (type === 'success') {
                const lotBid = {
                    lotId: this.lot.lotId,
                    increase: 0,
                    bid: this.amount,
                };

                this.storeBid(lotBid, this.lot.auctionId);

                this.formattedAmount = null;
            }
            this.$emit('bid-placed', {
                type: this.type,
                content: this.content,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.bid-lot-input {
    width: 100%;
    padding: 0;
}
button {
    margin-left: 0;
    margin-top: 0;
    span {
        display: inline-block;
        margin-left: 10px;
    }
    .fa-gavel {
        margin: 0 -0.2em 0 0;
    }
}
.btnsmall {
    .fa-gavel {
        margin: 0 -0.1em;
    }
}
.margin-top {
    display: inline-block;
    margin-top: 10px;
}

.fullwidth {
    width: 100%;
}

.alert {
    width: auto;
}

input[type='text'] {
    width: calc(100% - 34px);
    margin: 0;
}

.hasText input[type='text'] {
    width: calc(100% - 126px);
}
</style>
