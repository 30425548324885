export default {
    namespaced: true,
    state: {
        path: null,
        fn: null,
        args: null,
        component: null,
    },
    getters: {
        task: state => component => {
            if (
                typeof component === 'undefined' ||
                state.component === component
            ) {
                return {
                    path: state.path,
                    fn: state.fn,
                    args: state.args,
                    component: state.component,
                };
            } else {
                return {
                    path: null,
                    fn: null,
                    args: null,
                    component: null,
                };
            }
        },
    },
    mutations: {
        setTask(state, payload) {
            /* eslint-disable-next-line no-console */
            //console.log('set task');
            state.path = payload.path + '?runtask';
            state.fn = payload.fn;
            state.args = payload.args;
            state.component = payload.component;
        },
        unsetTask(state) {
            /* eslint-disable-next-line no-console */
            //console.log('unset task');
            state.path = null;
            state.fn = null;
            state.args = null;
            state.component = null;
        },
    },
};
