<template>
    <div class="lot-list-component">
        <pagination
            ref="PaginationTop"
            :hasPerPageSelect="false"
            @page-change="fetchLots()"
        />

        <router-link
            v-if="company.companyName && (!loading || items.length > 0)"
            class="company"
            :to="{
                name: 'page.companyDetail',
                params: { companySlug: company.companySlug },
            }"
        >
            <company-image
                v-if="company.companyId"
                :alt="company.companyLogoAlt"
                :title="company.companyLogoTitle"
                :companyId="company.companyId"
                :auctionId="auctionId"
                class="hidden-below-md"
            ></company-image>
        </router-link>

        <div v-if="items.length === 0 && !loading" class="no_results">
            {{ $t('results_empty') }}
        </div>
        <loader ref="loader"></loader>
        <template v-if="!resultGrouping">
            <div class="lot-list-wrapper">
                <div :class="'lot-list ' + this.displayMode">
                    <lot-list-item
                        v-for="lot in items"
                        :key="lot.lotId"
                        :lot="lot"
                        :showImage="showImage"
                        :showLotNr="showLotNr"
                        :showDescription="showDescription"
                        :showCompany="showCompany"
                        :showPrebidDate="isSearch"
                        :showAuction="showAuction"
                        :showStartingPrice="showStartingPrice"
                        :showEstimatePrice="showEstimatePrice"
                        :showResultPrice="showResultPrice"
                        :showDetailsButton="showDetailsButton"
                        :showBidding="showBidding"
                        :useImageFallback="!lot.hasImage"
                    ></lot-list-item>
                </div>
            </div>
        </template>

        <template v-if="resultGrouping">
            <div
                class="lot-list-wrapper"
                v-for="item in items"
                :key="item.auctionId"
            >
                <div class="auctionBar">
                    <strong
                        ><span>{{ item.companyName }}</span> –
                        {{ item.auctionName }}
                        <span class="auctionDate"
                            >{{ $t('date_end') }}:
                            {{ item.auctionEnd | dateFormater }}</span
                        ></strong
                    >
                </div>

                <div :class="'lot-list ' + displayMode">
                    <lot-list-item
                        v-for="lot in item.lots"
                        :key="lot.lotId"
                        :lot="lot"
                        :showImage="showImage"
                        :showLotNr="showLotNr"
                        :showDescription="showDescription"
                        :showCompany="showCompany"
                        :showPrebidDate="isSearch"
                        :showAuction="showAuction"
                        :showStartingPrice="showStartingPrice"
                        :showEstimatePrice="showEstimatePrice"
                        :showResultPrice="showResultPrice"
                        :showDetailsButton="showDetailsButton"
                        :showBidding="showBidding"
                        :useImageFallback="!lot.hasImage"
                    ></lot-list-item>
                </div>
            </div>
        </template>

        <pagination
            v-show="items.length > 0"
            ref="PaginationBottom"
            :hasPerPageSelect="false"
            @page-change="fetchLots()"
            @pagination-clicked="$emit('pagination-clicked')"
        />
    </div>
</template>

<script>
import Loader from './Loader';
import LotListItem from './LotListItem.vue';
import CompanyImage from './CompanyImage.vue';
import Pagination from './Pagination';
import { getLots } from '../lib/apis';
import { getWatchListIds } from '../lib/apis';
import { getDay, getMonth, ISODATE } from '../lib/date';

export default {
    name: 'LotList',
    computed: {
        isSearch() {
            return this.$route.name == 'page.lots' ||
                this.$route.name == 'page.companyLots'
                ? true
                : false;
        },
    },
    props: {
        company: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
        auctionId: {
            type: Number,
            required: false,
        },
        searchQuery: {
            type: Object,
            default: function () {
                return {};
            },
        },
        sortOn: {
            type: String,
            default: 'lot_number',
        },
        sortOrder: {
            type: String,
            default: 'asc',
        },
        resultsPerPage: {
            type: Number,
            default: 12,
        },
        displayMode: {
            type: String,
            default: 'large',
        },
        searchDomain: {
            type: String,
        },
        resultGrouping: {
            type: Boolean,
        },

        // View settings
        // Default shows all except company and result price

        showImage: {
            type: Boolean,
            required: false,
            default: true,
        },
        showLotNr: {
            type: Boolean,
            required: false,
            default: true,
        },
        showDescription: {
            type: Boolean,
            required: false,
            default: true,
        },
        showCompany: {
            type: Boolean,
            required: false,
            default: false,
        },
        showAuction: {
            type: Boolean,
            required: false,
            default: false,
        },
        showStartingPrice: {
            type: Boolean,
            required: false,
            default: true,
        },
        showEstimatePrice: {
            // if true will only show if estimate exists
            type: Boolean,
            required: false,
            default: true,
        },
        showResultPrice: {
            // if true will always show, either with a price or with ---
            type: Boolean,
            required: false,
            default: false,
        },
        showDetailsButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        showBidding: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        Loader,
        Pagination,
        LotListItem,
        CompanyImage,
    },
    data: function () {
        return {
            items: [],
            loading: false,
        };
    },
    mounted: function () {
        this.fetchLots();
    },
    methods: {
        hightlightSearchResults(terms) {
            var replaceSearchTerm = function (match) {
                return '[search-highlight]' + match + '[/search-highlight]';
            };

            if (this.resultGrouping === true) {
                this.items.forEach(function (group) {
                    group.lots.forEach(function (item) {
                        terms.forEach(function (term) {
                            var pattern = new RegExp('(' + term + ')', 'gi');
                            item.lotDescription = item.lotDescription.replace(
                                pattern,
                                replaceSearchTerm
                            );
                        });
                    });
                });
            } else {
                this.items.forEach(function (item) {
                    terms.forEach(function (term) {
                        var pattern = new RegExp('(' + term + ')', 'gi');
                        item.lotDescription = item.lotDescription.replace(
                            pattern,
                            replaceSearchTerm
                        );
                    });
                });
            }
        },
        fetchLots() {
            if (
                this.searchQuery.upcomingAuction == 1 &&
                typeof this.searchQuery.pastAuction === 'undefined'
            ) {
                this.searchQuery['start_from'] = getMonth(
                    this.$route.params.lang,
                    0,
                    ISODATE
                );
            }
            if (
                this.searchQuery.pastAuction == 1 &&
                typeof this.searchQuery.upcomingAuction === 'undefined'
            ) {
                this.searchQuery['end_to'] = getDay(
                    this.$route.params.lang,
                    -1,
                    ISODATE
                );
            }
            const data = {
                orderCol: this.sortOn,
                orderDirection: this.sortOrder,
                limit: this.$route.params.perPage,
                page: this.$route.params.page,
                groupByAuction: this.$route.query.groupByAuction,
                ...this.searchQuery,
            };

            const companySlug = this.$route.params.companySlug;

            const auctionId = this.$route.auctionId || this.auctionId;

            this.loading = true;
            this.$refs.loader.show();

            getLots({ companySlug, auctionId, data }, this.$route.params.lang)
                .then((lotsData) => {
                    this.items = lotsData.data.items;
                    this.hightlightSearchResults(lotsData.data.terms);
                    this.$refs.loader.hide();
                    this.loading = false;
                    this.$refs.PaginationTop.responseHelper(lotsData);
                    this.$refs.PaginationBottom.responseHelper(lotsData);

                    this.$emit('lots-fetched');

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );
                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });

                    // this is in here because otherwise call.cancel will not work because the URLs for lots and watchlist will alternate
                    getWatchListIds(this.$route.params.lang)
                        .then((response) => {
                            this.$store.commit(
                                'lotWatchlist/setWatches',
                                response.data
                            );
                        })
                        .catch((/* error */) => {
                            /* eslint-disable-next-line no-console */
                            //console.log("getWatchListIds:catch", error);
                            // ignore error
                        });
                })
                .catch((e) => {
                    if (e.response) {
                        if (e.response.status === 404) {
                            return this.$router.push(
                                `/${this.$route.params.lang}/404`
                            );
                        }
                    } else if (e.request) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                    this.genericApiErrorHandling(e);
                });
        },
    },
    watch: {
        '$route.params.companySlug': function () {
            this.$route.params.page = 1;
        },
        $route: function () {
            this.fetchLots();
        },
        sortOrder: function () {
            this.fetchLots();
        },
    },
};
</script>

<style lang="scss" scoped>
.company {
    display: block;
    cursor: pointer;
    border-top: 1px solid $grey-bg-light;
    border-bottom: 1px solid $grey-bg-light;
    overflow: hidden;
    picture {
        max-width: auto;
        width: 1200px;
    }
}

.no_results {
    text-align: center;
    margin-top: $mar-xl;
}

.auctionBar {
    border-bottom: 2px solid $grey-bg-dark;
    padding-bottom: $mar-sm;
    margin-bottom: $mar-xl;
    font-size: 18px;
    margin-top: 25px;
    .auctionDate {
        display: block;
        margin-top: 6px;
        @media (min-width: $md-size) {
            display: inline-block;
            float: right;
            margin-top: 4px;
        }
        font-size: 14px;
        color: $grey-bg-darker;
    }
}

.lot-list {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &.large {
        .lot-list-item {
            @media (min-width: $md-size) {
                width: calc(50% - #{ceil($mar-xl / 2) + 1});

                &:nth-child(odd) {
                    margin-right: $mar-xl;
                }
            }
        }
    }

    &.small {
        .lot-list-item {
            @media (min-width: $md-size) {
                width: calc(33% - #{ceil($mar-lg / 2)});
                margin-right: $mar-lg;

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    &.detail {
        .lot-list-item {
            @media (min-width: $md-size) {
                width: 100%;
            }
        }
    }
}
</style>
