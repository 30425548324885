<template>
    <div class="shipping-new">
        <div class="row">
            <div class="col col-lg-3">
                <input
                    type="text"
                    v-model="form.user_company"
                    :class="{ hasInput: form.user_company }"
                    :placeholder="$t('form_user_company')"
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-3">
                <select
                    required
                    v-model="form.user_title"
                    :class="{
                        hasInput: form.user_title,
                        hasError: errors.user_title,
                    }"
                >
                    <option value selected disabled>
                        {{ $t('form_user_title') }}
                    </option>
                    <option value="">---</option>
                    <option value="dr">{{ $t('form_user_dr') }}</option>
                    <option value="prof">{{ $t('form_user_prof') }}</option>
                    <option value="profDr">{{ $t('form_user_profDr') }}</option>
                </select>
            </div>
            <div class="col push-lg-3 col-lg-4" v-if="errors.user_title">
                <span class="alert alert--error">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ $t(this.errors.user_title) }}
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col col-lg-3">
                <input
                    type="text"
                    v-model="form.user_first_name"
                    :placeholder="$t('form_user_first_name')"
                    :class="{
                        hasInput: form.user_first_name,
                        hasError: errors.user_first_name,
                    }"
                    required
                    @blur="validateFieldEmpty('user_first_name')"
                />
            </div>
            <div class="col col-lg-3">
                <input
                    type="text"
                    v-model="form.user_last_name"
                    :placeholder="$t('form_user_last_name')"
                    :class="{
                        hasInput: form.user_last_name,
                        hasError: errors.user_last_name,
                    }"
                    required
                    @blur="validateFieldEmpty('user_last_name')"
                />
            </div>
            <div
                class="col col-lg-4"
                v-if="errors.user_first_name || errors.user_last_name"
            >
                <span class="alert alert--error">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ $t(errors.user_first_name)
                    }}<span
                        v-if="errors.user_first_name && errors.user_last_name"
                        >,
                    </span>
                    {{ $t(errors.user_last_name) }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-6">
                <input
                    type="text"
                    v-model="form.user_street"
                    :placeholder="$t('form_user_street')"
                    :class="{
                        hasInput: form.user_street,
                        hasError: errors.user_street,
                    }"
                    required
                    @blur="validateFieldEmpty('user_street')"
                />
            </div>
            <div class="col col-lg-4" v-if="errors.user_street">
                <span class="alert alert--error">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ $t(errors.user_street) }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-6">
                <input
                    type="text"
                    v-model="form.user_address2"
                    :class="{ hasInput: form.user_address2 }"
                    :placeholder="$t('form_user_address2')"
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-2">
                <input
                    type="text"
                    v-model="form.user_zip"
                    :placeholder="$t('form_user_zip')"
                    :class="{
                        hasInput: form.user_zip,
                        hasError: errors.user_zip,
                    }"
                    required
                    @blur="validateFieldEmpty('user_zip')"
                />
            </div>
            <div class="col col-lg-4">
                <input
                    type="text"
                    v-model="form.user_city"
                    :placeholder="$t('form_user_city')"
                    :class="{
                        hasInput: form.user_city,
                        hasError: errors.user_city,
                    }"
                    required
                    @blur="validateFieldEmpty('user_city')"
                />
            </div>
            <div
                class="col col-lg-4"
                v-if="errors.user_city || errors.user_zip"
            >
                <span class="alert alert--error">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ $t(errors.user_zip)
                    }}<span v-if="errors.user_city && errors.user_zip">, </span>
                    {{ $t(errors.user_city) }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col col-lg-3">
                <select
                    required
                    v-model="form.user_country"
                    :class="{
                        hasInput: form.user_country,
                        hasError: errors.user_country,
                    }"
                    @blur="validateFieldEmpty('user_country')"
                >
                    <option value selected disabled hidden>
                        {{ $t('form_user_country') }}
                    </option>
                    <option
                        v-for="(country, index) in countries"
                        :key="index"
                        :value="country.code"
                    >
                        {{ country.name }}
                    </option>
                </select>
            </div>
            <div class="col col-lg-3">
                <select
                    v-if="
                        form.user_country === 'USA' ||
                        form.user_country === 'CAN'
                    "
                    v-model="form.user_state"
                    :class="{
                        hasInput: form.user_state,
                        hasError: errors.user_state,
                    }"
                    @blur="
                        form.user_country === 'USA' ||
                        form.user_country === 'CAN'
                            ? validateFieldEmpty('user_state')
                            : ''
                    "
                >
                    <option value selected disabled hidden>
                        {{ $t('form_user_state') }}
                    </option>
                    <option
                        v-for="(state, index) in states"
                        :key="index"
                        :value="state.code"
                    >
                        {{ state.name }}
                    </option>
                </select>
            </div>
            <div
                class="col col-lg-4"
                v-if="errors.user_state || errors.user_country"
            >
                <span class="alert alert--error">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ $t(errors.user_country)
                    }}<span v-if="errors.user_state && errors.user_country"
                        >,
                    </span>
                    {{ $t(errors.user_state) }}
                </span>
            </div>
        </div>
        <div class="row" v-if="errors.duplicate">
            <div class="col col-lg-6">
                <span class="alert alert--error">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ errors.duplicate }}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col push-lg-4 col-lg-2">
                <button class="button button-blue" @click="addNewAddress()">
                    {{ $t('add') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { postNewShippingAddress } from '../lib/apis';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ShippingAddressNew',
    data() {
        return {
            form: {
                user_title: '',
                user_first_name: '',
                user_last_name: '',
                user_street: '',
                addition: '',
                user_zip: '',
                user_city: '',
                user_state: '',
                user_country: '',
            },
            errors: {},
        };
    },
    computed: {
        ...mapGetters({
            countries: 'countries/getCountriesWithPreferred',
            states: 'countries/getStates',
        }),
    },
    methods: {
        addNewAddress() {
            this.validateNewAddress();

            if (Object.keys(this.errors).length === 0) {
                const data = this.createAddressData(this.form);

                postNewShippingAddress(data, this.$route.params.lang)
                    .then((response) => {
                        if (response.data.success) {
                            this.$emit(
                                'address-added',
                                response.data.addressId
                            );
                        } else {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch((e) => {
                        this.genericApiErrorHandling(e);
                    });
            }
        },
        createAddressData(data) {
            let addressData = {
                user_company: data.user_company,
                user_title: data.user_title,
                user_first_name: data.user_first_name,
                user_last_name: data.user_last_name,
                user_address: data.user_street,
                user_address2: data.user_address2,
                user_zip: data.user_zip,
                user_city: data.user_city,
                user_country: data.user_country,
                user_state: data.user_state,
            };
            return addressData;
        },
        validateFieldEmpty(fieldName) {
            if (this.form[fieldName] === '' || !this.form[fieldName]) {
                Vue.set(this.errors, fieldName, `form.errors.${fieldName}`);
            } else {
                Vue.delete(this.errors, fieldName);
            }
        },
        validateNewAddress() {
            this.validateFieldEmpty('user_first_name');
            this.validateFieldEmpty('user_last_name');
            this.validateFieldEmpty('user_street');
            this.validateFieldEmpty('user_zip');
            this.validateFieldEmpty('user_city');
            this.validateFieldEmpty('user_country');

            if (
                this.form.user_country === 'US' ||
                this.form.user_country === 'CA'
            ) {
                if (this.form.user_state === '') {
                    Vue.set(
                        this.errors,
                        'user_state',
                        'form.errors.user_state'
                    );
                } else {
                    Vue.delete(this.errors, 'user_state');
                }
            } else {
                Vue.delete(this.errors, 'user_state');
            }
        },
    },
};
</script>

<style scoped></style>
