<template>
    <div id="page-highlight-all">
        <h1>{{ $t('highlights') }}</h1>
        <breadcrumb>{{ $t('highlights') }}</breadcrumb>
        <div class="highlight-list">
            <div class="lot-list large">
                <loader ref="loader"></loader>
                <div v-for="auction in auctions" :key="auction.auctionId">
                    <div class="auctionBar">
                        <strong
                            ><span>{{ auction.companyName }}</span> –
                            {{ auction.auctionTitle }}
                            <span class="auctionDate"
                                >{{ $t('date_end') }}:
                                {{ auction.auctionEnd | dateFormater }}</span
                            ></strong
                        >
                    </div>
                    <lot-list-item
                        v-for="lot in auction.lots"
                        :key="lot.lotId"
                        :lot="lot"
                        :showImage="true"
                        :showLotNr="true"
                        :showDescription="true"
                        :showCompany="false"
                        :showAuction="false"
                        :showStartingPrice="true"
                        :showEstimatePrice="true"
                        :showResultPrice="false"
                        :showDetailsButton="true"
                        :showBidding="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '../components/Loader';
import Breadcrumb from '../components/Breadcrumb.vue';
import LotListItem from '../components/LotListItem.vue';
import { getHighlights, getWatchListIds } from '../lib/apis';

export default {
    name: 'pageHighlightAll',
    components: {
        Breadcrumb,
        LotListItem,
        Loader,
    },
    data: function () {
        return {
            auctions: [],
        };
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$refs.loader.show();

            // Fetch auctions and nested lots.
            getHighlights(this.$route.params.lang)
                .then((response) => {
                    this.auctions = response.data.auctions;
                    this.$refs.loader.hide();

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );

                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    this.$refs.loader.hide();
                    this.genericApiErrorHandling(e);
                });

            // Fetch data for watched icon.
            getWatchListIds(this.$route.params.lang)
                .then((response) => {
                    this.$store.commit(
                        'lotWatchlist/setWatches',
                        response.data
                    );
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.highlight-list {
    margin-top: $mar-xl;
}
.group.view {
    text-align: right;
    margin-bottom: $mar-xl;
    .icon {
        display: inline-block;
        color: $text-color;
        padding: 5px 2px;
        margin-left: 5px;

        &.active {
            color: $main-color;
        }
    }
}
.lot-list {
    margin-top: $mar-xl;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .auctionBar {
        border-bottom: 2px solid $grey-bg-dark;
        padding-bottom: $mar-sm;
        margin-bottom: $mar-xl;
        font-size: 18px;
        margin-top: 25px;
        .auctionDate {
            display: block;
            margin-top: 6px;
            @media (min-width: $md-size) {
                display: inline-block;
                float: right;
                margin-top: 4px;
            }
            font-size: 14px;
            color: $grey-bg-darker;
        }
    }

    .lot-list-item {
        display: inline-block;
        @media (min-width: $md-size) {
            width: calc(50% - #{ceil($mar-xl / 2) + 1});

            &:nth-child(2n) {
                margin-right: $mar-xl;
            }
        }
    }
}
</style>
