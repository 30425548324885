<template>
    <div class="auction-calendar-page">
        <ad-banner
            v-if="getAds['calendar-top-banner-wide']"
            :ad="getAds['calendar-top-banner-wide']"
            :visible="['desktop']"
        />

        <ad-banner
            v-if="getAds['calendar-top-banner-medium']"
            :ad="getAds['calendar-top-banner-medium']"
            :visible="['tablet']"
        />

        <ad-banner
            v-if="getAds['calendar-top-banner-narrow']"
            :ad="getAds['calendar-top-banner-narrow']"
            :visible="['mobile']"
        />

        <div class="content">
            <h1>{{ $t('auction_calendar') }}</h1>
            <breadcrumb>{{ $t('auction_calendar') }}</breadcrumb>
            <auction-list-tabs
                :filters="filters"
                :allTabLongName="true"
            ></auction-list-tabs>
            <div
                class="auction-calendar-month"
                v-for="auctionKey in Object.keys(auctionObject).sort()"
                :key="auctionKey"
            >
                <h2>&mdash; {{ getMonthYearName(auctionObject[auctionKey][0].calendarStart) }} &mdash;</h2>
                <auction-calendar-item
                    v-for="(auction, i) in auctionObject[auctionKey]"
                    :auction="auction"
                    :key="i"
                ></auction-calendar-item>
            </div>
            <div
                v-if="
                    visibleAuctions.length >=
                    $global.showCounts.currentCalendarItems
                "
                @click="
                    $global.showCounts.currentCalendarItems =
                        $global.showCounts.currentCalendarItems +
                        itemsVisibleDefault
                "
                class="hidden-above-lg show-more"
            >
                <span>
                    {{ $t('auctions_more') }}
                    <i class="fas fa-caret-down"></i>
                </span>
            </div>
            <auction-list-tabs
                :filters="filters"
                :isBottom="true"
                :allTabLongName="true"
            ></auction-list-tabs>
        </div>
        <home-sidebar></home-sidebar>
    </div>
</template>

<script>
import HomeSidebar from '../layout/HomeSidebar';
import Breadcrumb from '../components/Breadcrumb.vue';
import AuctionListTabs from '../components/AuctionListTabs.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import AuctionCalendarItem from '../components/AuctionCalendarItem.vue';
import { PAGINATION_DEFAULTS, CALENDAR_ITEMS_VISIBLE } from '../config.js';
import { getAuctionCalendar } from '../lib/apis';
import { mapGetters } from 'vuex';
import AdBanner from '../components/AdBanner';
import { formatDate, YYYYMonth } from '../lib/date';

export default {
    components: {
        HomeSidebar,
        AuctionBanner,
        AuctionListTabs,
        Breadcrumb,
        AuctionCalendarItem,
        AdBanner,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_auctionscalendar'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_auctionscalendar'),
                },
            ],
        };
    },
    data: function () {
        return {
            auctions: [],
            visibleAuctions: [],
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            itemsVisibleDefault: CALENDAR_ITEMS_VISIBLE,
            filters: [],
        };
    },
    created: function () {
        this.fetchAuctions();
    },
    computed: {
        auctionObject() {
            return this.visibleAuctions.reduce((result, auction) => {
                var key = auction.calendarStart.substring(0,7);
                result[key] = result[key] || [];
                result[key].push(auction);
                return result;
            }, {});
        },
        ...mapGetters({ getAds: 'ads/getAds' }),
    },
    methods: {
        setVisibleAuctions() {
            const visibleAuctions = this.auctions.filter((auction, index) => {
                if (this.$global.isMobile) {
                    return index < this.$global.showCounts.currentCalendarItems;
                }
                return true;
            });

            this.visibleAuctions = visibleAuctions;
        },
        getMonthYearName: function (val) {
            return formatDate(this.$route.params.lang, val, YYYYMonth);
        },
        fetchAuctions() {
            getAuctionCalendar(this.$route.params.lang)
                .then((auctions) => {
                    this.auctions = auctions.data;
                    this.setVisibleAuctions();

                    this.$nextTick(() => {
                        // prerendering event after DOM update
                        document.dispatchEvent(
                            new Event('component-data-rendered')
                        );
                        // scroll page
                        this.$root.$emit('triggerScroll');
                    });
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
    watch: {
        '$global.isMobile'() {
            this.setVisibleAuctions();
        },
        '$global.showCounts.currentCalendarItems'() {
            this.setVisibleAuctions();
        },
    },
};
</script>

<style scoped lang="scss">
h2 {
    text-align: center;
    margin: $mar-2xl 0 $mar-xl;
}
</style>
