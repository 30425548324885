import { getCurrencyRates } from '../../lib/apis';

export const currencyTools = {
    computed: {
        currencyRates() {
            return this.$global.currencyRates;
        },
    },
    methods: {
        currencyConverter: function(amount, sourceCurrency, targetCurrency) {
            return (
                (amount / this.currencyRates.rates[sourceCurrency]) *
                this.currencyRates.rates[targetCurrency]
            );
        },
        currencyFormater: function(
            amount,
            currency = 'EUR' /*, locale = 'de-DE', displayAs = 'code'*/
        ) {
            amount = parseInt(amount);
            return (
                amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'") +
                ' ' +
                currency
            );
        },
        currencyMapper: function(currencysymbol) {
            const currencySymbolMap = {
                '₽': 'RUB',
                $: 'USD',
                '€': 'EUR',
                '¥': 'JPY',
                'CN¥': 'CNY',
                '฿': 'BTC',
                '£': 'GBP',
                Rp: 'IDR',
                kr: 'SEK',
                zł: 'PLN',
            };
            let currencyCode = currencySymbolMap[currencysymbol];
            if (typeof currencyCode != 'undefined' && currencyCode) {
                return currencyCode;
            } else {
                return currencysymbol;
            }
        },
        getConvertedValues: function(
            amount,
            sourceCurrency = 'EUR',
            removeSourceCurrency = true,
            targetCurrencies = ['EUR', 'GBP', 'CHF', 'USD' /*, 'JPY', 'CNY'*/]
        ) {
            amount = parseFloat(amount);
            var output = [];
            for (var value of targetCurrencies) {
                if (!removeSourceCurrency || sourceCurrency != value) {
                    output.push({
                        amount: this.currencyConverter(
                            amount,
                            sourceCurrency,
                            value
                        ),
                        currency: value,
                    });
                }
            }
            return output;
        },
        getCurrencyRatesDate: function() {
            return this.currencyRates.timestamp * 1000;
        },
        fetchCurrencyRates: function() {
            getCurrencyRates(this.$route.params.lang)
                .then(currencyRates => {
                    this.$global.currencyRates = currencyRates.data;
                })
                .catch(e => {
                    this.genericApiErrorHandling(e);
                });
        },
    },
};
