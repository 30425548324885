var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        typeof _vm.lot.frontendBiddingActive !== 'undefined' &&
        _vm.lot.lotPriceRealised !== -1
    )?_c('div',{staticClass:"bid-lot-input",class:{ hasText: _vm.showBidText && !_vm.$global.isTablet }},[(_vm.canBid)?[(_vm.lot.auctionAltBidLink == 0 || _vm.lot.auctionAltBidLink == 2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formattedAmount),expression:"formattedAmount"}],attrs:{"type":"text","placeholder":_vm.$t('bid_box')},domProps:{"value":(_vm.formattedAmount)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.bidLot()},"input":[function($event){if($event.target.composing){ return; }_vm.formattedAmount=$event.target.value},_vm.formatInput]}}):_vm._e(),(_vm.lot.auctionAltBidLink == 0 || _vm.lot.auctionAltBidLink == 2)?_c('button',{staticClass:"button button-for-input",class:{ btnsmall: !_vm.showBidText || _vm.$global.isTablet },on:{"click":function($event){return _vm.bidLot()}}},[_c('i',{staticClass:"fas fa-gavel"}),(_vm.showBidText && !_vm.$global.isTablet)?_c('span',[_vm._v(_vm._s(_vm.$t('bid_now')))]):_vm._e()]):_vm._e(),(_vm.lot.auctionAltBidLink == 1)?_c('a',{staticClass:"button fullwidth",class:{
                'button-grey button-blue-text': _vm.detailStyling == false,
                'button-blue': _vm.detailStyling == true,
            },attrs:{"href":_vm.lot.lotAltBidLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('bid_at_company')))]):_vm._e(),(_vm.detailStyling && _vm.lot.auctionAltBidLink == 2)?_c('a',{ref:"externalLink",staticClass:"button button-grey fullwidth margin-top",class:{
                'button-grey button-blue-text': _vm.detailStyling == false,
                'button-blue': _vm.detailStyling == true,
            },attrs:{"href":_vm.lot.lotAltBidLink,"target":"_blank"},on:{"click":function($event){$event.preventDefault();_vm.overlay = true}}},[_vm._v(_vm._s(_vm.$t('company_bid_there'))),_c('i',{staticClass:"fas fa-external-link-alt fa-sm"})]):_vm._e(),_c('leaving-overlay',{attrs:{"url":_vm.lot.lotAltBidLink,"shown":_vm.overlay,"type":"external-bidding"},on:{"closing":function($event){_vm.overlay = false}}})]:[(
                (!_vm.lot.lotPriceRealised || _vm.lot.lotPriceRealised == 0) &&
                _vm.showBiddingCompletedNotice
            )?_c('div',{staticClass:"alert alert--success"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v("\n            "+_vm._s(_vm.$t('auction_ended'))+"\n        ")]):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }