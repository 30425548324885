<template>
    <div
        v-if="
            typeof this.datashares !== 'undefined' &&
            Object.keys(this.datashares).length > 0
        "
    >
        <div class="row">
            <div class="col col-lg-6">
                <hr class="separator" />
            </div>
        </div>
        <h3>{{ $t('datashare_title') }}</h3>
        <div v-for="(datashare, key) in datashares" :key="datashare">
            <p>
                {{
                    $t('datashare_acceptance', [
                        $options.filters.dateFormater(datashare),
                    ])
                }}
            </p>
            <button :class="buttonClassString" @click="revokeDatashare(key)">
                {{ $t('datashare_revoke') }}
            </button>
        </div>
    </div>
</template>

<script>
import { getDatashares, deleteDatashare } from '../lib/apis';

export default {
    name: 'DatashareRevoke',
    props: {},
    data() {
        return {
            datashares: {},
            loading: false,
        };
    },
    methods: {
        revokeDatashare: function () {
            this.loading = true;
            deleteDatashare({}, this.$route.params.lang)
                .then(() => {
                    delete this.datashares['auex'];
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
    created: function () {
        getDatashares({}, this.$route.params.lang).then((result) => {
            this.datashares = result.data;
        });
    },
    computed: {
        buttonClassString: function () {
            if (this.loading) {
                return 'button button-dark-grey button-loading';
            } else {
                return 'button button-dark-grey';
            }
        },
    },
};
</script>

<style scoped lang="scss">
.separator {
    border: 0;
    border-top: 1px solid $grey-bg-dark;
    margin: #{2 * $mar-xl} 0;
}
</style>
