import Vue from '../../node_modules/vue/dist/vue.js';

Vue.filter('striphtml', function(value) {
    if (!value) return;
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = div.textContent || div.innerText || '';
    return text;
});

Vue.filter('renderBR', function(value) {
    if (!value) return;
    return value.replace('[br]', '<br>');
});

Vue.filter('removeBR', function(value) {
    if (!value) return;
    return value.replace('[br]', ' ');
});

Vue.filter('searchHighlights', function(value) {
    if (!value) return;
    value = value
        .split('[search-highlight]')
        .join('<span class="search-highlight">')
        .split('[/search-highlight]')
        .join('</span>');
    return value;
});
