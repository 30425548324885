<template>
    <div class="bidlist">
        <loader ref="loader"></loader>
        <div class="pagination-top-wrapper">
            <div class="sort">
                <label for>{{ $t('sort_by') }}:</label>

                <select v-model="sort" @change="handleChange()">
                    <option value="auctiontime">{{ $t('bid_end') }}</option>
                    <option value="bidamount">{{ $t('bid_amount') }}</option>
                </select>
                <a
                    class="icon"
                    :class="{ active: sortOrder === 'asc' }"
                    v-on:click="
                        sortOrder = 'asc';
                        handleChange();
                    "
                >
                    <i class="fas fa-long-arrow-alt-up"></i>
                </a>
                <a
                    class="icon"
                    :class="{ active: sortOrder === 'desc' }"
                    v-on:click="
                        sortOrder = 'desc';
                        handleChange();
                    "
                >
                    <i class="fas fa-long-arrow-alt-down"></i>
                </a>
            </div>

            <pagination
                ref="PaginationTop"
                @pagination-changed="fetchBiddings()"
                :hasPerPageSelect="!$global.isMobile"
                @pagination-clicked="$emit('pagination-clicked')"
                class="pagination-top"
            />
        </div>
        <bid-list-item
            v-for="auction in auctions"
            :key="auction.auctionId"
            :auction="auction"
        ></bid-list-item>

        <pagination
            ref="PaginationBottom"
            @pagination-changed="fetchBiddings()"
            :hasPerPageSelect="!$global.isMobile"
            @pagination-clicked="
                $emit('pagination-clicked');
                scrollToTop();
            "
        />
    </div>
</template>

<script>
import { getBiddings, getBiddingSheet } from '../lib/apis';

import BidListItem from '../components/BidListItem';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import { PAGINATION_DEFAULTS } from '../config.js';

export default {
    name: 'BidList',
    components: { BidListItem, Loader, Pagination, getBiddingSheet },
    props: {},
    data() {
        return {
            auctions: [],
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            apiParams: {},
            sort: 'auctiontime',
            sortOrder: 'desc',
        };
    },
    mounted: function () {
        if (this.$route.query.orderCol) {
            this.sort = this.$route.query.orderCol;
        }
        if (this.$route.query.orderDirection) {
            this.sortOrder = this.$route.query.orderDirection;
        }

        this.fetchBiddings(this.apiParams);
    },
    methods: {
        scrollToTop() {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = this.$el
                .querySelector('.pagination-top-wrapper')
                .getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top - 100 - 35;

            this.scrollTo({ to: offset });
        },
        fetchBiddings(data) {
            const dataMerged = {
                limit: this.$refs.PaginationTop.perPage,
                perPage: this.$refs.PaginationTop.perPage,
                page: this.$refs.PaginationTop.page,
                orderCol: this.sort,
                orderDirection: this.sortOrder,
                ...data,
            };

            this.$refs.loader.show();

            getBiddings(dataMerged, 1).then((response) => {
                this.auctions = response.data.items;

                this.$refs.PaginationTop.responseHelper(response);
                this.$refs.PaginationBottom.responseHelper(response);

                this.$refs.loader.hide();
            });
        },
        handleChange() {
            this.fetchBiddings(this.apiParams);
            this.pushNewURL({
                orderCol: this.sort,
                orderDirection: this.sortOrder,
            });
        },
        pushNewURL({ orderCol, orderDirection, page, perPage }) {
            let currentPage = page || this.$route.params.page;
            let currentPerPage = perPage || this.$route.params.perPage;

            const historyObj = {
                path: '',
                params: {
                    page: currentPage,
                    perPage: currentPerPage,
                },
                query: {
                    ...this.$route.query,
                    orderCol,
                    orderDirection,
                },
            };

            if (Object.keys(this.$route.query).length === 0) {
                this.$router.replace(historyObj);
            } else {
                this.$router.push(historyObj);
            }
        },
    },
    watch: {
        $route() {
            this.sort = this.$route.query.orderCol
                ? this.$route.query.orderCol
                : this.sort;
            this.sortOrder = this.$route.query.orderDirection
                ? this.$route.query.orderDirection
                : this.sortOrder;

            this.handleChange();
        },
    },
};
</script>

<style lang="scss">
.bidlist {
    min-height: 100px;
}

.pagination-top-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media (min-width: $lg-size) {
        justify-content: flex-end;
    }

    .sort {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        label {
            margin-right: 10px;
        }

        select {
            @media (max-width: $sm-size-max) {
                max-width: 160px;
                text-overflow: ellipsis;
            }
        }

        & > span {
            width: 50%;
        }

        .icon {
            display: inline-block;
            color: $text-color;
            padding: 5px 2px;
            margin-left: 5px;

            i {
                margin-left: 0;
            }

            &.active {
                color: $main-color;
            }
        }
    }

    .pagination-top {
        align-items: center;

        & > .pages-pagination,
        & > .entries-per-page {
            margin: 0 0 0 30px;
            @media (min-width: $lg-size) {
                margin: 0 0 0 40px;
            }
        }
    }
}
</style>
