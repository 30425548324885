<template>
    <div class="home">
        <ad-banner
            v-if="getAds['frontpage-top-banner-wide']"
            :ad="getAds['frontpage-top-banner-wide']"
            :visible="['desktop']"
        />

        <ad-banner
            v-if="getAds['frontpage-top-banner-medium']"
            :ad="getAds['frontpage-top-banner-medium']"
            :visible="['tablet']"
        />

        <ad-banner
            v-if="getAds['frontpage-top-banner-narrow']"
            :ad="getAds['frontpage-top-banner-narrow']"
            :visible="['mobile']"
        />

        <div class="content">
            <section-header
                :title="$t('auctions_upcoming')"
                :spacer="false"
                size="h1"
            ></section-header>

            <auction-list></auction-list>
        </div>

        <home-sidebar></home-sidebar>

        <div class="content">
            <section-header
                :title="$t('sixbid_companies')"
                :description="$t('sixbid_partnership')"
            ></section-header>

            <div class="auction-company-wrapper row">
                <div
                    class="col col-xs-6 col-sm-4 col-lg-3"
                    v-for="company in companies"
                    v-bind:key="company.companyId"
                >
                    <div class="companyCard" ref="card">
                        <div class="companyCard__front">
                            <company-image
                                v-if="company.companyId"
                                :alt="company.companyLogoAlt"
                                :title="company.companyLogoTitle"
                                :companyId="company.companyId"
                                isFrontPage="true"
                            ></company-image>
                        </div>
                        <div class="companyCard__back">
                            <div class="companyCard__content">
                                {{ company.companyNameShort }}
                                <br />({{
                                    company.companyCountry | countryName
                                }})
                                <span class="companyCard__tel"
                                    >{{ $t('phone') }}
                                    {{
                                        company.companyContactPhoneNumber
                                    }}</span
                                >
                            </div>
                            <div class="companyCard__links">
                                <a
                                    :href="`mailto:${company.companyContactEmail}`"
                                    >{{ $t('email') }}</a
                                >
                                <router-link
                                    :to="{
                                        name: 'page.companyDetail',
                                        params: {
                                            companySlug: company.companySlug,
                                        },
                                    }"
                                    >{{ $t('details') }}</router-link
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <router-link
                    :to="{ name: 'page.companyList' }"
                    class="link show-all-auctioneers"
                    >{{ $t('companies_show') }}</router-link
                >
            </div>

            <div class="slots">
                <div class="col col-xs-6 col-sm-4 col-lg-3">
                    <ad-banner
                        v-if="getAds['Content-Footer-1']"
                        :ad="getAds['Content-Footer-1']"
                    />
                </div>
                <div class="col col-xs-6 col-sm-4 col-lg-3">
                    <ad-banner
                        v-if="getAds['Content-Footer-2']"
                        :ad="getAds['Content-Footer-2']"
                    />
                </div>
                <div class="col col-xs-6 col-sm-4 col-lg-3">
                    <ad-banner
                        v-if="getAds['Content-Footer-3']"
                        :ad="getAds['Content-Footer-3']"
                    />
                </div>
                <div class="col col-xs-6 col-sm-4 col-lg-3">
                    <ad-banner
                        v-if="getAds['Content-Footer-4']"
                        :ad="getAds['Content-Footer-4']"
                    />
                </div>
            </div>

            <div class="spacer"></div>
        </div>
    </div>
</template>

<script>
import SectionHeader from '../components/SectionHeader';
import SidebarBox from '../components/SidebarBox';
import AdBanner from '../components/AdBanner';
import AuctionList from '../components/AuctionList';
import NewsList from '../components/NewsList';
import CollectionAreas from '../components/CollectionAreas';
import CompanyImage from '../components/CompanyImage.vue';
import HomeSidebar from '../layout/HomeSidebar';
import { getPartnerCompanies } from '../lib/apis';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            companies: [],
            ads: {},
        };
    },
    components: {
        SectionHeader,
        SidebarBox,
        AuctionList,
        NewsList,
        CollectionAreas,
        HomeSidebar,
        CompanyImage,
        AdBanner,
    },
    metaInfo() {
        return {
            titleTemplate: this.$t('meta_title_home'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('meta_description_home'),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({ getAds: 'ads/getAds' }),
    },
    mounted() {
        this.$root.$on('update:language', () => {
            this.$forceUpdate();
        });

        getPartnerCompanies(this.$route.params.lang).then((response) => {
            this.companies = response.data;
        });
    },
};
</script>

<style scoped lang="scss">
.spacer {
    margin-top: 80px;
}
.auctions-content {
    margin-top: 15px;
}

.auction-company-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .link {
        margin: 0 auto;
        clear: both;
        display: block;
        width: 100%;
        text-align: center;
    }
}

.slots {
    margin-top: $mar-lg;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.show-all-auctioneers.link {
    margin-top: $mar-lg;
}

.companyCard {
    position: relative;
    width: 100%;
    height: 120px;
    perspective: 800px;
    cursor: pointer;

    @media (min-width: $lg-size) {
        height: 150px;
    }

    &__front,
    &__back {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: transform 1s;
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }

    &__front {
        background: white;
        border: 1px solid $grey-bg;
        display: flex;
        justify-content: center;
        align-items: center;

        img,
        picture {
            display: block;
            height: auto;
            width: 90%;
            margin: 0 auto;
        }
    }

    &__back {
        background: $main_color;
        color: white;
        transform: rotateY(180deg);
    }

    &__content {
        padding: $pad-md;
    }

    &__links {
        position: absolute;
        width: 100%;
        bottom: 0;

        a {
            color: white;
            display: inline-block;
            width: 50%;
            line-height: 16px;
            padding: $pad-md $pad-sm;
            text-align: center;

            border-top: 1px solid white;

            &:first-child {
                border-right: 1px solid white;
            }
        }
    }

    &__tel {
        display: none;

        @media (min-width: $lg-size) {
            margin-top: $mar-md;
            display: inline-block;
        }
    }

    &:hover {
        .companyCard__front {
            transform: rotateY(-180deg);
        }
        .companyCard__back {
            transform: rotateY(0);
        }
    }
}
</style>
