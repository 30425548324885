<template>
    <div class="news-list-item clearfix">
        <h4 v-html="newsHeadline"></h4>
        <p
            :key="languageKey"
            v-html="
                $options.filters.dateLongFormater(newsDate * 1000) +
                ' – ' +
                newsText
            "
        ></p>
        <a :href="newsHref" target="_blank" class="button button-blue">{{
            $t('read_more')
        }}</a>
    </div>
</template>

<script>
export default {
    name: 'NewsListItem',
    data: function () {
        return {
            languageKey: '',
        };
    },
    props: {
        newsHeadline: {
            type: String,
            required: true,
        },
        newsHref: {
            type: String,
            required: true,
        },
        newsText: {
            type: String,
            required: true,
        },
        newsDate: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.$root.$on('update:language', (lang) => {
            this.languageKey = lang;
            this.$forceUpdate();
        });
    },
};
</script>
<style lang="scss">
.news-list-item {
    h4 {
        margin: 0;
    }

    &:nth-child(2) {
        margin-top: 40px;
    }

    a + #image {
        display: none;
    }
}
</style>
