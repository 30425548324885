<template>
    <div class="company-detail">
        <auction-banner :companyId="company.companyId"></auction-banner>
        <div class="content">
            <p class="style-like-h1">
                {{ $t('companies_sixbid') }}
            </p>
            <breadcrumb>
                <router-link :to="{ name: 'page.companyList' }">{{
                    $t('companies')
                }}</router-link>
                / {{ company.companyName }}
            </breadcrumb>
            <h1 class="style-like-h2">{{ company.companyName }}</h1>
            <p>{{ company.companyDescription }}</p>
            <div class="row">
                <div class="col col-lg-6">
                    <h3>{{ $t('contact_data') }}</h3>
                    <div class="address">
                        <div>
                            <span>{{ $t('address') }}:</span>

                            <span>
                                {{ company.companyStreet }}
                                <br />
                                {{ company.companyZip }}
                                {{ company.companyCity }}
                                <br />
                                {{ company.companyCountry | countryName }}
                            </span>
                        </div>
                        <div>
                            <strong>{{ $t('telephone') }}:</strong>
                            <strong
                                ><a
                                    class="tel-link"
                                    :href="
                                        'tel:' +
                                        company.companyContactPhoneNumber
                                    "
                                    >{{ company.companyContactPhoneNumber
                                    }}<span>&#xf095;</span></a
                                ></strong
                            >
                        </div>
                        <div>
                            <span>{{ $t('telefax') }}:</span>
                            {{ company.companyContactFaxNumber }}
                        </div>
                        <div>
                            <span>{{ $t('email') }}:</span>
                            <a
                                :href="`mailto:${company.companyContactEmail}`"
                                >{{ company.companyContactEmail }}</a
                            >
                        </div>
                        <div>
                            <span>{{ $t('company_url') }}:</span>
                            <a :href="company.companyUrl" target="_blank">{{
                                company.companyUrl
                            }}</a>
                        </div>
                        <div>
                            <span></span>

                            <a
                                :href="`${vcfAPI}/vcard-export/company/${company.companyId}`"
                                >{{ $t('contact_save') }}</a
                            >
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6">
                    <div v-if="!$global.isMobile">
                        <iframe
                            class="googlemap cmplazyload"
                            v-if="company.companyGoogleMaps"
                            src=""
                            title="googlemaps"
                            style="border: 0"
                            allowfullscreen
                            data-cmp-preview="1"
                            data-cmp-vendor="s1104"
                            :data-cmp-src="company.companyGoogleMaps"
                        ></iframe>
                    </div>
                </div>
            </div>

            <lot-search
                :companyName="company.companyName"
                :size="searchSize"
                v-if="!$global.isMobile"
                :hasCompanyContext="true"
            ></lot-search>

            <h3 v-if="company.companySlug && !$global.isMobile">
                {{ $t('company_auctions') }} {{ company.companyName }}
            </h3>
            <p v-if="company.companySlug && !$global.isMobile">
                {{ $t('company_auctions_registered') }}
            </p>
            <auction-list
                v-if="company.companySlug && !$global.isMobile"
                :hasTabs="false"
                :companySlug="company.companySlug"
            ></auction-list>
        </div>
        <home-sidebar v-if="!$global.isMobile"></home-sidebar>
    </div>
</template>

<script>
import AuctionList from '../components/AuctionList.vue';
import LotSearch from '../components/LotSearch.vue';
import Breadcrumb from '../components/Breadcrumb.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import HomeSidebar from '../layout/HomeSidebar.vue';
import { getCompanyDetails } from '../lib/apis';

export default {
    name: 'Company_Detail_Page',
    components: {
        AuctionList,
        AuctionBanner,
        LotSearch,
        Breadcrumb,
        HomeSidebar,
    },
    data: function () {
        return {
            company: {},
        };
    },
    computed: {
        vcfAPI() {
            return `${process.env.VUE_APP_GLOBAL_API_URL}`;
        },
        searchSize() {
            if (this.$global.isMobile || this.$global.isTablet) return 'small';
            return 'medium';
        },
    },
    created: function () {
        getCompanyDetails(
            this.$route.params.companySlug,
            this.$route.params.lang
        )
            .then((response) => {
                this.company = response.data;

                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
    },
};
</script>

<style scoped lang="scss">
.company-list-item {
    background-color: lightgray;
}

.header {
    height: 100px;

    @media (min-width: $md-size) {
        margin: #{-1 * $pad-xl} 0 $pad-xl 0;
    }
}

.header-grey {
    background: url('/assets/img/header-grey.png');
}

.googlemap {
    width: 100%;
    height: 240px;
}

.address {
    div {
        margin-top: $mar-sm;
        display: flex;
        flex-wrap: wrap;

        & > :nth-child(1) {
            min-width: 80px;
            width: 35%;
        }
    }
}

.lot-search-component {
    margin-top: $mar-xl;
}
</style>
