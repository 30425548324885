<template>
    <div class="zoomer-box" ref="wrapper">
        <div class="image-container">
            <img loading="lazy" :src="image" alt="zoom image" ref="image" width="1024" height="512" />

            <div
                v-show="!$global.isMobile"
                class="overlay-icon"
                :class="{ active: zoomActive }"
                ref="overlayicon"
            >
                <div class="overlay-icon__icon">
                    <i class="fas fa-search"></i>
                </div>
            </div>
            <div v-show="!$global.isMobile" class="overlay" ref="overlay"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Zoomer',
    props: {
        image: {
            type: String,
            required: true,
        },
        zoom: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            zoomActive: false,
        };
    },
    methods: {
        init() {
            let self = this,
                check = new Image(),
                imageWidth = this.$refs.image.clientWidth;

            check.onload = function () {
                if (this.width <= imageWidth) {
                    self.deactivate();
                } else {
                    self.activate();
                }
            };

            check.src = this.zoom;
        },
        deactivate() {
            this.$refs.overlay.parentNode.removeChild(this.$refs.overlay);
            this.$refs.overlayicon.parentNode.removeChild(
                this.$refs.overlayicon
            );
            this.$refs.imagelink.parentNode.removeChild(this.$refs.imagelink);
            this.$refs.wrapper.classList.remove('active');
        },
        activate() {
            this.$refs.overlay.style.backgroundImage = 'url(' + this.zoom + ')';

            this.$refs.wrapper.classList.add('active');

            this.$refs.image.removeEventListener('click', this.clickOverlay);
            this.$refs.image.addEventListener('click', this.clickOverlay);

            this.$refs.image.removeEventListener(
                'mouseleave',
                this.clickOverlay
            );
            this.$refs.image.addEventListener('mouseleave', this.leaveOverlay);

            this.$refs.image.removeEventListener(
                'mousemove',
                this.moveOnOverlay
            );
            this.$refs.image.addEventListener(
                'mousemove',
                this.moveOnOverlay,
                false
            );
        },
        clickOverlay() {
            if (this.zoomActive) {
                this.zoomActive = false;
                this.$refs.overlay.classList.remove('overlay--visible');
                this.$refs.wrapper.classList.remove('zoomer-box--active');
            } else {
                this.zoomActive = true;
                this.$refs.overlay.classList.add('overlay--visible');
                this.$refs.wrapper.classList.add('zoomer-box--active');
            }
        },
        leaveOverlay() {
            this.$refs.overlay.classList.remove('overlay--visible');
            this.$refs.wrapper.classList.remove('zoomer-box--active');
            this.zoomActive = false;
        },
        moveOnOverlay(e) {
            const x =
                e.pageX -
                window.pageXOffset -
                this.$refs.image.getBoundingClientRect().left;
            const y =
                e.pageY -
                window.pageYOffset -
                this.$refs.image.getBoundingClientRect().top;
            const imgWidth = this.$refs.image.width;
            const imgHeight = this.$refs.image.height;
            const overlayW = this.$refs.overlay.getBoundingClientRect().width;
            const overlayH = this.$refs.overlay.getBoundingClientRect().height;
            let xperc = (x / imgWidth) * 130 - 15;
            let yperc = (y / imgHeight) * 130 - 15;

            /* if (x > (.01 * imgWidth)) {
                    xperc += (.15 * xperc);
                }

                if (y >= (.01 * imgHeight)) {
                    yperc += (.15 * yperc);
                } */

            this.$refs.overlay.style.backgroundPositionX = xperc + '%';
            this.$refs.overlay.style.backgroundPositionY = yperc + '%';

            this.$refs.overlay.style.left = x - overlayW / 2 + 'px';
            this.$refs.overlay.style.top = y - overlayH / 2 + 'px';
        },
    },
    mounted() {
        setTimeout(
            function () {
                this.init();
            }.bind(this),
            100
        );
    },
    watch: {
        image: function () {
            this.init();
        },
    },
};
</script>

<style lang="scss">
.image-savelink {
    display: inline-block;
    text-align: center;
    padding-top: $pad-xl;
    width: 100%;
    position: absolute;
    bottom: -20px;
    left: 0;
}
.zoomer-box {
    position: relative;
    height: auto;
    max-height: 100%;

    &--active {
        cursor: crosshair;
    }

    .image-container {
        display: inline-block;
        position: relative;
    }

    img {
        height: auto;
        max-height: 60vh;
    }

    &:hover .overlay-icon {
        background-color: rgba(255, 255, 255, 0.2);
        opacity: 1;
    }
    &:hover .overlay-icon__icon {
        opacity: 1;
    }

    .overlay {
        width: 350px;
        height: 350px;
        background: no-repeat #fff;
        box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
        pointer-events: none;
        position: absolute;
        opacity: 0;
        border: 4px solid whitesmoke;
        z-index: 99;
        border-radius: 50%;
        display: block;
        transition: opacity 0.2s;

        &--visible {
            opacity: 1;
        }
    }

    .overlay-icon {
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: transparent;
        top: 0;
        left: 0;
        opacity: 1;

        transition: background-color 0.3s, opacity 0.3s;

        &.active {
            background-color: transparent;
            opacity: 0;
        }

        &__icon {
            background: $main-color;
            height: 40px;
            width: 40px;
            position: absolute;
            opacity: 0;
            transition: opacity 0.3s;

            display: flex;
            justify-content: center;
            align-items: center;

            top: calc(50% - 20px);
            left: calc(50% - 20px);
            border-radius: 20px;

            i {
                color: white;
                font-size: 20px;
            }
        }
    }
}
</style>
