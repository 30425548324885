var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collection-areas-component"},[_c('ul',{staticClass:"tab-button-list collection-filter hidden-below-lg"},[_c('li',[_c('button',{class:{
                    'button button-grey': _vm.activeTab !== null,
                    'button button-blue': _vm.activeTab === null,
                },on:{"click":function($event){return _vm.fetchCollectionAreaLabels()}}},[_vm._v("\n                "+_vm._s(_vm.$t('collection_all'))+"\n            ")])]),_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.collectionAreaTypeId},[_c('button',{class:{
                    'button button-grey':
                        _vm.activeTab !== tab.collectionAreaTypeId,
                    'button button-blue':
                        _vm.activeTab === tab.collectionAreaTypeId,
                },on:{"click":function($event){return _vm.fetchCollectionAreaLabels(tab.collectionAreaTypeId)}}},[_vm._v("\n                "+_vm._s(tab.collectionAreaTypeLabel)+"\n            ")])])})],2),_c('select',{ref:"select",staticClass:"hidden-above-lg collection-select",on:{"change":function($event){return _vm.selectChanged()}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('collection_all')))]),_vm._l((_vm.tabs),function(tab){return _c('option',{key:tab.collectionAreaTypeId,domProps:{"value":tab.collectionAreaTypeId}},[_vm._v("\n            "+_vm._s(tab.collectionAreaTypeLabel)+"\n        ")])})],2),_c('ul',{staticClass:"collection-list"},_vm._l((_vm.areas),function(area){return _c('li',{key:area.collectionAreaId},[_c('router-link',{staticClass:"button button-white",attrs:{"to":{
                    name: 'page.collectionArea',
                    params: { collectionAreaSlug: area.collectionAreaSlug },
                }}},[_vm._v(_vm._s(area.collectionAreaTitle))])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }