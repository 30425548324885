<template>
    <ul class="account-tabs tabs">
        <li>
            <router-link
                class="icon-bids button button-grey"
                :class="{ active: $route.name == 'page.accountBids' }"
                :to="{
                    name: 'page.accountBids',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
            >
                <span>{{ $t('page_title_bids') }}</span>
            </router-link>
        </li>
        <li>
            <router-link
                class="icon-watchlist button button-grey"
                :class="{ active: $route.name == 'page.accountWatchlist' }"
                :to="{
                    name: 'page.accountWatchlist',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
            >
                <span>{{ $t('watchlist') }}</span>
            </router-link>
        </li>
        <li>
            <router-link
                class="icon-buyNow button button-grey"
                :class="{ active: $route.name == 'page.accountBuyNow' }"
                :to="{
                    name: 'page.accountBuyNow',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
            >
                <span>{{ $t('buynow_items') }}</span>
            </router-link>
        </li>
        <li>
            <router-link
                class="icon-notifications button button-grey"
                :class="{ active: $route.name == 'page.accountNotifications' }"
                :to="{
                    name: 'page.accountNotifications',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
            >
                <span>{{ $t('searches_saved') }}</span>
            </router-link>
        </li>

        <li class="external">
            <a :href="profileLink" target="_blank">
                <span>{{ $t('page_title_profile.password') }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
import { AUTH_CLIENT_ID, AUTH_CLIENT_SECRET, AUTH_URL, PAGINATION_DEFAULTS } from '../config';

export default {
    name: 'AccountNavigation',
    data() {
        return {
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
        };
    },
    computed: {
        lang() {
            return this.$root.$i18n.locale;
        },
        profileLink() {
            return `${AUTH_URL}/${this.lang}/my?client_id=${AUTH_CLIENT_ID}&client_secret=${AUTH_CLIENT_SECRET}`;
        },
    }
};
</script>

<style scoped lang="scss">
.active {
    background: $dark-bg;
    color: white;
}

.account-tabs {
    padding-left: $pad-sm;
    @media (min-width: $lg-size) {
        padding-left: $pad-lg;
    }
}

.account-tabs button {
    text-transform: uppercase;
}

.external {
    background: $main-color-lightest-quarter;
    margin-left: 2rem;
    transition: background-color .2s;

    &:hover {
        background: $main-color-lightest;
    }

    a {
        color: $text-color;
        text-decoration: none;
    }
}

@media (max-width: $md-size-max) {
    li {
        margin: $mar-sm;
        padding: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    a[class*='icon'] {
        position: relative;
        width: 45px;
        height: 45px;
        padding: 0;

        &:before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 20px;
            padding: 5px;
            margin: 0 auto;
        }

        span {
            display: none;
        }
    }

    .icon-profile {
        &:before {
            content: '\f007';
        }
    }

    .icon-bids {
        &:before {
            content: '\f0e3';
        }
    }

    .icon-buyNow {
        &:before {
            content: '\f0d6';
        }
    }

    .icon-watchlist {
        &:before {
            content: '\f0f3';
        }
    }

    .icon-notifications {
        &:before {
            content: '\f024';
        }
    }
}

/*
#account-tabs {
    ul {
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        li {
            list-style: none;
            margin-bottom: 15px;
            cursor: pointer;

            &.active {
                span {
                    position: relative;
                    background-color: white;
                    border: 1px solid $grey-bg-light;

                    &::after {
                        display: block;
                        content: "▲";
                        position: absolute;
                        color: $grey-bg-light;
                        transform: scalex(1.5) scaleY(2) rotate(90deg);
                        right: -20px;
                        top: 8px;
                        height: 0;
                        width: 0;
                        z-index: -1;
                    }
                }
            }

            span {
                display: block;
                padding: 11px;
                text-decoration: none;
                background-color: $grey-bg-light;
                border: 1px solid $grey-bg-light;
                color: $main-color;

                &:hover {
                    background-color: white;
                    border: 1px solid $grey-bg-light;
                }
            }
        }
    }
}
@media (max-width: $sm-size-max) {
    ul {
        display: flex;
        justify-content: space-between;
        max-width: 400px;
    }
    li {
        transition: 0.5s;
        background: $grey-bg-lighter;
        padding: $pad-lg $pad-md;

        &:hover {
            background: $grey-bg;
        }

        &.active {
            color: white;
            background: $dark-bg;
        }

        &:first-child {
            margin-left: 0;
        }

        span {
            display: none !important;
        }
    }

    li[class*="icon"] {
        position: relative;
        display: inline-block;
        width: 55px;
        text-align: center;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 20px;
            padding: $pad-sm;
        }

        span {
            display: none;
        }
    }

    .icon-profile {
        &:before {
            content: "\f007";
        }
    }

    .icon-bids {
        &:before {
            content: "\f0e3";
        }
    }

    .icon-buyNow {
        &:before {
            content: "\f0d6";
        }
    }

    .icon-watchlist {
        &:before {
            content: "\f0f3";
        }
    }

    .icon-notifications {
        &:before {
            content: "\f024";
        }
    }
}
*/
</style>
