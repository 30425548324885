<template>
    <div>
        <h1>{{ $t('page_title_conditions') }}</h1>
        <breadcrumb>{{ $t('page_title_conditions') }}</breadcrumb>

        <div class="reading">
            <div v-html="$t('page_content_terms')"></div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';

export default {
    name: 'TermsAndConditions',
    components: {
        Breadcrumb,
    },
};
</script>

<style lang="scss">
.reading {
    ol {
        margin: $mar-xl 0;
        padding: 0 $pad-lg;

        li {
            margin: $mar-md 0;
        }
    }
}
</style>
