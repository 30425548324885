<template>
    <transition name="fade">
        <div
            class="overlay"
            v-if="shown"
            :class="{ 'overlay-supersize': supersize }"
            @click.stop
            @click="$emit('closing')"
        >
            <div class="overlay-content" @click.stop>
                <div class="close" @click="$emit('closing')">
                    <i class="fa fa-times" />
                </div>
                <div class="info">
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ContentOverlay',
    props: {
        shown: {
            type: Boolean,
            default: false,
        },
        supersize: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    cursor: default;
}

.overlay-content {
    text-align: center;
    background: white;
    min-width: 300px;
    max-width: 800px;
    padding: $pad-xl;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 90%;
    overflow: auto;

    .info {
        max-height: 100%;
    }

    @media (min-width: $sm-size) {
        padding: $pad-2xl;
    }
}

.overlay-supersize .overlay-content {
    max-width: 90%;
    width: 90%;
    overflow: visible;
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: $pad-sm;
    cursor: pointer;
}
</style>
