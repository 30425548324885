var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction-list-item clearfix",on:{"click":_vm.itemClicked}},[(_vm.companyId)?_c('company-image',{staticClass:"auction-list-item-img",attrs:{"isFrontPage":true,"alt":_vm.companyLogoAlt,"title":_vm.companyLogoTitle,"companyId":_vm.companyId,"auctionId":_vm.auctionId}}):_vm._e(),_c('div',{staticClass:"auction-list-item-info"},[(_vm.auctionLink)?_c('a',{ref:"externalLink",staticClass:"nostyle",attrs:{"href":_vm.auctionLink,"target":"_blank"},on:{"click":function($event){$event.preventDefault();_vm.overlay = true}}},[_c('h3',{staticClass:"auction-list-item-title"},[_vm._v("\n                "+_vm._s(_vm.auctionName)+"\n                "),_c('i',{staticClass:"fas fa-external-link-alt"})])]):_vm._e(),(_vm.auctionLink)?_c('leaving-overlay',{attrs:{"url":_vm.auctionLink,"shown":_vm.overlay,"type":"external"},on:{"closing":function($event){_vm.overlay = false}}}):_c('router-link',{staticClass:"nostyle",attrs:{"to":{
                name: 'page.auctionLotList',
                params: {
                    companySlug: _vm.companySlug,
                    auctionId: _vm.auctionId,
                    page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                    perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                },
            },"event":""},nativeOn:{"click":function($event){$event.preventDefault();}}},[_c('h3',{staticClass:"auction-list-item-title"},[_vm._v(_vm._s(_vm.auctionName))])]),_c('div',{staticClass:"auction-list-item-company"},[_vm._v(_vm._s(_vm.companyName))]),(_vm.auctionDescription)?_c('p',{staticClass:"auction-list-item-description",domProps:{"innerHTML":_vm._f("renderBR")(_vm.auctionDescription)}}):_vm._e(),(
                _vm.auctionLiveBiddingLink &&
                _vm.$route.name !== 'page.auctionListPast' &&
                _vm.$route.name !== 'page.auctionListPastCompany'
            )?_c('leaving-overlay',{attrs:{"url":_vm.auctionLiveBiddingLink,"shown":_vm.overlay},on:{"closing":function($event){_vm.overlay = false}}}):_vm._e()],1),_c('div',{staticClass:"auction-list-item-date"},[_c('div',[_vm._v(_vm._s(_vm.date))]),(_vm.auctionPrLink || _vm.auctionPrAvailable)?_c('div',{staticClass:"auction-list-item-results"},[(_vm.auctionPrLink != '' && _vm.auctionPrLink != null)?_c('a',{attrs:{"href":_vm.auctionPrLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('results')))]):_vm._e(),(_vm.auctionPrAvailable == 1)?_c('router-link',{attrs:{"to":{
                    name: 'page.auctionResults',
                    params: {
                        companySlug: _vm.companySlug,
                        auctionId: _vm.auctionId,
                        page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                        perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }}},[_vm._v(_vm._s(_vm.$t('results')))]):_vm._e()],1):_vm._e(),(_vm.auctionLiveBiddingLink && !_vm.auctionEnded)?_c('div',{staticClass:"auction-list-item-livebidding"},[(_vm.auctionLiveBiddingLink && _vm.liveBiddingEnabled == 1)?_c('router-link',{class:_vm.liveBiddingEnabled == 1 ? 'blue' : '',attrs:{"to":{
                    name: 'page.auctionLivebidding',
                    params: {
                        companySlug: _vm.companySlug,
                        auctionId: _vm.auctionId,
                        page: _vm.PAGINATION_DEFAULTS.START_PAGE,
                        perPage: _vm.PAGINATION_DEFAULTS.PER_PAGE,
                    },
                    query: this.$route.query,
                }}},[_vm._v(_vm._s(_vm.$t('livebidding')))]):_vm._e(),(_vm.auctionLiveBiddingLink && _vm.liveBiddingEnabled == 0)?_c('span',{},[_vm._v(_vm._s(_vm.$t('livebidding')))]):_vm._e()],1):_vm._e(),(_vm.auctionisNew)?_c('div',{staticClass:"auction-list-item-new"},[_vm._v("\n            "+_vm._s(_vm.$t('new'))+"\n        ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }