<template>
    <div class="no-print">
        <footer class="page-footer">
            <div class="wrapper clearfix">
                <div class="content">
                    <div class="row">
                        <div
                            class="col col-md-12 col-lg-3 hidden-below-lg"
                            style="position: relative"
                        >
                            <coin-collector></coin-collector>
                        </div>

                        <div class="col col-md-3">
                            <h3
                                class="page-footer-nav-headline"
                                :class="{ isOpen: auctionsShown }"
                                @click="auctionsShown = !auctionsShown"
                            >
                                {{ $t('auctions') }}
                            </h3>
                            <transition name="slide">
                                <ul
                                    class="page-footer-nav"
                                    v-if="!$global.isMobile || auctionsShown"
                                >
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.home' }"
                                            >{{
                                                $t('auctions_upcoming')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.auctionListPast',
                                                params: {
                                                    page: PAGINATION_DEFAULTS.START_PAGE,
                                                    perPage:
                                                        PAGINATION_DEFAULTS.PER_PAGE,
                                                },
                                            }"
                                            >{{
                                                $t('auctions_ended')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.auctionCalendar',
                                            }"
                                            >{{
                                                $t('auction_calendar')
                                            }}</router-link
                                        >
                                    </li>
                                </ul>
                            </transition>

                            <h3
                                class="page-footer-nav-headline"
                                :class="{ isOpen: serviceShown }"
                                @click="serviceShown = !serviceShown"
                            >
                                {{ $t('service') }}
                            </h3>
                            <transition name="slide">
                                <ul
                                    class="page-footer-nav"
                                    v-if="!$global.isMobile || serviceShown"
                                >
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.lots',
                                                params: {
                                                    page: PAGINATION_DEFAULTS.START_PAGE,
                                                    perPage:
                                                        PAGINATION_DEFAULTS.PER_PAGE,
                                                },
                                            }"
                                            >{{ $t('lot_search') }}</router-link
                                        >
                                    </li>
                                </ul>
                            </transition>
                        </div>
                        <div class="col col-md-4 col-lg-3">
                            <h3
                                class="page-footer-nav-headline"
                                :class="{ isOpen: sixbidShown }"
                                @click="sixbidShown = !sixbidShown"
                            >
                                {{ $t('sixbid_url') }}
                            </h3>
                            <transition name="slide">
                                <ul
                                    class="page-footer-nav"
                                    v-if="!$global.isMobile || sixbidShown"
                                >
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.about' }"
                                            >{{
                                                $t('page_title_aboutus')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.help' }"
                                            >{{
                                                $t('page_title_help')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.companyList' }"
                                            >{{ $t('companies') }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.termsAndConditions',
                                            }"
                                            >{{
                                                $t('page_title_conditions')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.privacy' }"
                                            >{{ $t('privacy') }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.imprint' }"
                                            >{{
                                                $t('page_title_imprint')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'page.contact' }"
                                            >{{
                                                $t('page_title_contact')
                                            }}</router-link
                                        >
                                    </li>
                                </ul>
                            </transition>
                        </div>
                        <div class="col col-md-4 col-lg-3" v-if="loggedIn">
                            <h3
                                class="page-footer-nav-headline"
                                :class="{ isOpen: accountShown }"
                                @click="accountShown = !accountShown"
                            >
                                {{ $t('page_title_account') }}
                            </h3>
                            <transition name="slide">
                                <ul
                                    class="page-footer-nav"
                                    v-if="!$global.isMobile || accountShown"
                                >
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.accountBids',
                                                params: {
                                                    page: PAGINATION_DEFAULTS.START_PAGE,
                                                    perPage:
                                                        PAGINATION_DEFAULTS.PER_PAGE,
                                                },
                                            }"
                                            >{{
                                                $t('page_title_bids')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.accountWatchlist',
                                                params: {
                                                    page: PAGINATION_DEFAULTS.START_PAGE,
                                                    perPage:
                                                        PAGINATION_DEFAULTS.PER_PAGE,
                                                },
                                            }"
                                            >{{ $t('watchlist') }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'page.accountBuyNow',
                                                params: {
                                                    page: PAGINATION_DEFAULTS.START_PAGE,
                                                    perPage:
                                                        PAGINATION_DEFAULTS.PER_PAGE,
                                                },
                                            }"
                                            >{{
                                                $t('buynow_items')
                                            }}</router-link
                                        >
                                    </li>
                                    <li>
                                        <a :href="profileLink" target="_blank">
                                            <span>{{ $t('page_title_profile.password') }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                    </div>
                </div>
                <div class="sidebar">
                    <ad-banner
                        v-if="getAds['Sidebar-Footer']"
                        :ad="getAds['Sidebar-Footer']"
                    ></ad-banner>
                </div>
            </div>
        </footer>
        <footer class="page-footer-bar">
            <ul class="page-footer-bar-nav">
                <li>&copy; {{ $t('sixbid_url') }}</li>
                |
                <li>
                    <router-link :to="{ name: 'page.imprint' }">{{
                        $t('page_title_imprint')
                    }}</router-link>
                </li>
                |
                <li>
                    <router-link :to="{ name: 'page.termsAndConditions' }">{{
                        $t('page_title_conditions')
                    }}</router-link>
                </li>
                |
                <li>
                    <router-link :to="{ name: 'page.privacy' }">{{
                        $t('privacy')
                    }}</router-link>
                </li>
            </ul>
        </footer>
    </div>
</template>
<script>
import CoinCollector from '../components/CoinCollector.vue';
import AdBanner from '../components/AdBanner';

import { AUTH_CLIENT_ID, AUTH_CLIENT_SECRET, AUTH_URL, PAGINATION_DEFAULTS } from '../config.js';
import { mapGetters } from 'vuex';

export default {
    name: 'Footer',
    components: {
        CoinCollector,
        AdBanner,
    },
    data() {
        return {
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            companies: [],
            auctionsShown: false,
            serviceShown: false,
            sixbidShown: false,
            accountShown: false,
            ads: {},
        };
    },
    computed: {
        ...mapGetters({ getAds: 'ads/getAds' }),
        loggedIn() {
            return this.$store.getters['account/loggedIn'];
        },
        lang() {
            return this.$root.$i18n.locale;
        },
        profileLink() {
            return `${AUTH_URL}/${this.lang}/my?client_id=${AUTH_CLIENT_ID}&client_secret=${AUTH_CLIENT_SECRET}`;
        },
    },
};
</script>
<style scoped lang="scss">
.page-footer {
    background: $grey-bg-lighter;

    @media (min-width: $md-size) {
        padding: $pad-lg 0;
    }

    @media (max-width: $sm-size-max) {
        padding-bottom: 1.5em;
    }

    &-nav {
        list-style: none;
        margin: $mar-lg 0 0 0;
        padding: 0;

        li {
            padding-bottom: 7px;
        }

        a {
            color: $text-color;
            text-decoration: none;
            font-size: 13px;
        }
    }
}

.page-footer-bar {
    background: #d4d4d4;
    text-align: center;
    line-height: 30px;

    @media (min-width: $md-size) {
        line-height: 40px;
    }

    &-nav {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            padding: 0 10px;
        }

        a {
            color: #333;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

h3 {
    margin-top: $mar-xl;
    margin-bottom: 0;

    &:first-child {
        @media (min-width: $md-size) {
            margin-top: 0;
        }
    }

    &:before {
        @media (max-width: $sm-size-max) {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f078';
            margin-right: 10px;
        }
    }

    &.isOpen {
        &:before {
            @media (max-width: $sm-size-max) {
                content: '\f077';
            }
        }
    }
}

.col {
    @media (max-width: $sm-size-max) {
        margin-top: 0;
    }
}

.sidebar {
    @media (max-width: $sm-size-max) {
        text-align: right;
        margin: 0;
        padding: $pad-lg 0;
    }
}
</style>
