<template>
    <span v-if="Object.keys($global.currencyRates).length > 0">
        <span class="convertable-price" v-if="value > 0">
            {{ currencyFormater(value, mappedCurrency) }}
            <div class="currency-conversion">
                <div
                    v-for="price in getConvertedValues(value, mappedCurrency)"
                    :key="price.amount + price.currency"
                >
                    {{ currencyFormater(price.amount, price.currency) }}
                </div>
                <hr />
                <span>{{ $t('rounded_values') }}</span>
                <span
                    >{{ $t('date') }}:
                    {{ getCurrencyRatesDate() | dateFormater }}</span
                >
            </div>
        </span>
        <span v-else-if="value < 0">
            {{ $t('lot_withdrawn') }}
        </span>
        <span v-else-if="value == 0 && showZeroAsUnsold">
            {{ $t('lot_not_sold') }}
        </span>
        <span v-else> --- </span>
    </span>
</template>

<script>
export default {
    name: 'price',
    props: {
        value: {
            type: Number,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
        showZeroAsUnsold: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        mappedCurrency() {
            return this.currencyMapper(this.currency);
        },
    },
};
</script>

<style scoped lang="scss">
.convertable-price {
    border-bottom: 1px dashed $main-color;
    position: relative;
    cursor: pointer;
}

.currency-conversion {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0 0 4px 0 $grey-bg-dark;
    padding: 5px 10px;
    bottom: 1.3em;
    left: 0;
    text-align: right;
    width: 130px;
    z-index: 5;
    white-space: normal;

    span {
        font-size: 0.8em;
        display: block;
    }
}

.convertable-price:hover {
    color: $main-color;
}

.convertable-price:hover .currency-conversion {
    display: inline-block;
    color: $text-color;
}
</style>
