<template>
    <div class="account-page" id="account-buy-now-page">
        <div class="row">
            <account-navigation />
            <div class="col col-md-12 col-lg-12">
                <account-header />
                <h1>{{ $t('buynow_items') }}</h1>
                <pagination
                    ref="PaginationTop"
                    @pagination-changed="getItems()"
                    :hasPerPageSelect="true"
                    v-show="!$global.isMobile"
                />
                <table width="100%">
                    <thead class="hidden-below-sm">
                        <tr>
                            <th>{{ $t('offer_title') }}</th>
                            <th>{{ $t('bid_from') }}</th>
                            <th>{{ $t('bid_state') }}</th>
                            <th>{{ $t('offer_price') }}</th>
                            <th>{{ $t('auctioneer') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in items"
                            :key="item.itemId"
                            :class="index % 2 === 1 ? 'odd' : 'even'"
                        >
                            <td>
                                <span class="hidden-above-sm">{{
                                    $t('offer_title')
                                }}</span>
                                <span>{{ item.itemTitle }}</span>
                            </td>
                            <td>
                                <span class="hidden-above-sm">{{
                                    $t('bid_from')
                                }}</span>
                                <span>
                                    <date-timezone :date="item.insertDate" />
                                </span>
                            </td>
                            <td>
                                <span class="hidden-above-sm">{{
                                    $t('bid_state')
                                }}</span>
                                <span>{{ item.offerStatus }}</span>
                            </td>
                            <td>
                                <span class="hidden-above-sm">{{
                                    $t('Angebots-Preis')
                                }}</span>
                                <price
                                    :value="item.itemPrice"
                                    :currency="item.itemCurrency"
                                ></price>
                            </td>
                            <td>
                                <span class="hidden-above-sm">{{
                                    $t('auctioneer')
                                }}</span>
                                <span
                                    ><router-link
                                        :to="{
                                            name: 'page.companyDetail',
                                            params: {
                                                companySlug: item.companySlug,
                                            },
                                        }"
                                        >{{ item.companyName }}</router-link
                                    ></span
                                >
                            </td>
                        </tr>
                        <tr v-if="!items.length">
                            <td colspan="5">
                                {{ $t('buynow_no_items') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination
                    ref="PaginationBottom"
                    @pagination-changed="getItems()"
                    :hasPerPageSelect="!$global.isMobile"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '../lib/Authorization';
import AccountHeader from '../components/AccountHeader';
import AccountNavigation from '../components/AccountNavigation';
import Pagination from '../components/Pagination';
import Price from '../components/Price';
import DateTimezone from '../components/DateTimezone';

export default {
    name: 'Account_BuyNow',
    components: {
        AccountHeader,
        AccountNavigation,
        Pagination,
        Price,
        DateTimezone,
    },
    data: function () {
        return {
            items: [],
        };
    },
    methods: {
        getItems: function () {
            const data = {
                limit: this.$route.params.perPage,
                page: this.$route.params.page,
                lang: this.$route.params.lang,
            };
            Auth.createAxiosClient()
                .then((axios) => {
                    return axios.get(
                        process.env.VUE_APP_BUY_NOW_API_URL +
                            '/my-buy-now-items',
                        { params: data }
                    );
                })
                .then((response) => {
                    this.items = response.data.items;
                    this.$refs.PaginationTop.responseHelper(response);
                    this.$refs.PaginationBottom.responseHelper(response);
                })
                .catch(() => {
                    /* eslint-disable-next-line no-console */
                    //console.log("error");
                });
        },
    },
    created: function () {
        this.getItems();
    },
};
</script>

<style scoped lang="scss">
/* reset table display */
table,
thead,
tbody,
tfoot,
tr,
td,
th {
    display: block;
}

thead {
    background: $grey-bg;
}

tr {
    @media (min-width: $sm-size) {
        display: flex;

        & > :nth-child(1),
        & > :nth-child(2),
        & > :nth-child(3),
        & > :nth-child(4),
        & > :nth-child(5) {
            width: 20%;
        }
    }
}

tbody tr {
    padding: $pad-md 0;
}

td,
th {
    padding: $pad-sm $pad-lg;
}

.even {
    background: white;
}

.odd {
    background: $grey-bg-lightest;
}

@media (max-width: $xs-size-max) {
    td {
        padding: $mar-sm;

        & > span:first-child {
            display: block;
            float: left;
        }

        & > span:nth-child(2) {
            display: block;
            margin-left: 110px;
        }
    }
}
</style>
