import './../node_modules/es6-promise/auto';

import Vue from './../node_modules/vue/dist/vue.js';
import App from './App.vue';
import store from './store/store';
import { currencyTools } from './components/mixins/currencyTools';
import scroller from './components/mixins/scroller';
import bidSheetTools from './components/mixins/bidSheetTools.js';
import lineClamp from 'vue-line-clamp';
import i18n, { getLanguageFromAvailableLanguages, loadLanguageAsync } from './i18n';
import router from './router';
import './filters/date.js';
import './filters/html.js';
import { TABLET_WIDTH_MAX, MOBILE_WIDTH_MAX } from './config';
import CountryPlugin from './plugins/country.js';
import { mapActions } from 'vuex';
import { getAds, getCountries } from './lib/apis';

import {
    AUCTION_ITEMS_VISIBLE,
    CALENDAR_ITEMS_VISIBLE,
    COMPANIES_VISIBLE,
} from './config';

// import { Feedback } from '@ivoviz/feedback.js';

require('es6-promise/auto');

Vue.config.productionTip = false;

const global = new Vue({
    data: {
        currencyRates: [],
        bidSheets: {},
        isMobile: false,
        isTablet: false,
        backNav: false,
        showCounts: {
            currentAuctionItems: AUCTION_ITEMS_VISIBLE,
            currentCalendarItems: CALENDAR_ITEMS_VISIBLE,
            currentCompanies: COMPANIES_VISIBLE,
        },
    },
    methods: {
        globalError(errors) {
            this.$emit('global-error', errors);
        },
    },
});

const GlobalPlugin = {
    install(Vue) {
        Vue.prototype.$global = global;
    },
};
Vue.use(GlobalPlugin);

// LINE CLAMP PLUGIN
Vue.use(lineClamp, {
    textOverflow: 'clip',
});

// MIXINS
Vue.mixin(currencyTools);
Vue.mixin(bidSheetTools);
Vue.mixin(scroller);
Vue.mixin({
    methods: {
        genericApiErrorHandling: function (e) {
            if (e.message.indexOf('#cancel#') === -1 && e.response.status === 500) {
                this.$global.globalError(this.$t('error_generic'));
            }
        },
    }
})

const main = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
    methods: {
        ...mapActions(['ads/setAds', 'countries/setCountries', 'countries/setTopCountries', 'countries/setStates', 'countries/setCountriesWithPreferred']),
        checkWindowWidth() {
            const windowWidth = Math.max(
                window.innerWidth,
                document.documentElement.clientWidth,
                document.querySelector('body').clientWidth
            );
            this.$global.isMobile = windowWidth < MOBILE_WIDTH_MAX;
            this.$global.isTablet =
                windowWidth < TABLET_WIDTH_MAX &&
                windowWidth >= MOBILE_WIDTH_MAX;
        },
    },
    mounted() {
        this.$nextTick(function () {
            window.addEventListener('resize', this.checkWindowWidth);
            this.checkWindowWidth();
        });

        // PRERENDERING
        // You'll need this for renderAfterDocumentEvent.
        document.dispatchEvent(new Event('root-mounted'));
        setTimeout(() => {
            // fallback if prerendering apis are not triggering
            document.dispatchEvent(new Event('component-data-rendered'));
        }, 15000);
    },
    created() {
        // Plugin needs access to vuex store
        Vue.use(CountryPlugin, this.$store);

        let langParam = this.$route.params.lang;

        const availableLanguage = getLanguageFromAvailableLanguages(langParam);
        store.dispatch('lang/setLang', langParam);

        loadLanguageAsync(availableLanguage).then(() => {

            this.fetchCurrencyRates();
            this.getBidSheetsFromStorage();

            getAds().then(response => {
                this['ads/setAds'](response.data.banner);
            });

            getCountries().then(response => {
                this['countries/setCountries'](response.data.countries);
                this['countries/setStates'](response.data.states);
                this['countries/setTopCountries'](response.data.top_countries);
                this['countries/setCountriesWithPreferred']();
            });
        });
    }
});

export default main;
