<template>
    <button
        :class="[classes, { 'button-disabled': loading }]"
        @click="doAction"
    >
        <slot v-if="!loading"></slot>

        <span v-if="loading" class="dots">
            <span class="bounce1"></span>
            <span class="bounce2"></span>
            <span class="bounce3"></span>
        </span>
    </button>
</template>

<script>
export default {
    name: 'ButtonLoader',
    props: {
        classes: {
            type: String,
            default: 'button button-blue',
        },
        clickHandler: {
            type: Function,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        doAction() {
            if (!this.loading) {
                this.clickHandler();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.dots > span {
    width: 8px;
    height: 8px;
    background-color: white;

    border-radius: 100%;
    display: inline-block;
    margin: 0 $mar-sm;
    animation: bouncedelay 1.2s infinite ease-in-out both;
}

.dots .bounce1 {
    animation-delay: -0.5s;
}

.dots .bounce2 {
    animation-delay: -0.25s;
}

@keyframes bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0.4);
    }
    40% {
        transform: scale(1);
    }
}
</style>
