<template>
    <div class="bidsheet">
        <div>
            <span class="bidsheet-title" @click="isExpanded = !isExpanded">
                <span class="bidsheet-title-prefix">
                    <i :class="expandIcon"></i>
                    {{ sheetTitle }}
                </span>
                &nbsp;&nbsp;—&nbsp;&nbsp;
                <span v-if="sheet.isBidsheet == 1"
                    >{{ $t('bid_sheet_nr')
                    }}{{ sheet.sheetId }}&nbsp;&nbsp;—&nbsp;&nbsp;</span
                >
                <price
                    :value="Number(sheet.sheetTotal)"
                    :currency="sheet.auctionCurrency"
                ></price>
                &nbsp;&nbsp;—&nbsp;&nbsp;
                <date-timezone :date="sheet.insertDate" />
            </span>
            <loader ref="loader"></loader>
            <div class="details" v-if="isExpanded == true">
                <table v-if="bids.length > 0">
                    <thead class="hidden-below-md">
                        <tr class="bid-sheet-header">
                            <th>{{ $t('image') }}</th>
                            <th>{{ $t('lot_nr') }}</th>
                            <th>{{ $t('lot_price_start') }}</th>

                            <th>{{ $t('result') }}</th>
                            <th v-if="sheet.isBidsheet == 0">
                                {{ $t('bid_hammer') }}
                            </th>
                            <th>{{ $t('bid_your') }}</th>
                        </tr>
                    </thead>
                    <tbody class="bid-sheet-lots" v-if="bids">
                        <template v-for="(lot, index) in bids">
                            <tr
                                class="bid-sheet-lot"
                                ref="lot"
                                :data-id="lot.lotId"
                                :key="'sheet_' + lot.lotId"
                                :class="index % 2 === 1 ? 'odd' : 'even'"
                            >
                                <td>
                                    <img
                                        width="256" height="128"
                                        v-if="lot.hasImage == '0'"
                                        loading="lazy"
                                        :alt="$t('lot')"
                                        :src="
                                            'https://lot-images.sixbid.com/placeholder/lot/' +
                                            sheet.companyId +
                                            '/' +
                                            sheet.auctionId +
                                            '/img.png'
                                        "
                                    />
                                    <img
                                        v-else
                                        width="256" height="128"
                                        loading="lazy"
                                        :src="getImage(lot, 'XS')"
                                        :alt="$t('lot')"
                                    />
                                </td>
                                <td>
                                    <router-link
                                        target="_blank"
                                        :to="{
                                            name: 'page.auctionLotDetail',
                                            params: {
                                                companySlug: lot.companySlug,
                                                auctionId: lot.auctionId,
                                                categorySlug: lot.categorySlug,
                                                lotId: lot.lotId,
                                                lotSlug: lot.lotSlug,
                                            },
                                            query: $route.query,
                                        }"
                                        >{{ $t('lot') }} {{ lot.lotNumber
                                        }}{{ lot.lotNumberAffix }}</router-link
                                    >

                                    <a
                                        @click="
                                            removeLotFromBidSheet(lot.lotId)
                                        "
                                        class="hidden-above-md"
                                    >
                                        <i class="far fa-trash-alt fa-lg"></i>
                                    </a>
                                </td>
                                <td class="prices">
                                    <div>
                                        <span class="priceLabel"
                                            >{{ $t('lot_price_start') }}: </span
                                        ><price
                                            :value="
                                                Number(lot.lotStartingPrice)
                                            "
                                            :currency="lot.auctionCurrency"
                                        ></price>
                                    </div>
                                </td>
                                <td>
                                    <span class="priceLabel"
                                        >{{ $t('result') }}: </span
                                    ><price
                                        :value="Number(lot.lotPriceRealised)"
                                        :currency="lot.auctionCurrency"
                                    ></price>
                                </td>
                                <td v-if="sheet.isBidsheet == 0">
                                    <span class="priceLabel"
                                        >{{ $t('bid_hammer') }}:
                                    </span>
                                    <i
                                        v-if="lot.bidSuccess == 1"
                                        class="fa fa-check fa-lg icon-green"
                                    ></i>
                                    <i
                                        v-else
                                        class="fa fa-times fa-lg icon-grey"
                                    ></i>
                                </td>
                                <td>
                                    <span class="priceLabel"
                                        >{{ $t('bid_your') }}: </span
                                    ><price
                                        :value="Number(lot.bidAmount)"
                                        :currency="lot.auctionCurrency"
                                    ></price>
                                </td>
                            </tr>
                        </template>
                        <tr class="bid-sheet-lot even" v-if="loaded">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td v-if="sheet.isBidsheet == 0">
                                <div class="bidsheet-total">
                                    <div class="title">
                                        {{ $t('bid_hammerTotal') }}:
                                    </div>
                                    <div class="price">
                                        <price
                                            :value="Number(sheet.hammerTotal)"
                                            :currency="sheet.auctionCurrency"
                                        ></price>
                                    </div>
                                </div>
                            </td>
                            <td v-if="sheet.isBidsheet == 0">
                                <div class="bidsheet-total">
                                    <div class="title">
                                        {{ $t('bid_sheet_total') }}:
                                    </div>
                                    <div class="price">
                                        <price
                                            :value="Number(sheet.sheetTotal)"
                                            :currency="sheet.auctionCurrency"
                                        ></price>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { getBiddingSheet, getLiveBiddingSheet } from '../lib/apis';

import Loader from '../components/Loader';
import Price from '../components/Price';
import CloudImageGetter from '../lib/CloudImageGetter';
import DateTimezone from '../components/DateTimezone';

export default {
    name: 'BidListItemSheet',
    components: { Loader, Price, DateTimezone },
    props: {
        sheet: {
            required: true,
        },
    },
    data() {
        return {
            bids: [],
            isExpanded: false,
            loaded: false,
        };
    },
    mounted: function () {},
    methods: {
        fetchSheet: function () {
            this.$refs.loader.show();
            if (this.sheet.isBidsheet == '0') {
                getLiveBiddingSheet(this.sheet.auctionId).then((response) => {
                    this.bids = response.data;
                    this.$refs.loader.hide();
                    this.loaded = true;
                });
            } else {
                getBiddingSheet(this.sheet.sheetId).then((response) => {
                    this.bids = response.data;
                    this.$refs.loader.hide();
                    this.loaded = true;
                });
            }
        },
        getImage: function (lot, size) {
            return CloudImageGetter(lot.googleBucketImagePath, size);
        },
    },
    computed: {
        expandIcon: function () {
            if (this.isExpanded) {
                return 'fas fa-caret-down';
            } else {
                return 'fas fa-caret-right';
            }
        },
        sheetTitle: function () {
            if (this.sheet.isBidsheet == '0') {
                return this.$t('bid_livebids');
            } else {
                return this.$t('bid_prebids');
            }
        }
    },
    watch: {
        isExpanded: function (val) {
            if (val === true) {
                this.fetchSheet();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.bidsheet {
    margin: 5px 0;
    border-bottom: $grey-bg solid 1px;
    padding: 20px 0;

    &:last-child {
        border-bottom: none;
    }

    i.fas {
        width: 10px;
        text-align: center;
        color: #000;
    }

    .details {
        min-height: 50px;
    }
}
.icon-grey {
    color: #888;
}
.icon-green {
    color: #0a0;
}
.bidsheet-title {
    cursor: pointer;

    font-size: 16px;

    .bidsheet-title-prefix {
        cursor: pointer;
        color: $main-color;
        text-transform: uppercase;

        font-size: 18px;
    }
}

.bidsheet-total {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
    .title {
        font-weight: bold;
    }

    .price {
    }
}

/* reset table layout */
table,
tr,
td,
th,
thead,
tbody,
tfoot {
    display: block;
}

table {
    margin-top: 15px;
}

.odd {
    @media (min-width: $md-size) {
        background: $grey-bg-lightest;
    }
}

.bid-sheet-wrapper {
    max-width: 1020px;
}

.company {
    p {
        text-align: center;
    }

    .company-image-wrapper {
        text-align: left;
        border-top: 1px solid $grey-bg-light;
        border-bottom: 1px solid $grey-bg-light;
    }

    .sep {
        &::after {
            display: inline-block;
            padding: 0 10px;
            content: '|';
        }
    }
}

.bid-sheet-lots {
    position: relative;
}

.bid-sheet-header,
.bid-sheet-lot,
.bid-sheet-total {
    display: flex;
    align-items: top;

    & > td,
    & > tr,
    & > th {
        padding: $pad-md $pad-lg;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            width: 20%;
        }
        &:nth-child(4) {
            width: 20%;
        }
        &:nth-child(5) {
            width: 10%;
        }
        // &:nth-child(4),
        // &:nth-child(5) {
        //     width: 15%;
        // }
        // &:nth-child(6) {
        //     width: 10%;
        // }

        strong {
            display: inline-block;
            width: 100px;
        }
    }
}

.bid-sheet-header {
    font-weight: bold;
    background: $grey-bg-light;
}

.bid-sheet-total {
    justify-content: space-between;
    margin-top: $mar-lg;

    & > :first-child {
        margin: 0 $mar-sm;

        @media (min-width: $md-size) {
            margin-left: calc(40% + #{$pad-lg});
        }
    }
}

.bid-sheet-lot-wrapper {
    border-top: 1px dashed $grey-bg-light;
    &:nth-child(2n - 1) {
        background-color: $grey-bg-lightest;
    }
    &:hover {
        background-color: $grey-bg-lighter;
    }
}

.bid-sheet-lot {
    .prices {
        div + div {
            margin-top: 10px;
        }
    }

    input {
        width: 30%;
        margin-right: $mar-sm;

        &:disabled {
            background-color: #fafafa;
            border: none;
        }
    }

    a {
        text-decoration: underline;
    }
}

.lot-error {
    padding: 10px 20px;
    td {
        max-width: 850px;
    }
}

.done {
    display: flex;
    align-items: center;
    float: right;

    span {
        margin-right: $mar-lg;
    }
}

.priceLabel {
    font-weight: bold;
    display: none;
}

@media (max-width: $sm-size-max) {
    .priceLabel {
        display: inline;
    }

    .bid-sheet-lot {
        display: block;
        padding: $pad-sm;
        border: 1px solid $grey-bg;

        & > td {
            &:nth-child(n) {
                width: 100%;
            }

            &:nth-child(1) {
                text-align: center;
            }

            &:nth-child(2) {
                display: flex;
                justify-content: space-between;
            }

            span,
            strong {
                display: inline-block;
                width: 130px;
            }

            input {
                max-width: 100px;
            }
        }
    }
}
</style>
