<template>
    <div class="auction-information">
        <auction-banner
            :companyId="company.companyId"
            :auctionId="auction.auctionId"
        ></auction-banner>
        <p class="style-like-h1">{{ auction.auctionName }}</p>
        <breadcrumb>
            <router-link
                :to="{
                    name: 'page.auctionLotList',
                    params: {
                        page: PAGINATION_DEFAULTS.START_PAGE,
                        perPage: PAGINATION_DEFAULTS.PER_PAGE,
                    },
                }"
                >{{ auction.auctionName }}:
                {{ auction.auctionDescription | removeBR }}</router-link
            >
            / {{ $t('auction_information') }}
        </breadcrumb>
        <auction-tabs
            :auctionId="$route.params.auctionId"
            :auction="auction"
        ></auction-tabs>
        <h1 class="style-like-h2">{{ auction.auctionName }}</h1>
        <h3 :inner-html.prop="auction.auctionDescription | renderBR"></h3>
        <div class="row">
            <div class="col col-md-5 col-lg-4">
                <div class="sidebarsection auction-info">
                    <h4>{{ $t('auction') }}</h4>

                    <div>
                        <span>{{ $t('date') }}</span>
                        {{ date }}
                    </div>
                    <div v-if="!hasEnded">
                        <span></span>
                        <a
                            :href="`${icsAPI}/calendar-export/auction/${auction.auctionId}`"
                            >{{ $t('date_save') }}</a
                        >
                    </div>
                    <div v-if="auction.auctionOnlineEnd">
                        <span>{{ $t('bid_end') }}:</span>
                        <date-timezone :date="auction.auctionOnlineEnd" />
                    </div>
                    <div
                        v-if="
                            auction.auctionLocationName ||
                            auction.auctionLocationStreet ||
                            auction.auctionLocationZip ||
                            auction.auctionLocationCity ||
                            auction.auctionLocationCountry
                        "
                    >
                        <span>{{ $t('location') }}</span>
                        <p>
                            {{ auction.auctionLocationName }}
                            <br v-if="auction.auctionLocationName" />
                            {{ auction.auctionLocationStreet }}
                            <br v-if="auction.auctionLocationStreet" />
                            {{ auction.auctionLocationZip }}
                            {{ auction.auctionLocationCity }}
                            <br
                                v-if="
                                    auction.auctionLocationZip ||
                                    auction.auctionLocationCity
                                "
                            />
                            {{ auction.auctionLocationCountry }}
                        </p>
                        <p v-if="auction.auctionLocationAdditionalText">
                            {{ auction.auctionLocationAdditionalText }}
                        </p>
                    </div>
                    <div v-if="auction.auctionPhone">
                        <span>
                            <strong>{{ $t('auction_phone') }}</strong>
                        </span>
                        <strong>{{ auction.auctionPhone }}</strong>
                    </div>
                    <div v-if="auction.auctionFax">
                        <span>{{ $t('auction_fax') }}</span>
                        {{ auction.auctionFax }}
                    </div>
                    <div>
                        <span></span>
                        <a :href="auctionVcfLink">{{ $t('contact_save') }}</a>
                    </div>
                </div>
                <div class="sidebarsection">
                    <h4>{{ $t('company') }}</h4>
                    <p>{{ auction.companyName }}</p>
                    <p>
                        <router-link :to="{ name: 'page.auctionMore' }">
                            <a>{{ $t('company_auctions_more') }}</a>
                        </router-link>
                    </p>
                </div>
                <div class="sidebarsection">
                    <h4>
                        {{ $t('auction_viewing') }} {{ auction.auctionName }}
                    </h4>
                    <p v-html="auction.auctionDescription2"></p>
                </div>
            </div>
            <div class="col col-md-7 col-lg-8">
                <iframe
                    class="googlemap cmplazyload"
                    v-if="company.auctionGoogleMaps"
                    src=""
                    title="googlemaps"
                    style="border: 0"
                    allowfullscreen
                    data-cmp-preview="1"
                    data-cmp-vendor="s1104"
                    :data-cmp-src="company.auctionGoogleMaps"
                ></iframe>
                <p v-html="auction.auctionDescription1"></p>
            </div>
        </div>
    </div>
</template>

<script>
import AuctionTabs from '../components/AuctionTabs';
import Breadcrumb from '../components/Breadcrumb.vue';
import AuctionBanner from '../components/AuctionBanner.vue';
import { PAGINATION_DEFAULTS } from '../config.js';
import { getAuctionDetails, getCompanyDetails } from '../lib/apis';
import { dateRange } from '@/filters/date';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DateTimezone from '../components/DateTimezone.vue';

export default {
    name: 'Auction_Information_Page',
    components: {
        AuctionTabs,
        Breadcrumb,
        AuctionBanner,
        DateTimezone,
    },
    data: function () {
        return {
            company: {},
            auction: {},
            PAGINATION_DEFAULTS: PAGINATION_DEFAULTS,
            auctionIcsLink:
                process.env.VUE_APP_GLOBAL_API_URL +
                '/calendar-export/auction/' +
                this.$route.params.auctionId,
            auctionVcfLink:
                process.env.VUE_APP_GLOBAL_API_URL +
                '/vcard-export/auction/' +
                this.$route.params.auctionId,
        };
    },
    computed: {
        icsAPI() {
            return `${process.env.VUE_APP_GLOBAL_API_URL}`;
        },
        date() {
            return dateRange(
                this.auction.auctionStart,
                this.auction.auctionEnd
            );
        },
        hasEnded() {
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const auctionEnd = dayjs
                .tz(this.auction.auctionOnlineEnd, 'Europe/Berlin')
                .local();
            const currentTime = new Date();

            return currentTime > auctionEnd;
        },
    },
    created: function () {
        Promise.all([
            getCompanyDetails(
                this.$route.params.companySlug,
                this.$route.params.lang
            ),
            getAuctionDetails(
                null,
                this.$route.params.companySlug,
                this.$route.params.auctionId,
                this.$route.params.lang
            ),
        ])
            .then(([company, auction]) => {
                this.company = company.data;
                this.auction = auction.data;

                if (this.company.companySlug != this.auction.companySlug) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }

                //prerendering event after DOM update
                this.$nextTick(() => {
                    // prerendering event after DOM update
                    document.dispatchEvent(
                        new Event('component-data-rendered')
                    );

                    // scroll page
                    this.$root.$emit('triggerScroll');
                });
            })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        return this.$router.push(
                            `/${this.$route.params.lang}/404`
                        );
                    }
                } else if (e.request) {
                    return this.$router.push(`/${this.$route.params.lang}/404`);
                }
                this.genericApiErrorHandling(e);
            });
    },
};
</script>

<style scoped lang="scss">
.auction-lot-list-item {
    border: 1px solid lightgray;
    padding: 1em;
}
.googlemap {
    width: 100%;
    height: 240px;
}

.auction-info {
    & > div {
        margin-bottom: 15px;

        & > span:first-child {
            font-weight: bold;
            display: block;
            margin-bottom: $mar-sm;
        }

        & > p {
            margin-top: 5px;
        }
    }
}
</style>
