<template>
    <div
        v-if="display == 'icon'"
        class="lot-bookmark no-print"
        :class="{ active: active }"
        v-on:click="toggleWatch(lotId)"
        :title="active ? $t('remove_lot_bookmark') : $t('set_lot_bookmark')"
    >
        <i class="fas fa-bookmark fa-2x"></i>
        <i v-if="active" class="fas fa-bell fa-sm"></i>
        <i v-else class="fas fa-bell-slash fa-sm"></i>
    </div>
    <span v-else-if="display == 'link'">
        <a
            v-if="!active"
            v-on:click.stop.prevent="toggleWatch(lotId)"
            :title="$t('set_lot_bookmark')"
            >{{ $t('lot_watch') }} <i class="fas fa-bell fa-sm"></i
        ></a>
        <a
            v-if="active"
            v-on:click.stop.prevent="toggleWatch(lotId)"
            :title="$t('remove_lot_bookmark')"
            >{{ $t('lot_watch_remove') }} <i class="fas fa-bell-slash fa-sm"></i
        ></a>
    </span>
</template>

<script>
export default {
    name: 'Bookmark',
    props: {
        display: {
            type: String,
            default: 'icon',
        },
        lotId: {
            type: Number,
            default: 1,
        },
    },
    mounted: function () {
        // trigger pending tasks
        let task = this.$store.getters['tasks/task'](
            this.$options.name + '#' + this.lotId
        );
        if (
            typeof this.$route.query.runtask !== 'undefined' &&
            task.fn !== null &&
            typeof this[task.fn] === 'function'
        ) {
            this[task.fn].call(this, task.args, true);
        }
    },
    methods: {
        toggleWatch: function (lotId, preventStore) {
            if (typeof preventStore === 'undefined') {
                this.$store.commit('tasks/setTask', {
                    path: this.$route.path,
                    fn: 'toggleWatch',
                    args: lotId,
                    component: this.$options.name + '#' + lotId,
                });
            }
            return this.$store
                .dispatch('lotWatchlist/toggleWatch', lotId)
                .then((response) => {
                    if (response.data.success) {
                        this.$store.commit('tasks/unsetTask');
                    } else {
                        this.$global.globalError(response.data.error);
                    }
                })
                .catch((e) => {
                    this.genericApiErrorHandling(e)
                });
        },
    },
    computed: {
        active() {
            return this.$store.getters['lotWatchlist/isBookmarked'](this.lotId);
        },
    },
};
</script>

<style scoped lang="scss">
.lot-bookmark {
    position: absolute;
    top: -5px;
    right: 15px;
    cursor: pointer;
    z-index: 5;

    i:first-child {
        color: $dark-grey-bg;
    }

    i:nth-child(2) {
        color: white;
        position: absolute;
        top: 6px;
        left: 3px;
    }

    &.active {
        i:first-child {
            color: $main-color;
        }
        i:nth-child(2) {
            top: 6px;
            left: 5px;
        }
    }
}
</style>
