export default {
    methods: {
        scrollTo({ to = 0, duration = 500, offset = 0, callback = () => {} }) {
            let headeroffset = this.$global.isMobile ? 70 : 50;
            headeroffset += offset;

            const getCurrentPosition = headeroffset => {
                let value =
                    document.documentElement.scrollTop ||
                    document.body.parentNode.scrollTop ||
                    document.body.scrollTop;
                value = value + headeroffset;
                return value;
            };

            const scrollToValue = (value, headeroffset) => {
                value = value - headeroffset;
                document.documentElement.scrollTop = value;
                document.body.parentNode.scrollTop = value;
                document.body.scrollTop = value;
            };

            const animateScroll = () => {
                currentTime += timeIncrement;

                const val = easeInOutCubic(
                    currentTime,
                    startPosition,
                    distance,
                    duration
                );

                scrollToValue(val, headeroffset);

                if (currentTime < duration) {
                    setTimeout(animateScroll, timeIncrement);
                } else {
                    callback();
                    return;
                }
            };

            const startPosition = getCurrentPosition(headeroffset);

            const distance = to - startPosition;
            const timeIncrement = 20;
            let currentTime = 0;

            animateScroll();
        },
    },
};

// const easeInOutQuad = (t, b, c, d) => {
//     t /= d / 2;
//     if (t < 1) return c / 2 * t * t + b;
//     t--;
//     return -c / 2 * (t * (t - 2) - 1) + b;
// };

const easeInOutCubic = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
};
