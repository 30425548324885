<template>
    <div class="how-to-buy-page">
        <h1>{{ $t('page_title_aboutus') }}</h1>
        <breadcrumb>
            <router-link :to="{ name: 'page.about' }">{{
                $t('page_title_aboutus')
            }}</router-link>
            / {{ $t('page_title_howtobuy') }}
        </breadcrumb>

        <about-us-tabs></about-us-tabs>

        <div class="reading">
            <h2>{{ $t('page_title_howtobuy') }}</h2>
            <div v-html="$t('page_content_howtobuy')"></div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import AboutUsTabs from '../components/AboutUsTabs.vue';

export default {
    name: 'HowToBuy',
    components: {
        AboutUsTabs,
        Breadcrumb,
    },
};
</script>

<style scoped></style>
