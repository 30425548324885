<template>
    <div id="account-header">
        <h1>{{ $t('hello') }}, {{ fullname }}</h1>
    </div>
</template>

<script>
export default {
    computed: {
        fullname() {
            return this.$store.getters['account/fullname'];
        },
    },
};
</script>

<style></style>
