<template>
    <div class="loader" :class="{ hidden: !visible }">
        <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#1c9cd7"
            ref="svg"
        >
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle
                        stroke-opacity="0.5"
                        cx="18"
                        cy="18"
                        r="18"
                    ></circle>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </path>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { LOADER_TIMEOUT } from '../config.js';

export default {
    name: 'Loader',
    data() {
        return {
            visible: false,
            currentTO: null,
        };
    },
    methods: {
        show: function() {
            clearTimeout(this.currentTO);
            this.currentTO = setTimeout(() => {
                this.visible = true;
            }, LOADER_TIMEOUT);
        },
        hide: function() {
            clearTimeout(this.currentTO);
            this.visible = false;
        },
    },
};
</script>

<style lang="scss">
.loader {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    padding: $pad-lg;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 10px 0 white;
    width: 100%;
    text-align: center;
}

.hidden {
    display: none;
}
</style>
