<template>
    <div class="pagination" :class="{ 'pagination-table-footer': tableFooter }">
        <template v-if="tableFooter">
            <div class="table-footer">
                <div>
                    <slot></slot>
                </div>
                <ul v-if="hasPerPageSelect" class="entries-per-page">
                    <li class="text">{{ $t('show') }}</li>
                    <li
                        v-for="item in perPageAllowed"
                        v-bind:key="item"
                        :class="{ active: perPage === item }"
                    >
                        <a
                            v-on:click="
                                perPage = item;
                                handleClick(true);
                            "
                            >{{ item }}</a
                        >
                    </li>
                    <li class="text">{{ $t('results_per_page') }}</li>
                </ul>
            </div>
            <div class="pages">
                <ul class="pages-pagination">
                    <li :class="{ disabled: page === 1 }">
                        <a
                            :href="firstLink"
                            v-on:click.prevent="
                                page = 1;
                                handleClick();
                            "
                        >
                            <i class="fas fa-step-forward fa-rotate-180"></i>
                        </a>
                    </li>
                    <li :class="{ disabled: page === 1 }">
                        <a
                            :href="prevLink"
                            v-on:click.prevent="
                                page--;
                                handleClick();
                            "
                        >
                            <i class="fas fa-play fa-rotate-180"></i>
                        </a>
                    </li>
                    <li v-if="maxPages">
                        {{ $t('pagination_x_of_y', { 0: page, 1: maxPages }) }}
                    </li>
                    <li v-else>{{ $t('pagination_x', { 0: page }) }}</li>
                    <li :class="{ disabled: page === maxPages }">
                        <a
                            :href="nextLink"
                            v-on:click.prevent="
                                page++;
                                handleClick();
                            "
                        >
                            <i class="fas fa-play"></i>
                        </a>
                    </li>
                    <li :class="{ disabled: page === maxPages }">
                        <a
                            :href="lastLink"
                            v-on:click.prevent="
                                page = maxPages;
                                handleClick();
                            "
                        >
                            <i class="fas fa-step-forward"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </template>
        <template v-else>
            <div>
                <slot></slot>
            </div>
            <ul class="pages-pagination">
                <li :class="{ disabled: page === 1 }">
                    <a
                        :href="firstLink"
                        v-on:click.prevent="
                            page = 1;
                            handleClick();
                        "
                    >
                        <i class="fas fa-step-forward fa-rotate-180"></i>
                    </a>
                </li>
                <li :class="{ disabled: page === 1 }">
                    <a
                        :href="prevLink"
                        v-on:click.prevent="
                            page--;
                            handleClick();
                        "
                    >
                        <i class="fas fa-play fa-rotate-180"></i>
                    </a>
                </li>
                <li v-if="maxPages">
                    {{ $t('pagination_x_of_y', { 0: page, 1: maxPages }) }}
                </li>
                <li v-else>{{ $t('pagination_x', { 0: page }) }}</li>
                <li :class="{ disabled: page === maxPages }">
                    <a
                        :href="nextLink"
                        v-on:click.prevent="
                            page++;
                            handleClick();
                        "
                    >
                        <i class="fas fa-play"></i>
                    </a>
                </li>
                <li :class="{ disabled: page === maxPages }">
                    <a
                        :href="lastLink"
                        v-on:click.prevent="
                            page = maxPages;
                            handleClick();
                        "
                    >
                        <i class="fas fa-step-forward"></i>
                    </a>
                </li>
            </ul>
            <ul v-if="hasPerPageSelect" class="entries-per-page">
                <li class="text">{{ $t('show') }}</li>
                <li
                    v-for="item in perPageAllowed"
                    v-bind:key="item"
                    :class="{ active: perPage === item }"
                >
                    <a
                        v-on:click="
                            perPage = item;
                            handleClick(true);
                        "
                        >{{ item }}</a
                    >
                </li>
                <li class="text">{{ $t('results_per_page') }}</li>
            </ul>
        </template>
    </div>
</template>

<script>
import { PAGINATION_DEFAULTS } from '../config.js';

export default {
    name: 'Pagination',
    props: {
        hasPerPageSelect: {
            type: Boolean,
            required: false,
            default: true,
        },
        tableFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            page: null,
            maxPages: null,
            perPage: null,
            perPageAllowed: PAGINATION_DEFAULTS.PER_PAGE_ALLOWED,
        };
    },
    computed: {
        pageDefault() {
            return this.$route.query.page || PAGINATION_DEFAULTS.START_PAGE;
        },
        perPageDefault() {
            return this.$route.query.page || PAGINATION_DEFAULTS.PER_PAGE;
        },
        firstLink() {
            return this.parseRoutePath(1, this.perPage);
        },
        prevLink() {
            return this.parseRoutePath(this.page - 1, this.perPage);
        },
        nextLink() {
            return this.parseRoutePath(this.page + 1, this.perPage);
        },
        lastLink() {
            return this.parseRoutePath(this.maxPages, this.perPage);
        },
    },
    methods: {
        parseRoutePath(page, perPage) {
            const pageRegex = /\/page\/\d+/;
            const perPageRegex = /\/perPage\/\d+/;
            const pageReplaced = this.$route.path.replace(
                pageRegex,
                `/page/${page}`
            );
            const perPageReplaced = pageReplaced.replace(
                perPageRegex,
                `/perPage/${perPage}`
            );
            return perPageReplaced;
        },
        changePage() {
            this.page = this.$route.params.page;
            this.perPage = this.$route.params.perPage;
        },
        responseHelper(response) {
            this.maxPages = response.data.total_pages;

            return response;
        },
        checkPageData() {
            if (this.page < 1) {
                this.page = 1;
            }

            if (this.maxPages && this.page > this.maxPages) {
                this.page = this.maxPages;
            }
        },
        handleClick(resetPage) {
            this.checkPageData();

            const params = this.$route.params;
            const query = this.$route.query;

            if (resetPage) {
                params.page = 1;
            } else {
                params.page = this.page;
            }

            params.perPage = this.perPage;

            this.$router.push({ params, query });
            this.$emit('pagination-clicked');
        },
        handleChange() {
            this.$emit('page-change');
        },
    },
    mounted() {
        this.page = this.pageDefault;
        this.perPage = this.perPageDefault;

        if (this.$route.params.page) {
            this.page = Number(this.$route.params.page);
        }

        if (this.$route.params.perPage) {
            let perPage = Number(this.$route.params.perPage);

            if (this.perPageAllowed.indexOf(perPage) === -1) {
                perPage = this.perPageAllowed[0];
            }

            this.perPage = perPage;
        }
    },
    watch: {
        page() {
            this.handleChange();
        },
        maxPages() {
            this.checkPageData();
        },
        perPage() {
            this.handleChange();
        },
        $route() {
            this.changePage();
            this.$emit('pagination-changed');
        },
    },
};
</script>

<style lang="scss">
.pagination {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    &-table-footer {
        padding: 0;
        display: block;

        & > * {
            width: 100%;
        }
    }
}

.pages-pagination {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    @media (max-width: $sm-size-max) {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    li {
        display: inline-block;
        padding: 0 $pad-sm;

        &:last-child {
            padding-right: 0;

            a {
                padding-right: 0;
            }
        }

        a {
            display: inline-block;
            padding: $pad-sm;
            text-decoration: none;

            i {
                margin: 0;
            }
        }

        &.disabled,
        &.disabled a {
            color: $disabled-color;
            cursor: default;
        }
    }
}

.entries-per-page {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    li {
        display: inline-block;

        a {
            display: inline-block;
            padding: 8px 2px;
        }

        &.active {
            color: $main-color;
            font-weight: 600;
        }

        &:before {
            margin: 0 5px;
            content: '';
            color: $text-color;
            font-weight: 400;
        }

        &:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) {
            &:before {
                content: '|';
                display: inline-block;
            }
        }
    }
}

.table-footer {
    background: $grey-bg;
    display: flex;
    justify-content: space-between;

    select {
        margin: 1px;
    }

    .entries-per-page {
        margin: 0 $mar-lg;
    }
}
</style>
