<template>
    <div @click="scrollTo({ to: 0 })" class="scroll-top">
        <i class="fas fa-angle-up"></i>
    </div>
</template>

<script>
export default {
    name: 'ScrollTopButton',
};
</script>

<style scoped lang="scss">
.scroll-top {
    cursor: pointer;
    position: fixed;
    bottom: 5%;
    right: 0;
    padding: 5px 10px;
    background: $main-color;
    color: white;
}
</style>
