<template>
    <div class="about-us-tabs">
        <span>
            <router-link
                v-if="$route.name !== 'page.help'"
                :to="{ name: 'page.help' }"
                >{{ $t('page_title_help') }}</router-link
            >
            <span v-else>{{ $t('page_title_help') }}</span>
        </span>
        <span>|</span>
        <span>
            <router-link
                v-if="$route.name !== 'page.howToBuy'"
                :to="{ name: 'page.howToBuy' }"
                >{{ $t('page_title_howtobuy') }}</router-link
            >
            <span v-else>{{ $t('page_title_howtobuy') }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'aboutUsTabs',
};
</script>

<style scoped lang="scss">
.about-us-tabs {
    list-style: none inside;
    padding: 0;
    margin: 0;

    margin-top: $mar-xl;

    & > span {
        display: inline-block;
        padding: 2px $pad-md 2px 0;
    }
}
</style>
