<template>
    <transition :name="transition">
        <div v-show="active" :style="{ width: `${width}` }" class="slide">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'slide',
    data() {
        return {
            active: false,
            transition: '',
            width: 'auto',
        };
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener('resize', this.calculateWidth);

            //Init
            this.calculateWidth();
        });
    },
    methods: {
        hide(direction) {
            this.transition = `carousel--transition-${direction}`;
            this.active = false;
        },
        show(direction) {
            this.transition = `carousel--transition-${direction}`;
            this.active = true;
        },
        calculateWidth() {
            this.resetWidth();

            // timeout is needed to let the component update with the reset value
            setTimeout(() => {
                this.setNewWidth();
            }, 100);
        },
        resetWidth() {
            this.width = 'auto';
        },
        setNewWidth() {
            this.width =
                this.$el.closest('.carousel').getBoundingClientRect().width +
                'px';
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateWidth);
    },
};
</script>

<style lang="scss"></style>
