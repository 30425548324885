import Pages from './Pages';
import { AUTH_CLIENT_ID, AUTH_CLIENT_SECRET, AUTH_URL, PAGINATION_DEFAULTS } from './config';
import Authorization from './lib/Authorization';

const isLoggedIn = (to, from, next) => {
    // check if tokenData is available    
    if (Authorization.isLoggedIn()) {
        next();
    } else {
        next({ path: '/' + to.params.lang + '/login' });
    }
};

export default [
    {
        path: '/:lang',
        component: Pages.Home,
        name: 'page.home',
    },
    // guarded routes, can only be accessed when logged in
    {
        path: 'account/profile',
        component: Pages.AccountProfile,
        name: 'page.accountProfile',
        beforeEnter: isLoggedIn,
    },
    {
        path: 'account/bids/page/:page/perPage/:perPage',
        component: Pages.AccountBids,
        name: 'page.accountBids',
        beforeEnter: isLoggedIn,
    },
    {
        path: 'account/watchlist/page/:page/perPage/:perPage',
        component: Pages.AccountWatchlist,
        name: 'page.accountWatchlist',
        beforeEnter: isLoggedIn,
    },
    {
        path: 'account/buy-now/page/:page/perPage/:perPage',
        component: Pages.AccountBuyNow,
        name: 'page.accountBuyNow',
        beforeEnter: isLoggedIn,
    },
    {
        path: 'account/notifications/page/:page/perPage/:perPage',
        component: Pages.AccountNotifications,
        name: 'page.accountNotifications',
        beforeEnter: isLoggedIn,
    },
    // -----------------------
    {
        path: 'about',
        component: Pages.AboutUs,
        name: 'page.about',
    },
    {
        path: 'help',
        component: Pages.Help,
        name: 'page.help',
    },
    {
        path: 'how-to-buy',
        component: Pages.HowToBuy,
        name: 'page.howToBuy',
    },
    {
        path: 'imprint',
        component: Pages.Imprint,
        name: 'page.imprint',
    },
    {
        path: 'privacy',
        component: Pages.Privacy,
        name: 'page.privacy',
    },
    {
        path: 'terms-and-conditions',
        component: Pages.TermsAndConditions,
        name: 'page.termsAndConditions',
    },
    {
        path: 'contact',
        component: Pages.Contact,
        name: 'page.contact',
    },
    {
        path: 'companies',
        component: Pages.CompanyList,
        name: 'page.companyList',
    },
    {
        path: 'lots/page/:page/perPage/:perPage',
        component: Pages.LotList,
        name: 'page.lots',
    },
    {
        path: 'lots/:companySlug/page/:page/perPage/:perPage',
        component: Pages.LotList,
        name: 'page.companyLots',
    },
    {
        path: 'companies/:companySlug',
        component: Pages.CompanyDetail,
        name: 'page.companyDetail',
    },
    {
        path: 'auctions/calendar',
        component: Pages.AuctionCalendar,
        name: 'page.auctionCalendar',
    },
    {
        path: 'auctions/past/page/:page/perPage/:perPage',
        component: Pages.AuctionListPast,
        name: 'page.auctionListPast',
    },
    {
        path: 'auctions/:companySlug/past/page/:page/perPage/:perPage',
        component: Pages.AuctionListPast,
        name: 'page.auctionListPastCompany',
    },
    {
        path: 'collecting/:collectionAreaSlug',
        component: Pages.CollectionArea,
        name: 'page.collectionArea',
    },
    {
        path: 'best-coins',
        component: Pages.BestCoinsAll,
        name: 'page.bestCoinsAll',
    },
    {
        path: 'highlight',
        component: Pages.HighlightAll,
        name: 'page.highlightAll',
    },
    {
        path: 'highlight-special',
        component: Pages.HighlightSpecialAll,
        name: 'page.highlightSpecialAll',
    },
    {
        path: ':companySlug/:auctionId',
        component: Pages.AuctionLotList,
        name: 'page.auctionLotListBasic',
    },
    {
        path: ':companySlug/:auctionId/information',
        component: Pages.AuctionInformation,
        name: 'page.auctionInformation',
    },
    {
        path: ':companySlug/:auctionId/conditions',
        component: Pages.AuctionConditions,
        name: 'page.auctionConditions',
    },
    {
        path: ':companySlug/:auctionId/livebidding',
        component: Pages.AuctionLivebidding,
        name: 'page.auctionLivebidding',
    },
    {
        path: ':companySlug/:auctionId/more',
        component: Pages.AuctionMore,
        name: 'page.auctionMore',
    },
    {
        path: ':companySlug/:auctionId/bidSheet',
        component: Pages.BidSheet,
        name: 'page.bidSheet',
    },
    {
        path: ':companySlug/:auctionId/results',
        component: Pages.AuctionResults,
        name: 'page.auctionResults',
    },
    {
        path: ':companySlug/:auctionId/page/:page/perPage/:perPage',
        component: Pages.AuctionLotList,
        name: 'page.auctionLotList',
    },
    {
        path: ':companySlug/:auctionId/:categorySlug/:lotId/:lotSlug',
        component: Pages.AuctionLotDetail,
        name: 'page.auctionLotDetail',
    },
    {
        path: 'archive/:companySlug/:auctionId/:categorySlug/:lotId/:lotSlug',
        component: Pages.ArchiveAuctionLotDetail,
        name: 'page.archiveAuctionLotDetail',
    },
    {
        path: '/:lang/404',
        component: Pages.NotFound,
        name: 'page.NotFound',
    },
    // REDIRECT HANLDERS
    // task handling (redirects to login with additional task data)
    {
        path: '/:lang/task',
        redirect: (to) => {
            Authorization.removeTokenData();

            const { args, component, fn, path, redirect } = to.query;
            const redirectUrl = new URL(redirect, window.location.origin);
            // we need to prefix this, so that the router will not remove them
            redirectUrl.searchParams.append('task_args', args);
            redirectUrl.searchParams.append('task_component', component);
            redirectUrl.searchParams.append('task_fn', fn);
            redirectUrl.searchParams.append('task_path', path);

            window.location.href = `${AUTH_URL}/${to.params.lang}/web/login?client_id=${AUTH_CLIENT_ID}&client_secret=${AUTH_CLIENT_SECRET}&redirect=${window.encodeURIComponent(redirectUrl.href)}`;
        },
        name: 'redirect.task',
    },
    // default login redirect
    // will redirect to the account bids page
    {
        path: '/:lang/login',
        redirect: (to) => {
            Authorization.removeTokenData();
            const route = `${to.params.lang}/account/bids/page/${PAGINATION_DEFAULTS.START_PAGE}/perPage/${PAGINATION_DEFAULTS.PER_PAGE}`
            const accountBidsRedirect = new URL(route, window.location.origin);
            // redirect to the account bids page
            let redirectUrl = window.encodeURIComponent(accountBidsRedirect.href);
            // if there is a redirect query param, use that instead
            if (to.query.redirect) {
                redirectUrl = window.encodeURIComponent(to.query.redirect);
            }
            window.location.href = `${AUTH_URL}/${to.params.lang}/web/login?client_id=${AUTH_CLIENT_ID}&client_secret=${AUTH_CLIENT_SECRET}&redirect=${redirectUrl}`;
        },
        name: 'redirect.login'
    },
    // register redirect
    // will redirect to the account bids page
    {
        path: '/:lang/register',
        redirect: (to) => {
            Authorization.removeTokenData();
            const route = `${to.params.lang}/account/bids/page/${PAGINATION_DEFAULTS.START_PAGE}/perPage/${PAGINATION_DEFAULTS.PER_PAGE}`
            const accountBidsRedirect = new URL(route, window.location.origin);
            window.location.href = `${AUTH_URL}/${to.params.lang}/web/signup?client_id=${AUTH_CLIENT_ID}&client_secret=${AUTH_CLIENT_SECRET}&redirect=${window.encodeURIComponent(accountBidsRedirect)}`;
        },
        name: 'redirect.register'
    },
    // logout redirect
    // will redirect to the home page
    // appends the current access_token
    {
        path: '/:lang/logout',
        redirect: (to) => {
            const { access_token } = Authorization.getTokenData();
            Authorization.removeTokenData();
            const route = `${to.params.lang}/`
            const homeRedirect = new URL(route, window.location.origin).href;
            window.location.href = `${AUTH_URL}/${to.params.lang}/web/session/logout?access_token=${access_token}&client_id=${AUTH_CLIENT_ID}&client_secret=${AUTH_CLIENT_SECRET}&redirect=${homeRedirect}`;
        },
        name: 'redirect.logout'
    },
    // catch all the rest
    {
        path: '*',
        redirect: '/:lang/404',
    },
];
