<template>
    <div>
        <h1>{{ $t('privacy') }}</h1>
        <breadcrumb>{{ $t('privacy') }}</breadcrumb>

        <div class="reading">
            <div v-html="$t('page_content_privacy')"></div>
            <div id="cmpcookieinfo"></div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';

export default {
    name: 'Privacy',
    components: {
        Breadcrumb,
    },
    mounted() {
        let consentManagerScript = document.createElement('script');
        consentManagerScript.setAttribute(
            'src',
            'https://consentmanager.mgr.consensu.org/delivery/cookieinfo.php?id=13057'
        );
        document.head.appendChild(consentManagerScript);
    },
};
</script>
