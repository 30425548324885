export default {
    computed: {
        bidSheets() {
            return this.$global.bidSheets;
        },
    },
    methods: {
        removeBid(lotId, auctionId) {
            const { bidSheets, currentBids } = this.load(auctionId);

            bidSheets[auctionId] = currentBids.filter(
                bid => bid.lotId !== lotId
            );

            this.save(bidSheets);
        },
        storeBid(lotBid, auctionId) {
            let { bidSheets, currentBids } = this.load(auctionId);

            if (typeof currentBids !== 'undefined') {
                currentBids.forEach((bid, index) => {
                    if (bid.lotId === lotBid.lotId) {
                        currentBids.splice(index, 1);
                    }
                });
                currentBids.push(lotBid);
            } else {
                currentBids = [lotBid];
            }

            bidSheets[auctionId] = currentBids;
            this.save(bidSheets);
        },
        updateBid({ lotId, bid, increase, auctionId }) {
            const { bidSheets, currentBids } = this.load(auctionId);

            bidSheets[auctionId] = currentBids.map(currentBid => {
                if (currentBid.lotId === lotId) {
                    currentBid = { ...currentBid, bid, increase };
                }
                return currentBid;
            });

            this.save(bidSheets);
        },
        removeBidSheetFromStorage(auctionId) {
            const { bidSheets } = this.load(auctionId);
            delete bidSheets[this.auctionId];
            this.save(bidSheets);
        },
        removeAllBidSheetsFromStorage() {
            localStorage.removeItem('bidSheets');
        },
        getBidSheetsFromStorage() {
            const bidSheets = JSON.parse(localStorage.getItem('bidSheets'));
            this.$global.bidSheets = bidSheets;
        },
        save(bidSheets) {
            this.$global.bidSheets = bidSheets;
            localStorage.setItem('bidSheets', JSON.stringify(bidSheets));
        },
        load(auctionId) {
            const bidSheets =
                JSON.parse(localStorage.getItem('bidSheets')) || {};
            let currentBids;

            if (bidSheets !== null) {
                currentBids = bidSheets[auctionId];
            } else {
                currentBids = [];
            }

            return { bidSheets, currentBids };
        },
    },
};
