const initialState = () => {
    let loggedIn = false;
    let fullname = '';
    let userData = localStorage.getItem('userData');
    if (userData !== null) {
        userData = JSON.parse(userData);
        loggedIn = true;
        fullname = userData.fullname;
    }
    return {
        loggedIn: loggedIn,
        fullname: fullname,
        userData: userData,
    };
};

const state = initialState();

const getters = {
    loggedIn(state) {
        return state.loggedIn;
    },
    fullname(state) {
        return state.fullname;
    },
    userData(state) {
        return state.userData;
    },
};

const mutations = {
    loginSuccess(state) {
        state.loggedIn = true;
        localStorage.setItem(
            'userData',
            JSON.stringify({
                fullname: '',
            })
        );
    },
    logout(state) {
        state.loggedIn = false;
        state.fullname = '';
        localStorage.removeItem('userData');
    },
    setFullname(state, fullname) {
        state.fullname = fullname;
    },
};

const actions = {
    login({ commit }) {
        commit('loginSuccess');
    },
    logout({ commit }) {
        commit('logout');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
