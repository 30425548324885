<template>
    <a :class="`sixbid-slot ${getClass}`" :href="adLink" target="_blank"
        ><img
            loading="lazy"
            alt="ad-banner"
            :width="imageWidth"
            :height="imageHeight"
            :src="`${route}/banner/${ad.banner_file.split('|')[1]}`"
            :srcset="
                `${route}/banner/${
                    ad.banner_file.split('|')[1]
                } 1x, ${route}/banner/${ad.banner_file.split('|')[0]} 2x`
            "
    /></a>
</template>

<script>
export default {
    name: 'AdBanner',
    props: {
        ad: {
            type: Object,
            required: true,
        },
        visible: {
            type: Array,
            required: false,
        },
    },
    computed: {
        adLink() {
            let links = this.ad.banner_link;
            let link = '';

            if (typeof links == 'string') {
                link = links;
            } else if (typeof links == 'object') {
                let numlinks = links.length;

                if (numlinks > 0) {
                    let currlink = 0;

                    // URL Rotation every x Minutes
                    const minutesPerURL = 60;

                    currlink =
                        Math.floor(Date.now() / (60000 * minutesPerURL)) %
                        numlinks;

                    //link = currlink;
                    link = links[currlink];
                }
            }

            if (link != '' && this.ad.banner_utm_campaign) {
                if (link.indexOf('?') > -1) {
                    link += '&utm_campaign=' + this.ad.banner_utm_campaign;
                } else {
                    link += '?utm_campaign=' + this.ad.banner_utm_campaign;
                }
            }

            return link;
        },
        getClass() {
            var classesString = '';

            //Check if visible parameter was set and is an array
            if (
                typeof this.visible === 'object' &&
                typeof this.visible !== 'undefined'
            ) {
                //If is set and array push every visible child to class string
                this.visible.forEach(item => {
                    classesString += ' visible-' + item;
                });
            } else {
                //If not set class string is only 'visible'
                classesString = 'visible';
            }

            //Add Banner position to class string
            classesString += ' position-' + this.ad.banner_place;

            return classesString;
        },
        imageWidth() {
            // assuming '408/en/1170x195.jpg|408/en/2340x390.jpg'
            try {
                
                return this.ad.banner_file.split('|')[0].split('/').slice(-1)[0].split('.')[0].split('x')[0];
            } catch {
                return null
            }
        },
        imageHeight() {
            // assuming '408/en/1170x195.jpg|408/en/2340x390.jpg'
            try {
                return this.ad.banner_file.split('|')[0].split('/').slice(-1)[0].split('.')[0].split('x')[1];
            } catch {
                return null
            }
        }
    },
    data() {
        return {
            route: process.env.VUE_APP_ADS_API_URL,
        };
    },
};
</script>

<style scoped lang="scss">
.sixbid-slot {
    display: none;

    &.position-frontpage-top-banner-wide,
    &.position-companies-top-banner-wide,
    &.position-ended-auctions-top-banner-wide,
    &.position-calendar-top-banner-wide {
        img {
            padding: 0 2px;
        }

        margin: -20px 0 11px 0;
    }

    &.position-frontpage-top-banner-medium,
    &.position-companies-top-banner-medium,
    &.position-ended-auctions-top-banner-medium,
    &.position-calendar-top-banner-medium {
        img {
            padding: 0 1px;
        }

        margin: -17px -16px 11px -16px;
    }

    &.position-frontpage-top-banner-narrow,
    &.position-companies-top-banner-narrow,
    &.position-ended-auctions-top-banner-narrow,
    &.position-calendar-top-banner-narrow {
        margin: -17px -16px 11px -16px;
    }

    img {
        width: 100%;
    }

    &.visible {
        display: block;
    }

    &.visible-mobile {
        @media (max-width: 414px) {
            display: block;
            img {
                max-height: 120px;
            }
        }
    }
    &.visible-tablet {
        @media (min-width: 415px) and (max-width: 767px) {
            display: block;
            img {
                max-height: 150px;
            }
        }
    }
    &.visible-desktop {
        @media (min-width: 768px) {
            display: block;
            img {
                max-height: 195px;
            }
        }
    }
}
</style>
