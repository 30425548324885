import { postLotToWatchlist, deleteLotFromWatchlist } from '../lib/apis';

export default {
    namespaced: true,
    state: {
        ids: [],
    },
    getters: {
        isBookmarked: state => lotId => {
            return state.ids.indexOf(lotId) != -1;
        },
    },
    mutations: {
        setWatches(state, lotIds) {
            state.ids = lotIds.map(lotId => Number(lotId));
        },
        addWatch(state, lotId) {
            state.ids.push(Number(lotId));
        },
        removeWatch(state, lotId) {
            state.ids = state.ids.filter(function(val) {
                return val != lotId;
            });
        },
    },
    actions: {
        toggleWatch({ commit, state }, lotId) {
            if (state.ids.indexOf(lotId) == -1) {
                // Add watch
                return postLotToWatchlist({ lotId })
                    .then(response => {
                        commit('addWatch', lotId);
                        return response;
                    })
                    .catch(error => {
                        throw error;
                    });
            } else {
                // Remove Watch
                return deleteLotFromWatchlist({ lotId })
                    .then(response => {
                        commit('removeWatch', lotId);
                        return response;
                    })
                    .catch((error) => {
                        throw error;
                    });
            }
        },
    },
};
