<template>
    <div class="account-page" id="account-watchlist-page">
        <div class="row">
            <account-navigation />
            <div class="col col-md-12 col-lg-12">
                <div class="col-wrapper">
                    <account-header />
                    <h1>{{ $t('profile') }}</h1>

                    <form
                        @submit.prevent="submitProfile"
                        class="form grid"
                        novalidate
                    >
                        <fieldset>
                            <legend>{{ $t('personal_information') }}</legend>
                            <div class="row">
                                <div class="col col-sm-6 col-lg-3">
                                    <select
                                        v-model="salutation"
                                        :class="{
                                            error: profileFeedback.errors
                                                .user_title,
                                        }"
                                        required
                                    >
                                        <option value disabled>
                                            {{ $t('title') }}
                                        </option>
                                        <option value="">---</option>
                                        <option value="dr">
                                            {{ $t('form_user_dr') }}
                                        </option>
                                        <option value="prof">
                                            {{ $t('form_user_prof') }}
                                        </option>
                                        <option value="profDr">
                                            {{ $t('form_user_profDr') }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-sm-6 col-lg-3">
                                    <input
                                        type="text"
                                        v-model="firstname"
                                        v-bind:placeholder="
                                            $t('form_user_first_name')
                                        "
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_first_name,
                                        }"
                                        required
                                    />
                                </div>
                                <div class="col col-sm-6 col-lg-3">
                                    <input
                                        type="text"
                                        v-model="lastname"
                                        v-bind:placeholder="
                                            $t('form_user_last_name')
                                        "
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_last_name,
                                        }"
                                        required
                                    />
                                </div>
                                <div
                                    class="col col-lg-5 push-lg-1"
                                    v-if="
                                        profileFeedback.errors
                                            .user_first_name ||
                                        profileFeedback.errors.user_last_name
                                    "
                                >
                                    <span class="alert alert--error">
                                        <i
                                            class="fas fa-exclamation-circle"
                                        ></i>
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_first_name
                                            )
                                        }}
                                        <span
                                            v-if="
                                                profileFeedback.errors
                                                    .user_first_name &&
                                                profileFeedback.errors
                                                    .user_last_name
                                            "
                                            >,</span
                                        >
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_last_name
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col col-sm-6 col-lg-3">
                                    <input
                                        type="text"
                                        v-model="company"
                                        v-bind:placeholder="
                                            $t('form_user_company')
                                        "
                                        :class="{
                                            error: profileFeedback.errors
                                                .user_company,
                                        }"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ $t('contact_information') }}</legend>

                            <div class="row">
                                <div class="col col-sm-6 col-lg-3">
                                    <input
                                        type="text"
                                        v-model="email"
                                        v-bind:placeholder="$t('email')"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_email,
                                        }"
                                        required
                                    />
                                </div>
                                <div
                                    class="col col-sm-6 col-lg-5 push-lg-4"
                                    v-if="profileFeedback.errors.user_email"
                                >
                                    <span class="alert alert--error">
                                        <i
                                            class="fas fa-exclamation-circle"
                                        ></i>
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_email
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-sm-6 col-lg-3">
                                    <input
                                        type="text"
                                        v-model="phone"
                                        v-bind:placeholder="$t('telephone')"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_phone,
                                        }"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ $t('language_select') }}</legend>
                            <div class="row">
                                <div class="col col-sm-6">
                                    <select
                                        v-model="language"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_language,
                                        }"
                                        required
                                    >
                                        <option disabled value>
                                            {{ $t('display_language') }}
                                        </option>
                                        <option
                                            v-for="l in languages"
                                            :value="l.code"
                                            :key="l.code"
                                        >
                                            {{ $t(l.name) }} -
                                            {{ l.originalName }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ $t('address_details') }}</legend>
                            <div class="row">
                                <div class="col col-sm-6">
                                    <input
                                        type="text"
                                        v-model="street"
                                        v-bind:placeholder="$t('street')"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_address,
                                        }"
                                        required
                                    />
                                </div>
                                <div class="col col-sm-6 col-lg-5 push-lg-1">
                                    <span
                                        class="alert alert--error"
                                        v-if="
                                            profileFeedback.errors.user_address
                                        "
                                    >
                                        <i
                                            class="fas fa-exclamation-circle"
                                        ></i>
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_address
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-sm-6">
                                    <input
                                        type="text"
                                        v-model="street_2"
                                        v-bind:placeholder="$t('street_2')"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_address2,
                                        }"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-sm-2 col-lg-1">
                                    <input
                                        type="text"
                                        v-model="zip"
                                        v-bind:placeholder="$t('form_user_zip')"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors.user_zip,
                                        }"
                                        required
                                    />
                                </div>
                                <div class="col col-sm-4 col-lg-5">
                                    <input
                                        type="text"
                                        v-model="city"
                                        v-bind:placeholder="$t('city')"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_city,
                                        }"
                                        required
                                    />
                                </div>
                                <div class="col col-sm-6 col-lg-5 push-lg-1">
                                    <span
                                        class="alert alert--error"
                                        v-if="
                                            profileFeedback.errors.user_zip ||
                                            profileFeedback.errors.user_city
                                        "
                                    >
                                        <i
                                            class="fas fa-exclamation-circle"
                                        ></i>
                                        {{
                                            $t(profileFeedback.errors.user_zip)
                                        }}
                                        <span
                                            v-if="
                                                profileFeedback.errors
                                                    .user_zip &&
                                                profileFeedback.errors
                                                    .user_last_name
                                            "
                                            >,</span
                                        >
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_last_name
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-sm-6 col-lg-3">
                                    <select
                                        v-model="country"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_country,
                                        }"
                                        required
                                    >
                                        <option disabled value>
                                            {{ $t('country') }}
                                        </option>
                                        <option
                                            v-for="c in countries"
                                            :value="c.code"
                                            :key="c.code"
                                        >
                                            {{ c.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col col-sm-6 col-lg-3">
                                    <select
                                        v-model="state"
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_state,
                                        }"
                                        v-show="
                                            country === 'USA' ||
                                            country === 'CAN'
                                        "
                                        required
                                    >
                                        <option disabled value>
                                            {{ $t('state') }}
                                        </option>
                                        <option
                                            v-for="s in states"
                                            :value="s.code"
                                            :key="s.code"
                                        >
                                            {{ s.name }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="
                                        col
                                        push-sm-6
                                        col-sm-6 col-lg-5
                                        push-lg-1
                                    "
                                    v-if="
                                        profileFeedback.errors.user_country ||
                                        profileFeedback.errors.user_state
                                    "
                                >
                                    <span class="alert alert--error">
                                        <i
                                            class="fas fa-exclamation-circle"
                                        ></i>
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_country
                                            )
                                        }}
                                        <span
                                            v-if="
                                                profileFeedback.errors
                                                    .user_country &&
                                                profileFeedback.errors
                                                    .user_last_name
                                            "
                                            >,</span
                                        >
                                        {{
                                            $t(
                                                profileFeedback.errors
                                                    .user_last_name
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>{{ $t('reference') }}</legend>
                            <div class="row">
                                <div class="col col-lg-6">
                                    <textarea
                                        v-model="reference"
                                        v-bind:placeholder="
                                            $t('reference_info')
                                        "
                                        :class="{
                                            hasError:
                                                profileFeedback.errors
                                                    .user_reference,
                                        }"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div class="row" v-if="profileFeedback.message">
                            <div class="col col-lg-6">
                                <div
                                    class="alert"
                                    :class="`alert--${profileFeedback.type}`"
                                >
                                    <i class="fas fa-check-circle"></i>
                                    {{ $t(profileFeedback.message) }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="
                                    col col-md-6
                                    push-md-6
                                    col-lg-3
                                    push-lg-3
                                "
                            >
                                <button
                                    class="button button-blue"
                                    type="submit"
                                >
                                    {{ $t('profile_submit') }}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="row">
                        <div class="col col-lg-6">
                            <hr class="separator" />
                        </div>
                    </div>

                    <div>
                        <h3>{{ $t('password') }}</h3>
                        <p>
                            {{ $t('password_choose') }}
                        </p>
                        <ul class="password-sanity-checklist">
                            <li>
                                <i
                                    v-if="passwordSanityCheck.validLength"
                                    class="fas fa-check fg-green"
                                />
                                <i v-else class="fas fa-times fg-red" />
                                <span>{{ $t('password_min') }}</span>
                            </li>
                            <li>
                                <i
                                    v-if="
                                        passwordSanityCheck.containsSpecialCharacter
                                    "
                                    class="fas fa-check fg-green"
                                />
                                <i v-else class="fas fa-times fg-red" />
                                <span>{{ $t('password_special') }}</span>
                            </li>
                            <li>
                                <i
                                    v-if="passwordSanityCheck.containsNumber"
                                    class="fas fa-check fg-green"
                                />
                                <i v-else class="fas fa-times fg-red" />
                                <span>{{ $t('password_number') }}</span>
                            </li>
                        </ul>
                    </div>

                    <form
                        @submit.prevent="submitPassword"
                        class="form grid pw-form"
                        novalidate
                    >
                        <div class="row">
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="password"
                                    v-model="passwordForm.oldPassword"
                                    v-bind:placeholder="$t('password_old')"
                                />
                            </div>
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="password"
                                    v-model="passwordForm.newPassword"
                                    v-bind:placeholder="$t('password_new')"
                                />
                            </div>
                            <div class="col col-md-4 col-lg-3">
                                <input
                                    type="password"
                                    v-model="passwordForm.newPasswordConfirm"
                                    v-bind:placeholder="
                                        $t('password_new_confirm')
                                    "
                                />
                            </div>
                        </div>

                        <div class="row" v-if="passwordFeedback.message">
                            <div class="col col-lg-6">
                                <div
                                    class="alert"
                                    :class="`alert--${passwordFeedback.type}`"
                                >
                                    <i class="fas fa-check-circle"></i>
                                    {{ $t(passwordFeedback.message) }}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col col-lg-3">
                                <button
                                    type="submit"
                                    class="button button-blue"
                                >
                                    {{ $t('password_submit') }}
                                </button>
                            </div>
                        </div>
                    </form>

                    <datashare-revoke></datashare-revoke>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountHeader from '../components/AccountHeader';
import AccountNavigation from '../components/AccountNavigation';
import DatashareRevoke from '../components/DatashareRevoke';
import { AVAILABLE_LANGUAGES } from '../config';
import Auth from '../lib/Authorization';

export default {
    name: 'Account_Profile',
    components: {
        AccountHeader,
        AccountNavigation,
        DatashareRevoke,
    },
    data: function () {
        return {
            salutation: '',
            firstname: '',
            lastname: '',
            company: '',
            email: '',
            phone: '',
            street: '',
            street_2: '',
            zip: '',
            city: '',
            country: 'de',
            state: '',
            reference: '',
            language: '',
            languages: AVAILABLE_LANGUAGES,
            profileFeedback: {
                type: '',
                message: '',
                errors: {},
            },
            passwordForm: {
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            },
            passwordFeedback: {
                type: '',
                message: '',
            },
            passwordSanityCheck: {
                validLength: false,
                containsSpecialCharacter: false,
                containsNumber: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            countries: 'countries/getCountriesWithPreferred',
            states: 'countries/getStates',
        }),
    },
    methods: {
        showError(e) {
            this.genericApiErrorHandling(e);
        },
        getProfile() {
            Auth.createAxiosClient()
                .then((axios) => {
                    return axios.get(
                        process.env.VUE_APP_USER_PRIVATE_API_URL +
                            '/my-profile',
                        { params: { lang: this.$route.params.lang } }
                    );
                })
                .then((response) => {
                    this.salutation = response.data.user_title;
                    this.firstname = response.data.user_first_name;
                    this.lastname = response.data.user_last_name;
                    this.company = response.data.user_company;
                    this.email = response.data.user_email;
                    this.phone = response.data.user_phone;
                    this.street = response.data.user_address;
                    this.street_2 = response.data.user_address2;
                    this.zip = response.data.user_zip;
                    this.city = response.data.user_city;
                    this.country = response.data.user_country;
                    this.state = response.data.user_state;
                    this.reference = response.data.user_reference;
                    this.language = response.data.user_language;
                })
                .catch(this.showError);
        },
        submitProfile() {
            Auth.createAxiosClient()
                .then((axios) => {
                    let user_state = this.state;
                    if (this.country != 'USA' && this.country != 'CAN') {
                        user_state = '';
                    }
                    return axios.post(
                        process.env.VUE_APP_USER_PRIVATE_API_URL +
                            '/my-profile',
                        {
                            lang: this.$route.params.lang,
                            user_title: this.salutation,
                            user_first_name: this.firstname,
                            user_last_name: this.lastname,
                            user_company: this.company,
                            user_email: this.email,
                            user_phone: this.phone,
                            user_address: this.street,
                            user_address2: this.street_2,
                            user_zip: this.zip,
                            user_city: this.city,
                            user_country: this.country,
                            user_state: user_state,
                            user_reference: this.reference,
                            user_language: this.language,
                        }
                    );
                })
                .then((response) => {
                    if (response.data.success) {
                        this.profileFeedback.type = 'success';
                        this.profileFeedback.message = response.data.message;
                        this.profileFeedback.errors = {};
                        this.$store.commit(
                            'account/setFullname',
                            this.firstname + ' ' + this.lastname
                        );
                    } else {
                        this.profileFeedback.type = 'error';
                        this.profileFeedback.message = response.data.message;
                        this.profileFeedback.errors = response.data.errors;
                    }
                })
                .catch(this.showError);
        },
        submitPassword() {
            if (
                this.passwordForm.newPassword !=
                this.passwordForm.newPasswordConfirm
            ) {
                this.passwordFeedback.type = 'error';
                this.passwordFeedback.message = this.$t('password_mismatch');
            } else {
                Auth.createAxiosClient()
                    .then((axios) => {
                        return axios.post(
                            process.env.VUE_APP_USER_PRIVATE_API_URL +
                                '/password',
                            {
                                oldPassword: this.passwordForm.oldPassword,
                                password: this.passwordForm.newPassword,
                            }
                        );
                    })
                    .then((response) => {
                        if (response.data.success) {
                            this.passwordFeedback.type = 'success';
                            this.passwordFeedback.message =
                                response.data.message;
                        } else {
                            this.passwordFeedback.type = 'error';
                            this.passwordFeedback.message =
                                response.data.message;
                        }
                    })
                    .catch((e) => {
                        this.genericApiErrorHandling(e);
                    });
            }
        },
    },
    created: function () {
        this.getProfile();
    },
    mounted() {
        this.$root.$on('update:language', () => {
            this.$forceUpdate();
        });
    },
    watch: {
        'passwordForm.newPassword': function (newValue) {
            this.passwordSanityCheck.validLength = newValue.length >= 8;
            this.passwordSanityCheck.containsSpecialCharacter =
                !/^[A-Za-z0-9öäüß ]*$/.test(newValue);
            this.passwordSanityCheck.containsNumber = /[0-9]+/.test(newValue);
        },
    },
};
</script>

<style scoped lang="scss">
fieldset {
    @extend .clearfix;
    legend {
        padding-top: $pad-xl;
    }
}
.separator {
    border: 0;
    border-top: 1px solid $grey-bg-dark;
    margin: #{2 * $mar-xl} 0;
}
.fa-check {
    color: green;
}
.fa-times {
    color: red;
}
.password-sanity-checklist {
    padding-left: 0;
    list-style-type: none;
}
.password-sanity-checklist li {
    @extend .clearfix;
    margin-bottom: $mar-lg;
}

.password-sanity-checklist li i {
    display: block;
    width: 20px;
    float: left;
}

.password-sanity-checklist li span {
    display: block;
    float: left;
    width: calc(100% - 20px);
}
</style>
